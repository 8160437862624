import entities from 'config';
import { config as requesterConfig } from 'schemas/requester';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { TLinkEntity } from 'components/ListPage';

type Keys = (typeof entities.invitedRequester.columns)[number];

type RequesterKeys = `requester.${(typeof entities.requester.columns)[number]}`;

export const defaultMobileColumns: Array<Keys | RequesterKeys> = [
  'bahai_inquirerid',
  'requester.bahai_cellphone',
  'requester.bahai_cityid',
];

export const links = {
  requester: entities.requester.columns,
  event: {
    from: 'bahai_event_imsid',
    to: 'bahai_eventid',
    fields: entities.event.columns,
    condition: [],
  },
} as TLinkEntity;

export const columns = [
  ...entities.invitedRequester.columns,
  ...entities.requester.columns.map((column) => `requester.${column}`),
];

export const config: AdditionalConfigType<Keys | 'bahai_eventparticipant_imsid' | RequesterKeys> = {
  bahai_inquirerid: {
    searchable: true,
  },
  bahai_eventid: {
    hiddenForTable: true,
  },
  ...Object.fromEntries(
    Object.entries(requesterConfig).map(([key, props]) => ['requester.' + key, { ...props, searchable: false }])
  ),
  'requester.bahai_name': {
    hiddenForTable: true,
  },
  'requester.bahai_birthcountryid': {
    hiddenForTable: true,
  },
  ...Object.fromEntries(entities.event.columns.map((key) => ['event.' + key, { hiddenForTable: true }])),
};

export const getFormConfig: FormConfigGetter<Keys> = () => [];
