import entities, { Fields } from 'config';
import { AdditionalConfigType, FieldType, FormConfigGetter } from 'lib';
import { TCellComponent } from 'components/Table';
import { EmailComponent, PhoneComponent } from 'lib/components';
import { dateAdapter, getDefaultAdapter } from 'lib/adapter';
import { TAdapter } from 'components/Table';

const { columns } = entities.areaagency;
export { columns };
export type Keys = Fields<(typeof columns)[number]>;

export const defaultMobileColumns: Keys[] = ['bahai_rolecode', 'bahai_name', 'bahai_cellphon'];

const getCellComponent = (WrpperСomponent?: TCellComponent<Record<string, any>>, adapter?: TAdapter) => {
  const CellComponent: TCellComponent<Record<string, any>> = ({ data, name, defaultValue, ...rest }) => {
    let content = defaultValue;
    const value = adapter ? adapter(data, name, defaultValue) : data[name];
    if (value) {
      content = WrpperСomponent ? <WrpperСomponent {...{ data, name, defaultValue, ...rest }} /> : (value as any);
    }
    return <>{data['bahai_highlight'] ? <b>{content}</b> : content}</>;
  };
  return CellComponent;
};

export const config: AdditionalConfigType<Keys> = {
  bahai_areaagencyid: { hiddenForTable: true },

  bahai_highlight: { hiddenForTable: true },

  bahai_emailaddress1: { hiddenForTable: true },

  bahai_emailaddress2: { hiddenForTable: true },

  bahai_typecode: {
    component: getCellComponent(undefined, getDefaultAdapter(FieldType.Choice)),
  },

  bahai_rolecode: {
    component: getCellComponent(undefined, getDefaultAdapter(FieldType.Choice)),
  },

  bahai_name: { searchable: true, component: getCellComponent() },

  bahai_rolephone: {
    component: getCellComponent(PhoneComponent),
  },

  bahai_cellphon: {
    component: getCellComponent(PhoneComponent),
  },

  bahai_roleemail: { searchable: true, component: getCellComponent(EmailComponent) },

  bahai_personalemail: { searchable: true, component: getCellComponent(EmailComponent) },

  bahai_cityid: {
    component: getCellComponent(undefined, getDefaultAdapter(FieldType.Lookup)),
  },

  bahai_rolestartdate: {
    component: getCellComponent(undefined, dateAdapter),
  },
};

export const getFormConfig: FormConfigGetter<Keys> = () => [];
