import { FunctionComponent, SVGProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from './icons/back.svg';
import { ReactComponent as ReloadIcon } from './icons/refresh.svg';
import { ReactComponent as DeleteIcon } from './icons/delete.svg';
import { ReactComponent as EditIcon } from './icons/edit.svg';
import { ReactComponent as AddIcon } from './icons/add.svg';
import { ReactComponent as AssignIcon } from './icons/assign.svg';
import { useSecurity } from 'providers/AuthProvider';
import { Privilege, TEntityName } from 'lib';

export enum ActionType {
  SYSTEM_ACTION, // subgrid/homepage system actions
  CUSTOM_ACTION, // entity action
}

export enum AllowedDevices {
  MOBILE_ONLY,
  DESKTOP_ONLY,
  All,
}

export enum ActionContext {
  ListPage,
  SinglePage,
  All,
  SubGid,
  ListPageAndSubGid,
}

type DisplayProps = {
  data: Record<string, any>;
  isCreateAllowed: boolean;
  isRemoveAllowed: boolean;
  isEditAllowed: boolean;
  isAssignAllowed: boolean;
  context: ActionContext;
  selectedItemsCount?: number;
  entityName: TEntityName;
};

type ClickProps = { context: ActionContext; selectedItems: Record<string, any>[]; query: any; reload: () => void };

export type Action = {
  title: string;
  name: string;
  onClick: (props: ClickProps) => void;
  Icon?: FunctionComponent<SVGProps<any>>;
  display?: (props: DisplayProps) => boolean;
  type: ActionType;
  allowedDevices: AllowedDevices;
  alwaysKeepTitle?: boolean;
  actionContext: ActionContext;
  isPinned?: boolean;
  onSelectionOnly?: boolean;
  addDivider?: boolean;
  order?: number;
};

export type HeaderAction = Action & { onClick: () => void };

export const useActions = ({
  onCreate,
  onReload,
  onRemove,
  onAssign,
  onEdit = () => null,
  additionalActions = [],
  displayName,
}: {
  onCreate?: () => void;
  onReload: () => void;
  onRemove: Action['onClick'];
  onEdit?: Action['onClick'];
  onAssign?: Action['onClick'];
  displayName: string;
  additionalActions?: Action[];
  fullScreen?: boolean;
}) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { isGranted } = useSecurity();

  const back: Action = useMemo(
    () => ({
      name: 'back',
      title: t('Back'),
      order: 24,
      onClick: () => goBack(),
      display: ({ context }) => context === ActionContext.SinglePage,
      Icon: BackIcon,
      type: ActionType.SYSTEM_ACTION,
      actionContext: ActionContext.SinglePage,
      allowedDevices: AllowedDevices.All,
      alwaysKeepTitle: false,
    }),
    [t, goBack]
  );

  const edit: Action = useMemo(
    () => ({
      title: t('Edit'),
      name: 'edit',
      order: 22,
      onClick: onEdit,
      display: ({ context, isEditAllowed }) => context === ActionContext.SinglePage && isEditAllowed,
      Icon: EditIcon,
      type: ActionType.SYSTEM_ACTION,
      actionContext: ActionContext.SinglePage,
      allowedDevices: AllowedDevices.All,
      alwaysKeepTitle: false,
    }),
    [t, onEdit]
  );

  const create: Action = useMemo(
    () => ({
      title: displayName,
      name: 'create',
      order: 23,
      onClick: () => onCreate && onCreate(),
      display: ({ context, isCreateAllowed, selectedItemsCount = 0 }) =>
        context === ActionContext.ListPage && isCreateAllowed && selectedItemsCount === 0,
      Icon: AddIcon,
      type: ActionType.SYSTEM_ACTION,
      actionContext: ActionContext.ListPageAndSubGid,
      allowedDevices: AllowedDevices.All,
      alwaysKeepTitle: false,
      isPinned: true,
    }),
    [displayName, onCreate]
  );

  const refresh: Action = useMemo(
    () => ({
      title: t('Refresh'),
      onClick: onReload,
      display: ({ selectedItemsCount = 0, context }) =>
        context === ActionContext.SinglePage || selectedItemsCount === 0,
      name: 'refresh',
      order: 23,
      Icon: ReloadIcon,
      type: ActionType.SYSTEM_ACTION,
      actionContext: ActionContext.All,
      allowedDevices: AllowedDevices.All,
      alwaysKeepTitle: false,
    }),
    [t, onReload]
  );

  const remove: Action = useMemo(
    () => ({
      title: t('Delete'),
      onClick: onRemove,
      display: ({ isRemoveAllowed }) => isRemoveAllowed,
      Icon: DeleteIcon,
      name: 'delete',
      order: -10,
      type: ActionType.SYSTEM_ACTION,
      actionContext: ActionContext.All,
      allowedDevices: AllowedDevices.All,
      alwaysKeepTitle: false,
    }),
    [t, onRemove]
  );

  const assign: Action = useMemo(
    () => ({
      title: t('Assign'),
      onClick: (props) => onAssign && onAssign(props),
      display: ({ isAssignAllowed, entityName }) => isAssignAllowed && isGranted(entityName, Privilege.Assign),
      Icon: AssignIcon,
      name: 'assign',
      order: 12,
      type: ActionType.SYSTEM_ACTION,
      actionContext: ActionContext.SinglePage,
      allowedDevices: AllowedDevices.All,
    }),
    [t, onAssign, isGranted]
  );

  return useMemo(
    () => [back, create, edit, ...additionalActions, remove, refresh, assign],
    [additionalActions, assign, back, create, edit, refresh, remove]
  );
};
