import ListPage, { TListPage } from 'components/ListPage';
import * as baseConfig from 'schemas/interaction';
import { ChainImprover } from 'lib/improvers';

const chains = [['bahai_inquiryid', 'bahai_inquirerid']];

export const Improver = () => <ChainImprover chains={chains} entityName="interaction" />;

const InteractionList = (props: Partial<TListPage>) => {
  return <ListPage entityName="interaction" {...baseConfig} FormImprover={Improver} {...props} />;
};

export default InteractionList;
