import { ReactComponent as CheckedIconBase } from './icons/checked.svg';
import { ReactComponent as UncheckedIconBase } from './icons/unchecked.svg';
import classes from './checkbox.module.scss';
import cx from 'classnames';

const CheckBox = ({
  CheckedIcon = CheckedIconBase,
  UncheckedIcon = UncheckedIconBase,
  checked,
  header,
  className,
}: {
  checked: boolean;
  header?: boolean;
  CheckedIcon?: typeof CheckedIconBase;
  UncheckedIcon?: typeof CheckedIconBase;
  className?: string;
}) => {
  return (
    <div className={cx(classes.wrapper, { [classes.header]: header }, className)}>
      {checked ? <CheckedIcon className={classes.filled} /> : <UncheckedIcon />}
    </div>
  );
};

export default CheckBox;
