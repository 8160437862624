import { useMemo } from 'react';
import { DateTimePicker as DatePickerBase } from 'components/DateTimePicker';
import cx from 'classnames';
import classes from 'components/Form/Input/input.module.scss';
import { TInputProps } from 'components/Form/types';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { exprUSADate, exprUSAOutput } from 'lib/adapter';

export const DatePicker = ({ input, label, className, meta, ...props }: TInputProps) => {
  const expr = useMemo(() => (props.showTime ? exprUSAOutput : exprUSADate), [props.showTime]);

  const errors = useMemo(
    () => (meta.touched || expr.test(String(input.value)) ? meta.error || meta.submitError : undefined),
    [expr, input.value, meta.error, meta.submitError, meta.touched]
  );

  return (
    <div className={cx(classes.wrapper, className, { [classes.disabled]: props.disabled })}>
      <label
        className={cx(classes.label, { [classes.required]: props.required, [classes.disabled]: !!props.disabled })}
        htmlFor={input.name}
      >
        {label}
      </label>
      <DatePickerBase showTime={false} {...input} {...props} hasError={!!errors} />
      <ValidationMessage error={errors} />
    </div>
  );
};
