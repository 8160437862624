import entities from 'config';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import * as rules from 'lib/rules';
import { TextEditor } from 'components/Form/TextEditor';
import { FieldValidator } from 'final-form';
import { AdditionalConfigType, FieldType, FormConfigGetter } from 'lib';
import { EmailComponent, HTMLComponent, PhoneComponent, ToolTipTextComponent, UrlComponent } from 'lib/components';
import { Trans, useTranslation } from 'react-i18next';
import {
  statuscode as StatusCode,
  bahai_contactmethodcode as ContactMethod,
} from 'config/EntityMetadata/bahai_contact_ims';
import { TSinglePage } from 'components/SinglePage';
import { useMetaData } from 'lib/hooks';
import { useCallback } from 'react';
import { FormProps } from 'react-final-form';
import { bahai_preferredlanguage } from 'config/EntityMetadata/common';

const { columns } = entities.contact;
export type Keys = (typeof columns)[number] | 'bahai_contact_imsid';

export const defaultMobileColumns: Keys[] = ['bahai_name', 'bahai_cellphone', 'bahai_rawimportantnote'];

export { columns };

export const config: AdditionalConfigType<Keys> = {
  bahai_id: {
    component: ({ data }) => (
      <HistoryLink to={r.contact({ id: data.bahai_contact_imsid || 0 })}>{data.bahai_id}</HistoryLink>
    ),
  },
  bahai_profileimage: { hiddenForTable: true },
  bahai_name: {
    searchable: true,
    component: ({ data }) => (
      <HistoryLink to={r.contact({ id: data.bahai_contact_imsid || 0 })}>{data.bahai_name}</HistoryLink>
    ),
  },
  bahai_contactmethodcode: {
    sortable: false,
    isRequired: true,
    fieldProps: ({ classes, context }) => (context === 'SINGLE_PAGE' ? {} : { className: classes.long }),
  },
  bahai_lastname: { isRequired: true },
  bahai_cellphone: {
    component: PhoneComponent,
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  bahai_homephone: {
    component: PhoneComponent,
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  bahai_workphone: {
    component: PhoneComponent,
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  bahai_otherlanguage: {
    fieldProps: () => ({
      maxLength: 100,
    }),
  },
  bahai_emailaddress1: { searchable: true, component: EmailComponent },
  bahai_emailaddress2: { component: EmailComponent },
  bahai_websiteurl: { component: UrlComponent },
  bahai_socialmediaurl: { component: UrlComponent },
  bahai_importantnote: {
    hiddenForTable: true,
    type: FieldType.String,
    fieldProps: ({ classes }) => ({ className: classes.long }),
    field: TextEditor,
    component: HTMLComponent('bahai_rawimportantnote'),
  },
  bahai_rawimportantnote: {
    searchable: true,
    component: ToolTipTextComponent('bahai_importantnote'),
  },
  statecode: {
    hiddenForTable: true,
  },
};
export const getDetailsConfig: FormConfigGetter<Keys> = (data) => [
  [
    <Trans>Personal Data</Trans>,
    [
      'bahai_firstname',
      'bahai_lastname',
      'bahai_nickname',
      'bahai_middlename',
      'bahai_gendercode',
      'bahai_preferredlanguagecode',
      ...((data.bahai_preferredlanguagecode == bahai_preferredlanguage.OtherLanguage
        ? ['bahai_otherlanguage']
        : []) as Keys[]),
      'bahai_importantnote',
    ] as Keys[],
  ],
  [
    <Trans>Contact Data</Trans>,
    [
      'bahai_emailaddress1',
      'bahai_emailaddress2',
      'bahai_cellphone',
      'bahai_workphone',
      'bahai_homephone',
      'bahai_socialmediaurl',
      'bahai_websiteurl',
      'bahai_addressline1',
      'bahai_contactmethodcode',
    ] as Keys[],
  ],
];

export const getFormConfig: FormConfigGetter<Keys> = (data) => [
  [
    <Trans>Personal Data</Trans>,
    [
      'bahai_firstname',
      'bahai_lastname',
      'bahai_nickname',
      'bahai_middlename',
      'bahai_gendercode',
      'bahai_preferredlanguagecode',
      ...((Number(data.bahai_preferredlanguagecode) === bahai_preferredlanguage.OtherLanguage
        ? ['bahai_otherlanguage']
        : []) as Keys[]),
      'bahai_importantnote',
    ] as Keys[],
  ],
  [
    <Trans>Contact Data</Trans>,
    [
      'bahai_contactmethodcode',
      'bahai_emailaddress1',
      'bahai_emailaddress2',
      'bahai_cellphone',
      'bahai_workphone',
      'bahai_homephone',
      'bahai_socialmediaurl',
      'bahai_websiteurl',
      'bahai_addressline1',
    ] as Keys[],
  ],
];

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_firstname: rules.compose([rules.maxLength(30)]),
  bahai_middlename: rules.maxLength(25),
  bahai_lastname: rules.compose([rules.required, rules.maxLength(25)]),
  bahai_nickname: rules.maxLength(100),
  bahai_contactmethodcode: rules.required,
  bahai_otherlanguage: rules.maxLength(100),
  bahai_emailaddress1: rules.compose([
    rules.email,
    rules.maxLength(200),
    rules.conditionalRequired((values) => values.bahai_emailaddress2, <Trans>Please add primary email first</Trans>),
  ]),
  bahai_emailaddress2: rules.compose([
    rules.email,
    rules.maxLength(200),
    rules.alternativeEmail('bahai_emailaddress1'),
  ]),

  bahai_cellphone: rules.phone,
  bahai_workphone: rules.phone,
  bahai_homephone: rules.phone,

  bahai_socialmediaurl: rules.maxLength(200),
  bahai_websiteurl: rules.maxLength(200),
  bahai_addressline1: rules.maxLength(40),

  bahai_importantnote: rules.maxLengthEscapeTags(2000),
};

export const isActive = (data: Record<Keys, any>) => Number(data.statuscode) === StatusCode.Active;

export const isNotEditable = (data: Record<Keys, any>) => !isActive(data) && <Trans>Contact Status is Inactive</Trans>;

export const statusFields: Required<TSinglePage>['statusFields'] = () => ['statuscode'];

export const isConfirmationMessageNeeded = () => true;
export const isConfirmationMessageRequired = () => true;

const contactMethodRelated = {
  [ContactMethod.Phone]: ['bahai_cellphone', 'bahai_workphone', 'bahai_homephone'],
  [ContactMethod.Email]: ['bahai_emailaddress1'],
  [ContactMethod.Text]: ['bahai_cellphone', 'bahai_workphone', 'bahai_homephone'],
  [ContactMethod.SocialMedia]: ['bahai_socialmediaurl'],
  [ContactMethod.Website]: ['bahai_websiteurl'],
  [ContactMethod.Mail]: [],
  [ContactMethod.VideoConference]: ['bahai_emailaddress1'],
  [ContactMethod.HomeVisit]: [],
};

export const useContactMethodValidation = () => {
  const { t } = useTranslation();
  const { entityConfig } = useMetaData('requester');

  const contactMethodValidation = useCallback(
    ({ bahai_contactmethodcode: value, ...values }: Record<string | 'bahai_contactmethodcode', any>) => {
      if (value && value.length) {
        return (value.map((v: string) => Number(v)) as ContactMethod[])
          .map((method) => ({ method, fields: contactMethodRelated[method] || [] }))
          .map(({ fields }) => {
            const names = fields.map((name) => entityConfig.fields[name].label).join(', ');
            const message =
              fields.length > 1
                ? t('Please fill out any of the next fields {{ names }}', {
                    names: ': ' + names,
                  })
                : t('Please fill {{ names }}', { names });
            if (fields.length === 0) return;
            return rules.relatedRequired(fields, message)(value, values);
          })
          .filter((v?: string) => !!v)
          .filter((v: string, index: number, arr: string[]) => arr.indexOf(v) === index);
      }
      return [];
    },
    [entityConfig, t]
  );

  const checkMethods = useCallback(
    (values: Record<string, any>, keys: number[]) => {
      return keys
        .map((v) => contactMethodValidation({ ...values, bahai_contactmethodcode: [v] }))
        .some((v) => v.length === 0);
    },
    [contactMethodValidation]
  );

  const baseValidation = useCallback(
    (values: Record<string, any>) => {
      if (checkMethods(values, Object.values(ContactMethod) as ContactMethod[])) return [];
      return [t('At least one Preferred Contact Method should be selected')];
    },
    [checkMethods, t]
  );

  const validate: FormProps['validate'] = useCallback(
    (values: Record<string, any>) => {
      const errors = [
        ...contactMethodValidation(values),
        ...([values.bahai_firstname, values.bahai_nickname].map((v: string) => (v || '').trim()).some((v) => !!v)
          ? []
          : [t('First Name or Nickname is required')]),
        ...baseValidation(values),
      ];

      return errors.length === 0 ? undefined : { _general: errors };
    },
    [contactMethodValidation, t, baseValidation]
  );

  return { validate };
};
