import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import classes from './search.module.scss';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import SearchBox from 'components/ListPage/components/SearchBox';

type Props = {
  value: string;
  onChange: (v: string) => void;
  onBlur?: () => void;
  fields: string[];
  className?: string;
};

export const SearchBar = ({ value: defaultValue, onChange, onBlur, fields, className }: Props) => {
  const [value, setValue] = useState<string>(defaultValue || '');

  const timeOut = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (value !== defaultValue && !timeOut.current) setValue(defaultValue ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (value !== defaultValue) {
      timeOut.current = setTimeout(() => {
        onChange(value);
        timeOut.current = null;
      }, 300);
      return () => {
        timeOut.current && clearTimeout(timeOut.current);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const { t } = useTranslation();

  const onSearchBlur = useCallback(() => {
    onChange((value || '').trim());
    onBlur && onBlur();
  }, [onBlur, onChange, value]);

  const tooltipContent = useMemo(
    () => (
      <div className={classes.toolTip}>
        <div className={classes.header}>{t('You can search by')}</div>
        <ul className={classes.list}>
          {fields.map((name) => (
            <li key={name}>{name}</li>
          ))}
        </ul>
      </div>
    ),
    [t, fields]
  );

  return (
    <div className={cx(classes.wrapper, className)}>
      <SearchBox onBlur={onSearchBlur} value={value} onChange={setValue} tooltipContent={tooltipContent} />
    </div>
  );
};
