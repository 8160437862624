import { useRef, useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import classes from './style.module.scss';

type TooltipContentProps = {
  content: string | JSX.Element;
  node: HTMLDivElement;
};

const offset = 15;
const gap = 10;

export const Content = ({ content, node }: TooltipContentProps) => {
  const {
    width: nodeWidth,
    height: nodeHeight,
    top: nodeTop,
    bottom: nodeBottom,
    left: nodeLeft,
    right: nodeRight,
  } = useMemo(() => node.getBoundingClientRect(), [node]);
  const contentRef = useRef<HTMLDivElement>(null);

  const [contentPosition, setContentPosition] = useState<Record<string, any>>({ opacity: 0 });
  const [arrowPosition, setArrowPosition] = useState<Record<string, any>>({});

  useEffect(() => {
    if (contentRef.current) {
      const { width: contentWidth } = contentRef.current.getBoundingClientRect();
      const { offsetWidth: docWidth, offsetHeight } = document.body;
      const docHeight = Math.min(offsetHeight, window.visualViewport?.height || Infinity);
      // content
      const displayOnTop = docHeight / 2 < nodeTop;
      const toLeft = docWidth / 2 < nodeLeft;

      setContentPosition({
        ...(toLeft
          ? { right: Math.min(docWidth - nodeRight - offset, docWidth - gap - contentWidth) }
          : { left: Math.min(nodeLeft - offset, docWidth - gap - contentWidth) }),
        ...(displayOnTop ? { bottom: docHeight - nodeTop } : { top: nodeBottom + window.scrollY }),
        maxHeight: displayOnTop ? nodeTop - gap : docHeight - nodeBottom - gap,
        opacity: 1,
        minWidth: node.clientWidth,
      });
      //arrow
      setArrowPosition({
        left: nodeLeft + nodeWidth / 2,
        ...(displayOnTop ? { bottom: docHeight - nodeTop } : { top: nodeBottom + window.scrollY }),
        transform: `translate(-50%, ${displayOnTop ? '-' : ''}5px) rotate(45deg)`,
      });
    }
  }, [node, node.clientWidth, node.offsetWidth, nodeBottom, nodeHeight, nodeLeft, nodeRight, nodeTop, nodeWidth]);

  return ReactDOM.createPortal(
    <div className={cx(classes.wrapper)} style={{ ...contentPosition }} ref={contentRef}>
      <div className={classes.contentWrapper}>
        <div className={classes.content}>{content}</div>
        <div className={classes.arrow} style={arrowPosition} />
      </div>
    </div>,
    document.body
  );
};
