import { useCallback, useMemo } from 'react';
import cx from 'classnames';
import classes from 'components/Form/Input/input.module.scss';
import { TInputProps } from 'components/Form/types';
import { Select as BaseSelect } from 'components/Select';
import { ValidationMessage } from 'components/Form/ValidationMessage';

type TSelectProps = TInputProps<string | string[] | undefined> & {
  isMultiple?: boolean;
  items: Map<string, string | JSX.Element>;
};

export const Select = ({
  input: { name, value, onChange },
  isMultiple,
  label,
  className,
  meta,
  ...props
}: TSelectProps) => {
  const errors = useMemo(() => (meta.touched ? meta.error || meta.submitError : undefined), [meta]);
  const selectValue = useMemo(() => (value !== undefined ? [value].flat().map((x) => x.toString()) : []), [value]);
  const onSelectChange = useCallback(
    (value: string[]) => {
      onChange(isMultiple ? value : value[0]);
    },
    [isMultiple, onChange]
  );

  return (
    <div className={cx(classes.wrapper, className, { [classes.disabled]: props.disabled })}>
      <label
        className={cx(classes.label, {
          [classes.required]: props.required,
          [classes.readOnly]: props.readOnly,
          [classes.disabled]: !!props.disabled,
        })}
        htmlFor={name}
      >
        {label}
      </label>
      <BaseSelect
        value={selectValue}
        onChange={onSelectChange}
        isMultiple={isMultiple}
        {...props}
        hasError={!!errors}
      />
      <ValidationMessage error={errors} />
    </div>
  );
};

export const createSelect = (items: Map<string, string | JSX.Element>, isMultiple = false) => {
  return (props: TInputProps<string | string[] | undefined>) => (
    <Select isMultiple={isMultiple} {...props} items={items} />
  );
};
