import config from 'config/index';

export const SelectType = '#Microsoft.Dynamics.CRM.PicklistAttributeMetadata';
export const MultiSelectType = '#Microsoft.Dynamics.CRM.MultiSelectPicklistAttributeMetadata';

export enum Privilege {
  'Append' = 'Append',
  'Create' = 'Create',
  'Read' = 'Read',
  'Share' = 'Share',
  'AppendTo' = 'AppendTo',
  'Write' = 'Write',
  'Assign' = 'Assign',
  'Delete' = 'Delete',
  'None' = 'None',
}

export type TEntityName = keyof typeof config;

export enum FieldFormat {
  DateOnly = 'DateOnly',
  DateAndTime = 'DateAndTime',
}

export const isDateTime = (v?: string) => v !== FieldFormat.DateOnly;

export type SavedQuery = {
  savedqueryid?: string;
  userqueryid?: string;
  isdefault?: boolean;
  name: string;
  layoutxml: string;
  layoutjson: string;
  fetchxml: string;
  returnedtypecode: string;
  description?: string;
};

export type ViewSettings = {
  bahai_portaluserqueryid: string;
  bahai_name: string;
  bahai_description?: string;
  bahai_tablelogicalname?: string;
  bahai_definition: string;
  bahai_isdefault?: boolean;
};

export type FetchQuery = {
  fetch: {
    _attributes: {
      page?: number;
      count?: number;
      version?: string;
    };
    entity: {
      order?:
        | {
            _attributes: {
              attribute: string;
            };
          }
        | {
            _attributes: {
              attribute: string;
              descending: boolean;
            };
          }[];
      attribute?: {
        _attributes: {
          name: string;
        };
      }[];
    };
  };
};

export type SavedQueryData = Omit<SavedQuery, 'savedqueryid' | 'isdefault'>;

export type TStatusConfig = {
  StatusCode: number;
  DisplayName: string;
  IsReminderDateTimeAvailable: boolean;
  IsStatusDetailsAvailable: boolean;
  IsStatusDetailsRequired: boolean;
  IsAvailable: boolean;
  IsActive: boolean;
  Warning?: string;
  DataToUpdate?: Record<string, any>;
  NativeStatusCode: number;
  NativeStateCode: number;
  AdditionalFields: {
    DisplayName: string;
    LogicalName: string;
    Type: string;
    MinValue: number;
    MaxValue: number;
    Required: boolean;
  }[];
  AvailableStatusDetails: { Code: number; DisplayName: string; Warning?: string; DataToUpdate?: Record<string, any> }[];
};

export interface TBulkActionResult {
  Messages: { Message: string; ToolTipMessage?: string; Rows: { Id: string; Name: string }[] }[];
}
