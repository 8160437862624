import { memo, useCallback, useMemo } from 'react';
import { TValueComponent, TMultiReferenceEntity } from 'lib';
import { createAutocomplete, IAutoCompleteProps } from 'components/AutoComplete';
import { useTranslation } from 'react-i18next';
import { AutocompleteType } from 'components/AutoComplete/hooks';

export const createFilterAutocomplete = (
  entities: string[] | TMultiReferenceEntity[],
  type: AutocompleteType
): TValueComponent => {
  const Autocomplete = createAutocomplete({
    entities: entities,
    isMultiple: true,
    type: type,
  } as IAutoCompleteProps);
  return memo(({ value = '', onChange }) => {
    const { t } = useTranslation();
    const arrayValue = useMemo(() => value?.split(',').filter((v) => !!v), [value]);

    const onArrayValueChange = useCallback(
      (value: string | string[] | undefined) => {
        onChange(value ? [value].flat().join(',') : '');
      },
      [onChange]
    );

    return <Autocomplete value={arrayValue} onChange={onArrayValueChange} placeholder={t('Please select value')} />;
  });
};
