import { FC, ReactNode } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { Loader } from 'components/Loader';
import { loginRequest } from 'domain/authConfig';
import PopupBlockedPlaceholder from 'components/PupupBlockedPlaceholder';

export const MsalBlocker: FC<{ children: ReactNode }> = ({ children }) => (
  <MsalAuthenticationTemplate
    interactionType={InteractionType.Redirect}
    authenticationRequest={loginRequest}
    errorComponent={() => <PopupBlockedPlaceholder />}
    loadingComponent={() => <Loader fullScreenOverlay />}
  >
    {children}
  </MsalAuthenticationTemplate>
);
