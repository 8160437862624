import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef, useState } from 'react';
import { ReactComponent as AttendedIcon } from 'components/Actions/icons/attended.svg';
import { ReactComponent as NotAttendedIcon } from 'components/Actions/icons/notattended.svg';
import { ReactComponent as AllAttendedIcon } from 'components/Actions/icons/markallattended.svg';
import { ReactComponent as AllNotAttendedIcon } from 'components/Actions/icons/markallnotattended.svg';
import { ReactComponent as SendInvitationIcon } from 'components/Actions/icons/sendinvitation.svg';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { parseError } from 'lib/errorParser';
import { useListRecords, useUpdateAttendanceStatus } from 'domain/operations';
import { useMetaData } from 'lib/hooks';
import { Modal } from 'components/Modal';
import { useApi } from 'domain/api';
import { NotificationPopup } from 'components/NotificationPopup';
import classes from 'pages/Event/event.module.scss';
import { ParticipantType, participationEntities } from 'schemas/event';
import * as eventMetadata from 'config/EntityMetadata/bahai_event_ims';

export const useUpdateAttendanceStatusActions = (
  participantType: ParticipantType,
  context?: ParticipantType | 'event'
) => {
  const { setAttendanceStatusToYes, setAttendanceStatusToNo } = useUpdateAttendanceStatus(participantType);
  const { t } = useTranslation();
  const { addActionCompleted, addActionFailed } = useNotifications();
  const [loading, setLoading] = useState(false);
  const {
    entityConfig: { displayCollectionName },
  } = useMetaData(context || participantType);

  const onMarkAttended = useCallback(
    (reload: () => void) => {
      addActionCompleted(t(`{{ displayCollectionName }} are marked as attended`, { displayCollectionName }));
      reload();
    },
    [addActionCompleted, displayCollectionName, t]
  );

  const onMarkNotAttended = useCallback(
    (reload: () => void) => {
      addActionCompleted(t(`{{ displayCollectionName }} are marked as not attended`, { displayCollectionName }));
      reload();
    },
    [addActionCompleted, displayCollectionName, t]
  );

  const markAllAttended: Action = useMemo(
    () => ({
      title: t('Mark All Attended'),
      name: 'markAllAttended',
      onClick: ({ query, reload }) => {
        setLoading(true);
        setAttendanceStatusToYes(query)
          .then(() => onMarkAttended(reload))
          .catch((error) => addActionFailed(parseError(error)))
          .finally(() => setLoading(false));
      },
      order: 8,
      Icon: AllAttendedIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SubGid,
      allowedDevices: AllowedDevices.All,
      display: ({ selectedItemsCount, data }) => !selectedItemsCount && data.length > 0,
    }),
    [addActionFailed, onMarkAttended, setAttendanceStatusToYes, t]
  );

  const markAllNotAttended: Action = useMemo(
    () => ({
      title: t('Mark All Not Attended'),
      name: 'markAllNotAttended',
      onClick: ({ query, reload }) => {
        setLoading(true);
        setAttendanceStatusToNo(query)
          .then(() => onMarkNotAttended(reload))
          .catch((error) => addActionFailed(parseError(error)))
          .finally(() => setLoading(false));
      },
      order: 7,
      Icon: AllNotAttendedIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SubGid,
      allowedDevices: AllowedDevices.All,
      display: ({ selectedItemsCount, data }) => !selectedItemsCount && data.length > 0,
    }),
    [addActionFailed, onMarkNotAttended, setAttendanceStatusToNo, t]
  );

  const markAttended: Action = useMemo(
    () => ({
      title: t('Attended'),
      name: 'attended',
      onClick: ({ reload, selectedItems }) => {
        setLoading(true);
        setAttendanceStatusToYes(selectedItems)
          .then(() => onMarkAttended(reload))
          .catch((error) => addActionFailed(parseError(error)))
          .finally(() => setLoading(false));
      },
      Icon: AttendedIcon,
      order: 9,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SubGid,
      allowedDevices: AllowedDevices.All,
      onSelectionOnly: true,
      display: ({ selectedItemsCount }) => (selectedItemsCount || 0) > 0,
    }),
    [addActionFailed, onMarkAttended, setAttendanceStatusToYes, t]
  );

  const markNotAttended: Action = useMemo(
    () => ({
      title: t('Not Attended'),
      name: 'notAttended',
      onClick: ({ reload, selectedItems }) => {
        setLoading(true);
        setAttendanceStatusToNo(selectedItems)
          .then(() => onMarkNotAttended(reload))
          .catch((error) => addActionFailed(parseError(error)))
          .finally(() => setLoading(false));
      },
      order: 8,
      Icon: NotAttendedIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SubGid,
      allowedDevices: AllowedDevices.All,
      onSelectionOnly: true,
      display: ({ selectedItemsCount }) => (selectedItemsCount || 0) > 0,
    }),
    [addActionFailed, onMarkNotAttended, setAttendanceStatusToNo, t]
  );

  return { loading, actions: [markAllAttended, markAllNotAttended, markAttended, markNotAttended] };
};

export const useSendInvitations = (participantType: ParticipantType, eventId: string) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Record<string, any>[]>();
  const { sendInvitations } = useApi();
  const { addActionFailed, addWarning, addActionUncompleted, addNotification } = useNotifications();
  const { displayName, displayCollectionName } = useMetaData(participantType);
  const { url, PrimaryIdAttribute, logicalName } = useMetaData(participationEntities[participantType]);
  const getRecords = useListRecords(url, { id: PrimaryIdAttribute });

  const getSendInvitationsToSelected = useCallback(
    (data: Record<string, any>): Action => ({
      title: t('Send Invitation To Selected'),
      name: 'sendInvitationToSelected',
      order: 11,
      onClick: async ({ selectedItems, reload }) => {
        if (Number(data.statecode) !== eventMetadata.statecode.Active) {
          addActionUncompleted(t('Event State should be Active to Send invitations'));
        } else {
          reloadList.current = reload;
          setData(await getRecords(selectedItems));
        }
      },
      Icon: SendInvitationIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SubGid,
      allowedDevices: AllowedDevices.All,
      onSelectionOnly: true,
      display: () => false,
    }),
    [t, addActionUncompleted, getRecords]
  );

  const reloadList = useRef<() => void>();

  const getSendInvitationsToAll = useCallback(
    (data: Record<string, any>): Action => ({
      title: t('Send Invitations'),
      name: 'sendInvitations',
      order: 10,
      onClick: async ({ query, reload }) => {
        if (Number(data.statecode) !== eventMetadata.statecode.Active) {
          addActionUncompleted(t('Event State should be Active to Send invitations'));
        } else {
          reloadList.current = reload;
          setData(await getRecords(query));
        }
      },
      Icon: SendInvitationIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SubGid,
      allowedDevices: AllowedDevices.All,
      display: () => false,
    }),
    [t, addActionUncompleted, getRecords]
  );

  const sendNotifications = useCallback(async () => {
    setLoading(true);
    const participantsIds = data?.map((x) => x.id) as string[];

    try {
      const messages = await sendInvitations(eventId, participantsIds, logicalName);
      reloadList.current && reloadList.current();
      if (messages.length === 1 && messages[0].Message.includes('successfully sent')) {
        addNotification({
          type: NotificationType.SUCCESS,
          title: t('All Invitations were sent to {{ displayCollectionName }}', { displayCollectionName }),
        });
      } else {
        const withoutEmailsErrors =
          messages
            .filter((x) => x.Message.includes('has no email'))?.[0]
            ?.Rows?.map((x) => ({
              label: x.Name,
              content: t('{{ displayName }} does not have an email address', { displayName }),
            })) ?? [];

        const alreadyInvitedErrors =
          messages
            .filter((x) => x.Message.includes('invited already'))?.[0]
            ?.Rows?.map((x) => ({
              label: x.Name,
              content: t('{{ displayName }} was already invited', { displayName }),
            })) ?? [];

        addWarning({
          title: t('Some of {{ displayCollectionName }} can’t be invited', {
            displayCollectionName,
          }),
          content: (
            <>
              {t('Please see details below')}
              <br />
              <NotificationPopup
                label={t('Show Details')}
                header={t('Info')}
                description={t('Invitations were not sent to the {{ displayCollectionName }} listed below', {
                  displayCollectionName,
                })}
                errors={[...withoutEmailsErrors, ...alreadyInvitedErrors]}
              />
            </>
          ),
        });
      }
    } catch {
      addActionFailed(
        t(
          'Something went wrong. Please try again or contact your regional seeker response coordinator or Inquiry Services liaison'
        )
      );
    } finally {
      setData(undefined);
      setLoading(false);
    }
  }, [
    data,
    sendInvitations,
    eventId,
    logicalName,
    addNotification,
    t,
    displayCollectionName,
    addWarning,
    displayName,
    addActionFailed,
  ]);

  const content = useMemo(() => {
    return (
      <>
        {data && (
          <Modal
            className={classes.sendInvitationDialog}
            title={t('Send Invitations?')}
            header={t('Do you want to send invitations?')}
            onClose={() => setData(undefined)}
            controls={[
              { title: t('Yes'), type: 'submit', role: 'primary', onClick: sendNotifications },
              {
                title: t('No'),
                onClick: () => setData(undefined),
              },
            ]}
            loading={loading}
          />
        )}
      </>
    );
  }, [data, loading, sendNotifications, t]);

  const getActions = useCallback(
    (data: Record<string, any>) => [getSendInvitationsToSelected(data), getSendInvitationsToAll(data)],
    [getSendInvitationsToAll, getSendInvitationsToSelected]
  );

  return { getActions, content };
};
