import { useCallback, useMemo, useState } from 'react';
import { Modal } from 'components/Modal';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/Select';
import { timeZoneToString, useTimeZone } from 'lib/hooks';
import { ReactComponent as Icon } from './info.svg';
import classes from './tz.module.scss';

const allTimeZones = (Intl as any).supportedValuesOf('timeZone') as string[];

const regions = new Map(Array.from(new Set(allTimeZones.map((v) => v.split('/')[0]))).map((v) => [v, v]));

export const TimeZoneSettings = ({ onClose }: { onClose: () => void }) => {
  const { timeZone, updateTimeZone } = useTimeZone();
  const { t } = useTranslation();

  const [region, setRegion] = useState(() => timeZone.split('/')[0]);

  const [newTimeZone, setNewTimeZone] = useState(timeZone);

  const save = useCallback(() => {
    updateTimeZone(newTimeZone);
    onClose();
  }, [newTimeZone, onClose, updateTimeZone]);

  const timeZones = useMemo(
    () => new Map(allTimeZones.filter((v) => v.startsWith(region + '/')).map((v) => [v, timeZoneToString(v)])),
    [region]
  );

  const updateRegion = useCallback(([region]: string[]) => {
    setNewTimeZone('');
    setRegion(region);
  }, []);

  return (
    <Modal
      onClose={onClose}
      title={t('Time Zone Settings')}
      header={
        <div>
          {t('Set the time zone you are in. This time zone must match your current computer time zone settings')}
        </div>
      }
      controls={[
        { title: t('Save'), disabled: !newTimeZone || newTimeZone === timeZone, onClick: save, role: 'primary' },
        { title: t('Cancel'), onClick: onClose },
      ]}
    >
      <div className={classes.label}>{t('Region')}</div>
      <Select
        onChange={updateRegion}
        items={regions}
        value={[region]}
        isClearable={false}
        isMultiple={false}
        isSearchable
        autoCompleteOff={true}
      />
      <div className={classes.label}>{t('Major City')}</div>
      <Select
        onChange={([timeZone]) => setNewTimeZone(timeZone)}
        items={timeZones}
        value={newTimeZone ? [newTimeZone] : []}
        isClearable={false}
        isMultiple={false}
        isSearchable
        autoCompleteOff={true}
      />
      <div className={classes.note}>
        <Icon />
        {t('Please note that changes to other open tabs will be applied only after the tab is refreshed.')}
      </div>
    </Modal>
  );
};
