import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classes from './style.module.scss';

export const DropdownWrapper = ({
  parent,
  children,
  offset = 5,
  onClose,
}: {
  parent: HTMLElement;
  offset?: number;
  children?: JSX.Element;
  onClose: () => void;
}) => {
  const [position, setPosition] = useState({ right: 0, top: 0 });
  const ref = useRef<HTMLDivElement>(null);

  // TODO: If required we can add here more directions
  useEffect(() => {
    const { right, bottom } = parent.getBoundingClientRect();
    setPosition({ right: Math.max(offset, window.innerWidth - right), top: bottom });
  }, [offset, parent]);

  useEffect(() => {
    const close = () => {
      setTimeout(onClose, 5);
    };
    window.addEventListener('click', close, true);
    window.addEventListener('scroll', close, true);
    return () => {
      window.removeEventListener('click', close, true);
      window.removeEventListener('scroll', close, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return position.right ? (
    createPortal(
      <div className={classes.wrapper} ref={ref} style={position}>
        {children}
      </div>,
      document.body
    )
  ) : (
    <></>
  );
};
