import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useState, useMemo, useRef } from 'react';
import { ReactComponent as DeleteIcon } from 'components/Actions/icons/delete.svg';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import * as eventMetadata from 'config/EntityMetadata/bahai_event_ims';
import { useMultiRemove } from 'components/ListPage/components/MultiRemovePopup';
import { useNotifications } from 'providers/NotificationsProvider';
import classes from 'pages/Event/event.module.scss';
import { Modal } from 'components/Modal';
import { TEntityName } from 'lib';
import { useMetaData } from 'lib/hooks';
import { ParticipantType, participationEntities } from 'schemas/event';

export const useRemoveEventParticipants = (
  parentEntityName: TEntityName,
  childEntityName: TEntityName,
  isNotRemovable?: (data: Record<string, any>) => JSX.Element | false
) => {
  const participantType: ParticipantType = useMemo(
    () => ([parentEntityName, childEntityName].includes('resource') ? 'resource' : 'requester'),
    [childEntityName, parentEntityName]
  );

  const { t } = useTranslation();
  const { addActionUncompleted } = useNotifications();

  const [isVisible, setIsVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState(new Array<Record<string, any>>());

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const reloadRef = useRef(() => {});

  const getRemoveParticipantsAction = useMemo(
    () =>
      (data: Record<string, any>): Action => ({
        title: t('Remove'),
        name: 'remove',
        order: -10,
        onClick: ({ reload, selectedItems }) => {
          if (parentEntityName === 'event' && Number(data.statecode) !== eventMetadata.statecode.Active) {
            addActionUncompleted(<Trans>Invitees can’t be removed from a Finished or Canceled Event</Trans>);
          } else {
            reloadRef.current = reload;
            setSelectedItems(selectedItems);
            setIsVisible(true);
          }
        },
        Icon: DeleteIcon,
        type: ActionType.CUSTOM_ACTION,
        actionContext: ActionContext.All,
        allowedDevices: AllowedDevices.All,
        onSelectionOnly: true,
        alwaysKeepTitle: false,
        display: ({ isRemoveAllowed }) => isRemoveAllowed,
      }),
    [addActionUncompleted, parentEntityName, t]
  );

  const hide = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const reload = useCallback(() => {
    reloadRef.current();
  }, []);

  const { displayCollectionName: childCollectionName, PrimaryNameAttribute } = useMetaData(childEntityName);

  const { displayName, displayCollectionName } = useMetaData(participantType);

  const whom = useMemo(
    () => (parentEntityName === 'event' ? displayCollectionName : displayName),
    [displayCollectionName, displayName, parentEntityName]
  );

  const from = useMemo(() => (parentEntityName === 'event' ? t('Event') : t('Events')), [parentEntityName, t]);

  const { onRemove, Popup: removeProgress } = useMultiRemove({
    entityName: participationEntities[participantType],
    displayName: childCollectionName,
    nameAttribute: parentEntityName === 'event' ? `${participantType}.${PrimaryNameAttribute}` : 'event.bahai_id',
    selectedItems,
    isNotRemovable,
    isConfirmationMessageNeeded: () => false,
    onClose: reload,
    isRemove: true,
  });

  const remove = useCallback(() => {
    hide();
    onRemove();
  }, [onRemove, hide]);

  const content = useMemo(() => {
    return (
      <>
        {removeProgress && removeProgress}

        {isVisible && (
          <Modal
            className={classes.removeInvitedDialog}
            title={t(`Remove {{ whom }} from the {{ from }}?`, { whom, from })}
            header={t(`Do you want to remove {{ whom }} from the {{ from }}?`, { whom, from })}
            onClose={hide}
            controls={[
              {
                title: t('Remove'),
                role: 'primary',
                onClick: remove,
              },
              {
                title: t('Cancel'),
                onClick: hide,
              },
            ]}
          />
        )}
      </>
    );
  }, [removeProgress, isVisible, t, whom, from, hide, remove]);

  return { getRemoveParticipantsAction, content };
};
