import { memo, useCallback, useMemo } from 'react';
import { TValueComponent } from 'lib';
import { Select } from 'components/Select';
import { useTranslation } from 'react-i18next';

export const createFilterSelect = (items: Map<string, string | JSX.Element>): TValueComponent => {
  return memo(({ value = '', onChange }) => {
    const { t } = useTranslation();

    const arrayValue = useMemo(() => value?.split(',').filter((v) => !!v), [value]);

    const onArrayValueChange = useCallback(
      (value: string | string[] | undefined) => {
        onChange(value ? [value].flat().join(',') : '');
      },
      [onChange]
    );

    return (
      <Select
        value={arrayValue}
        onChange={onArrayValueChange}
        items={items}
        isMultiple={true}
        placeholder={t('Please select value')}
      />
    );
  });
};
