import { useCallback } from 'react';
import { TSubmitProps } from 'components/ListPage/components/Form';
import { Trans, useTranslation } from 'react-i18next';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { HistoryLink } from 'components/HistoryLink';
import { routes } from 'domain/routes';
import { parseSaveFormError } from 'lib/errorParser';
import { useRecord } from 'lib/record';
import { bahai_inquiry_typecode as types } from 'config/EntityMetadata/bahai_inquiry';
import { useApi } from 'domain/api';

async function chain<T>([current, ...rest]: Array<() => Promise<T>>, results = [] as T[]): Promise<T[]> {
  return new Promise((resolve) => {
    if (typeof current === 'function') {
      current().then((result) => {
        chain(rest, [...results, result]).then(resolve);
      });
    } else {
      return resolve(results);
    }
  });
}

export const useInquiry = () => {
  const { save } = useRecord('inquiry');
  const { requesterIsDraft } = useApi();
  const { addNotification, addActionUncompleted } = useNotifications();
  const { t } = useTranslation();
  const onSubmit = useCallback(
    async ({ data: { bahai_typecode, ...data }, postAction, id }: TSubmitProps) => {
      if (bahai_typecode.includes(`${types.BecomeaBahai}`)) {
        if (await requesterIsDraft(data.bahai_inquirerid)) {
          addActionUncompleted(
            <Trans>
              To save Inquiry with Type "Become a Baha’i" please fill needed information for Requester to be saved as
              non Draft. Go to{' '}
              <HistoryLink to={routes.requester({ id: data.bahai_inquirerid }) + '#edit'} target="_blank">
                hyperlink
              </HistoryLink>{' '}
              to update Requester.
            </Trans>
          );
          throw { bahai_inquirerid: [t('Inquiry Type "Become a Baha’i" is not available for Draft Requester')] };
        }
      }
      const errors = await chain(
        bahai_typecode.map((code: string, index: number) => async () => {
          try {
            const resp = await save({ bahai_typecode: [code], ...data }, id);
            if (resp.status > 204) {
              // check this for throw
              return Promise.reject({ _general: [<Trans>Something went wrong</Trans>] });
            } else {
              const recordId = id || resp?.headers?.location?.match(/\((.+)\)/)?.[1];
              if (!id) {
                addNotification({
                  title: <Trans>Record was created</Trans>,
                  type: NotificationType.SUCCESS,
                  content: recordId ? (
                    <Trans>
                      Please, go to <HistoryLink to={routes.inquiry({ id: recordId })}>hyperlink</HistoryLink> to see
                      it.
                    </Trans>
                  ) : undefined,
                });
              } else {
                addNotification({
                  type: NotificationType.SUCCESS,
                  title: <Trans>Your changes have been saved</Trans>,
                });
              }
              if (index === bahai_typecode.length - 1) postAction(recordId);
            }
          } catch (e) {
            return e;
          }
        })
      );
      const errorMeesages = errors.filter((x) => x).map((x) => parseSaveFormError(x, !!id));
      const textMessages = Array.from(new Set(errorMeesages.map((x) => x.props.children as string)));
      const uniqueMessages = textMessages.map((x) => errorMeesages.find((e) => e.props.children == x));

      if (uniqueMessages.length > 0) {
        throw { _general: [uniqueMessages] };
      }
    },
    [addActionUncompleted, addNotification, requesterIsDraft, save, t]
  );

  return { onSubmit };
};
