import entities from 'config';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { Trans, useTranslation } from 'react-i18next';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import { EmailComponent, PhoneComponent, HTMLComponent, AreaComponent } from 'lib/components';
import { TLinkEntity } from 'components/ListPage';
import { createFormAutocomplete } from 'components/Form/Autocomplete';
import * as rules from 'lib/rules';
import { FieldValidator } from 'final-form';
import * as resourceMetadata from 'config/EntityMetadata/systemuser';
import { isLocalityCalculation, ClasterWarning } from 'schemas/requester';
import { getLockMessageDefault, TSinglePage } from 'components/SinglePage';
import { useMetaData } from 'lib/hooks';
import { useCallback } from 'react';
import { bahai_availability, bahai_contactmethodcode as ContactMethod } from 'config/EntityMetadata/systemuser';
import { FormProps } from 'react-final-form';
import { TextEditor } from 'components/Form/TextEditor';
import { FieldType } from 'dynamics-meta';

export type Keys = (typeof entities.resource.columns)[number] | 'cluster.bahai_name';

export const defaultMobileColumns: Keys[] = ['fullname', 'mobilephone', 'businessunitid'];

const coverageColumns = entities.coverage.columns.map((v) => 'coverage.' + v);

export const columns = [...entities.resource.columns, ...coverageColumns, 'cluster.bahai_name'];

export const statusFields: Required<TSinglePage>['statusFields'] = ({ bahai_resourceavailability }) => [
  'bahai_resourceavailability',
  ...(bahai_resourceavailability === bahai_availability.Here
    ? []
    : ['bahai_coveragereason', 'bahai_coveringresourceid', 'bahai_enddatetime'].map((v) => 'coverage.' + v)),
];

export const links = {
  cluster: {
    to: 'bahai_clusterid',
    from: 'businessunitid',
    fields: ['bahai_name'],
    condition: [],
  },
  coverage: {
    from: 'bahai_coverageid',
    to: 'bahai_currentcoverage',
    fields: entities.coverage.columns,
  },
} as TLinkEntity;

export const isActive = (data: Record<Keys, any>) => !data.isdisabled;

export const isNotEditable = (data: Record<Keys, any>) => {
  if (data.isdisabled) {
    return <Trans>Resource State is Inactive</Trans>;
  }
  if (isLocalityCalculation(data)) return ClasterWarning;
  return false;
};
export const isNotRemovable = () => (
  <Trans>
    You do not have access to remove a Resource record. Please contact your regional seeker response coordinator or
    Inquiry Services liaison for assistance.
  </Trans>
);

export const config: AdditionalConfigType<Keys | 'systemuserid'> = {
  entityimage: { hiddenForTable: true, excludeFromListQuery: true },
  isdisabled: { hiddenForTable: true },
  createdby: { hiddenForTable: true },
  internalemailaddress: { searchable: true, component: EmailComponent },
  fullname: {
    searchable: true,
    component: ({ data }) => <HistoryLink to={r.resource({ id: data.systemuserid || 0 })}>{data.fullname}</HistoryLink>,
  },
  mobilephone: {
    searchable: true,
    component: PhoneComponent,
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  bahai_workphone: {
    component: PhoneComponent,
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  homephone: {
    component: PhoneComponent,
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  bahai_role: { sortable: false },
  'cluster.bahai_name': {
    label: <Trans>Cluster Name</Trans>,
  },

  bahai_emailaddress2: { component: EmailComponent },
  bahai_postalcodeid: {
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'bahai_postalcode',
          filters: { bahai_countryidname: 'USA', bahai_stateid: 'bahai_stateid', bahai_cityid: 'bahai_cityid' },
        },
      ],
      dependencies: ['bahai_stateid', 'bahai_cityid'],
    }),
  },
  bahai_cityid: {
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'bahai_city',
          filters: { bahai_countryidname: 'USA', bahai_stateid: 'bahai_stateid' },
        },
      ],
      dependencies: ['bahai_stateid'],
    }),
  },
  bahai_stateid: {
    field: createFormAutocomplete({
      entities: [{ entityName: 'bahai_state', filters: { bahai_countryidname: 'USA' } }],
    }),
  },
  bahai_addressline1: {
    hiddenForTable: true,
  },
  bahai_addressline2: {
    hiddenForTable: true,
  },
  bahai_traininginstituteexperiencecode: {
    sortable: false,
  },
  bahai_fluentlanguagecode: {
    sortable: false,
  },
  bahai_ethnicitycode: {
    sortable: false,
  },
  bahai_otherlanguage: {
    fieldProps: () => ({
      maxLength: 100,
    }),
  },
  bahai_otherethnicity: {
    fieldProps: () => ({
      maxLength: 25,
    }),
  },
  bahai_startdate: { fieldProps: () => ({ showTime: false }) },
  bahai_notes: {
    hiddenForTable: true,
    type: FieldType.String,
    field: TextEditor,
    component: HTMLComponent('bahai_rawnotes'),
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_lastname: { isRequired: true },
  bahai_firstname: { isRequired: true },
  bahai_compositeaddress: {
    fieldProps: ({ classes }) => ({ className: classes.long }),
    component: AreaComponent,
  },
  bahai_localitycalculationstatuscode: {
    hiddenForTable: true,
  },
  bahai_contactmethodcode: {
    sortable: false,
  },
  bahai_racecode: {
    sortable: false,
  },
  bahai_autoassignstatecode: {
    sortable: false,
  },
  ...coverageColumns.reduce(
    (acc, name) => ({
      [name]: { hiddenForTable: true },
      ...acc,
    }),
    {}
  ),
};

export const getLockMessage = (data: Record<string, any>) =>
  isLocalityCalculation(data)
    ? {
        message: (
          <Trans>
            Please, note that Cluster is being calculated. The form will be unlocked and can be edited after Cluster is
            determined.
          </Trans>
        ),
        forceDisplay: true,
        icon: 'clock',
      }
    : getLockMessageDefault();

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_postalcodeid: rules.groupRequired([
    'bahai_postalcodeid',
    'bahai_stateid',
    'bahai_cityid',
    'bahai_addressline1',
    'bahai_addressline2',
  ]),
  bahai_stateid: rules.groupRequired([
    'bahai_postalcodeid',
    'bahai_stateid',
    'bahai_cityid',
    'bahai_addressline1',
    'bahai_addressline2',
  ]),
  bahai_cityid: rules.groupRequired([
    'bahai_postalcodeid',
    'bahai_stateid',
    'bahai_cityid',
    'bahai_addressline1',
    'bahai_addressline2',
  ]),

  bahai_addressline1: rules.maxLength(40),
  bahai_addressline2: rules.maxLength(40),
  bahai_notes: rules.maxLengthEscapeTags(2000),
  bahai_emailaddress2: rules.compose([
    rules.maxLength(200),
    rules.email,
    rules.alternativeEmail('internalemailaddress'),
  ]),
  bahai_lastname: rules.compose([rules.maxLength(25), rules.required]),
  bahai_firstname: rules.compose([rules.maxLength(30), rules.required]),
  middlename: rules.maxLength(25),
  bahai_otherethnicity: rules.maxLength(25),
  bahai_otherlanguage: rules.maxLength(100),
  mobilephone: rules.phone,
  bahai_workphone: rules.phone,
  homephone: rules.phone,
};

export const getDetailsConfig: FormConfigGetter<Keys> = (data) => [
  [
    <Trans>Personal Data</Trans>,
    [
      'bahai_nametitle',
      'bahai_contactid',
      'bahai_lastname',
      'bahai_firstname',
      'middlename',
      'bahai_fluentlanguagecode',
      ...((data.bahai_fluentlanguagecode?.includes(String(resourceMetadata.bahai_fluentlanguagecode.OtherLanguage))
        ? ['bahai_otherlanguage']
        : []) as Keys[]),
      'bahai_gendercode',
      'bahai_agerangecode',
      'bahai_racecode',
      'bahai_ethnicitycode',
      ...((data.bahai_ethnicitycode?.includes(String(resourceMetadata.bahai_ethnicity.OtherEthnicity))
        ? ['bahai_otherethnicity']
        : []) as Keys[]),
      'businessunitid',
      'bahai_startdate',
      'bahai_role',
      'bahai_traininginstituteexperiencecode',
      'bahai_autoassign',
      'bahai_autoassignstatecode',
      'bahai_notes',
    ] as Keys[],
  ],
  [
    <Trans>Contact Data</Trans>,
    [
      'bahai_contactmethodcode',
      'internalemailaddress',
      'bahai_emailaddress2',
      'mobilephone',
      'bahai_workphone',
      'homephone',
    ] as Keys[],
  ],
  [
    <Trans>Address</Trans>,
    ['bahai_compositeaddress', 'bahai_clusterid', 'cluster.bahai_name', 'bahai_regionid'] as Keys[],
  ],
];

export const getFormConfig: FormConfigGetter<Keys> = (data) => [
  [
    <Trans>Personal Data</Trans>,
    [
      'bahai_nametitle',
      'bahai_lastname',
      'bahai_firstname',
      'middlename',
      'bahai_fluentlanguagecode',
      ...((data.bahai_fluentlanguagecode?.includes(String(resourceMetadata.bahai_fluentlanguagecode.OtherLanguage))
        ? ['bahai_otherlanguage']
        : []) as Keys[]),
      'bahai_gendercode',
      'bahai_agerangecode',
      'bahai_racecode',
      'bahai_ethnicitycode',
      ...((data.bahai_ethnicitycode?.includes(String(resourceMetadata.bahai_ethnicity.OtherEthnicity))
        ? ['bahai_otherethnicity']
        : []) as Keys[]),
      'bahai_startdate',
      'bahai_traininginstituteexperiencecode',
      'bahai_notes',
    ] as Keys[],
  ],
  [
    <Trans>Contact Data</Trans>,
    ['bahai_contactmethodcode', 'bahai_emailaddress2', 'mobilephone', 'bahai_workphone', 'homephone'] as Keys[],
  ],
  [
    <Trans>Address</Trans>,
    ['bahai_postalcodeid', 'bahai_stateid', 'bahai_cityid', 'bahai_addressline1', 'bahai_addressline2'] as Keys[],
  ],
];

const addressFields = ['bahai_postalcodeid', 'bahai_stateid', 'bahai_cityid'];

const contactMethodRelated = {
  [ContactMethod.Phone]: ['bahai_workphone', 'homephone', 'mobilephone'],
  [ContactMethod.Email]: [],
  [ContactMethod.Text]: ['bahai_workphone', 'homephone', 'mobilephone'],
  [ContactMethod.Mail]: ['bahai_compositeaddress'],
  [ContactMethod.VideoConference]: [],
  [ContactMethod.HomeVisit]: ['bahai_compositeaddress'],
};

export const useContactMethodValidation = () => {
  const { t } = useTranslation();
  const { entityConfig } = useMetaData('resource');

  const contactMethodValidation = useCallback(
    ({ bahai_contactmethodcode: value, ...values }: Record<string | 'bahai_contactmethodcode', any>) => {
      if (value && value.length) {
        return (value.map((v: string) => Number(v)) as ContactMethod[])
          .map((method) => ({ method, fields: contactMethodRelated[method] || [] }))
          .map(({ method, fields }) => {
            const names = fields.map((name) => entityConfig.fields[name].label).join(', ');
            const message =
              fields.length > 1
                ? t('Please fill out any of the next fields {{ names }}', {
                    names: ': ' + names,
                  })
                : t('Please fill {{ names }}', { names });
            if (fields.length === 0) return undefined;
            switch (method) {
              case ContactMethod.Mail:
              case ContactMethod.HomeVisit:
                return addressFields.some((v) => !values[v]) ? t('Please fill in address') : undefined;
              default:
                if (fields.length === 0) return undefined;
                return rules.relatedRequired(fields, message)(value, values);
            }
          })
          .filter((v?: string) => !!v)
          .filter((v: string, index: number, arr: string[]) => arr.indexOf(v) === index);
      }
      return [];
    },
    [entityConfig, t]
  );

  const checkMethods = useCallback(
    (values: Record<string, any>, keys: number[]) => {
      return keys
        .map((v) => contactMethodValidation({ ...values, bahai_contactmethodcode: [v] }))
        .some((v) => v.length === 0);
    },
    [contactMethodValidation]
  );

  const baseValidation = useCallback(
    (values: Record<string, any>) => {
      if (checkMethods(values, Object.values(ContactMethod) as ContactMethod[])) return [];
      return [t('At least one Preferred Contact Method should be selected')];
    },
    [checkMethods, t]
  );

  const validate: FormProps['validate'] = useCallback(
    (values: Record<string, any>) => {
      const errors = [...contactMethodValidation(values), ...baseValidation(values)];

      return errors.length === 0 ? undefined : { _general: errors };
    },
    [contactMethodValidation, baseValidation]
  );

  return { validate };
};
