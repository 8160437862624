import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as Image } from 'components/PupupBlockedPlaceholder/images/image.svg';
import classes from 'components/PupupBlockedPlaceholder/popup.module.scss';

const PopupBlockedPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.image}>
        <Image />
      </div>
      <h2>{t('Pop-up blocked')}</h2>
      <div>
        {t(
          'We attempted to launch IMS login page in a new window, but a pop-up blocker is preventing it from opening.'
        )}
      </div>
      <div>
        <Trans>
          Please, disable pop-up blockers for this site according to <strong>"Help with Login Issues" </strong>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://sites.google.com/bahai.us/inquiry-services-resource-site/inquiry-management-system-ims?authuser=0"
          >
            quick start guide
          </a>
          .
        </Trans>

        <div className={classes.reload}>{t('Please, refresh this page after you have allowed pop-up.')}</div>
      </div>
    </div>
  );
};

export default PopupBlockedPlaceholder;
