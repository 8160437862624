import { SelectionType, THeadProps } from 'components/Table/types';
import { useCallback, useMemo } from 'react';
import { Direction, useFilters, useSorting } from 'components/Table/hooks';
import CheckBox from 'components/Table/CheckBox';
import wClasses from './wrapper.module.scss';

const THead = <Data extends Record<string, any>>({
  columns,
  TheadComponent,
  Th,
  config,
  classes,
  cellSizes,
  pinnedColumns,
  selectionProps,
  isEmpty,
  tableName,
  ...props
}: THeadProps<Data>) => {
  const { sorting, sortByName, clearByName } = useSorting();
  const { filters, removeFilter, clearOtherFilters } = useFilters();
  const filterValues = useMemo(() => Object.fromEntries(filters.map((v) => [v.attribute, v])), [filters]);

  const isMultiSorting = useMemo(() => Object.keys(sorting).length > 1, [sorting]);

  const getCellProps = useCallback(
    (name: string, filterAs?: string) => {
      const isFiltered = !!filterValues[filterAs || name];
      const sensitiveProps = {
        name,
        sortDirection: sorting[name]?.direction,
        sortIndex: isMultiSorting ? sorting[name]?.index : undefined,
        isPinned: pinnedColumns.includes(name),
        removeFilter: isFiltered ? () => removeFilter(filterAs || name) : undefined,
        clearOtherFilters: isFiltered && filters.length > 1 ? () => clearOtherFilters(filterAs || name) : undefined,
        isMultiSorting,
      };

      return {
        ...sensitiveProps,
        width: cellSizes[name] || 100,
        sortByName: (direction?: Direction) => sortByName(name, direction),
        clearByName: (clearOther = false) => clearByName(name, clearOther),
        classes,
      };
    },
    [
      filterValues,
      sorting,
      isMultiSorting,
      pinnedColumns,
      filters.length,
      cellSizes,
      classes,
      removeFilter,
      clearOtherFilters,
      sortByName,
      clearByName,
    ]
  );

  const renderTh = useCallback(
    (name: string) => {
      const fieldConfig = config[name];
      if (!fieldConfig) throw new Error(`Config for field "${tableName}.${name}" not found`);
      const filterConfig = fieldConfig.filterAs ? config[fieldConfig.filterAs] : fieldConfig;
      const Header = fieldConfig.headerComponent || Th;

      return (
        <Header
          key={name}
          config={fieldConfig}
          filterConfig={filterConfig}
          tableName={tableName}
          {...props}
          {...getCellProps(name, fieldConfig.filterAs)}
        />
      );
    },
    [config, Th, tableName, props, getCellProps]
  );

  return (
    <TheadComponent classes={classes}>
      <div className={classes.headerSticky}>
        {selectionProps &&
          (selectionProps.selectionType === SelectionType.CHECK_BOX ? (
            <button type="button" onClick={selectionProps.toggle}>
              <CheckBox checked={selectionProps.selected && !isEmpty} header={true} />
            </button>
          ) : (
            <div className={wClasses.holder} />
          ))}
        {columns.filter((name) => pinnedColumns.includes(name)).map(renderTh)}
      </div>
      {columns.filter((name) => !pinnedColumns.includes(name)).map(renderTh)}
    </TheadComponent>
  );
};

// const MemoThead = memo(THead, compareProps) as typeof THead;
// export { MemoThead as THead }
export { THead };
