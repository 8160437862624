import { FC, useCallback } from 'react';
import { Select } from 'components/Select';
import { hoursToMinutes, fromUnixTime } from 'date-fns';
import { ReactComponent as clockIcon } from './icons/clock.svg';
import { formatInTimeZone } from 'date-fns-tz';

export type TTimePickerProps = {
  listRef: React.RefObject<HTMLDivElement>;
  value: string;
  onChange: (value: string) => void;
  minutesIncrement: number;
  hasError?: boolean;
};

const getTimeOptions = (minutesIncrement: number, timeFormat = 'hh:mm a'): Map<string, string> => {
  const result = [] as Array<[string, string]>;
  for (let i = 0; i < hoursToMinutes(24); i += minutesIncrement) {
    const date = fromUnixTime(i * 60);
    result.push([formatInTimeZone(date, 'UTC', timeFormat), formatInTimeZone(date, 'UTC', timeFormat)]);
  }
  return new Map<string, string>(result);
};

export const TimePicker: FC<TTimePickerProps> = ({
  value = '12:00 AM',
  onChange,
  minutesIncrement,
  listRef,
}): JSX.Element => {
  const items = getTimeOptions(minutesIncrement);

  const onTimeChange = useCallback(([value]: string[]) => onChange(value), [onChange]);

  return (
    <Select
      listRef={listRef}
      isClearable={false}
      items={items}
      value={[value]}
      onChange={onTimeChange}
      CustomIcon={clockIcon}
    />
  );
};
