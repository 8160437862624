import { useCallback, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useApi } from 'domain/api';
import { useMetaData } from 'lib/hooks';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { ReactComponent as CompleteIcon } from './icons/complete.svg';
import { ReactComponent as NotifyIcon } from './icons/notify.svg';
import { Modal } from 'components/Modal';
import { parseError } from 'lib/errorParser';
import { useNotifications } from 'providers/NotificationsProvider';
import { TEntityName } from 'lib';
import { bahai_statuscode } from 'config/EntityMetadata/bahai_reminder';
import { bahai_inquirerstatus as requesterStatus } from 'config/EntityMetadata/bahai_inquirer';
import { statecode, statuscode } from 'config/EntityMetadata/bahai_code';
import { useTableConfig } from 'lib/helpers';
import {
  config as interactionConfig,
  getFormConfig as getInteractionFormConfig,
  validation,
  WarningsImprover,
} from 'schemas/interaction';
import { Form as ListForm } from 'components/ListPage/components/Form';
import { getFormattedNow } from 'lib/adapter';
import { useRecord } from 'lib/record';
import { useLocation } from 'react-router-dom';

export const useNotifyReminder = () => {
  const { reminderNotify } = useApi();
  const { t } = useTranslation();
  const { addActionFailed, addActionCompleted, addActionUncompleted } = useNotifications();

  const [id, setId] = useState('');
  const close = useCallback(() => {
    setId('');
    setLoading(false);
  }, []);
  const [loading, setLoading] = useState(false);

  const send = useCallback(() => {
    setLoading(true);
    reminderNotify(id)
      .then(() => addActionCompleted(t('All Preliminary Notices were sent')))
      .catch(() => addActionFailed(t('Something went wrong')))
      .finally(close);
  }, [addActionCompleted, addActionFailed, close, id, reminderNotify, t]);

  const content = useMemo(
    () =>
      id ? (
        <Modal
          title={t('Send Preliminary Notice')}
          portal={false}
          onClose={close}
          loading={loading}
          fixedSize={true}
          header={t('Would you like to send a preliminary notice about this Reminder?')}
          controls={[
            { title: t('Send'), type: 'submit', role: 'primary', onClick: send },
            { title: t('Cancel'), onClick: close },
          ]}
        />
      ) : null,
    [close, id, loading, send, t]
  );

  const action: Action = useMemo(
    () => ({
      title: t('Notify'),
      name: 'notify',
      display: ({ isEditAllowed }) => isEditAllowed,
      onClick: ({ selectedItems }) => {
        if (Number(selectedItems[0].bahai_statuscode) === bahai_statuscode.Upcoming) {
          setId(selectedItems?.[0]?.activityid);
        } else {
          addActionUncompleted(t('Reminder Status should be Upcoming to Send Preliminary Notices'));
        }
      },
      Icon: NotifyIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SinglePage,
      allowedDevices: AllowedDevices.All,
    }),
    [addActionUncompleted, t]
  );

  return { action, content };
};

export const useCompleteReminder = (isBulk = false, onItemsComplited?: () => void) => {
  const entityName: TEntityName = 'reminder';
  const [items, setItems] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(false);
  const reloadRef = useRef<() => void>(() => undefined);
  const { t } = useTranslation();
  const { addActionFailed, addActionCompleted } = useNotifications();
  const { request } = useApi();
  const {
    PrimaryIdAttribute,
    entityConfig: { url },
  } = useMetaData(entityName);

  const {
    entityConfig: { fields },
  } = useMetaData('interaction');
  const config = useTableConfig(fields, interactionConfig, 'interaction');

  const [initialValues, setInitialValues] = useState<Record<string, any>>();

  const interactionForm = useMemo(
    () => (
      <ListForm
        onClose={() => {
          setInitialValues(undefined);
          onItemsComplited && onItemsComplited();
        }}
        entityName="interaction"
        {...{
          config,
          getFormConfig: getInteractionFormConfig,
          validation,
          initialValues,
          postAction: () => {
            setInitialValues(undefined);
            onItemsComplited && onItemsComplited();
          },
          WarningsImprover,
        }}
      />
    ),
    [config, initialValues, onItemsComplited]
  );

  const completeAction: Action = useMemo(
    () => ({
      title: t('Complete'),
      name: 'completeReminder',
      onClick: ({ selectedItems, reload }) => {
        setItems(selectedItems);
        reloadRef.current = reload;
      },
      display: ({ data, context, selectedItemsCount, isEditAllowed }) =>
        context === ActionContext.SinglePage
          ? isEditAllowed && data.bahai_statuscode !== bahai_statuscode.Completed
          : !!selectedItemsCount,
      Icon: CompleteIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.All,
      allowedDevices: AllowedDevices.All,
    }),
    [t]
  );

  const { getData } = useRecord('requester');

  const notCompletedItems = useMemo(
    () => items.filter((v) => v.bahai_statuscode !== bahai_statuscode.Completed),
    [items]
  );

  const { logicalName: inquiryLogicalName } = useMetaData('inquiry');
  const { pathname } = useLocation();

  const complete = useCallback(async () => {
    setLoading(true);
    try {
      for (const { [PrimaryIdAttribute]: id } of notCompletedItems) {
        await request<Record<string, any>>({
          url: `${url}(${id})`,
          data: {
            bahai_statuscode: bahai_statuscode.Completed,
            statecode: statecode.Completed,
            statuscode: statuscode.Completed,
          },
          method: 'patch',
        });
      }

      addActionCompleted(isBulk ? t('Reminders are Completed') : t('Reminder is Completed'));
      const { _bahai_inquirerid_value: requester } = items[0];
      const {
        _regardingobjectid_value: inquiry,
        '_regardingobjectid_value@Microsoft.Dynamics.CRM.lookuplogicalname': regardingType,
        description = null,
      } = notCompletedItems[0] || {};

      let bahai_inquiryid = null;

      if (pathname.includes('/inquiry/')) {
        bahai_inquiryid = items[0]._regardingobjectid_value.split('<|>').pop();
      } else if (
        regardingType === inquiryLogicalName &&
        notCompletedItems.every((v) => v._regardingobjectid_value === inquiry)
      ) {
        bahai_inquiryid = inquiry.split('<|>').pop();
      }

      const { bahai_statuscode: status } = await getData(requester);
      if (status !== requesterStatus.DoNotContact) {
        setInitialValues({
          bahai_directioncode: true,
          bahai_communicatedon: getFormattedNow(),
          bahai_inquirerid: requester,
          bahai_inquiryid,
          bahai_comment: notCompletedItems.length === 1 ? description : null,
        });
      } else {
        onItemsComplited && onItemsComplited();
      }

      setItems([]);
      reloadRef.current();
    } catch (e) {
      addActionFailed(parseError(e));
    } finally {
      setLoading(false);
    }
  }, [
    notCompletedItems,
    addActionCompleted,
    isBulk,
    t,
    items,
    pathname,
    inquiryLogicalName,
    getData,
    PrimaryIdAttribute,
    request,
    url,
    addActionFailed,
    onItemsComplited,
  ]);

  const content = useMemo(() => {
    if (initialValues) return interactionForm;
    if (!items.length) return null;
    return (
      <Modal
        title={isBulk ? t('Complete Reminders?') : t('Complete Reminder?')}
        onClose={() => setItems([])}
        controls={[
          { title: t('Complete'), type: 'button', onClick: complete, role: 'primary' },
          { title: t('Cancel'), type: 'button', onClick: () => setItems([]) },
        ]}
        loading={loading}
        portal={false}
      >
        <div>
          <Trans>
            {isBulk
              ? `Do you want to complete selected Reminders`
              : `Do you want to complete Reminder <strong>${items[0].subject}</strong>`}
            ? This is an irreversible action
          </Trans>
        </div>
      </Modal>
    );
  }, [initialValues, interactionForm, items, isBulk, t, complete, loading]);

  return { completeAction, content };
};
