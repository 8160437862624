import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Panel from 'components/Panel';
import classes from 'components/ListPage/components/ColumnsPanel/panel.module.scss';
import cx from 'classnames';
import { Switch } from 'components/Switch';
import { Cell } from 'components/ListPage/components/ColumnsPanel';

export type TabConfig = {
  name: string;
  label: string;
  visible?: boolean;
};

export type PanelProps = {
  tabs: TabConfig[];
  setTabsConfig: (props: TabConfig[]) => void;
  onClose: () => void;
  initialConfig?: TabConfig[];
};

export const TabsPanel = ({ tabs, initialConfig, setTabsConfig, onClose }: PanelProps) => {
  const [cfg, setCfg] = useState([...tabs]);

  const reset = useCallback(() => setCfg(initialConfig || tabs), [initialConfig, tabs]);
  const onApply = useCallback(() => setTabsConfig(cfg), [cfg, setTabsConfig]);

  const update = useCallback(
    (value: TabConfig) => {
      const result = [...cfg];
      result.splice(
        cfg.findIndex((v) => v.label === value.label),
        1,
        value
      );
      setCfg(result);
    },
    [cfg, setCfg]
  );

  const move = useCallback(
    (source: TabConfig, target: TabConfig, bottom = false) => {
      setCfg((cfg) => {
        const result = cfg.filter((v) => v.label !== target.label);
        if (bottom) return [...result, target];
        result.splice(
          result.findIndex((v) => v.label === source.label),
          0,
          target
        );
        return result;
      });
    },
    [setCfg]
  );

  const allDisabled = useMemo(() => cfg.every((v) => !v.visible), [cfg]);
  const toggleAll = useCallback(() => setCfg((cfg) => cfg.map((v) => ({ ...v, visible: allDisabled }))), [allDisabled]);

  const { t } = useTranslation();

  return (
    <Panel
      onClose={onClose}
      title={t('Edit Tabs')}
      visible={true}
      controls={[
        { title: t('Apply'), role: 'primary', onClick: onApply },
        { title: t('Restore To Default'), onClick: reset },
      ]}
    >
      <div className={classes.root}>
        <div className={classes.cellsWrapper}>
          <div className={cx(classes.cell, classes.toggle)}>
            <Switch onChange={toggleAll} value={!allDisabled} />
            <div className={classes.label}>{allDisabled ? t('Select All') : t('Unselect All')}</div>
          </div>
          {cfg.map((props, index) => (
            <Cell
              key={Object.values({ ...props, index }).join('-')}
              move={move}
              props={{ ...props, name: props.label }}
              showPin={false}
              onChange={update}
              up={index > 0 ? () => move(cfg[index - 1], props) : undefined}
              down={index < cfg.length - 1 ? () => move(props, cfg[index + 1]) : undefined}
              top={index > 0 ? () => move(cfg[0], props) : undefined}
              bottom={index < cfg.length - 1 ? () => move(cfg[cfg.length - 1], props, true) : undefined}
            />
          ))}
        </div>
      </div>
    </Panel>
  );
};
