import { ReactComponent as LockIcon } from './icons/lock.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as DraftIcon } from './icons/draft.svg';
import { ReactComponent as ClockIcon } from './icons/clock.svg';
import { ReactComponent as ExclamationIcon } from './icons/exclamation.svg';
import classes from './alertBar.module.scss';
import cx from 'classnames';

const icons = {
  lock: <LockIcon />,
  warning: <WarningIcon />,
  info: <InfoIcon />,
  draft: <DraftIcon />,
  clock: <ClockIcon />,
  exclamation: <ExclamationIcon />,
};

export const AlertBar = ({
  message,
  isWarning,
  icon = 'lock',
  styles,
}: {
  message?: string | JSX.Element;
  isWarning?: boolean;
  icon?: keyof typeof icons;
  styles?: Record<string, any>;
}) => {
  return (
    <div style={styles} className={cx(classes.lockAlert, { [classes.isWarning]: isWarning })}>
      <div className={classes.lockIcon}>{isWarning ? icons.warning : icons[icon]}</div>
      <div className={classes.text}>{message}</div>
    </div>
  );
};
