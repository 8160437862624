const columns = [
  'fullname',
  'sharepointcreatedon',
  'author',
  'bahai_description',
  'filetype',
  'documentid',
  'readurl',
  'isrecursivefetch',
  'absoluteurl',
] as const;

export default { columns, fields: [] };
