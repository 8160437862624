import { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const ScreenContext = createContext({
  isMobile: false,
  isTablet: false,
  isDescTop: true,
  orientation: 'H',
});

const TABLET_WIDTH = 768;
const PC_WIDTH = 1024;

export const ScreenProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const isMobile = useMemo(() => width < TABLET_WIDTH, [width]);
  const isTablet = useMemo(() => width < PC_WIDTH && width >= TABLET_WIDTH, [width]);
  const isDescTop = useMemo(() => !isMobile && !isTablet, [isMobile, isTablet]);

  const orientation = useMemo(() => (width > height ? 'H' : 'V'), [height, width]);

  const checkSize = useCallback(() => {
    if (window.innerWidth !== width) setWidth(window.innerWidth);
    if (window.innerHeight !== height) setHeight(window.innerHeight);
  }, [height, width]);

  const timer = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    checkSize();
    window.addEventListener('resize', checkSize);
    timer.current = setInterval(checkSize, 300);
    return () => {
      if (timer.current) clearInterval(timer.current);
      window.removeEventListener('resize', checkSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <ScreenContext.Provider value={{ isMobile, isTablet, isDescTop, orientation }}>{children}</ScreenContext.Provider>
  );
};
