import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TEntityName } from 'lib';
import { useMetaData, useRemove } from 'lib/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'components/Modal';
import classes from './multiRemove.module.scss';
import Tooltip from 'components/Tooltip';
import { ReactComponent as Icon } from './icon.svg';
import { Spinner } from 'components/ListPage/components/MultiRemovePopup/components/Spinner';

type PreRemove = (item: Record<string, any>) => Promise<void | JSX.Element>;

const preRemoveDefault: PreRemove = () => new Promise<void | JSX.Element>((resolve) => resolve());

export const useMultiRemove = ({
  entityName,
  displayName,
  nameAttribute,
  selectedItems,
  isNotRemovable = () => false,
  isConfirmationMessageNeeded,
  onClose,
  isRemove = false,
  customRemove,
}: {
  entityName: TEntityName;
  displayName?: string;
  nameAttribute?: string;
  selectedItems: Record<string, any>[];
  isNotRemovable?: (data: Record<string, any>) => JSX.Element | false;
  isConfirmationMessageNeeded?: (data: Record<string, any>) => boolean;
  onClose: (reloadRequired: boolean) => void;
  isRemove?: boolean;
  customRemove?: (data: Record<string, any>) => void;
}) => {
  const { t } = useTranslation();
  const { remove, removeWithConfirmation } = useRemove(entityName, customRemove);
  const { PrimaryIdAttribute, PrimaryNameAttribute, displayCollectionName } = useMetaData(entityName);
  const [index, setIndex] = useState(-1);
  const errors = useRef([] as Array<{ item: Record<string, any>; error: JSX.Element }>);
  const confirmationMessage = useRef<string>();
  const preRemoveFunction = useRef(preRemoveDefault);

  const onFinish = useCallback(() => {
    onClose(index !== errors.current.length);
    errors.current = [];
    setIndex(-1);
  }, [onClose, setIndex, index]);

  const removeNext = useCallback(() => {
    const item = selectedItems[index];
    const error = isNotRemovable(item);
    if (error) {
      errors.current.push({ item, error });
      setTimeout(() => setIndex((v) => v + 1), 100);
    } else {
      preRemoveFunction
        .current(item)
        .then((error) => {
          if (error) {
            errors.current.push({ item, error });
            setIndex((v) => v + 1);
          } else {
            (isConfirmationMessageNeeded && isConfirmationMessageNeeded(item)
              ? removeWithConfirmation(confirmationMessage.current as string, item)
              : remove(item)
            )
              .then(() => {
                setIndex((v) => v + 1);
              })
              .catch((e) => {
                let error = <Trans>Server Error</Trans>;
                try {
                  if (e.response.status === 403) {
                    error = <Trans>You have no Permissions to Delete this Record</Trans>;
                  } else {
                    error = JSON.parse(e.response.data.error.message)
                      .map((v: any) => v.Message)
                      .join(', ');
                  }
                } catch (e) {
                  console.error(e);
                } finally {
                  errors.current.push({ item, error });
                  setIndex((v) => v + 1);
                }
              });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [selectedItems, index, isNotRemovable, isConfirmationMessageNeeded, removeWithConfirmation, remove]);

  useEffect(() => {
    if (index > -1 && index < selectedItems.length) {
      removeNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, selectedItems.length]);

  const onRemove = (message?: string, preRemove = preRemoveDefault) => {
    confirmationMessage.current = message;
    preRemoveFunction.current = preRemove;
    setIndex(0);
  };

  const operation = useMemo(() => (isRemove ? t('Removing') : t('Deleting')), [isRemove, t]);
  const operationDone = useMemo(() => (isRemove ? t('Removed') : t('Deleted')), [isRemove, t]);

  const Popup = useMemo(() => {
    if (index === -1) return null;
    return (
      <Modal
        controls={[{ title: t('Ok'), role: 'primary', disabled: index < selectedItems.length, onClick: onFinish }]}
      >
        <div className={classes.progressWrapper}>
          <Spinner
            success={index >= selectedItems.length}
            text={
              <>
                <strong>{Math.min(selectedItems.length, index + 1)}</strong>
                {`/${selectedItems.length}`}
              </>
            }
          />
          <div className={classes.header}>
            {index < selectedItems.length
              ? t('{{ operation }} in progress', { operation })
              : t('{{ operation }} completed', { operation })}
          </div>
          <div className={classes.info}>
            {t('{{ operationDone }} {{ value }} {{ displayCollectionName }} from {{ total }} selected', {
              value: Math.min(index, selectedItems.length) - errors.current.length,
              total: selectedItems.length,
              displayCollectionName: displayName ?? displayCollectionName,
              operationDone,
            })}
          </div>
        </div>
        {errors.current.length > 0 && (
          <>
            <div className={classes.description}>{t('Error description')}</div>
            <div className={classes.errors}>
              {errors.current.map(({ item, error }) => (
                <div key={item[PrimaryIdAttribute]} className={classes.error}>
                  <div className={classes.errorLabel}>
                    {item[nameAttribute ?? PrimaryNameAttribute] || item.bahai_id}
                  </div>
                  <Tooltip content={error}>
                    <div className={classes.iconWrapper}>
                      <Icon />
                    </div>
                  </Tooltip>
                </div>
              ))}
            </div>
          </>
        )}
      </Modal>
    );
  }, [
    index,
    t,
    selectedItems.length,
    onFinish,
    operation,
    displayName,
    displayCollectionName,
    operationDone,
    PrimaryIdAttribute,
    nameAttribute,
    PrimaryNameAttribute,
  ]);

  return { onRemove, Popup };
};
