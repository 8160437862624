import { SinglePage } from 'components/SinglePage';
import {
  config,
  getFormConfig,
  getDetailsConfig,
  isActive,
  isNotEditable,
  isNotRemovable,
  statusFields,
  validate,
  validation,
  Keys,
} from 'schemas/email';
import { Improver } from 'pages/EmailList';
import { useCallback } from 'react';
import { Action } from 'components/Actions';
import { useSendDraftEmail, useReSendEmail } from 'schemas/email/actions';
import { useMetaData } from 'lib/hooks';
import { useTranslation } from 'react-i18next';
import { useEmail } from 'schemas/email/hooks';
import { Privilege } from 'lib';
import { useSecurity } from 'providers/AuthProvider';

export const Email = () => {
  const {
    entityConfig: { fields },
  } = useMetaData('email');
  const { t } = useTranslation();

  const getLockMessage = useCallback(
    (data: Record<Keys, any>) => ({
      message: t('Record State is {{ status }}', {
        status: new Map(fields.statecode.options).get(`${data.statecode}`),
      }),
    }),
    [fields.statecode.options, t]
  );

  const { isGranted } = useSecurity();

  const { action: sendAction, content } = useSendDraftEmail();
  const { action: resendAction, content: resendContent } = useReSendEmail();

  const getActions = useCallback(
    (baseActions: Action[]) => [
      ...baseActions,
      ...(isGranted('email', Privilege.Write) ? [sendAction, resendAction] : []),
    ],
    [isGranted, sendAction, resendAction]
  );

  const { getActionControls, onSubmit } = useEmail();

  return (
    <>
      {content}
      {resendContent}
      <SinglePage
        entityName="email"
        FormImprover={Improver}
        renderHeader={(data) => `${data.subject || 'No Subject'}`}
        {...{
          getFormConfig,
          getDetailsConfig,
          getLockMessage,
          config,
          validation,
          validate,
          isNotEditable,
          isActive,
          getActionControls,
          onSubmit,
          statusFields,
          isNotRemovable,
          getActions,
        }}
      />
    </>
  );
};
