import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { mathRouteName } from 'domain/routes';
import { TEntityName } from 'lib';

export const getEntityNameFromRoute = (name: string) => name.replace('List', '') as TEntityName;

type Colors = { color: string; bg: string; subBg: string };

export const colorsConfig: Partial<Record<TEntityName, Colors>> = {
  requester: {
    color: '#3949AB',
    bg: '#C5CAE9',
    subBg: '#E8EAF6',
  },
  inquiry: {
    color: '#8E24AA',
    bg: '#E1BEE7',
    subBg: '#F3E5F5',
  },
  event: {
    color: '#039BE5',
    bg: '#B3E5FC',
    subBg: '#E1F5FE',
  },
  resource: {
    color: '#00897B',
    bg: '#B2DFDB',
    subBg: '#E0F2F1',
  },
  email: {
    color: '#FFA000',
    bg: '#FFECB3',
    subBg: '#FFF8E1',
  },
  contact: {
    color: '#546E7A',
    bg: '#CFD8DC',
    subBg: '#ECEFF1',
  },
};

const getColorsByEntityName = (name?: TEntityName) =>
  name && colorsConfig[name]
    ? (colorsConfig[name] as Colors)
    : {
        color: '#D81B60',
        bg: '#F8BBD0',
        subBg: '#FCE4EC',
      };

export const useColors = () => {
  const { pathname } = useLocation();

  const getColorsByPathName = useCallback((pathname: string) => {
    const routeName = mathRouteName(pathname);
    const entityName = routeName ? getEntityNameFromRoute(routeName) : undefined;
    return getColorsByEntityName(entityName);
  }, []);

  const colors = useMemo(() => getColorsByPathName(pathname), [getColorsByPathName, pathname]);

  return { colors, getColorsByPathName };
};
