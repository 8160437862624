import { useMemo } from 'react';
import cx from 'classnames';
import classes from 'components/Form/Input/input.module.scss';
import { TInputProps } from 'components/Form/types';
import { createAutocomplete, IAutoCompleteProps } from 'components/AutoComplete';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { AutocompleteType } from 'components/AutoComplete/hooks';
import { TMultiReferenceEntity } from 'lib/filters';

type TFormAutocompleteProps = TInputProps & {
  isMultiple?: boolean;
};

export const createFormAutocomplete = ({
  entities,
  isMultiple = false,
  type = AutocompleteType.Lookup,
  dependencies,
  searchBy,
}: {
  entities: Array<string | TMultiReferenceEntity>;
  isMultiple?: boolean;
  type?: AutocompleteType;
  dependencies?: string[];
  searchBy?: string[];
}) => {
  const Autocomplete = createAutocomplete({
    entities,
    isMultiple,
    type,
    dependencies,
    searchBy,
  } as IAutoCompleteProps);
  return ({ input: inputProps, label, className, meta, ...props }: TFormAutocompleteProps) => {
    const errors = useMemo(() => (meta.touched ? meta.error || meta.submitError : undefined), [meta]);

    return (
      <div className={cx(classes.wrapper, className, { [classes.disabled]: !!props.disabled })}>
        <label
          className={cx(classes.label, {
            [classes.required]: props.required,
            [classes.readOnly]: props.readOnly,
          })}
          htmlFor={inputProps.name}
        >
          {label}
        </label>
        <Autocomplete {...inputProps} {...props} hasError={!!errors} data={meta.data} />
        <ValidationMessage error={errors} />
      </div>
    );
  };
};
