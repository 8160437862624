import ListPage, { TListPage } from 'components/ListPage';
import { useTranslation } from 'react-i18next';
import * as baseConfig from 'schemas/requesterEvents';

const EventsTab = (props: Partial<TListPage>) => {
  const { t } = useTranslation();
  return (
    <ListPage
      {...props}
      entityName="invitedRequester"
      placeholderText={t('There are not any Events yet')}
      {...baseConfig}
      allowRowClick={false}
    />
  );
};

export default EventsTab;
