import { usePagination } from 'components/Table/hooks';
import classes from './pagination.module.scss';
import cx from 'classnames';
import { ReactComponent as NextIcon } from './icons/next.svg';
import { ReactComponent as PrevIcon } from './icons/prev.svg';
import { Select } from 'components/Select';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ScreenContext } from 'providers/ScreenProvider';

const items = new Map([
  ['50', '50'],
  ['100', '100'],
  ['250', '250'],
]);

export const Pagination = ({ pages }: { pages: number }) => {
  const { page: currentPage, goToPage, lastPage, firstPage, pagesList, pageSize, setPageSize } = usePagination(pages);
  const { isMobile } = useContext(ScreenContext);
  const { t } = useTranslation();
  const changePageSize = useCallback(([size]: string[]) => setPageSize(Number(size)), [setPageSize]);
  return (
    <div className={classes.pagination}>
      {!isMobile && (
        <div className={classes.pagesSelect}>
          <span>{t('Show:')}</span>
          <Select
            className={classes.select}
            isClearable={false}
            value={[pageSize.toString()]}
            items={items}
            onChange={changePageSize}
          />
        </div>
      )}
      <button disabled={currentPage === 1} className={cx(classes.btn)} onClick={firstPage}>
        <PrevIcon />
      </button>
      {pagesList.map((page) => (
        <button
          className={cx(classes.btn, {
            [classes.active]: page === currentPage,
          })}
          key={page}
          onClick={() => goToPage(page)}
        >
          {page}
        </button>
      ))}
      <button disabled={currentPage === pages} className={cx(classes.btn, classes.last)} onClick={lastPage}>
        <NextIcon />
      </button>
    </div>
  );
};
