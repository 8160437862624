import ListPage, { TListPage } from 'components/ListPage';
import * as baseConfig from 'schemas/document';
import { useCallback, useMemo } from 'react';
import { Action } from 'components/Actions';
import { useDownload, useEditDescription, useUpload } from 'pages/DocumentList/hooks';
import { useApi } from 'domain/api';
import { useMetaData } from 'lib/hooks';
import { TEntityName } from 'lib/types';

const DocumentList = ({
  parentEntityName,
  parentId,
  isNotCreatable,
  isNotEditable,
  ...props
}: Partial<TListPage> & { parentEntityName: TEntityName; parentId: string; isNotEditable?: JSX.Element | false }) => {
  const { deleteDocument, request } = useApi();
  const { PrimaryIdAttribute, logicalName, url } = useMetaData(parentEntityName);

  const remove = useCallback(
    async (data: Record<string, any>) => {
      try {
        await deleteDocument(
          data['documentid'],
          data['filetype'],
          data['locationid'],
          data['sharepointdocumentid'],
          logicalName,
          PrimaryIdAttribute,
          parentId
        );
      } catch (e: any) {
        if (!(e.response.status === 400 && e.response.data?.error?.message === 'List item does not exist')) {
          throw e;
        }
      }
    },
    [PrimaryIdAttribute, logicalName, parentId, deleteDocument]
  );

  const onRemove = useCallback(
    async (itemsCount: number) => {
      if (itemsCount === 1) {
        await request<Record<string, any>>({
          url: `${url}(${parentId})`,
          data: { bahai_isattachmentcreated: false },
          method: 'patch',
        });
      }
    },
    [parentId, url, request]
  );

  const download = useDownload();
  const { getAction: getEditAction, content: editContent } = useEditDescription(parentEntityName);
  const { getAction: getUploadAction, content: uploadContent } = useUpload(parentEntityName, parentId);

  const getActions = useCallback(
    (baseActions: Action[]) => {
      return [getUploadAction(isNotCreatable), ...baseActions, getEditAction(isNotEditable), download];
    },
    [download, getEditAction, getUploadAction, isNotCreatable, isNotEditable]
  );

  const links = useMemo(
    () => ({
      [parentEntityName]: {
        from: PrimaryIdAttribute,
        to: 'regardingobjectid',
        fields: [],
        condition: [{ attribute: PrimaryIdAttribute, operator: 'eq', value: parentId }],
      },
    }),
    [PrimaryIdAttribute, parentEntityName, parentId]
  );

  return (
    <>
      <ListPage
        entityName="document"
        {...baseConfig}
        {...props}
        getActions={getActions}
        customRemove={remove}
        isSubgrid={true}
        isCreateHidden={true}
        displayViews={false}
        allowRowClick={false}
        onItemsRemoved={onRemove}
        links={links}
      />
      {editContent}
      {uploadContent}
    </>
  );
};

export default DocumentList;
