import { FC, ReactNode, useState } from 'react';
import classes from './accordion.module.scss';
import cx from 'classnames';

export const Accordion: FC<{ children: ReactNode; title?: string | JSX.Element; disabled?: boolean }> = ({
  children,
  title,
  disabled = false,
}) => {
  const [isOpened, setIsOpened] = useState(true);
  return (
    <div className={cx(classes.root, { [classes.closed]: !isOpened })}>
      <h2 className={classes.header} onClick={() => setIsOpened((v) => !v)}>
        {title}
      </h2>
      <div className={cx(classes.content, { [classes.disabled]: disabled })}>{children}</div>
    </div>
  );
};
