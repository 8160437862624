import ListPage, { TListPage } from 'components/ListPage';
import { useCallback } from 'react';
import * as baseConfig from 'schemas/email';
import { useSystemUserId } from 'lib/helpers';
import { useEmail } from 'schemas/email/hooks';
import { Action } from 'components/Actions';
import { useCreateTemplateFromList } from 'schemas/template/actions';

export const Improver = () => <></>;

export const useEmailInitialValues = () => {
  const userId = useSystemUserId();

  return useCallback(async () => {
    return {
      directioncode: true,
      from: 'systemuser<|>' + userId,
    };
  }, [userId]);
};

const EmailList = (props: Partial<TListPage>) => {
  const getEmailListInitialValues = useEmailInitialValues();
  const getInitialValues = props.getInitialValues ?? getEmailListInitialValues;

  const { getActionControls, onSubmit } = useEmail();

  const { createTemplateAction, content } = useCreateTemplateFromList();

  const getActions = useCallback(
    (baseActions: Action[]) => {
      return [...baseActions, createTemplateAction];
    },
    [createTemplateAction]
  );

  return (
    <>
      {content}
      <ListPage
        getActionControls={getActionControls}
        onSubmit={onSubmit}
        entityName="email"
        {...baseConfig}
        {...props}
        getInitialValues={getInitialValues}
        getActions={getActions}
      />
    </>
  );
};

export default EmailList;
