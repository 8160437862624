import { SinglePage, Tab } from 'components/SinglePage';
import {
  config,
  getFormConfig,
  getDetailsConfig,
  links,
  validation,
  isNotEditable,
  isActive,
  isNotRemovable,
  isConfirmationMessageNeeded,
  isConfirmationMessageRequired,
  statusFields,
  getLockMessage,
  getWarningsImprover,
} from 'schemas/inquiry';
import { Improver } from 'pages/InquiryList';
import { RequesterFormProvider } from 'schemas/inquiry/components/RequesterFormProvider';
import { Trans, useTranslation } from 'react-i18next';
import InteractionList from 'pages/InteractionList';
import { useParams } from 'react-router-dom';
import { useCallback, useMemo, useContext } from 'react';
import { config as interactionConfig, getInitialValues as getBaseInteractionInitialValues } from 'schemas/interaction';
import { AdditionalConfigType, ApiFilter, TEntityName } from 'lib';
import { Keys as InteractionKeys } from 'schemas/interaction';
import { useChangeStatus } from 'components/ChangeStatus';
import { Action } from 'components/Actions';
import { Loader } from 'components/Loader';
import classes from 'pages/Common/pages.common.module.scss';
import DocumentList from 'pages/DocumentList';
import { config as documentConfig } from 'schemas/document';
import { validate } from 'schemas/inquiry';
import { getFormattedNow } from 'lib/adapter';
import { useSystemUserId } from 'lib/helpers';
import ReminderList from 'pages/ReminderList';
import { isNotRemovable as isReminderNotRemovable } from 'schemas/reminder';
import EmailList from 'pages/EmailList';
import { config as emailConfig } from 'schemas/email';
import { isDoNotContact } from 'schemas/requester';
import { useNotifications } from 'providers/NotificationsProvider';
import { AuthContext } from 'providers/AuthProvider';
import { useExport } from 'lib/hooks';

const tabNames: TEntityName[] = ['interaction', 'email', 'reminder'];

const getFileName = (data: Record<string, any>, format: string) =>
  ['Inquiry', data['requester.bahai_firstname'], data['requester.bahai_lastname'], getFormattedNow()]
    .filter((v) => !!v)
    .join('_') +
  '.' +
  format;

const updatedInteractionConfig: AdditionalConfigType<InteractionKeys> = {
  ...interactionConfig,
  bahai_inquirerid: {
    ...interactionConfig.bahai_inquirerid,
    fieldProps: ({ classes }) => ({ readOnly: true, className: classes.long }),
  },
  bahai_inquiryid: {
    ...interactionConfig.bahai_inquiryid,
    fieldProps: ({ classes }) => ({ readOnly: true, className: classes.long }),
  },
};

export const Inquiry = () => {
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();

  const { addWarning } = useNotifications();

  const userId = useSystemUserId();

  const getReminderInitialValues = useCallback(
    async (data: Record<string, any>) => {
      const to = ['systemuser<|>' + userId];
      const ownerId = data._ownerid_value;
      if (data['_ownerid_value@Microsoft.Dynamics.CRM.lookuplogicalname'] === `systemuser` && ownerId !== userId) {
        to.push('systemuser<|>' + ownerId);
      }
      return {
        bahai_inquirerid: data._bahai_inquirerid_value,
        regardingobjectid: 'bahai_inquiry<|>' + id,
        requiredattendees: to,
      };
    },
    [id, userId]
  );

  const getInteractionInitialValues = useCallback(
    async (data: Record<string, any>) => {
      const baseValues = await getBaseInteractionInitialValues();
      return {
        ...baseValues,
        bahai_inquirerid: data._bahai_inquirerid_value,
        bahai_inquiryid: id,
        bahai_communicatedon: getFormattedNow(),
      };
    },
    [id]
  );

  const isNotCreatable = useCallback(
    (data: Record<string, any>) => !isActive(data) && <Trans>Inquiry State is Inactive</Trans>,
    []
  );

  const isRequesterValidForEmail = useCallback((data: Record<string, any>) => {
    switch (true) {
      case isDoNotContact(data):
        return <Trans>Requester Status is Do Not Contact</Trans>;
      case !isActive(data):
        return <Trans>Requester State is Inactive</Trans>;
      case !data.bahai_emailaddress1 && !data.bahai_emailaddress2:
        return <Trans>Requester doesn't have an email address</Trans>;
      default:
        return false;
    }
  }, []);

  const isDocumentNotRemovable = (data: Record<string, any>) =>
    !isActive(data) && <Trans>Inquiry State should be Active to delete it</Trans>;

  const {
    changeStatus,
    content: changeStatusContent,
    pageLoading: changeStatusLoading,
  } = useChangeStatus({
    entityName: 'inquiry',
    statusFieldName: 'bahai_statuscode',
    additionalNotesFieldName: 'bahai_additionalnote',
    links,
  });

  const isReminderNotCreatable = useCallback(
    (data: Record<string, any>) => !isActive(data) && <Trans>Inquiry State is Inactive</Trans>,
    []
  );

  const { action: exportAction, content: exportContent } = useExport(id, 'inquiry', tabNames, getFileName);

  const getActions = useCallback(
    (baseActions: Action[]) => [...baseActions, exportAction, changeStatus],
    [changeStatus, exportAction]
  );

  const filters: ApiFilter[] = useMemo(
    () => [{ condition: [{ attribute: 'bahai_inquiryid', operator: 'eq', value: id }] }],
    [id]
  );

  const hiddenFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'regardingobjectid', value: id }] }],
    [id]
  );

  const getEmailInitialValues = useCallback(
    (data: Record<string, any>) => async () => {
      const requesterError = isRequesterValidForEmail({
        bahai_statuscode: data['requester.bahai_statuscode'],
        statecode: data['requester.statecode'],
        bahai_emailaddress1: data['requester.bahai_emailaddress1'],
        bahai_emailaddress2: data['requester.bahai_emailaddress2'],
      });

      if (requesterError)
        addWarning({ title: t('Requester can’t be added as Email recipient'), content: requesterError });

      return {
        directioncode: true,
        from: 'systemuser<|>' + userId,
        regardingobjectid: 'bahai_inquiry<|>' + id,
        ...(!requesterError ? { to: 'bahai_inquirer<|>' + data._bahai_inquirerid_value } : {}),
      };
    },
    [addWarning, id, isRequesterValidForEmail, t, userId]
  );
  const { businessUnitType } = useContext(AuthContext);
  const WarningsImprover = getWarningsImprover(businessUnitType);

  return (
    <RequesterFormProvider>
      {changeStatusLoading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      {changeStatusContent}
      {exportContent}
      <SinglePage
        entityName="inquiry"
        FormImprover={Improver}
        displayAssign={true}
        isWordExportAllowed={false}
        {...{
          getActions,
          getFormConfig,
          getDetailsConfig,
          config,
          validate,
          validation,
          links,
          isNotEditable,
          isActive,
          isNotRemovable,
          isConfirmationMessageNeeded,
          isConfirmationMessageRequired,
          statusFields,
          displayRemove: true,
          getLockMessage,
          WarningsImprover,
        }}
        additionalTabs={
          [
            {
              label: t('Interactions'),
              tab: 'interaction',
              isDefault: true,
              content: (data: Record<string, any>, reload: () => void) => (
                <InteractionList
                  getInitialValues={() => getInteractionInitialValues(data)}
                  hiddenFilters={filters}
                  isSubgrid={true}
                  config={updatedInteractionConfig}
                  isNotCreatable={isNotCreatable(data)}
                  onItemCreated={reload}
                  onItemsRemoved={reload}
                />
              ),
            },
            {
              label: t('Reminders'),
              tab: 'reminder',
              content: (data: Record<string, any>, reload: () => void) => {
                return (
                  <ReminderList
                    isNotCreatable={isReminderNotCreatable(data)}
                    onItemCreated={reload}
                    onItemsRemoved={reload}
                    getInitialValues={() => getReminderInitialValues(data)}
                    hiddenFilters={hiddenFilters}
                    isSubgrid={true}
                    isNotRemovable={isReminderNotRemovable}
                    onItemsComplited={reload}
                  />
                );
              },
            },
            {
              label: t('Emails'),
              tab: 'email',
              isDefault: true,
              content: (data: Record<string, any>) => (
                <EmailList
                  getInitialValues={getEmailInitialValues(data)}
                  hiddenFilters={hiddenFilters}
                  isSubgrid={true}
                  config={emailConfig}
                />
              ),
            },
            {
              label: t('Documents'),
              tab: 'document',
              content: (data: Record<string, any>, reload: () => void) => (
                <DocumentList
                  config={documentConfig}
                  onItemCreated={reload}
                  onItemsRemoved={reload}
                  parentEntityName="inquiry"
                  parentId={id}
                  isNotCreatable={isNotCreatable(data)}
                  isNotEditable={isNotEditable(data)}
                  isNotRemovable={() => isDocumentNotRemovable(data)}
                />
              ),
            },
          ].filter((v) => v.tab !== 'document') as Tab[]
        }
      />
    </RequesterFormProvider>
  );
};
