import { useApi } from '../api';
import { useMetaData } from 'lib/hooks';
import { useCallback } from 'react';
import { parseError } from 'lib/errorParser';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { useTranslation } from 'react-i18next';
import { FetchQuery } from 'lib';
import { NotificationPopup } from 'components/NotificationPopup';
import { ParticipantType, participationEntities } from 'schemas/event';

export const useListRecords = <T extends Record<string, string>>(url: string, columns: T) => {
  const { request } = useApi();

  const prepareQuery = useCallback((query: FetchQuery, attributesToAdd: string[]) => {
    const newQuery = { fetch: {} as typeof query.fetch };
    Object.assign(newQuery.fetch, query.fetch);
    newQuery.fetch._attributes = {};
    newQuery.fetch.entity = {};
    Object.assign(newQuery.fetch._attributes, query.fetch._attributes);
    Object.assign(newQuery.fetch.entity, query.fetch.entity);

    delete newQuery.fetch._attributes.page;
    delete newQuery.fetch._attributes.count;
    delete newQuery.fetch.entity.order;

    newQuery.fetch.entity.attribute = attributesToAdd.filter((x) => x).map((name) => ({ _attributes: { name } }));

    return newQuery;
  }, []);

  const columnNames = Object.values(columns);

  return useCallback(
    async (data: Record<string, any>[] | FetchQuery) => {
      if (!(data instanceof Array)) {
        const query = prepareQuery(data, columnNames);
        const response = await request<{ value: Record<string, any>[] }>({ url, query });
        data = response.data.value;
      }

      return data.map((x) => {
        const record = {} as {
          [Property in keyof T]: any;
        };

        for (const [key, value] of Object.entries(columns)) {
          record[key as keyof T] = x[value];
        }

        return record;
      });
    },
    [columns, url, columnNames, prepareQuery, request]
  );
};

export const useAddParticipantToEvent = (eventId: string, participantType: ParticipantType) => {
  const { inviteParticipantsToEvent } = useApi();
  const { url, PrimaryIdAttribute, PrimaryNameAttribute, displayCollectionName } = useMetaData(participantType);
  const { addWarning, addNotification, addActionFailed } = useNotifications();
  const { t } = useTranslation();
  const getRecords = useListRecords(url, { id: PrimaryIdAttribute, name: PrimaryNameAttribute });

  return useCallback(
    async (data: Record<string, any>[] | FetchQuery) => {
      try {
        const participants = await getRecords(data);

        const validationMessages = await inviteParticipantsToEvent(eventId, participants, participantType);

        if (validationMessages.length === 0) {
          addNotification({
            type: NotificationType.SUCCESS,
            title: t('{{ displayCollectionName }} were invited to the Event', { displayCollectionName }),
            content: t('Please continue adding participants to the Event'),
          });
        } else {
          const errors = validationMessages
            .map((x) =>
              x.Rows.map((r) => ({
                label: r.Name,
                content: x.ToolTipMessage || 'Unknown Error',
              }))
            )
            .flat();

          const noOneInvited = errors.length === participants.length;

          addWarning({
            title: noOneInvited
              ? t('{{ displayCollectionName }} were not invited to the Event', {
                  displayCollectionName,
                })
              : t('Some {{displayCollectionName}} were not invited to the Event', {
                  displayCollectionName,
                }),
            content: (
              <>
                {t('Please see details below.')}
                <br />
                <NotificationPopup
                  label={t('Show Details')}
                  header={t('Info')}
                  description={t('The {{ displayCollectionName }} listed below were not invited to the Event', {
                    displayCollectionName,
                  })}
                  errors={errors}
                />
              </>
            ),
          });
        }

        return true;
      } catch (e) {
        addActionFailed(parseError(e));
        return false;
      }
    },
    [
      getRecords,
      inviteParticipantsToEvent,
      eventId,
      participantType,
      addNotification,
      t,
      displayCollectionName,
      addWarning,
      addActionFailed,
    ]
  );
};

export const useAddRequestersToGroup = (groupId: string) => {
  const { addRequestersToGroup } = useApi();
  const requesterConfig = useMetaData('requester').entityConfig;
  const { addActionFailed, addActionCompleted, addNotification } = useNotifications();
  const { t } = useTranslation();
  const getRecords = useListRecords(requesterConfig.url, { id: 'bahai_inquirerid', name: 'bahai_name' });

  return useCallback(
    async (data: Record<string, any>[] | FetchQuery) => {
      try {
        const requesters = await getRecords(data);
        const response = await addRequestersToGroup(groupId, requesters);

        const requestersNotAddedToGroupNames = JSON.parse(response.data.RequestersNotAddedToGroup)
          .map((r: { Name: string }) => r.Name)
          .join(',');

        if (!requestersNotAddedToGroupNames && !response.data.Message) {
          addActionCompleted(t('All Requesters were successfully added to the Group'));
        }

        if (requestersNotAddedToGroupNames) {
          addNotification({
            type: NotificationType.WARNING,
            content:
              t('The Requesters listed below were not added to the Group (already in the Group)') +
              ': ' +
              requestersNotAddedToGroupNames,
          });
        }

        if (response.data.Message) {
          addNotification({ content: response.data.Message });
        }

        return true;
      } catch (e) {
        addActionFailed(parseError(e));
        return false;
      }
    },
    [getRecords, addRequestersToGroup, groupId, addActionCompleted, t, addNotification, addActionFailed]
  );
};

export const useUpdateAttendanceStatus = (participantType: ParticipantType) => {
  const { request } = useApi();
  const { url, PrimaryIdAttribute } = useMetaData(participationEntities[participantType]);
  const { addActionFailed } = useNotifications();
  const getRecords = useListRecords(url, { id: PrimaryIdAttribute });

  const updateAttendanceStatus = useCallback(
    async (data: Record<string, any>[] | FetchQuery, isAttended: boolean) => {
      try {
        const invitedParticipants = await getRecords(data);

        const promises = invitedParticipants.map((r) =>
          request({
            url: `${url}(${r.id})`,
            method: 'patch',
            data: { bahai_isattended: isAttended },
          })
        );

        await Promise.all(promises);

        return true;
      } catch (e) {
        addActionFailed(parseError(e));
        return false;
      }
    },
    [getRecords, request, url, addActionFailed]
  );

  return {
    setAttendanceStatusToYes: useCallback(
      (data: Record<string, any>[] | FetchQuery) => updateAttendanceStatus(data, true),
      [updateAttendanceStatus]
    ),
    setAttendanceStatusToNo: useCallback(
      (data: Record<string, any>[] | FetchQuery) => updateAttendanceStatus(data, false),
      [updateAttendanceStatus]
    ),
  };
};
