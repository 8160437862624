import { TConfig } from 'components/Table';
import classes from './form.module.scss';
import { Input } from 'components/Form';
import { useFormChanges, useMetaData } from 'lib/hooks';
import { Field, Form as FinalForm, FormProps, useFormState } from 'react-final-form';
import { Control, Panel } from 'components/Panel';
import {
  Dispatch,
  FC,
  Fragment,
  KeyboardEventHandler,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DatePicker } from 'components/Form/DatePicker';
import { createFormAutocomplete } from 'components/Form/Autocomplete';
import { Switch } from 'components/Form/Switch';
import { FieldValidator, FormApi, ValidationErrors } from 'final-form';
import { Trans, useTranslation } from 'react-i18next';
import {
  DATE_PLACEHOLDER,
  DATETIME_PLACEHOLDER,
  FieldType,
  FormConfigGetter,
  isDateTime,
  MultiSelectType,
  TEntityName,
} from 'lib';
import { Notification } from 'components/Notification';
import { createSelect } from 'components/Form/Select';
import { Loader } from 'components/Loader';
import { AutocompleteType } from 'components/AutoComplete/hooks';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { HistoryLink } from 'components/HistoryLink';
import { routes } from 'domain/routes';
import cx from 'classnames';
import { useRecord } from 'lib/record';
import { createPortal } from 'react-dom';
import * as rules from 'lib/rules';
import { parseSaveFormError } from 'lib/errorParser';
import { deepEqual } from 'providers/TableProvider';
import { isValid, parseISO } from 'date-fns';
import { toISO, toISODate } from 'lib/adapter';

export type DisplayTabFunction<T extends string> = (data: Record<T, any>) => boolean;
export type FormConfigType<T extends string> = Array<
  [string | JSX.Element, T[]] | [string | JSX.Element, T[], DisplayTabFunction<T>]
>;

const minPossibleDate = new Date('1753-01-01');

const parseDate = (dateString: string, withTime = false) => {
  try {
    const Date = parseISO(withTime ? toISO(dateString) : toISODate(dateString));
    return isValid(Date) ? Date : null;
  } catch (e) {
    return null;
  }
};

export type TActionControlsProps = {
  loading: boolean;
  refresh: () => void;
  data: Record<string, any>;
  errors: ValidationErrors;
  postAction: (id?: string) => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  id?: string;
  form: FormApi<Partial<Record<string, any>>>;
};

export type TSubmitProps = {
  data: Partial<Record<string, any>>;
  id?: string;
  postAction: (id?: string) => void;
};

export type SetWarnings = (message: Array<JSX.Element | string>) => void;

type ListPageForm<Data extends Record<string, any>> = {
  entityName: TEntityName;
  onClose: () => void;
  config: Record<string, TConfig<Data>>;
  postAction: (id?: string) => void;
  getFormConfig: FormConfigGetter<string>;
  validate?: FormProps['validate'];
  validation?: Partial<Record<string, FieldValidator<any>>>;
  initialValues?: Partial<Data>;
  id?: string;
  getActionControls?: (props: TActionControlsProps) => Control[];
  onSubmit?: (props: TSubmitProps) => Promise<any>;
  FormImprover?: () => JSX.Element | null;
  preSaveUpdate?: (data: Partial<Data>) => Partial<Data>;
  isSubForm?: boolean;
  helper?: JSX.Element | string;
  customDisplayName?: string;
  WarningsImprover?: FC<{ setWarnings: (warnings: Array<string | JSX.Element>) => void }>;
  context?: string;
  data?: Record<string, any>;
};

type TFieldProps = TConfig<any> & {
  validation?: Partial<Record<string, FieldValidator<any>>>;
  values?: Record<string, any>;
  initialValues?: Record<string, any>;
  entityName: TEntityName;
  searchBy?: string[];
  data?: Record<string, any>;
  context: string;
};

export type WarningsFn = (values: Record<string, any>, addWarnings: SetWarnings) => void;

export const getWarningImprover = (
  fields: string[],
  warningsFn: WarningsFn,
  isStatic?: boolean
): FC<{ setWarnings: (warnings: Array<string | JSX.Element>) => void }> => {
  return ({ setWarnings }) => {
    const { values } = useFormState();
    const { changes } = useFormChanges(fields);

    const [params, setParams] = useState<Record<string, any>>();

    useEffect(() => {
      const currentValues = Object.fromEntries(fields.map((v) => [v, isStatic ? changes[v] || values[v] : changes[v]]));
      if (!deepEqual(params, currentValues)) {
        setParams(currentValues);
      }
    }, [changes, params, values]);

    useEffect(() => {
      if (params && isStatic) warningsFn(params, setWarnings);
      if (changes && !isStatic) warningsFn(changes, setWarnings);
    }, [changes, params, setWarnings]);
    return null;
  };
};

const createMap = (options: any): Map<string, string> => {
  if (Array.isArray(options)) return new Map(options);
  if (typeof options === 'object')
    return new Map((Object.entries(options) as [string, string][]).map((v) => [v[0].toLowerCase(), v[1]]));
  return new Map();
};

const FieldComponent = memo(
  ({
    name,
    isRequired,
    isDisabled,
    field,
    fieldRenderer: FieldRender,
    fieldProps = () => ({}),
    validation = {},
    values = {},
    entityName,
    data,
    context,
  }: TFieldProps) => {
    const { entityConfig, getJoinLogicalName, getTargetLogicalNames, getFieldDefinition } = useMetaData(entityName);
    const { type, format: fieldFormat } = getFieldDefinition(name);
    const showBooleanAsPicklist =
      type === FieldType.Boolean && createMap(entityConfig.fields[name].options).get('false') !== 'No';
    const passedFieldProps = useMemo(() => fieldProps({ classes, values, context }), [context, fieldProps, values]);
    const { filterValues, isMultiple, searchBy } = useMemo(() => passedFieldProps, [passedFieldProps]);
    const format = (value: any) => {
      if (showBooleanAsPicklist && typeof value !== 'undefined') {
        return value.toString();
      }
      return value;
    };

    const getDateValidation = useCallback(
      (withTime = false) =>
        (dateString: unknown) => {
          if (typeof dateString !== 'string' || !dateString) return;
          const date = parseDate(dateString, withTime);
          if (!date) return `Invalid format ${withTime ? DATETIME_PLACEHOLDER : DATE_PLACEHOLDER}`;
          if (date < minPossibleDate) return `Can't be before 01/01/1753`;
        },
      []
    );

    const extraValidation = useMemo(
      () => (type === FieldType.DateTime ? [getDateValidation(isDateTime(fieldFormat))] : []),
      [fieldFormat, getDateValidation, type]
    );

    const pickListOptions = useMemo(() => {
      if (type === FieldType.Picklist || type === FieldType.Virtual) {
        const entries = [...entityConfig.fields[name].options];
        return createMap(filterValues ? entries.filter(filterValues) : entries);
      }
      return new Map();
    }, [entityConfig.fields, filterValues, name, type]);

    const fieldComponent = useMemo(() => {
      switch (type) {
        case FieldType.DateTime:
          return DatePicker;
        case FieldType.Lookup:
          return createFormAutocomplete({
            entities: [getJoinLogicalName(name)],
            isMultiple,
            searchBy,
          });
        case FieldType.Virtual:
        case FieldType.Picklist:
          return createSelect(pickListOptions, entityConfig.fields[name].extraType === MultiSelectType);
        case FieldType.Boolean:
          if (showBooleanAsPicklist) {
            return createSelect(createMap(entityConfig.fields[name].options), false);
          }
          return Switch;
        case FieldType.PartyList:
          return createFormAutocomplete({
            entities: getTargetLogicalNames(name),
            isMultiple,
            type: AutocompleteType.PartyList,
            searchBy,
          });
        default:
          return Input;
      }
    }, [
      entityConfig.fields,
      getJoinLogicalName,
      getTargetLogicalNames,
      isMultiple,
      name,
      pickListOptions,
      searchBy,
      showBooleanAsPicklist,
      type,
    ]);

    return FieldRender ? (
      <FieldRender key={name} name={name} />
    ) : (
      <Field
        name={name}
        component={field || fieldComponent}
        required={typeof isRequired === 'function' ? isRequired(values, data) : isRequired}
        disabled={typeof isDisabled === 'function' ? isDisabled(values, data) : isDisabled}
        label={entityConfig.fields[name].label}
        key={name}
        className={['text', 'boolean'].includes(type) ? classes.long : ''}
        format={format}
        validate={rules.compose(
          [...extraValidation, validation[name]].flat().filter((v) => !!v) as FieldValidator<any>[]
        )}
        values={values}
        data={data}
        {...passedFieldProps}
      />
    );
  }
);

const ErrorsWrapper: FC<{ relative?: boolean; children: ReactNode }> = ({ children, relative }) => {
  const myRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    myRef.current?.scrollIntoView();
  }, []);

  return (
    <div ref={myRef} className={cx(classes.errorHolder, { [classes.relative]: relative })}>
      {children}
    </div>
  );
};

export const useErrors = () => {
  const [hidden, setHidden] = useState<Array<string | JSX.Element>>([]);
  const hide = useCallback((error: string | JSX.Element) => setHidden((list) => list.concat(error)), [setHidden]);

  const refresh = useCallback(() => {
    document.querySelector("div[class*='input_error']")?.scrollIntoView(false);
    setHidden([]);
  }, [setHidden]);

  const ErrorsBlock = useCallback(
    ({ errors, relative = false }: { errors?: ValidationErrors; relative?: boolean }) => {
      const { _general = [], warnings = [] } = errors || { _general: [], warnings: [] };
      if (!_general.length && !warnings.length) return null;
      return (
        <ErrorsWrapper relative={relative}>
          {warnings
            .filter((error: string) => !hidden.includes(error))
            .map((error: string | JSX.Element) => (
              <Notification type={'warning'} onHide={() => hide(error)} key={String(error)}>
                {error}
              </Notification>
            ))}
          {_general
            .filter((error: string) => !hidden.includes(error))
            .map((error: string) => (
              <Notification onHide={() => hide(error)} key={error}>
                {error}
              </Notification>
            ))}
        </ErrorsWrapper>
      );
    },
    [hidden, hide]
  );

  return { refresh, ErrorsBlock };
};

export const Form = <Data extends Record<string, any>>({
  entityName,
  postAction,
  config,
  getFormConfig,
  validate,
  onClose,
  initialValues = {},
  validation,
  id,
  FormImprover,
  getActionControls,
  onSubmit,
  preSaveUpdate = (v: Partial<Data>) => v,
  helper,
  customDisplayName,
  WarningsImprover,
  context = 'FORM_CREATE',
  data: recordData,
}: ListPageForm<Data>) => {
  const [loading, setLoading] = useState(false);
  const { displayName, hiddenFields } = useMetaData(entityName);
  const { t } = useTranslation();

  const { addNotification } = useNotifications();
  const { save: saveRecord } = useRecord(entityName);

  const save = useCallback(
    async (data: Partial<Data>) => {
      try {
        return await saveRecord(data, id);
      } catch (e: any) {
        console.error(e);
        throw {
          _general: [parseSaveFormError(e, !!id)],
        };
      }
    },
    [id, saveRecord]
  );

  const onFormSubmit = useCallback(
    async (data: Partial<Data>, form: FormApi<Partial<Data>>) => {
      const { valid } = form.getState();
      if (valid) {
        setLoading(true);
        try {
          if (onSubmit) {
            await onSubmit({
              data,
              id,
              postAction,
            });
          } else {
            const resp = await save(preSaveUpdate(data));
            const recordId = id || resp?.headers?.location.match(/\((.+)\)/)?.[1];
            if (!id) {
              addNotification({
                title: t('Record was created'),
                type: NotificationType.SUCCESS,
                content: recordId ? (
                  <Trans>
                    Please, go to{' '}
                    <HistoryLink to={(routes as any)[entityName]({ id: recordId })}>hyperlink</HistoryLink> to see
                  </Trans>
                ) : undefined,
              });
            } else {
              addNotification({ type: NotificationType.SUCCESS, title: t('Your changes have been saved') });
            }
            postAction(recordId);
          }
        } catch (errors) {
          return errors;
        } finally {
          setLoading(false);
        }
      }
    },
    [addNotification, entityName, id, onSubmit, postAction, preSaveUpdate, save, t]
  );

  const { refresh, ErrorsBlock } = useErrors();

  const defaultControls = [
    {
      title: t('Save'),
      role: 'primary',
      disabled: loading,
      type: 'submit',
      onClick: refresh,
      id: 'button_save',
    } as Control,
  ];

  const [warnings, setWarnings] = useState<Array<JSX.Element | string>>([]);

  const data = useMemo(() => (recordData ? { id, ...recordData } : { id }), [id, recordData]);

  const formRef = useRef<HTMLFormElement>(null);
  const submitFormByEnter = useCallback(() => {
    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }, []);

  useEffect(() => {
    if (formRef.current) {
      document.addEventListener('keydown', (event) => {
        if (event.target instanceof HTMLBodyElement && event.key === 'Enter') {
          submitFormByEnter();
        }
      });
      return document.removeEventListener('keydown', submitFormByEnter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLFormElement> = (event) => {
    if (event.key === 'Enter') {
      switch ((event.target as Element).tagName) {
        case 'INPUT':
          event.preventDefault();
          (event.target as HTMLElement)?.blur();
          break;
        case 'DIV':
          if (!(event.target as Element).ariaLabel) {
            (event.target as HTMLElement)?.blur();
            event.preventDefault();
          }
      }
    }
  };

  return (
    <FinalForm
      onSubmit={onFormSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, values, errors, submitFailed, submitErrors, form }) =>
        createPortal(
          <form onSubmit={handleSubmit} onKeyDown={handleKeyDown} ref={formRef}>
            <Panel
              id={id ? 'panelEdit' : 'panelCreate'}
              className={classes.panel}
              portal={false}
              visible={true}
              onClose={onClose}
              title={<>{(id ? t('Edit') : t('New')) + ' ' + (customDisplayName || displayName)}</>}
              controls={
                getActionControls
                  ? getActionControls({
                      loading,
                      refresh,
                      data: { ...values },
                      errors,
                      postAction,
                      setLoading,
                      id,
                      form,
                    } as TActionControlsProps)
                  : defaultControls
              }
              helper={helper}
            >
              {loading && (
                <div className={classes.loader}>
                  <Loader />
                </div>
              )}
              <ErrorsBlock errors={{ warnings, ...(submitFailed ? { ...errors, ...submitErrors } : {}) }} />
              <div className={classes.fields}>
                <div className={classes.formGrid}>
                  {getFormConfig(values, false).map(([label, fields]) => (
                    <Fragment key={fields.join()}>
                      <div className={classes.header}>{label}</div>
                      {fields
                        .filter((v) => !hiddenFields.includes(v))
                        .map((field) => (
                          <FieldComponent
                            {...{
                              initialValues,
                              values,
                              validation,
                              ...config[field],
                              entityName,
                              data,
                              context,
                            }}
                            key={field}
                          />
                        ))}
                    </Fragment>
                  ))}
                  {FormImprover && <FormImprover />}
                  {WarningsImprover && <WarningsImprover setWarnings={setWarnings} />}
                </div>
              </div>
            </Panel>
          </form>,
          document.body
        )
      }
    />
  );
};
