import classes from './notice.module.scss';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScreenContext } from 'providers/ScreenProvider';

export const Notice = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapse = useCallback(() => setCollapsed((v) => !v), []);

  const { isMobile, isTablet } = useContext(ScreenContext);
  const LINE_HEIGHT = useMemo(() => {
    if (isMobile) return 12;
    if (isTablet) return 20;
    return 24;
  }, [isMobile, isTablet]);

  const [{ displayShowMore, lines }, setTextStyle] = useState<Record<string, any>>({
    displayShowMore: false,
    lines: 0,
  });

  const redraw = useCallback(() => {
    setTextStyle((v) => ({ ...v, displayShowMore: false, lines: 0 }));
    setCollapsed(true);
    if (!wrapperRef.current || !headerRef.current || !bottomRef.current || !textRef.current) return;
    const { height: totalHeight } = wrapperRef.current.getBoundingClientRect();
    const { height: headerHeight } = headerRef.current.getBoundingClientRect();
    const { height: bottomHeight } = bottomRef.current.getBoundingClientRect();

    const { height: textHeight } = textRef.current.getBoundingClientRect();
    const displayShowMore = textRef.current?.scrollHeight > textHeight;

    const lines = Math.floor((totalHeight - headerHeight - bottomHeight) / LINE_HEIGHT) - (displayShowMore ? 1 : 0);

    setTextStyle((v) => ({
      ...v,
      displayShowMore,
      lines,
    }));
  }, [LINE_HEIGHT]);

  const styles = useMemo(
    () => ({
      WebkitLineClamp: collapsed && displayShowMore ? lines : 'unset',
      height: collapsed && displayShowMore ? lines * LINE_HEIGHT : 'auto',
    }),
    [LINE_HEIGHT, collapsed, displayShowMore, lines]
  );

  useEffect(() => {
    redraw();
    window.visualViewport?.addEventListener('resize', redraw);
    return () => {
      window.visualViewport?.removeEventListener('resize', redraw);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();
  return (
    <div className={classes.root} style={collapsed ? {} : { height: 'auto' }}>
      <div className={classes.textWrapper} ref={wrapperRef}>
        <div className={classes.header} ref={headerRef}>
          God hath prescribed unto every one the duty of teaching His Cause.
        </div>
        <div className={classes.text} style={styles} ref={textRef}>
          Whoever ariseth to discharge this duty, must needs, ere he proclaimeth His Message, adorn himself with the
          ornament of an upright and praiseworthy character, so that his words may attract the hearts of such as are
          receptive to his call.
        </div>
        {displayShowMore && (
          <button onClick={toggleCollapse} className={classes.showMore}>
            {collapsed ? t('Read More') : t('Read Less')}
          </button>
        )}
        <div ref={bottomRef} className={classes.author}>
          Gleanings, Bahá’u’lláh
        </div>
      </div>
    </div>
  );
};
