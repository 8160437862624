import { useCallback, useMemo } from 'react';
import { Button } from 'components/Button';
import { Dialog } from 'components/Dialog';
import classes from 'components/ListPage/components/MultiRemovePopup/multiRemove.module.scss';
import Tooltip from 'components/Tooltip';
import { ReactComponent as Icon } from './icon.svg';
import { useTranslation } from 'react-i18next';
import { useNotifications } from 'providers/NotificationsProvider';

export enum NPButtonType {
  Button,
  Link,
}

const Link = ({ children }: { children: JSX.Element | string; onClick: () => void }) => <a>{children}</a>;

export const NotificationPopup = ({
  label,
  type = NPButtonType.Button,
  header,
  description,
  errors,
}: {
  label: string;
  header: string;
  type?: NPButtonType;
  description?: string;
  errors: Array<{ label: string; content: string | JSX.Element }>;
}) => {
  const { t } = useTranslation();

  const { addContent } = useNotifications();

  const Component = useMemo(() => (type === NPButtonType.Button ? Button : Link), [type]);

  const content = useMemo(
    () => (hide: () => void) => (
      <Dialog onClose={hide} isDraggingAllowed={true}>
        <>
          <div className={classes.header}>{header}</div>
          <div className={classes.info}>{description}</div>
          <br />
          <div className={classes.description}>{t('Description')}</div>
          <div className={classes.errors}>
            {errors.map(({ label, content }) => (
              <div key={label} className={classes.error}>
                <div className={classes.errorLabel}>{label}</div>
                <Tooltip content={<>{content}</>}>
                  <div className={classes.iconWrapper}>
                    <Icon />
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
          <div className={classes.footer}>
            <Button role="primary" onClick={hide}>
              {t('Ok')}
            </Button>
          </div>
        </>
      </Dialog>
    ),
    [description, errors, header, t]
  );

  const show = useCallback(() => addContent(content), [addContent, content]);

  return (
    <>
      <Component onClick={show}>{label}</Component>
    </>
  );
};
