export enum statuscode {
  Canceled = 3,
  Completed = 2,
  Open = 1,
  Scheduled = 4,
}
export enum statecode {
  Open = 0,
  Completed = 1,
  Canceled = 2,
  Scheduled = 3,
}
