import { useDrag, useDrop } from 'react-dnd';
import { Switch } from 'components/Switch';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as PinIcon } from './icons/pin.svg';
import { ReactComponent as ReorderIcon } from './icons/reorder.svg';
import { ReactComponent as UpIcon } from './icons/up.svg';
import { ReactComponent as DownIcon } from './icons/down.svg';
import { ReactComponent as TopIcon } from './icons/top.svg';
import { ReactComponent as BottomIcon } from './icons/bottom.svg';
import classes from './panel.module.scss';
import cx from 'classnames';
import Panel from 'components/Panel';
import { useTranslation } from 'react-i18next';
import { useMetaData } from 'lib/hooks';
import { TEntityName } from 'lib';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { IconButton } from 'components/IconButton';
import { MenuButton } from 'components/MenuButton';
import { ScreenContext } from 'providers/ScreenProvider';

type ColumnConfig = {
  name: string;
  pinned: boolean;
  visible: boolean;
  width: number;
};

type PanelProps = {
  entityName: TEntityName;
  config: ColumnConfig[];
  setConfig: (props: ColumnConfig[]) => void;
  onClose: () => void;
  initialConfig?: ColumnConfig[];
  additionalConfig?: Partial<Record<string, { label?: string | JSX.Element }>>;
};

export const CellPlaceholder = ({
  visible,
  pinned,
  showPin = false,
  label,
}: ColumnConfig & { label: string; showPin?: boolean }) => {
  return (
    <div className={cx(classes.cell, classes.preview)}>
      <Switch onChange={() => null} value={visible} />
      {showPin && (
        <button style={{ marginRight: 12 }} type="button" onClick={() => null}>
          <PinIcon className={cx(classes.pin, { [classes.enabled]: pinned })} />
        </button>
      )}
      <div>{label}</div>
      <ReorderIcon className={classes.dots} />
    </div>
  );
};

export const Cell = <T extends Record<string, any>>({
  props,
  onChange,
  move,
  up,
  down,
  top,
  bottom,
  showPin = false,
}: {
  props: T & { label: string | JSX.Element; name?: string };
  onChange: (props: T) => void;
  move: (source: T, target: T) => void;
  up?: () => void;
  down?: () => void;
  top?: () => void;
  bottom?: () => void;
  showPin?: boolean;
}) => {
  const onToggle = useCallback(() => onChange({ ...props, visible: !props.visible }), [onChange, props]);
  const onPin = useCallback(() => onChange({ ...props, pinned: !props.pinned }), [onChange, props]);

  const { isMobile, isTablet } = useContext(ScreenContext);
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'CELL',
    item: () => ({ ...props, showPin }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'CELL',
    drop: (item: T) => {
      if (item && item.name !== props.name) move(props, item);
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
      };
    },
  }));

  if (!isMobile && !isTablet) drop(drag(ref));

  const { t } = useTranslation();
  return (
    <div
      style={{ opacity: isDragging ? 0 : undefined }}
      className={cx(classes.cell, { [classes.hovered]: isOver })}
      ref={ref}
    >
      <Switch onChange={onToggle} value={props.visible} />
      {showPin && (
        <button className={classes.pinBtn} type="button" onClick={onPin}>
          <PinIcon className={cx(classes.pin, { [classes.enabled]: props.pinned })} />
        </button>
      )}
      <div>{props.label}</div>
      <div className={classes.controls}>
        <IconButton iconOnly Icon={UpIcon} onClick={up} disabled={!up} />
        <IconButton iconOnly Icon={DownIcon} onClick={down} disabled={!down} />
        <MenuButton Icon={ReorderIcon} right>
          <div>
            {top && (
              <IconButton className={classes.menuButton} Icon={TopIcon} onClick={top}>
                {t('Move To Top')}
              </IconButton>
            )}
            {bottom && (
              <IconButton className={classes.menuButton} Icon={BottomIcon} onClick={bottom}>
                {t('Move To Bottom')}
              </IconButton>
            )}
          </div>
        </MenuButton>
      </div>
    </div>
  );
};

export const ColumnsPanel = ({
  entityName,
  config,
  initialConfig,
  setConfig,
  onClose,
  additionalConfig,
}: PanelProps) => {
  const [cfg, setCfg] = useState<Array<ColumnConfig & { label?: string }>>([...config]);

  const updatedInitialConfig = useMemo(
    () =>
      initialConfig?.concat(
        cfg
          .filter((item) => !initialConfig.some((v) => v.name === item.name))
          .map((item) => ({ ...item, visible: false, pinned: false }))
      ),
    [cfg, initialConfig]
  );

  const reset = useCallback(() => setCfg(updatedInitialConfig || config), [setCfg, config, updatedInitialConfig]);
  const onApply = useCallback(() => setConfig(cfg.map(({ label: _, ...rest }) => rest)), [setConfig, cfg]);

  const update = useCallback(
    (value: ColumnConfig) => {
      const result = [...cfg];
      result.splice(
        cfg.findIndex((v) => v.name === value.name),
        1,
        value
      );
      setCfg(result);
    },
    [cfg, setCfg]
  );

  const move = useCallback(
    (source: ColumnConfig, target: ColumnConfig, bottom = false) => {
      setCfg((cfg) => {
        const result = cfg.filter((v) => v.name !== target.name);
        if (bottom) return [...result, target];
        result.splice(
          result.findIndex((v) => v.name === source.name),
          0,
          target
        );
        return result;
      });
    },
    [setCfg]
  );

  const allDisabled = useMemo(() => cfg.every((v) => !v.visible), [cfg]);
  const toggleAll = useCallback(() => setCfg((cfg) => cfg.map((v) => ({ ...v, visible: allDisabled }))), [allDisabled]);

  const { t } = useTranslation();

  const { getLabel } = useMetaData(entityName);

  return (
    <Panel
      onClose={onClose}
      title={t('Edit Columns')}
      visible={true}
      controls={[
        { title: t('Apply'), disabled: allDisabled, role: 'primary', onClick: onApply, id: 'button_columns_apply' },
        { title: t('Restore To Default'), onClick: reset },
      ]}
    >
      <div className={classes.root}>
        <div className={classes.cellsWrapper}>
          <div className={cx(classes.cell, classes.toggle)}>
            <Switch onChange={toggleAll} value={!allDisabled} />
            <div className={classes.label}>{allDisabled ? t('Select All') : t('Unselect All')}</div>
          </div>
          {cfg.map((props, index) => (
            <Cell
              key={Object.values({ ...props, index }).join('-')}
              move={move}
              props={{ ...props, label: additionalConfig?.[props.name]?.label || getLabel(props.name) }}
              onChange={update}
              showPin={true}
              up={index > 0 ? () => move(cfg[index - 1], props) : undefined}
              down={index < cfg.length - 1 ? () => move(props, cfg[index + 1]) : undefined}
              top={index > 0 ? () => move(cfg[0], props) : undefined}
              bottom={index < cfg.length - 1 ? () => move(cfg[cfg.length - 1], props, true) : undefined}
            />
          ))}
        </div>
      </div>
    </Panel>
  );
};
