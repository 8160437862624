import { SinglePage } from 'components/SinglePage';
import { config as emailConfig } from 'schemas/email';
import {
  config,
  getFormConfig,
  getDetailsConfig,
  isActive,
  isConfirmationMessageNeeded,
  isConfirmationMessageRequired,
  isNotEditable,
  statusFields,
  useContactMethodValidation,
  validation,
} from 'schemas/contact';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import EmailList from 'pages/EmailList';
import { useSystemUserId } from 'lib/helpers';
import { Action } from 'components/Actions';
import EventList from 'pages/EventList';
import { useSimpleChangeStatus } from 'schemas/contact/actions';
import { useSecurity } from 'providers/AuthProvider';
import { ApiFilter, Privilege } from 'lib';

export const Contact = () => {
  const { validate } = useContactMethodValidation();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const userId = useSystemUserId();
  const { isGranted } = useSecurity();

  const isEmailNotCreatable = useCallback((data: Record<string, any>) => {
    switch (true) {
      case !data.bahai_emailaddress1 && !data.bahai_emailaddress2:
        return <Trans>Please add an email address. Only recipients with an email address can receive emails</Trans>;
      default:
        return false;
    }
  }, []);

  const getEmailInitialValues = useCallback(async () => {
    return {
      directioncode: true,
      from: 'systemuser<|>' + userId,
      to: 'bahai_contact_ims<|>' + id,
    };
  }, [id, userId]);

  const { changeStatusAction, content } = useSimpleChangeStatus({
    entityName: 'contact',
    statusFieldName: 'statecode',
    confirmationFieldName: 'bahai_confirmationmessage',
  });

  const getActions = useCallback(
    (baseActions: Action[]) => [...baseActions, ...(isGranted('contact', Privilege.Write) ? [changeStatusAction] : [])],
    [changeStatusAction, isGranted]
  );

  const emailFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'bahai_primaryrowid', value: id }] }],
    [id]
  );

  const eventFilters: ApiFilter[] = useMemo(
    () => [
      {
        logicOperator: 'or',
        condition: [
          { operator: 'eq', attribute: 'bahai_designatedcontactobjectid', value: id },
          { operator: 'eq', attribute: 'bahai_designatedhostobjectid', value: id },
        ],
      },
    ],
    [id]
  );

  return (
    <>
      {content}
      <SinglePage
        entityName="contact"
        displayAssign={true}
        {...{
          getActions,
          getFormConfig,
          getDetailsConfig,
          config,
          validate,
          validation,
          isNotEditable,
          isActive,
          isConfirmationMessageNeeded,
          isConfirmationMessageRequired,
          statusFields,
        }}
        additionalTabs={[
          {
            label: t('Emails'),
            tab: 'email',
            content: (data: Record<string, any>) => (
              <EmailList
                getInitialValues={getEmailInitialValues}
                hiddenFilters={emailFilters}
                isSubgrid={true}
                config={emailConfig}
                isNotCreatable={isEmailNotCreatable(data)}
              />
            ),
          },
          {
            label: t('Events'),
            tab: 'event',
            content: () => (
              <EventList hiddenFilters={eventFilters} isCreateHidden={true} isSubgrid={true} isRemoveHidden={true} />
            ),
          },
        ]}
      />
    </>
  );
};
