import { useInviteParticipant } from 'pages/Invite';
import { useCallback, useMemo } from 'react';
import { useRemoveEventParticipants } from 'pages/Common/hooks';
import { useSendInvitations, useUpdateAttendanceStatusActions } from 'pages/Event/hooks';
import { EmailRecipient, SendEmailValidateFunction, useSendEmailFromList } from 'schemas/email/actions';
import { Action } from 'components/Actions';
import classes from 'pages/Event/event.module.scss';
import { Loader } from 'components/Loader';
import { ParticipantType } from 'schemas/event';
import Resources from 'pages/Event/components/ResourcesTab';
import Requesters from 'pages/Event/components/RequestersTab';
import { Trans } from 'react-i18next';
import { ApiFilter } from 'lib';

export const requesterToRecipientMapper = (data: Record<string, any>): EmailRecipient => ({
  id: data._bahai_inquirerid_value,
  content: <Trans>Requester</Trans>,
  label: data['requester.bahai_id'],
  type: 'requester',
});

export const resourceToRecipientMapper = (data: Record<string, any>): EmailRecipient => ({
  id: data.systemuserid || data._bahai_systemuserid_value,
  content: <Trans>Resource</Trans>,
  label: data.fullname || data['resource.fullname'],
  type: 'resource',
});

export const contactToRecipientMapper = (data: Record<string, any>): EmailRecipient => ({
  id: data.bahai_contact_imsid,
  content: <Trans>Contact</Trans>,
  label: data.bahai_name,
  type: 'contact',
});

export const ParticipantTab = ({
  data,
  id,
  participantType,
  sendEmailValidation,
}: {
  data: Record<string, any>;
  id: string;
  participantType: ParticipantType;
  sendEmailValidation: SendEmailValidateFunction;
}) => {
  const { getAction: getInviteAction, content: inviteContent } = useInviteParticipant(id, participantType);

  const ListComponent = useMemo(() => (participantType === 'requester' ? Requesters : Resources), [participantType]);

  const { getRemoveParticipantsAction: getRemoveAction, content: removeContent } = useRemoveEventParticipants(
    'event',
    participantType
  );

  const { loading, actions: attendanceActions } = useUpdateAttendanceStatusActions(participantType);

  const { actions: sendEmailActions, content: emailForm } = useSendEmailFromList(
    participantType,
    sendEmailValidation,
    participantType === 'requester' ? 'invitedRequester' : 'invitedResource',
    participantType === 'requester' ? requesterToRecipientMapper : resourceToRecipientMapper,
    `bahai_event_ims<|>${id}`
  );

  const { getActions: getSendInvitationActions, content: sendInvitationContent } = useSendInvitations(
    participantType,
    id
  );

  const getActions = useCallback(
    (data: Record<string, any>) => (baseActions: Action[]) => {
      return [
        getInviteAction(data),
        ...baseActions.filter((a) => a.name !== 'delete'),
        getRemoveAction(data),
        ...attendanceActions,
        ...sendEmailActions,
        ...getSendInvitationActions(data),
      ];
    },
    [attendanceActions, getInviteAction, getRemoveAction, getSendInvitationActions, sendEmailActions]
  );

  const hiddenFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'bahai_eventid', value: id }] }],
    [id]
  );

  return (
    <>
      {loading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      {removeContent}
      {emailForm}
      {sendInvitationContent}
      {inviteContent}
      <ListComponent
        getActions={getActions(data)}
        isSubgrid={true}
        isCreateHidden={true}
        isRemoveHidden={false}
        hiddenFilters={hiddenFilters}
        displayViews={false}
      />
    </>
  );
};
