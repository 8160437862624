import classes from './file.module.scss';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useApi } from 'domain/api';
import { IconButton } from 'components/IconButton';
import { ReactComponent as FileIcon } from './icons/file.svg';
import { ReactComponent as RemoveIcon } from './icons/remove.svg';
import { ReactComponent as ReloadIcon } from './icons/reload.svg';
import { ReactComponent as DownloadIcon } from './icons/download.svg';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNotifications } from 'providers/NotificationsProvider';
import { saveAs } from 'file-saver';

export type FileProps = {
  filename?: string;
  filesize?: number;
  activitymimeattachmentid?: string;
  isLoaded?: boolean;
  onLoad?: () => void;
  onRemoveFile: () => void;
  file: File;
  id: string;
  readonly?: boolean;
  onUploadSuccess: (fileWithId: { file: File; id: string; activitymimeattachmentid: string }) => void;
  uploadStatus: { status: string; progress: number };
  uniqueKey: string;
};

export const Progress = ({ value = 0 }: { value?: number }) => (
  <div className={classes.progress}>
    <div style={{ width: `${Math.ceil(100 * value)}%` }} />
  </div>
);

export const File = ({
  filename,
  filesize,
  activitymimeattachmentid,
  file,
  readonly,
  onRemoveFile,
  id,
  onUploadSuccess,
  uploadStatus,
  uniqueKey,
}: FileProps) => {
  const [progress, setProgress] = useState(uploadStatus?.progress || 0);
  const { request } = useApi();
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const [attachmentId, setAttachmentId] = useState(activitymimeattachmentid);

  const [removeInProgress, setRemoveInProgress] = useState(false);
  const [loading, setLoading] = useState(uploadStatus?.status === 'uploading');

  const controller = useRef(new AbortController());

  const onRemove = useCallback(() => {
    if (attachmentId) {
      setRemoveInProgress(true);
      request({ url: `activitymimeattachments(${attachmentId})`, method: 'delete' })
        .then(() => onRemoveFile())
        .catch(() => {
          setError('Remove error');
          setTimeout(() => setError(''), 2000);
        })
        .finally(() => setRemoveInProgress(false));
    } else {
      onRemoveFile();
    }
  }, [attachmentId, onRemoveFile, request]);

  const { addError } = useNotifications();

  const onDownload = useCallback(() => {
    if (attachmentId) {
      controller.current = new AbortController();
      setLoading(true);
      request<{ body: string; filename: string; mimetype: string }>({
        url: `activitymimeattachments(${attachmentId})`,
        method: 'get',
        signal: controller.current.signal,
        onDownloadProgress: ({ loaded }) => setProgress(Math.min(100, loaded / (filesize || 1))),
      })
        .then(({ data: { body, filename, mimetype } }) => {
          const byteCharacters = atob(body);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          saveAs(new Blob([byteArray], { type: mimetype }), filename);
        })
        .catch(() => {
          if (!controller.current.signal.aborted) addError({ title: t('File download Error') });
        })
        .finally(() => setLoading(false));
    }
  }, [addError, attachmentId, filesize, request, t]);

  const upload = useCallback(() => {
    if (file) {
      setError('');
      uploadStatus.status = 'uploading';
      const reader = new FileReader();
      controller.current = new AbortController();
      reader.readAsDataURL(file);
      setLoading(true);
      reader.onloadend = function () {
        request({
          url: 'activitymimeattachments',
          method: 'post',
          signal: controller.current.signal,
          data: {
            filename: file.name,
            body: (reader.result as string).replace('data:', '').replace(/^.+,/, ''),
            objecttypecode: 'appointment',
            'objectid_activitypointer@odata.bind': `/appointments(${id})`,
          },
          onUploadProgress: ({ loaded, total = Infinity }) => {
            const newProgress = loaded / total;
            setProgress(newProgress);
            uploadStatus.progress = newProgress;
          },
        })
          .then((resp) => {
            const newId = resp.headers.location.substr(-37, 36);
            onUploadSuccess({ file, id: uniqueKey, activitymimeattachmentid: newId });
            setAttachmentId(newId);
          })
          .catch(() => {
            if (!controller.current.signal.aborted) setError(t('Upload Failed'));
          })
          .finally(() => setLoading(false));
      };
    }
  }, [file, id, request, t, onUploadSuccess, uniqueKey, uploadStatus]);

  useEffect(() => {
    if (file && !attachmentId && uploadStatus?.status !== 'uploading') upload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const abort = useCallback(() => {
    controller.current.abort();
    setProgress(0);
    if (!readonly) setError(t('Canceled'));
  }, [readonly, t]);

  const onCancel = useCallback(() => (loading ? abort() : onRemove()), [abort, loading, onRemove]);

  return (
    <div className={cx(classes.root, { [classes.remove]: removeInProgress })}>
      <div className={classes.content}>
        <FileIcon />
        <span className={classes.fileName}>{filename || file.name}</span>
        <span> ({Intl.NumberFormat('en-US').format((filesize || file.size) / (1024 * 1024))} MB)</span>
        <div className={classes.controls}>
          {error && <span className={classes.error}>{error} </span>}
          {error && <IconButton iconOnly Icon={ReloadIcon} onClick={upload} />}
          {readonly && !loading ? (
            <IconButton iconOnly Icon={DownloadIcon} onClick={onDownload} />
          ) : (
            <IconButton
              iconOnly
              Icon={() => <RemoveIcon className={cx({ [classes.errorIcon]: !!error })} />}
              onMouseDown={onCancel}
            />
          )}
        </div>
      </div>
      {!attachmentId || loading ? <Progress value={progress} /> : <div className={classes.progressPlaceholder} />}
    </div>
  );
};
