import { useMemo } from 'react';
import classes from './editor.module.scss';
import cx from 'classnames';
import { TInputProps } from 'components/Form/types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const TextEditor = ({ label, meta, input: { name, value, onChange, onBlur }, className, ...props }: TInputProps) => {
  const errors: string | JSX.Element | undefined = useMemo(
    () => (meta.touched ? meta.error || meta.submitError : undefined),
    [meta]
  );

  return (
    <div className={cx(classes.wrapper, className)}>
      <label className={cx(classes.label, { [classes.required]: props.required })} htmlFor={name}>
        {label}
      </label>
      <div className={cx({ [classes.hasError]: !!errors })}>
        <CKEditor
          editor={ClassicEditor}
          name
          data={value ?? ``}
          className={classes.input}
          config={{
            typing: {
              transformations: {
                remove: ['oneHalf', 'oneThird', 'twoThirds', 'oneForth', 'threeQuarters'],
              },
            },
            toolbar: {
              items: [
                'heading',
                '|',
                'bold',
                'italic',
                '|',
                'link',
                '|',
                'undo',
                'redo',
                '|',
                'numberedList',
                'bulletedList',
                '|',
                'insertTable',
              ],
            },
          }}
          onChange={(event: any, editor: any) => {
            onChange(editor?.getData() || '<div/>');
            onBlur();
          }}
        />
      </div>
      <div className={classes.bottomWrapper}>
        {errors && <div className={classes.error}>{errors}</div>}
        <div className={classes.counter}>
          {((value as string) || '').replace(/\<.+?\>/g, '').replaceAll('&nbsp;', ' ').length} /{' '}
          {props.maxLength || 2000}
        </div>
      </div>
    </div>
  );
};

export { TextEditor };
