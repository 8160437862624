import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { FieldValidator } from 'final-form';
import { Trans } from 'react-i18next';
import { createFormAutocomplete } from 'components/Form/Autocomplete';
import { AutocompleteType } from 'components/AutoComplete/hooks';
import { PartyListComponent, PolymorphicComponent, AreaTooltipComponent } from 'lib/components';
import { ParticipationTypeMask } from 'config/EntityMetadata/email';
import * as rules from 'lib/rules';
import { TSinglePage } from 'components/SinglePage';
import { bahai_statuscode } from 'config/EntityMetadata/bahai_reminder';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import entities, { Fields } from 'config';

export const columns = entities.reminder.columns;
export type Keys = Fields<(typeof entities.reminder.columns)[number]>;

export const defaultMobileColumns: Keys[] = ['bahai_reminderdatetime', 'bahai_statuscode', 'description'];

export const statusFields: Required<TSinglePage>['statusFields'] = () => ['bahai_statuscode'];

export const isActive = (data: Record<string, any>) => {
  return data.bahai_statuscode !== bahai_statuscode.Completed;
};

export const isNotRemovable = (data: Record<string, any>) => {
  return !isActive(data) && <Trans>Reminder Status must be Upcoming or Action Needed to delete</Trans>;
};

export const isNotEditable = (data: Record<string, any>) =>
  !isActive(data) && <Trans>Reminder Status should be Upcoming or Action Needed</Trans>;

export const config: AdditionalConfigType<Keys | 'activityid'> = {
  subject: {
    component: ({ data, context, defaultValue }) =>
      context === 'TABLE' ? (
        <HistoryLink to={r.reminder({ id: data.activityid || 0 })} from="/dashboard">
          {data.subject || defaultValue}
        </HistoryLink>
      ) : (
        <>{data.subject || defaultValue} </>
      ),
  },
  bahai_inquirerid: {
    searchable: true,
    isRequired: true,
    fieldProps: ({ classes, context }) =>
      context !== 'SINGLE_PAGE' ? { className: classes.long, readOnly: true } : { readOnly: true },
  },
  description: {
    searchable: true,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
    component: AreaTooltipComponent,
  },

  regardingobjectid: {
    searchable: true,
    component: PolymorphicComponent,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'event',
          links: {
            invitedRequester: {
              from: 'bahai_eventid',
              to: 'bahai_event_imsid',
              fields: ['bahai_inquirerid'],
              condition: [],
            },
          },
          filters: [
            { attribute: 'statuscode', operator: 'ne', value: '200630004' },
            {
              attribute: 'bahai_inquirerid',
              entityname: 'invitedRequester',
              operator: 'eq',
              value: 'bahai_inquirerid',
            },
          ],
        },
        {
          entityName: 'inquiry',
          filters: [
            { attribute: 'statecode', operator: 'eq', value: '0' },
            { attribute: 'bahai_inquirerid', operator: 'eq', value: 'bahai_inquirerid' },
          ],
        },
      ],
      type: AutocompleteType.Polymorphic,
      dependencies: ['bahai_inquirerid'],
    }),
    fieldProps: ({ classes, context }) =>
      context !== 'SINGLE_PAGE'
        ? {
            className: classes.long,
            readOnly: context === 'FORM_CREATE' && window.location.pathname.includes('/inquiry/'),
          }
        : {},
  },

  requiredattendees: {
    isRequired: true,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'resource',
          links: {
            businessunit: {
              from: 'businessunitid',
              to: 'businessunitid',
              fields: [],
            },
          },
          filters: [
            {
              logicOperator: 'and',
              condition: [
                { attribute: 'isdisabled', operator: 'eq', value: '0' },
                { attribute: 'businessunit.parentbusinessunitid', operator: 'not-null' },
                { attribute: 'businessunit.name', operator: 'ne', value: 'Fund Development' },
              ],
            },
          ],
        },
        {
          entityName: 'contact',
          filters: [
            {
              logicOperator: 'and',
              condition: [
                { attribute: 'bahai_emailaddress1', operator: 'not-null' },
                { attribute: 'statecode', operator: 'eq', value: '0' },
              ],
            },
          ],
        },
      ],
      isMultiple: true,
      type: AutocompleteType.PartyList,
    }),
    fieldProps: ({ classes, context }) => (context !== 'SINGLE_PAGE' ? { className: classes.long } : {}),

    component: PartyListComponent({
      targetEntityName: 'bahai_reminder',
      participationTypeMask: ParticipationTypeMask.requiredattendees,
    }),

    hiddenForTable: true,
    textViewField: 'bahai_to',
  },

  bahai_to: {
    searchable: true,
    component: PartyListComponent({
      targetEntityName: 'bahai_reminder',
      participationTypeMask: ParticipationTypeMask.requiredattendees,
    }),
    filterAs: 'requiredattendees',
    sortable: false,
  },

  bahai_reminderdatetime: {
    isRequired: true,
    fieldProps: ({ classes, context }) => ({
      showTime: true,
      canSelectPastDate: false,
      className: context !== 'SINGLE_PAGE' ? classes.long : '',
    }),
  },
};

export const getDetailsConfig: FormConfigGetter<Keys> = () => [
  [
    '',
    [
      'bahai_reminderdatetime',
      'bahai_inquirerid',
      'description',
      'regardingobjectid',
      'requiredattendees',
      'createdon',
      'createdby',
    ] as Keys[],
  ],
];

export const getFormConfig: FormConfigGetter<Keys> = () => [
  [
    '',
    ['bahai_inquirerid', 'bahai_reminderdatetime', 'requiredattendees', 'description', 'regardingobjectid'] as Keys[],
  ],
];

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  requiredattendees: rules.required,
  bahai_reminderdatetime: rules.compose([rules.required, rules.futureTime]),
  description: rules.conditionalRule((values) => values.description, rules.length(0, 2000)),
};
