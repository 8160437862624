import entities from 'config';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { MouseEvent } from 'react';

const { columns } = entities.document;
type Keys = (typeof columns)[number] | 'sharepointdocumentid';

export { columns };

export const config: AdditionalConfigType<Keys> = {
  fullname: {
    searchable: true,
    component: ({ data }) => {
      const onClick = (e: MouseEvent) => {
        e.stopPropagation();
      };

      return (
        <a href={data.readurl || data.absoluteurl} target="_blank" rel="noreferrer" onClick={onClick}>
          {data.fullname}
        </a>
      );
    },
    fieldProps: ({ classes }) => ({
      className: classes.long,
      readOnly: true,
    }),
  },
  filetype: {
    hiddenForTable: true,
  },
  documentid: {
    hiddenForTable: true,
  },
  readurl: {
    hiddenForTable: true,
  },
  absoluteurl: {
    hiddenForTable: true,
  },
  isrecursivefetch: {
    hiddenForTable: true,
  },
  bahai_description: {
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
};

export const getFormConfig: FormConfigGetter<Keys> = () => [['', ['fullname', 'bahai_description']]];
