import { useNotifications, Notification, NotificationType } from 'providers/NotificationsProvider';
import { MouseEventHandler, useCallback, useEffect, useRef } from 'react';
import classes from './snack.module.scss';
import { ReactComponent as ErrorIcon } from './icons/error.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import { ReactComponent as SuccessIcon } from './icons/success.svg';
import { IconButton } from 'components/IconButton';
import { createPortal } from 'react-dom';
import cx from 'classnames';

const DISPLAY_TIME = 8000;

const Snack = ({ notification }: { notification: Notification }) => {
  const { removeNotification } = useNotifications();
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const onClose = useCallback(() => {
    if (timer.current) clearTimeout(timer.current);
    removeNotification(notification);
  }, [notification, removeNotification]);

  const runTimer = useCallback(() => {
    timer.current = setTimeout(() => removeNotification(notification), DISPLAY_TIME);
  }, [notification, removeNotification]);

  const stopTimer = useCallback(() => {
    timer.current && clearTimeout(timer.current);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(runTimer, []);

  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    ({ target }) => {
      if (target && ['A', 'BUTTON'].includes((target as HTMLElement).tagName)) {
        setTimeout(() => removeNotification(notification), 300);
      }
    },
    [notification, removeNotification]
  );

  const { title, type = NotificationType.INFO, content } = notification;
  return (
    <div
      className={cx(classes.snackRoot, classes[type])}
      onMouseEnter={stopTimer}
      onMouseLeave={runTimer}
      onClick={onClick}
    >
      <IconButton className={classes.close} Icon={CloseIcon} onClick={onClose} />
      <div className={classes.icon}>
        {type === NotificationType.ERROR && <ErrorIcon />}
        {type === NotificationType.WARNING && <WarningIcon />}
        {type === NotificationType.INFO && <InfoIcon />}
        {type === NotificationType.SUCCESS && <SuccessIcon />}
      </div>
      {title && <h4 className={classes.title}>{title}</h4>}
      {content && <div className={classes.content}>{content}</div>}
    </div>
  );
};

export const SnackBarNotifications = () => {
  const { notifications } = useNotifications();

  return createPortal(
    <div className={classes.root}>
      {notifications.map((notification) => (
        <Snack key={notification.key} notification={notification} />
      ))}
    </div>,
    document.body
  );
};
