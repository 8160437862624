import classes from './info.module.scss';
import { Modal } from 'components/Modal';
import { useTranslation } from 'react-i18next';
import { AgreementResponse } from 'components/AgreementInfo/hooks';

export const AgreementContent = ({
  onAgree,
  'agreement.bahai_title': title,
  'agreement.bahai_body': body,
}: { onAgree: () => void } & AgreementResponse) => {
  const { t } = useTranslation();

  const info = (
    <Modal
      controls={[
        {
          title: t('Agree'),
          type: 'button',
          role: 'primary',
          onClick: onAgree,
        },
      ]}
      className={classes.agreement}
    >
      <span className={classes.header}>{title}</span>
      <pre className={classes.body}>{body}</pre>
    </Modal>
  );
  return info;
};
