import { useCallback } from 'react';
import ListPage, { TListPage } from 'components/ListPage';
import * as baseConfig from 'schemas/reminder';
import { useCompleteReminder } from 'schemas/reminder/actions';
import { Action } from 'components/Actions';
import { useSecurity } from 'providers/AuthProvider';
import { Privilege } from 'lib';
import { ChainImprover } from 'lib/improvers';

const chains = [['bahai_inquiryid', 'bahai_inquirerid']];

export const Improver = () => <ChainImprover chains={chains} entityName="reminder" />;

const ReminderList = (props: Partial<TListPage> & { onItemsComplited?: () => void }) => {
  const { isGranted } = useSecurity();

  const { completeAction, content } = useCompleteReminder(true, props.onItemsComplited);

  const getActions = useCallback(
    (baseActions: Action[]) => [...baseActions, ...(isGranted('reminder', Privilege.Write) ? [completeAction] : [])],
    [completeAction, isGranted]
  );

  return (
    <>
      {content}
      <ListPage
        entityName="reminder"
        {...{
          ...baseConfig,
          getActions,
        }}
        {...props}
        FormImprover={Improver}
      />
    </>
  );
};

export default ReminderList;
