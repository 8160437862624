import classes from './switch.module.scss';
import cx from 'classnames';

export const Switch = ({ value = false, onChange }: { value?: boolean; onChange: () => void }) => {
  return (
    <button type="button" className={cx(classes.btn, { [classes.checked]: value })} onClick={onChange}>
      <div className={classes.circle} />
    </button>
  );
};
