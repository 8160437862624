const columns = [
  'bahai_systemuserid',
  'bahai_eventid',
  'bahai_groupid',
  'bahai_invitationstatuscode',
  'bahai_invitationresponsestatuscode',
  'bahai_isattended',
] as const;

export default { columns, fields: [] };
