import { AdditionalConfigType, FormConfigGetter } from 'lib';
import entities, { Fields } from 'config';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import * as rules from 'lib/rules';
import { FieldValidator } from 'final-form';
import { TextEditor } from 'components/Form/TextEditor';
import { HTMLComponent, ToolTipTextComponent } from 'lib/components';
import { Hidden } from 'components/Form/Hidden';

export const columns = entities.template.columns;
export type Keys = Fields<(typeof entities.template.columns)[number]>;
export const isActive = () => true;

export const defaultMobileColumns: Keys[] = ['bahai_name', 'bahai_subject', 'createdon'];

export const config: AdditionalConfigType<Keys> = {
  bahai_name: {
    searchable: true,
    isRequired: true,
    fieldProps: ({ classes }) => ({ className: classes.long, maxLength: 256 }),
    component: ({ data }) => (
      <HistoryLink to={r.template({ id: data.bahai_templateid || 0 })}>{data.bahai_name}</HistoryLink>
    ),
  },
  bahai_subject: {
    searchable: true,
    isRequired: true,
    fieldProps: ({ classes }) => ({ className: classes.long, maxLength: 256 }),
  },
  bahai_body: {
    hiddenForTable: true,
    isRequired: true,
    field: TextEditor,
    component: HTMLComponent('bahai_rawbody'),
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 5000,
    }),
  },
  bahai_rawbody: { component: ToolTipTextComponent('bahai_body') },
  bahai_templateid: { hiddenForTable: true },
  ownerid: {
    field: Hidden,
  },
};

export const getDetailsConfig: FormConfigGetter<Keys> = () => [
  ['', ['bahai_subject', 'createdon', 'createdby'] as Keys[]],
  ['', ['bahai_body'] as Keys[]],
];

export const getFormConfig: FormConfigGetter<Keys> = () => [
  ['', ['bahai_name', 'bahai_subject', 'bahai_body', 'ownerid'] as Keys[]],
];

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_body: rules.compose([rules.required, rules.maxLengthEscapeTags(5000)]),
  bahai_subject: rules.compose([rules.required, rules.maxLength(256)]),
  bahai_name: rules.compose([rules.required, rules.maxLength(256)]),
};
