import classes from './listPage.module.scss';
import { Pagination } from 'components/Table/Pagination';
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Header, { HEADER_CONTEXT } from 'components/ListPage/components/Header';
import { useMetaData } from 'lib/hooks';
import { ReactComponent as FilterIcon } from './icons/filter.svg';
import { ReactComponent as SettingsIcon } from './icons/settings.svg';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as ClearFiltersIcon } from './icons/clear.svg';
import { ReactComponent as ZoomIn } from './icons/zoom-in.svg';
import { ReactComponent as ZoomOut } from './icons/zoom-out.svg';

import { ColumnConfig, useExportToExcel, useExportToWord, useListPageApi } from 'components/ListPage/hook';
import { Form, TActionControlsProps, TSubmitProps } from './components/Form';
import { SearchBar } from 'components/ListPage/components/SearchBar';
import { ScreenContext } from 'providers/ScreenProvider';
import { useHistory } from 'react-router-dom';
import { ColumnsPanel } from 'components/ListPage/components/ColumnsPanel';
import { IconButton } from 'components/IconButton';
import { useTranslation } from 'react-i18next';
import { FormProps } from 'react-final-form';
import { AdditionalConfigType, ApiFilter, Condition, FormConfigGetter, Privilege, TEntityName } from 'lib';
import { useTableConfig } from 'lib/helpers';
import { FieldValidator, FormApi } from 'final-form';
import { Loader } from 'components/Loader';
import { ReactComponent as NoDataIcon } from 'components/Table/no-data.svg';
import { useFilters } from 'components/Table/hooks';
import cx from 'classnames';
import { FiltersPanel } from 'components/ListPage/components/FiltersPanel';
import { ViewsButton } from 'components/ListPage/components/ViewList';
import { RemoveModal } from 'components/RemoveModal';
import { ReactComponent as CheckAll } from 'components/ListPage/icons/select-in.svg';
import { ReactComponent as UncheckAll } from 'components/ListPage/icons/select-out.svg';
import { Action, ActionContext, ActionType, AllowedDevices, HeaderAction, useActions } from 'components/Actions';
import { FC } from 'lib';

import { useMultiRemove } from './components/MultiRemovePopup';
import { Control } from 'components/Panel';
import { useNotifications } from 'providers/NotificationsProvider';
import { ListTable } from 'components/ListPage/components/ListTable';
import { useSecurity } from 'providers/AuthProvider';
import { connectListPropsProvider, TableContext } from 'providers/TableProvider';
import { createPortal } from 'react-dom';

export type EntityLink = {
  from: string;
  to: string;
  fields: readonly string[];
  condition?: Condition[];
  links?: TLinkEntity;
  linkType?: 'inner' | 'outer';
};

export type TLinkEntity = Partial<Record<TEntityName, EntityLink | readonly string[]>>;

const PortalWrapper: FC = ({ children }) => createPortal(children, document.body);

export type TListPage = {
  entityName: TEntityName;
  placeholderText?: string;
  config?: AdditionalConfigType<string>;
  getMobileTitle?: (data: Record<string, any>) => string;
  getFormConfig: FormConfigGetter<string>;
  validate?: FormProps['validate'];
  validation?: Partial<Record<string, FieldValidator<any>>>;
  getInitialValues?: () => Promise<Record<string, any>>;
  FormImprover?: () => JSX.Element | null;
  additionalQuery?: Record<string, any>;
  links?: TLinkEntity;
  getActionControls?: (props: TActionControlsProps) => Control[];
  onSubmit?: (props: TSubmitProps) => Promise<any>;
  onItemCreated?: () => void;
  onItemsRemoved?: (itemsCount: number) => void;
  isSubgrid?: boolean;
  isCreateHidden?: boolean;
  isNotCreatable?: JSX.Element | false;
  hiddenFilters?: ApiFilter[];
  preSaveUpdate?: (data: Record<string, any>) => Record<string, any>;
  isRemoveHidden?: boolean;
  isNotRemovable?: (data: Record<string, any>) => JSX.Element | false;
  isConfirmationMessageNeeded?: (data: Record<string, any>) => boolean;
  isConfirmationMessageRequired?: (data: Record<string, any>) => boolean;
  getActions?: (baseActions: Action[]) => Array<Action>;
  children?: (props: {
    selectedItems: Record<string, any>[];
    query: any;
    data: Array<any>;
    reload: () => void;
    loading: boolean;
  }) => JSX.Element;
  allowRowClick?: boolean;
  isExportAllowed?: boolean;
  isWordExportAllowed?: boolean;
  systemView?: string;
  displayViews?: boolean;
  customRemove?: (data: Record<string, any>) => void;
  WarningsImprover?: FC<{ setWarnings: (warnings: Array<string | JSX.Element>) => void }>;
  formHelper?: JSX.Element | string;
  distinct?: boolean;
  showEditColumns?: boolean;
  showFilters?: boolean;
  showSearch?: boolean;
  defaultMobileColumns?: string[];
  hideFullScreen?: boolean;
};

const getActionsDefault = (v: Action[]) => v;

const SearchMobileWrapper: FC = ({ children }) => <div className={classes.searchWrapper}>{children}</div>;

const ListPage = ({
  entityName,
  placeholderText,
  getMobileTitle,
  config: additionalConfig = {},
  getFormConfig,
  validate,
  validation = {},
  getInitialValues,
  FormImprover,
  links,
  getActionControls,
  onSubmit,
  onItemCreated,
  onItemsRemoved,
  isSubgrid = false,
  hiddenFilters,
  preSaveUpdate,
  isCreateHidden = false,
  isNotCreatable = false,
  isRemoveHidden = false,
  isNotRemovable = () => false,
  isConfirmationMessageNeeded,
  isConfirmationMessageRequired,
  allowRowClick = true,
  getActions = getActionsDefault,
  children,
  isExportAllowed = false,
  isWordExportAllowed = false,
  displayViews = true,
  customRemove,
  WarningsImprover,
  formHelper,
  distinct,
  showEditColumns = true,
  showFilters = true,
  showSearch = true,
  defaultMobileColumns,
  hideFullScreen = false,
}: TListPage) => {
  // Table settings props

  const { entityConfig, displayName, displayCollectionName, PrimaryIdAttribute } = useMetaData(entityName);

  const { isGranted } = useSecurity();
  const [initialValues, setInitialValues] = useState({});
  const { push, location } = useHistory();
  const config = useTableConfig(entityConfig.fields, additionalConfig, entityName, links);
  const [loadingAbsolute, setLoadingAbsolute] = useState(false);

  const onRowDblClick = useCallback(
    (data: Record<string, any>) => {
      if (allowRowClick) {
        push(`/${entityName}/${data[PrimaryIdAttribute]}`, { from: location.pathname });
      }
    },
    [push, entityName, PrimaryIdAttribute, allowRowClick, location.pathname]
  );

  const { t } = useTranslation();

  const { queryParams, updateQueryParams, views, currentView, columnsConfig, setColumnsConfig } =
    useContext(TableContext);
  const setSearch = useCallback((search?: string) => updateQueryParams({ search, page: 1 }), [updateQueryParams]);

  const initialConfig = useMemo(() => views?.[currentView]?.columnsConfig, [currentView, views]);

  const {
    data,
    reload,
    loading,
    stats: { pages, recordsOverflow, total, firstIndex, lastIndex },
    query,
  } = useListPageApi({
    config,
    ready: true,
    links,
    entityName,
    hiddenFilters,
    queryParams,
    distinct,
  });

  const exportToExcelAction = useExportToExcel(isExportAllowed, query, setLoadingAbsolute, columnsConfig, entityName);
  const exportToWordAction = useExportToWord(isWordExportAllowed, setLoadingAbsolute, entityName);

  const [selected, setSelected] = useState<number[]>([]);
  const toggleSelectAll = useCallback(
    () => setSelected((selected) => (selected.length === data.length ? [] : data.map((_, index) => index))),
    [data, setSelected]
  );
  const toggleSelect = useCallback(
    (index: number) =>
      setSelected((selected) =>
        selected.includes(index) ? selected.filter((v) => v !== index) : selected.concat(index)
      ),
    [setSelected]
  );

  const fieldsToSearch = useMemo(
    () =>
      Object.entries(config)
        .filter(([_, { searchable }]) => searchable)
        .map(([name]) => entityConfig.fields[name].label),
    [config, entityConfig.fields]
  );

  useEffect(() => setSelected([]), [data]);

  const [removeVisible, setRemoveVisible] = useState(false);
  const closeRemove = useCallback(
    (reloadRequired?: boolean) => {
      setRemoveVisible(false);
      if (reloadRequired) {
        reload();
        onItemsRemoved && onItemsRemoved(data.length);
      }
    },
    [data.length, setRemoveVisible, reload, onItemsRemoved]
  );

  const [newItemPopupVisible, setNewItemPopupVisibility] = useState(false);

  const hideNewItemPopup = useCallback(() => setNewItemPopupVisibility(false), [setNewItemPopupVisibility]);

  const onFormSubmit = useCallback(() => {
    hideNewItemPopup();
    reload();
    onItemCreated && onItemCreated();
  }, [hideNewItemPopup, reload, onItemCreated]);

  const { addActionUncompleted } = useNotifications();

  const showNewItemPanel = useCallback(() => {
    if (isNotCreatable) {
      addActionUncompleted(isNotCreatable);
      return;
    }
    if (getInitialValues) {
      getInitialValues().then((data) => {
        setInitialValues(data);
        setNewItemPopupVisibility(true);
      });
    } else {
      setNewItemPopupVisibility(true);
    }
  }, [isNotCreatable, getInitialValues, addActionUncompleted]);

  const isRemovePossible = useMemo(() => selected.length !== 0, [selected]);

  const isRemoveConfirmationNeeded = useMemo(
    () => (isConfirmationMessageNeeded ? selected.some((index) => isConfirmationMessageNeeded(data[index])) : false),
    [isConfirmationMessageNeeded, selected, data]
  );

  const isConfirmationRequired = useMemo(
    () =>
      isConfirmationMessageRequired ? selected.some((index) => isConfirmationMessageRequired(data[index])) : false,
    [isConfirmationMessageRequired, selected, data]
  );

  const { isMobile, isTablet } = useContext(ScreenContext);
  const [fullScreen, setFullScreen] = useState(false);

  const selectionAction: Action = useMemo(
    () => ({
      onClick: toggleSelectAll,
      name: selected.length !== data.length ? 'selectAll' : 'unselectAll',
      order: 30,
      title: selected.length !== data.length ? 'Select All' : 'Unselect All',
      Icon: selected.length !== data.length ? CheckAll : UncheckAll,
      type: ActionType.SYSTEM_ACTION,
      allowedDevices: AllowedDevices.MOBILE_ONLY,
      actionContext: ActionContext.SubGid,
      onSelectionOnly: true,
    }),
    [data.length, selected.length, toggleSelectAll]
  );

  const baseActions = useActions({
    displayName,
    onCreate: showNewItemPanel,
    onReload: reload,
    onRemove: () => setRemoveVisible(true),
  });

  const defaultActions = useMemo(
    () => [selectionAction, ...baseActions, exportToExcelAction, exportToWordAction],
    [baseActions, exportToExcelAction, exportToWordAction, selectionAction]
  );

  const selectedItems = useMemo(() => selected.map((index) => data[index]), [data, selected]);

  const actions: Array<HeaderAction> = useMemo(
    () =>
      getActions(defaultActions)
        .filter(({ display = () => true }) =>
          display({
            isCreateAllowed: !isCreateHidden && isGranted(entityName, Privilege.Create),
            isRemoveAllowed: !isRemoveHidden && isGranted(entityName, Privilege.Delete) && isRemovePossible,
            isEditAllowed: false,
            data,
            isAssignAllowed: false,
            context: ActionContext.ListPage,
            selectedItemsCount: selectedItems.length,
            entityName,
          })
        )
        .map((props) => ({
          ...props,
          onClick: () =>
            props.onClick({
              selectedItems,
              context: isSubgrid ? ActionContext.SubGid : ActionContext.ListPage,
              query,
              reload,
            }),
        })),
    [
      getActions,
      defaultActions,
      isCreateHidden,
      isGranted,
      entityName,
      isRemoveHidden,
      isRemovePossible,
      data,
      selectedItems,
      isSubgrid,
      query,
      reload,
    ]
  );

  const [settingsPopupVisible, setSettingsPopupVisible] = useState(false);
  const hideSettingsPopup = useCallback(() => setSettingsPopupVisible(false), [setSettingsPopupVisible]);
  const showSettingsPopup = useCallback(() => setSettingsPopupVisible(true), [setSettingsPopupVisible]);

  const [filtersPopupVisible, setFiltersPopupVisible] = useState(false);
  const hideFiltersPopup = useCallback(() => setFiltersPopupVisible(false), [setFiltersPopupVisible]);
  const showFiltersPopup = useCallback(() => setFiltersPopupVisible(true), [setFiltersPopupVisible]);

  const [searchVisible, setSearchVisible] = useState(false);
  const toggleSearch = useCallback(() => setSearchVisible((v) => !v), [setSearchVisible]);

  const updateColumnConfig = useCallback(
    (config: ColumnConfig[]) => {
      setColumnsConfig(config);
      hideSettingsPopup();
    },
    [hideSettingsPopup, setColumnsConfig]
  );

  const { filters, clearOtherFilters } = useFilters();
  const placeholder = useMemo(() => {
    if (loading)
      return (
        <div className={classes.loader}>
          <Loader />
        </div>
      );
    if (data.length === 0 && filters.length === 0 && !queryParams.search)
      return (
        <div className={classes.loader}>
          <NoDataIcon />
          <p>{placeholderText || t('There are not any {{ displayCollectionName }} yet', { displayCollectionName })}</p>
          {!isCreateHidden && (
            <button type="button" onClick={showNewItemPanel}>
              {t('Add First {{ displayName }}', { displayName })}
            </button>
          )}
        </div>
      );
    if (data.length === 0)
      return (
        <div className={classes.loader} id="loader">
          <NoDataIcon />
          <p>{t('No data available')}</p>
        </div>
      );
  }, [
    loading,
    data.length,
    filters.length,
    queryParams.search,
    placeholderText,
    t,
    displayCollectionName,
    isCreateHidden,
    showNewItemPanel,
    displayName,
  ]);

  const { onRemove, Popup: removeProgress } = useMultiRemove({
    entityName,
    selectedItems,
    isNotRemovable,
    isConfirmationMessageNeeded,
    onClose: closeRemove,
    customRemove,
  });

  const startRemoving = useCallback(
    (formData: { Message?: string }, form: FormApi<{ Message: string }>) => {
      const { valid } = form.getState();
      if (valid) {
        onRemove(formData.Message);
      }
    },
    [onRemove]
  );

  const clearFilters = useCallback(() => clearOtherFilters(), [clearOtherFilters]);

  useEffect(() => {
    if (!loading && window.location.hash === '#create') {
      window.history.replaceState({}, document.title, window.location.href.split('#')[0]);
      document.getElementById('action_create')?.click();
    }
  }, [loading]);

  const SearchWrapper = useMemo(() => (isMobile ? SearchMobileWrapper : Fragment), [isMobile]);

  const Wrapper = useMemo(() => (fullScreen ? PortalWrapper : Fragment), [fullScreen]);

  return (
    <Wrapper>
      {loadingAbsolute && (
        <div className={classes.loaderAbsolute}>
          <Loader />
        </div>
      )}
      <div
        id="content"
        className={cx(classes.listPage, { [classes.subgrid]: isSubgrid, [classes.fullScreen]: fullScreen })}
      >
        {removeVisible && (
          <RemoveModal
            header={t('Do you want to delete selected {{ displayCollectionName }}? This is an irreversible action', {
              displayCollectionName,
            })}
            progress={removeProgress}
            onRemove={startRemoving}
            entityName={displayCollectionName}
            onClose={() => closeRemove(false)}
            isConfirmationMessageNeeded={isRemoveConfirmationNeeded}
            isConfirmationMessageRequired={isConfirmationRequired}
          />
        )}
        {newItemPopupVisible && (
          <Form
            onClose={hideNewItemPopup}
            postAction={onFormSubmit}
            entityName={entityName}
            {...{
              config,
              getFormConfig,
              validate,
              validation,
              initialValues,
              FormImprover,
              getActionControls,
              onSubmit,
              preSaveUpdate,
              WarningsImprover,
              helper: formHelper,
            }}
          />
        )}
        {settingsPopupVisible && (
          <ColumnsPanel
            {...{ entityName, initialConfig, additionalConfig }}
            config={columnsConfig}
            setConfig={updateColumnConfig}
            onClose={hideSettingsPopup}
          />
        )}
        {filtersPopupVisible && <FiltersPanel config={config} onClose={hideFiltersPopup} entityName={entityName} />}
        <div className={classes.subHeader}>
          <div className={classes.headerButtons}>
            <Header
              headerContext={HEADER_CONTEXT.SUBGRID}
              actions={actions}
              selectedItemsCount={selectedItems.length}
            />
            {(selected.length === 0 || !isMobile) && (
              <div className={classes.tableControls}>
                {displayViews && <ViewsButton classes={classes} entityName={entityName} />}
                {showEditColumns && (
                  <IconButton
                    id="action_editColumns"
                    className={cx({ [classes.iconButtonMobile]: isMobile || isTablet })}
                    iconOnly={isMobile || isTablet}
                    Icon={SettingsIcon}
                    onClick={showSettingsPopup}
                  >
                    {t('Edit Columns')}
                  </IconButton>
                )}
                {showFilters && (
                  <IconButton
                    id="action_filters"
                    className={cx({
                      [classes.filtersActive]: filters.length > 0,
                      [classes.iconButtonMobile]: isMobile || isTablet,
                    })}
                    iconOnly={isMobile || isTablet}
                    extraIcon={
                      filters.length > 0 ? <span className={classes.filterIndicator}>{filters.length}</span> : null
                    }
                    onClick={showFiltersPopup}
                    Icon={FilterIcon}
                  >
                    {t('Filters')}
                  </IconButton>
                )}
                {filters.length > 0 && (
                  <>
                    <div className={classes.separator} />
                    <IconButton iconOnly Icon={ClearFiltersIcon} onClick={clearFilters} />
                  </>
                )}
                {(isMobile || isTablet) && !searchVisible && showSearch && (
                  <IconButton
                    className={cx({
                      [classes.filtersActive]: !!queryParams.search,
                      [classes.iconButtonMobile]: isMobile || isTablet,
                    })}
                    iconOnly={isMobile || isTablet}
                    onClick={toggleSearch}
                    Icon={SearchIcon}
                  />
                )}
              </div>
            )}
          </div>
          {(!(isMobile || isTablet) || searchVisible) && showSearch && (
            <SearchWrapper>
              <SearchBar
                value={queryParams.search || ''}
                onChange={setSearch}
                fields={fieldsToSearch}
                onBlur={toggleSearch}
              />
            </SearchWrapper>
          )}
          {isSubgrid && !hideFullScreen && (
            <IconButton iconOnly onClick={() => setFullScreen(!fullScreen)} Icon={fullScreen ? ZoomOut : ZoomIn} />
          )}
        </div>
        <ListTable
          {...{
            data,
            onRowDblClick,
            config,
            entityName,
            selected,
            toggleSelect,
            getMobileTitle,
            placeholder,
            setSelected,
            allowRowClick,
            loading,
            defaultMobileColumns,
          }}
        />
        {data.length !== 0 && (
          <div className={classes.footer} id="list_page_footer">
            {(!isMobile || selected.length === 0) && (
              <div className={classes.records}>
                {t('{{ firstIndex }} - {{lastIndex}} of {{ total }}', {
                  total,
                  firstIndex,
                  lastIndex,
                  selected: selected.length,
                })}
                {recordsOverflow ? '+ ' : ' '}
              </div>
            )}
            {selected.length > 0 && (
              <div className={classes.selectedText}>{t('{{ selected}} selected', { selected: selected.length })}</div>
            )}
            <Pagination pages={pages} />
          </div>
        )}
      </div>
      {children && children({ selectedItems, query, reload, data, loading })}
    </Wrapper>
  );
};

export default connectListPropsProvider(ListPage);
