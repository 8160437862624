import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Modal } from 'components/Modal';
import { Trans, useTranslation } from 'react-i18next';
import { ListTable } from 'components/ListPage/components/ListTable';
import { ColumnConfig, useListPageApi } from 'components/ListPage/hook';
import { config as additionalConfig, getDetailsConfig, Keys, links, statusFields, validation } from 'schemas/requester';
import { useTableConfig } from 'lib/helpers';
import { useMetaData } from 'lib/hooks';
import classes from './merge.module.scss';
import { Accordion } from 'components/Accordion';
import { Stepper } from 'components/Stepper';
import { IconButton } from 'components/IconButton';
import { ReactComponent as Arrow } from './back.svg';
import { Radio } from 'components/Radio';
import { useRequesterValidation } from 'schemas/requester/hooks';
import { ReadOnlyValue } from 'components/Form/ReadOnlyValue';
import { SelectionType, TCellComponent, TConfig } from 'components/Table';
import { useErrors } from 'components/ListPage/components/Form';
import cx from 'classnames';
import { DataBlock, StatusBlock } from 'components/SinglePage';
import spClasses from 'components/SinglePage/singlePage.module.scss';

import {
  bahai_inquirerstatus,
  bahai_inquirerstatusdetails,
  bahai_inquirertype as InquirerType,
} from 'config/EntityMetadata/bahai_inquirer';
import { useApi } from 'domain/api';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { useRecord } from 'lib/record';
import { HistoryLink } from 'components/HistoryLink';
import { routes } from 'domain/routes';
import { deepEqual, TableContext, TableProvider } from 'providers/TableProvider';
import { ApiFilter } from 'lib';
import { Switch } from 'components/Switch';
import { SearchBar } from 'components/ListPage/components/SearchBar';
import { Loader } from 'components/Loader';
import { ReactComponent as NoDataIcon } from 'components/Table/no-data.svg';
import { Pagination } from 'components/Table/Pagination';
import { FieldState } from 'final-form';
import { getFormattedNow, parseDate, toISO } from 'lib/adapter';
import { InquiryPanel } from 'schemas/requester/components/MergeRequester/components/InquiryPanel';
import { ShowMore } from 'components/ShowMore';
import { DateOnlyComponent } from 'lib/components';

enum Types {
  DraftSeeker,
  NotDraftSeeker,
  General,
  OtherRegistrant,
  EnrolledRegistrant,
  InEnrollmentRegistrant,
  Member,
}

const visibleColumns: Keys[] = [
  'bahai_id',
  'bahai_firstname',
  'bahai_lastname',
  'bahai_nickname',
  'bahai_isdraft',
  'bahai_localityid',
  'bahai_emailaddress1',
  'bahai_emailaddress2',
  'bahai_statuscode',
  'bahai_statusdetailscode',
  'bahai_inquirertypecode',
  'createdon',
  'bahai_cellphone',
  'bahai_workphone',
  'bahai_homephone',
  'bahai_websiteurl',
  'bahai_socialmediaurl',
];

const primaryFields: Keys[] = [
  'bahai_id',
  'bahai_isdraft',
  'bahai_contactid',
  'bahai_inquirertypecode',
  'bahai_statuscode',
  'bahai_statusdetailscode',
  'ownerid',
  'createdon',
  'bahai_sourcecode',
];

const steps = [<Trans>Select Duplicates</Trans>, <Trans>Compare Duplicates</Trans>, <Trans>Сonfirm Merge</Trans>];

type Requester = {
  bahai_isdraft: boolean;
  bahai_inquirertypecode: InquirerType;
  bahai_statuscode: bahai_inquirerstatus;
  bahai_statusdetailscode: bahai_inquirerstatusdetails;
};

const checkType = ({
  bahai_isdraft: isDraft,
  bahai_inquirertypecode: type,
  bahai_statuscode: status,
  bahai_statusdetailscode: details,
}: Requester): Types => {
  switch (type) {
    case InquirerType.Registrant:
      if (status === bahai_inquirerstatus.Enrolled) return Types.EnrolledRegistrant;
      if (
        (status === bahai_inquirerstatus.Verified &&
          details === bahai_inquirerstatusdetails.PendingEnrollmentwithMembership) ||
        (status === bahai_inquirerstatus.NeedsVerification &&
          details === bahai_inquirerstatusdetails.PendingEnrollmentwithLSA)
      )
        return Types.InEnrollmentRegistrant;
      return Types.OtherRegistrant;
    case InquirerType.Seeker:
      return isDraft ? Types.DraftSeeker : Types.NotDraftSeeker;
    case InquirerType.Member:
      return Types.Member;
    case InquirerType.General:
      return Types.General;
  }
};

const customComponents: Record<string, TCellComponent<any>> = {
  bahai_originationdate: DateOnlyComponent,
};

// ___ isCurrentPrimary isEditable isManualAllowed
const rules = [
  ['111', '010', '010', '010', '000', '000', '000'],
  ['110', '111', '111', '010', '000', '000', '000'],
  ['110', '111', '111', '010', '000', '000', '000'],
  ['110', '110', '110', '111', '000', '000', '000'],
  ['100', '100', '100', '100', '000', '000', '000'],
  ['100', '100', '100', '100', '000', '000', '000'],
  ['100', '100', '100', '100', '000', '000', '000'],
];

const getRule = (current: Requester, duplicate: Requester) => {
  const currentType = checkType(current) as number;
  const duplicateType = checkType(duplicate) as number;
  return rules[currentType][duplicateType] || '111';
};

const schema: Array<[JSX.Element, Keys[], boolean]> = [
  [
    <Trans>General</Trans>,
    [
      'bahai_title',
      'bahai_firstname',
      'bahai_nickname',
      'bahai_middlename',
      'bahai_lastname',
      'bahai_suffix',
      'bahai_gendercode',
      'bahai_birthcountryid',
      'bahai_birthstateid',
      'bahai_birthcityid',
      'bahai_race',
      'bahai_ethnicity',
      'bahai_otherethnicity',
      'bahai_declarationdate',
      'bahai_preferredlanguagecode',
      'bahai_otherlanguage',
      'bahai_importantnote',
    ],
    true,
  ],
  [<Trans>Birth Date</Trans>, ['bahai_estimatedagerange', 'bahai_birthdate'], false],
  [
    <Trans>Contact Method</Trans>,
    [
      'bahai_contactmethodcode',
      'bahai_emailaddress1',
      'bahai_emailaddress2',
      'bahai_cellphone',
      'bahai_workphone',
      'bahai_homephone',
      'bahai_socialmediaurl',
      'bahai_websiteurl',
    ],
    true,
  ],
  [
    <Trans>Address</Trans>,
    [
      'bahai_isoutofusa',
      'bahai_postalcodeid',
      'bahai_cityid',
      'bahai_stateid',
      'bahai_addressline1',
      'bahai_addressline2',
      'bahai_compositeaddress',
      'bahai_regionid',
      'bahai_clusterid',
      'bahai_localityid',
    ],
    false,
  ],
  [
    <Trans>Mailing Address</Trans>,
    [
      'bahai_usedifferentmailingaddress',
      'bahai_mailingisoutofusa',
      'bahai_mailingpostalcodeid',
      'bahai_mailingcityid',
      'bahai_mailingstateid',
      'bahai_mailingaddressline1',
      'bahai_mailingaddressline2',
      'bahai_mailingcompositeaddress',
    ],
    false,
  ],
  [
    <Trans>Parent Permission</Trans>,
    [
      'bahai_parentrequesterid',
      'bahai_parentmemberid',
      'bahai_dateofpermissiongiven',
      'bahai_typeofpermission',
      'bahai_permissionnote',
    ],
    false,
  ],
];

const extraFields: Array<Keys[]> = [
  ['bahai_clusterid', 'cluster.bahai_name'],
  ['bahai_clusterid', 'cluster.bahai_stageofgrowth'],
  ['bahai_localityid', 'locality.bahai_name'],
  ['bahai_localityid', 'locality.bahai_localitytype'],
];

const formKeys = schema.map((v) => v[1]).flat();

type MergeProps = {
  id?: string;
  formValues?: Record<string, any>;
  idList: string[];
  onClose: () => void;
  closer: (id: string) => void;
  isDraft: boolean;
};

const MergeRequesterComponent = ({ id, formValues = {}, idList, onClose, closer, isDraft }: MergeProps) => {
  const { t } = useTranslation();
  const { entityConfig, getLabel, PrimaryIdAttribute } = useMetaData('requester', 'Default');
  const [search, setSearch] = useState('');
  const [useManual, setUseManual] = useState(false);
  const { mergeRequesters, getAvailableStatuses } = useApi();

  const trimmedSearch = useMemo(() => search.trim(), [search]);

  const columnsConfig: ColumnConfig[] = useMemo(
    () => visibleColumns.map((name) => ({ name, width: 160, visible: true, pinned: false })),
    []
  );

  const config = useTableConfig(entityConfig.fields, additionalConfig, 'requester', links);

  const CellComponent: TCellComponent<Record<string, any>> = useMemo(
    () =>
      ({ data, name, context }) => (
        <ReadOnlyValue context={context} name={name} entity={'requester'} data={data} config={config} />
      ),
    [config]
  );

  const mainConfig = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(config).map(([key, config]) => [
          key,
          {
            ...config,
            component: customComponents[key] || CellComponent,
          } as TConfig<Record<string, any>>,
        ])
      ),
    [CellComponent, config]
  );

  const { getData, getFormValues, save, patch } = useRecord('requester', true);

  const [recordData, setRecordData] = useState({
    bahai_isdraft: isDraft,
    bahai_inquirertypecode: InquirerType.Seeker,
    createdon: getFormattedNow(),
  } as Record<Keys, any>);

  const [recordLoading, setRecordLoading] = useState(!!id);

  useEffect(() => {
    if (id) {
      setRecordLoading(true);
      getData(id, links)
        .then((data) => {
          setRecordData(data);
        })
        .finally(() => setRecordLoading(false));
    }
  }, [getData, id]);

  const { queryParams } = useContext(TableContext);

  const hiddenFilters: ApiFilter[] = useMemo(
    () => [
      ...(useManual || idList.length === 0
        ? [{ condition: [{ operator: 'like', attribute: 'bahai_name', value: `%${trimmedSearch}%` }] } as ApiFilter]
        : [{ condition: [{ operator: 'in', attribute: 'bahai_inquirerid', value: idList }] } as ApiFilter]),
      ...(id ? [{ condition: [{ operator: 'ne', attribute: 'bahai_inquirerid', value: id }] } as ApiFilter] : []),
    ],
    [id, idList, trimmedSearch, useManual]
  );

  const {
    data,
    loading: listLoading,
    stats: { pages, recordsOverflow, total, firstIndex, lastIndex },
  } = useListPageApi({
    config,
    ready: true,
    entityName: 'requester',
    hiddenFilters,
    queryParams,
    links,
  });

  const duplicates = useMemo(() => (idList.length === 0 && !useManual ? [] : data), [data, idList.length, useManual]);

  const [errorsVisible, setErrorsVisible] = useState(false);
  const [selected, select] = useState<number[]>([]);

  useEffect(() => {
    if (search) select([]);
  }, [search]);

  const switchManual = useCallback(() => {
    select([]);
    setSearch('');
    setUseManual((v) => !v);
  }, [select]);

  const duplicate: Record<Keys, any> = useMemo(() => (selected.length ? data[selected[0]] : {}), [data, selected]);

  const rule = useMemo(() => getRule(recordData, duplicate), [duplicate, recordData]);

  const [betterRecord, isRecordEditable, isManualAllowed] = useMemo(
    () => rule.split('').map((v) => Number(v) as 0 | 1),
    [rule]
  );

  const setSelected = useCallback((indexList: number[]) => {
    return select(indexList.slice(-1));
  }, []);

  const prevStep = useCallback(() => setStep((v) => v - 1), []);

  const [values, setValues] = useState<Partial<Record<Keys | 'id', 0 | 1>>>({});

  const switchValue = useCallback((key: Keys | 'id' | Array<Keys | 'id'>, value: 0 | 1) => {
    setValues((v) => ({ ...v, ...Object.fromEntries([key].flat().map((key) => [key, value])) }));
  }, []);

  const isChecked = useCallback(
    (key: Keys | 'id' | Array<Keys | 'id'>, value: 0 | 1) => ![key].flat().some((key) => values[key] !== value),
    [values]
  );

  const additionalFields = useMemo(() => ['bahai_regionid', 'bahai_clusterid', 'bahai_localityid'], []);

  const recordFormValues = useMemo(
    () => ({
      ...getFormValues(recordData, [], additionalFields),
      ...formValues,
    }),
    [formValues, getFormValues, recordData, additionalFields]
  );
  const duplicateFormValues = useMemo(
    () => getFormValues(duplicate, [], additionalFields),
    [duplicate, getFormValues, additionalFields]
  );

  const mainRecord: Record<Keys, any> = useMemo(
    () => (!values.id ? recordData : duplicate),
    [duplicate, recordData, values.id]
  );

  const isDuplicatePrimary = useMemo(() => values.id === 1, [values.id]);

  const newRecordFormData: Record<string, any> = useMemo(() => {
    return {
      ...(isDuplicatePrimary ? duplicateFormValues : getFormValues(recordData, [], additionalFields)),
      ...Object.fromEntries(
        Object.entries(values)
          .filter((v) => v[0] !== 'id')
          .map(([key, rightSide]) => {
            return [key, rightSide ? duplicateFormValues[key] : recordFormValues[key]];
          })
      ),
      ...(!isDraft ? { bahai_isdraft: false } : {}),
    };
  }, [
    duplicateFormValues,
    getFormValues,
    isDraft,
    isDuplicatePrimary,
    recordData,
    recordFormValues,
    values,
    additionalFields,
  ]);

  const duplicateId = useMemo(() => duplicate[PrimaryIdAttribute as Keys] as string, [PrimaryIdAttribute, duplicate]);
  const { validate } = useRequesterValidation();

  const datesData = useMemo(() => {
    const interaction = Math.max(
      ...[duplicate.bahai_lastinteraction, recordData.bahai_lastinteraction]
        .filter((v) => !!v && parseDate(v, true))
        .map((v) => (parseDate(v, true) as Date).getTime())
    );
    const origination = Math.min(
      ...[duplicate.bahai_originationdate, recordData.bahai_originationdate]
        .filter((v) => !!v && parseDate(v, false))
        .map((v) => (parseDate(v, false) as Date).getTime())
    );
    return {
      ...(isFinite(interaction) ? { bahai_lastinteraction: toISO(new Date(interaction)) } : {}),
      ...(isFinite(origination) ? { bahai_originationdate: toISO(new Date(origination)) } : {}),
    };
  }, [
    duplicate.bahai_lastinteraction,
    duplicate.bahai_originationdate,
    recordData.bahai_lastinteraction,
    recordData.bahai_originationdate,
  ]);

  const extraFormFields = useMemo(
    () => ({
      ...Object.fromEntries([
        ...extraFields.map(([key, value]) => {
          const leftSide = !values[key];
          return [value, leftSide ? recordData[value] : duplicate[value]];
        }),
        ...(id
          ? [
              ['bahai_mergeddatetime', getFormattedNow()],
              ['bahai_mergedwith', !isDuplicatePrimary ? duplicateId : (id as string)],
            ]
          : []),
      ]),
      ...datesData,
    }),
    [datesData, duplicate, duplicateId, id, isDuplicatePrimary, recordData, values]
  );

  const resultRecordId = useMemo(
    () => (isDuplicatePrimary ? duplicateId : (id as string)),
    [duplicateId, id, isDuplicatePrimary]
  );

  const getStatusData = useCallback(async () => {
    if (
      (mainRecord.bahai_inquirertypecode === InquirerType.Seeker &&
        mainRecord.bahai_statuscode === bahai_inquirerstatus.Archive) ||
      bahai_inquirerstatus.DoNotContact
    ) {
      const { Statuses } = await getAvailableStatuses(resultRecordId, 'bahai_inquirer', true);
      const list = [bahai_inquirerstatus.New, bahai_inquirerstatus.InConversation, bahai_inquirerstatus.Approached]
        .map((code) => Statuses.find((v) => v.StatusCode === code && v.IsAvailable))
        .filter((v) => !!v);
      if (list[0]) {
        const { NativeStatusCode: statuscode, NativeStateCode: statecode, StatusCode: bahai_statuscode } = list[0];
        return { bahai_statuscode, bahai_statusdetailscode: null, statuscode, statecode };
      }
    }
  }, [getAvailableStatuses, mainRecord.bahai_inquirertypecode, mainRecord.bahai_statuscode, resultRecordId]);

  const errors: Record<string, any> = useMemo(
    () =>
      mainRecord.bahai_isdraft === true
        ? {}
        : {
            ...validate(newRecordFormData),
            ...Object.fromEntries(
              Object.entries(validation)
                .map(([key, fn]) => [
                  key,
                  fn(newRecordFormData[key], newRecordFormData, {
                    data: { id: resultRecordId },
                  } as unknown as FieldState<any>),
                ])
                .filter((v) => !!v[1])
            ),
          },
    [mainRecord.bahai_isdraft, newRecordFormData, resultRecordId, validate]
  );

  const [step, setStep] = useState(0);
  const { refresh, ErrorsBlock } = useErrors();
  const nextStep = useCallback(() => {
    if (step === 1 && errors._general && errors._general.length) {
      setErrorsVisible(true);
      refresh();
    } else {
      setStep((v) => v + 1);
    }
  }, [errors._general, refresh, step]);

  const nextAllowed = useMemo(() => {
    switch (step) {
      case 0:
        return selected[0] !== undefined;
      case 1:
        return Object.keys(errors).filter((v) => v !== '_general').length === 0;
      default:
        return true;
    }
  }, [errors, selected, step]);

  const viewConfig = useMemo(() => getDetailsConfig(newRecordFormData as Record<Keys, any>, true), [newRecordFormData]);

  // Choose main Record
  useEffect(() => {
    const olderRecord = new Date(duplicate.createdon) > parseDate(recordData.createdon, true) ? 0 : 1;
    if (isManualAllowed) {
      switchValue(['id', ...formKeys], olderRecord);
    } else {
      switchValue(['id', ...formKeys], betterRecord ? 0 : 1);
    }
  }, [betterRecord, duplicate.createdon, isManualAllowed, recordData.createdon, selected, switchValue]);

  const [loading, setLoading] = useState(false);

  const mergeParams: [string, string] = useMemo(
    () => (isDuplicatePrimary ? [duplicateId, id as string] : [id as string, duplicateId]),
    [duplicateId, id, isDuplicatePrimary]
  );

  const { addNotification, addActionFailed } = useNotifications();

  const saveChanges = useCallback(
    async (updateStatus = true) => {
      setLoading(true);
      try {
        if (id) await mergeRequesters(...mergeParams);
        await save({ ...newRecordFormData, ...datesData, bahai_confirmationmessage: null }, resultRecordId);
        if (updateStatus) {
          const statusData = await getStatusData();
          if (statusData) await patch(statusData, resultRecordId);
        }
        addNotification({
          type: NotificationType.SUCCESS,
          title: t('Merging complete'),
          content: (
            <Trans>
              Please, go to <HistoryLink to={routes.requester({ id: resultRecordId })}>hyperlink</HistoryLink> to see
            </Trans>
          ),
        });
        onClose();
        closer(resultRecordId);
      } catch (e) {
        setLoading(false);
        addActionFailed(t('Something went wrong'));
      }
    },
    [
      addActionFailed,
      addNotification,
      closer,
      datesData,
      getStatusData,
      id,
      mergeParams,
      mergeRequesters,
      newRecordFormData,
      onClose,
      patch,
      resultRecordId,
      save,
      t,
    ]
  );

  const [updateStatusVisible, setUpdateStatusVisibility] = useState(false);

  const acceptStatusUpdate = useCallback(() => {
    setUpdateStatusVisibility(false);
    saveChanges(true).then();
  }, [saveChanges]);

  const ignoreStatusUpdate = useCallback(() => {
    setUpdateStatusVisibility(false);
    saveChanges(false).then();
  }, [saveChanges]);

  const merge = useCallback(() => {
    if (
      mainRecord.bahai_inquirertypecode === InquirerType.Seeker &&
      mainRecord.bahai_statuscode === bahai_inquirerstatus.Archive
    ) {
      setUpdateStatusVisibility(true);
    } else {
      saveChanges().then();
    }
  }, [mainRecord.bahai_inquirertypecode, mainRecord.bahai_statuscode, saveChanges]);

  const warnings = useMemo(
    () => [<Trans>Please note that you may lose access to the Requester after saving.</Trans>],
    []
  );

  const placeholder = useMemo(() => {
    if (listLoading)
      return (
        <div className={classes.loader}>
          <Loader />
        </div>
      );
    if (duplicates.length === 0)
      return (
        <div className={classes.loader}>
          <NoDataIcon />
          <p>{t('No data available')}</p>
        </div>
      );
  }, [duplicates.length, listLoading, t]);

  const currentRecordData = useMemo(
    () => [{ ...(recordData || {}), ...recordFormValues }],
    [recordData, recordFormValues]
  );

  const requesterFieldsDiff = useMemo(
    () =>
      Object.fromEntries(
        schema
          .map((v) => v[1])
          .flat()
          .map((key) => [key, deepEqual(recordFormValues[key], duplicateFormValues[key])])
      ),
    [recordFormValues, duplicateFormValues]
  );

  const [activeTab, setActiveTab] = useState<'general' | 'inquiry'>('general');

  return (
    <Modal
      title={t('Merge Duplicate Records')}
      onClose={onClose}
      loading={loading}
      className={classes.modal}
      fixedSize={true}
      showZoom={true}
      isDraggingAllowed={false}
      collapsedLabel={t(`Merge potential Duplicate Records`)}
      noPadding
      controls={[
        () => (
          <div key={'footer'} className={classes.footer}>
            <IconButton onClick={prevStep} disabled={step === 0} bordered Icon={Arrow}>
              {t('Back')}
            </IconButton>
            <div className={classes.stepper}>
              <Stepper steps={steps} current={step} />
            </div>
            <IconButton
              disabled={!nextAllowed}
              bordered
              right
              onClick={step === 2 ? merge : nextStep}
              Icon={step === 2 ? undefined : () => <Arrow style={{ transform: 'rotate(180deg)' }} />}
              className={classes.primary}
            >
              {step === 2 ? t('Merge') : t('Next')}
            </IconButton>
          </div>
        ),
      ]}
    >
      {step === 0 && (
        <div className={classes.dialogWrapper}>
          <div className={classes.mainWrapper}>
            <Accordion title={t('My current Record')}>
              <TableProvider entityName="requester" dialog="merge-main" columns={visibleColumns} systemFields={false}>
                <ListTable
                  {...{
                    data: currentRecordData,
                    config: mainConfig,
                    columnsConfig,
                    entityName: 'requester',
                    showControls: false,
                    visibleColumns,
                    allowRowClick: false,
                    loading: recordLoading,
                    className: classes.table,
                    selectionType: SelectionType.OPTION,
                    selected: [0],
                    setSelected: () => null,
                    context: 'MERGE',
                    placeholder: recordLoading ? placeholder : undefined,
                  }}
                />
              </TableProvider>
            </Accordion>
          </div>
          <div className={classes.duplicatesWrapper}>
            <Accordion title={t('Potential Duplicate Records')}>
              <div className={classes.manualBox}>
                <div className={classes.toggle}>
                  <Switch value={useManual} onChange={switchManual} />
                  {t('Manual Search')}
                </div>
                <div className={classes.search}>
                  {useManual && <SearchBar value={search} onChange={setSearch} fields={['IDN', 'Full Name']} />}
                </div>
              </div>
              <div className={classes.table}>
                <ListTable
                  {...{
                    data: duplicates,
                    config,
                    columnsConfig,
                    entityName: 'requester',
                    selected,
                    toggleSelect: (index) => setSelected([index]),
                    visibleColumns,
                    getMobileTitle: () => '',
                    setSelected,
                    allowRowClick: false,
                    loading: listLoading,
                    selectionType: SelectionType.OPTION,
                    placeholder,
                    context: 'MERGE',
                  }}
                />
                {duplicates.length !== 0 && (
                  <div className={classes.pages}>
                    {t('{{ firstIndex }} - {{lastIndex}} of {{ total }}', {
                      total,
                      firstIndex,
                      lastIndex,
                    })}
                    {recordsOverflow ? '+ ' : ' '}
                    <Pagination pages={pages} />
                  </div>
                )}
              </div>
            </Accordion>
          </div>
        </div>
      )}
      {step === 1 && (
        <div className={classes.scroll}>
          <ErrorsBlock errors={{ warnings }} relative />
          {errors._general && errorsVisible && <ErrorsBlock errors={errors} relative />}
          <Accordion title={t('Primary Record')} disabled={!isManualAllowed}>
            <div className={classes.wrapper}>
              <div className={classes.line}>
                <div className={classes.label}>{t('Primary Record')}</div>
                <button
                  className={cx(classes.field, classes.head)}
                  type="button"
                  onClick={() => switchValue(['id', ...schema.map((v) => v[1]).flat()], 0)}
                >
                  <Radio checked={values.id === 0} />
                  <div>{t('My Current Record')}</div>
                </button>
                <button
                  className={cx(classes.field, classes.head)}
                  type="button"
                  onClick={() => switchValue(['id', ...schema.map((v) => v[1]).flat()], 1)}
                >
                  <Radio checked={values.id === 1} />
                  <div>{t('Selected Duplicate')}</div>
                </button>
              </div>
              {primaryFields.map((key) => (
                <div className={classes.line} key={key}>
                  <div className={classes.label}>{getLabel(key)}</div>
                  <div className={classes.field}>
                    <div className={classes.placeholder} />
                    {config[key].adapter(recordData || {}, key, '---')}
                  </div>
                  <div className={classes.field}>
                    <div className={classes.placeholder} />
                    {config[key].adapter(duplicate, key, '---')}
                  </div>
                </div>
              ))}
            </div>
          </Accordion>
          {schema.map(([title, keys, isEditable]) => (
            <Accordion
              key={keys.join(',')}
              disabled={!isRecordEditable}
              title={
                <>
                  {title}
                  {keys.filter((key) => errors[key]).length > 0 && <div className={classes.errorLabel}>!</div>}
                </>
              }
            >
              <div className={classes.wrapper}>
                <div className={classes.line}>
                  <div className={classes.label} />
                  <button
                    className={cx(classes.field, classes.head)}
                    type="button"
                    onClick={() => switchValue(keys, 0)}
                  >
                    <Radio checked={isChecked(keys, 0)} />
                    <div>{t('Select All')}</div>
                  </button>
                  <button
                    className={cx(classes.field, classes.head)}
                    type="button"
                    onClick={() => switchValue(keys, 1)}
                  >
                    <Radio checked={isChecked(keys, 1)} />
                    <div>{t('Select All')}</div>
                  </button>
                </div>

                {keys.map((key) => (
                  <div key={key} className={cx(classes.line, { [classes.fieldHighlight]: !requesterFieldsDiff[key] })}>
                    <div
                      className={cx(classes.label, {
                        [classes.withError]: errors[key],
                      })}
                    >
                      {getLabel(key)}
                    </div>
                    <button className={classes.field} type="button" onClick={() => switchValue(key, 0)}>
                      {isEditable ? <Radio checked={values[key] === 0} /> : <div className={classes.placeholder} />}

                      <ReadOnlyValue
                        context="MERGE"
                        config={config}
                        entity={'requester'}
                        name={key}
                        data={{ [key]: recordFormValues[key] }}
                      />
                    </button>
                    <button className={classes.field} type="button" onClick={() => switchValue(key, 1)}>
                      {isEditable ? <Radio checked={values[key] === 1} /> : <div className={classes.placeholder} />}
                      <div className={classes.value}>
                        <ReadOnlyValue
                          context="MERGE"
                          config={config}
                          entity={'requester'}
                          name={key}
                          data={{ [key]: duplicateFormValues[key] }}
                        />
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            </Accordion>
          ))}
        </div>
      )}
      {step === 2 && (
        <div className={cx(classes.scroll, classes.scrollPreview)}>
          {updateStatusVisible && (
            <Modal
              title={t('Confirm Status Update')}
              header={t('Would you like to keep Primary record as Archive?')}
              onClose={() => setUpdateStatusVisibility(false)}
              portal={true}
              controls={[
                {
                  title: t('Yes'),
                  role: 'primary',
                  onClick: ignoreStatusUpdate,
                },
                {
                  title: t('No'),
                  onClick: acceptStatusUpdate,
                },
              ]}
            />
          )}
          <div className={classes.preview}>
            <div className={cx(classes.header)}>
              <ShowMore maxHeight={70}>
                <div className={classes.headerTitle}>
                  <div className={classes.h2}>{t('Everything is ready to Merge')}</div>
                  <div>
                    {t(
                      'This is how your record will look like. Please, note that Inquiries list may not be completely displayed and will be updated after merging is done.'
                    )}
                  </div>
                </div>
                <StatusBlock
                  fields={statusFields(values.id && recordData ? recordData : duplicate)}
                  config={config}
                  data={values.id === 0 ? recordData || {} : duplicate}
                  className={classes.statusBlock}
                />
              </ShowMore>
            </div>
            <div className={cx(spClasses.tabBtnWrapper, classes.space)}>
              <button
                className={cx(spClasses.tabBtn, { [spClasses.selected]: activeTab === 'general' })}
                onClick={() => setActiveTab('general')}
              >
                {t('General')}
              </button>
              <button
                className={cx(spClasses.tabBtn, { [spClasses.selected]: activeTab === 'inquiry' })}
                onClick={() => setActiveTab('inquiry')}
              >
                {t('Inquiries')}
              </button>
            </div>

            {activeTab === 'general' && (
              <DataBlock
                config={mainConfig}
                data={{ ...mainRecord, ...newRecordFormData, ...extraFormFields }}
                viewConfig={viewConfig}
              />
            )}
            {activeTab === 'inquiry' && (
              <div className={classes.tableWrapper}>
                <InquiryPanel mainId={resultRecordId} duplicateId={isDuplicatePrimary ? id : duplicateId} />
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export const MergeRequester = (props: MergeProps) => (
  <TableProvider
    entityName="requester"
    dialog="merge"
    initialValues={{ sorting: [{ name: 'createdon', direction: 'desc' }] }}
    columns={visibleColumns}
    systemFields={false}
  >
    <MergeRequesterComponent {...props} />
  </TableProvider>
);
