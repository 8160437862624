import classes from './stepper.module.scss';
import cx from 'classnames';

export const Stepper = ({ steps, current = 0 }: { steps: Array<string | JSX.Element>; current?: number }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        {steps.map((title, index) => (
          <div
            key={`${index}-${current}`}
            className={cx(classes.step, {
              [classes.completed]: index <= current,
              [classes.current]: index === current,
            })}
          >
            <div className={classes.index}>{index + 1}</div>
            <div className={classes.title}>{title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
