import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { SelectionType, TRowProps } from '../../types';
import CheckBox from 'components/Table/CheckBox';
import { Radio } from 'components/Radio';
import rowClasses from './row.module.css';

const RadioComponent = ({ checked }: { checked?: boolean }) => (
  <div className={rowClasses.radioWrapper}>
    <Radio checked={checked} />
  </div>
);

const Row = <Data extends Record<string, any>>({
  data,
  columns,
  Cell,
  CellComponent,
  config,
  edit = false,
  RowComponent,
  classes,
  onSubmit,
  cellSizes,
  defaultValue,
  selectionProps,
  pinnedColumns,
  onRowDblClick,
  selectionType,
  context,
}: TRowProps<Data>) => {
  const submit = useCallback(
    (data: Record<string, any>) => {
      if (onSubmit) onSubmit(data);
    },
    [onSubmit]
  );

  const SelectionComponent = useMemo(
    () => (selectionType === SelectionType.CHECK_BOX ? CheckBox : RadioComponent),
    [selectionType]
  );

  if (edit) {
    return (
      <RowComponent
        selected={selectionProps?.selected}
        classes={classes}
        onDoubleClick={onRowDblClick}
        onClick={selectionProps?.toggle}
      >
        {selectionProps && <SelectionComponent checked={selectionProps.selected} />}
        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {columns
                .map((name) => config[name])
                .map(({ component = CellComponent, adapter, ...props }) => (
                  <Cell
                    width={cellSizes[props.name]}
                    data={data}
                    classes={classes}
                    key={props.name}
                    component={component}
                    edit={true}
                    defaultValue={defaultValue}
                    value={adapter(data, props.name, defaultValue)}
                    context={context}
                    {...props}
                  />
                ))}
            </form>
          )}
        />
      </RowComponent>
    );
  } else {
    return (
      <RowComponent
        selected={selectionProps?.selected}
        classes={classes}
        onDoubleClick={onRowDblClick}
        onClick={selectionProps?.toggle}
      >
        <div className={classes.rowSticky}>
          {selectionProps && <SelectionComponent checked={selectionProps.selected} />}
          {columns
            .filter((name) => pinnedColumns.includes(name))
            .map((name) => config[name])
            .map(({ component = CellComponent, adapter, ...props }) => (
              <Cell
                width={cellSizes[props.name]}
                data={data}
                classes={classes}
                key={props.name}
                component={component}
                defaultValue={defaultValue}
                value={adapter(data, props.name, defaultValue)}
                context={context}
                {...props}
              />
            ))}
        </div>
        {columns
          .filter((name) => !pinnedColumns.includes(name))
          .map((name) => config[name])
          .map(({ component = CellComponent, adapter, ...props }) => (
            <Cell
              width={cellSizes[props.name]}
              data={data}
              classes={classes}
              key={`${props.name}-${cellSizes[props.name]}`}
              component={component}
              defaultValue={defaultValue}
              value={adapter(data, props.name, defaultValue)}
              context={context}
              {...props}
            />
          ))}
      </RowComponent>
    );
  }
};

export { Row };
