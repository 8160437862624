import Bowser from 'bowser';
import { useEffect } from 'react';
import versions from 'config/browsers/browsers.json';
import { useNotifications } from 'providers/NotificationsProvider';
import { useTranslation } from 'react-i18next';
import { devLog } from 'lib/helpers';

export const BrowserChecker = () => {
  const { addWarning } = useNotifications();
  const { t } = useTranslation();

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const actualVersion = Number(versions[browser.getBrowserName(true) as keyof typeof versions]);
    const currentVersion = Number(browser.getBrowserVersion().split('.').shift());
    if (!actualVersion) {
      addWarning({
        title: t('Your browser is not supported by IMS Portal'),
        content: t(
          'For the best IMS Portal experience, please install one of the recommended browsers: Google Chrome, Safari or Firefox'
        ),
      });
    } else if (actualVersion > currentVersion) {
      addWarning({
        title: t('You browser version is outdated'),
        content: t('For the best IMS Portal experience, please update your browser to the latest version'),
      });
    } else {
      devLog(browser.getBrowserName(), currentVersion, ' / ', actualVersion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
