import { Button } from 'components/Button';
import { FC, SVGProps, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classes from './err.module.scss';
import { useMsal } from '@azure/msal-react';
import { msalConfig } from 'domain/authConfig';
import cx from 'classnames';
import { debounce, devLog } from 'lib/helpers';

export type TErrorPageProps = {
  title: React.ReactElement;
  description?: React.ReactElement;
  CustomIcon?: FC<SVGProps<SVGSVGElement>>;
  showGoHome?: boolean;
  showRefresh?: boolean;
  showLogout?: boolean;
  background?: string;
};

export const ErrorPage = ({
  title,
  description,
  CustomIcon,
  showGoHome = true,
  showRefresh = false,
  showLogout = false,
  background = undefined,
}: TErrorPageProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { instance } = useMsal();
  const [bg] = useState(background);

  const goHome = useCallback(() => {
    history.push('/');
    history.go(0);
  }, [history]);

  const refresh = useCallback(() => {
    history.go(0);
  }, [history]);

  const logout = useMemo(
    () =>
      debounce(
        () =>
          instance
            .logoutRedirect({ postLogoutRedirectUri: msalConfig.auth.redirectUri })
            .then(() => {
              devLog('Logout success or Popup closed');
            })
            .catch((e) => {
              devLog('Logout Failure', e);
            }),
        300
      ),
    [instance]
  );

  return (
    <div className={cx(classes.root, { [classes.background]: !bg })} style={{ backgroundColor: bg }}>
      {CustomIcon && (
        <div className={classes.imgWrapper}>
          <CustomIcon className={classes.errrImg} />
        </div>
      )}
      <div className={classes.errrText}>
        <div className={classes.title}>{title}</div>
        {description && <div className={classes.description}>{description}</div>}
      </div>
      <div className={classes.buttonsWrapper}>
        {showLogout && (
          <Button className={classes.goHomeButton} type="button" role={'primary'} onClick={logout}>
            {t('Sign Out')}
          </Button>
        )}
        {showGoHome && (
          <Button className={classes.goHomeButton} type="button" role={'primary'} onClick={goHome}>
            {t('Go To Home')}
          </Button>
        )}
        {showRefresh && (
          <Button className={classes.RefreshButton} type="button" role={'secondary'} onClick={refresh}>
            {t('Refresh')}
          </Button>
        )}
      </div>
      <div className={classes.contactEmail}>
        <Trans>
          If you think this is an error, contact our support team at{' '}
          <a href={`mailto:ims-help@bahai.us`}>ims-help@bahai.us</a>
        </Trans>
      </div>
    </div>
  );
};
