import { memo } from 'react';
import { TCellProps } from '../../types';
import cx from 'classnames';

const TABLE_CONTEXT = 'TABLE';

const Cell = <Data extends Record<string, any>>({
  component: Component,
  //field = 'input',
  //fieldRenderer: FieldRenderer,
  //edit = false,
  //editable = false,
  classes,
  width,
  value,
  sticky,
  ...props
}: TCellProps<Data>) => {
  // if (editable && edit) {
  //   if (FieldRenderer){
  //     return <FieldRenderer context={TABLE_CONTEXT} name={name} value={value} />;
  //   } else {
  //     return <Field context={TABLE_CONTEXT} component={field} name={name} value={value} />
  //   }
  // }

  if (Component) {
    return (
      <div style={{ width: `${width}px` }} className={cx(classes.cell, { [classes.sticky]: sticky })}>
        <Component classes={classes} {...props} />
      </div>
    );
  }

  return (
    <div style={{ width: `${width}px` }} className={cx(classes.cell, { [classes.sticky]: sticky })}>
      {value}
    </div>
  );
};

const MemoizedCell = memo(Cell, (prevProps, nextProps) => {
  if (nextProps.component) return prevProps.data === nextProps.data;
  return prevProps.value === nextProps.value;
}) as typeof Cell;

export { MemoizedCell as Cell };
