import { Switch } from 'components/Switch';
import { Button } from 'components/Button';
import { Dialog } from 'components/Dialog';
import { TEntityName } from 'lib';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMetaData } from 'lib/hooks';
import classes from './style.module.scss';
import cx from 'classnames';
import { ReactComponent as DocIcon } from './doc.svg';
import { ReactComponent as PdfIcon } from './pdf.svg';
import { ReactComponent as BrowserIcon } from './browser.svg';
import { Radio } from 'components/Radio';

export const RecordExport = ({
  onExport,
  onClose,
  loading,
  tabNames,
}: {
  onExport: (tabs: TEntityName[], format: string, inBrowser?: boolean) => void;
  loading: boolean;
  onClose: () => void;
  tabNames: TEntityName[];
}) => {
  const { config } = useMetaData();
  const [tabs, setTabs] = useState([] as TEntityName[]);
  const { t } = useTranslation();
  const [format, setFormat] = useState('pdf');
  const switchTab = useCallback((tab: TEntityName) => {
    setTabs((tabs) => (tabs.includes(tab) ? tabs.filter((v) => v !== tab) : tabs.concat(tab)));
  }, []);
  return (
    <Dialog loading={loading} onClose={onClose} isDraggingAllowed>
      <div className={classes.header}>{t('Export Details')}</div>
      <div className={classes.text}>
        {t('Please select the file format you wish to export')}
        <span style={{ color: 'var(--error)' }}>*</span>
      </div>
      <div className={classes.format}>
        <button onClick={() => setFormat('pdf')}>
          <Radio checked={format === 'pdf'} />
          <PdfIcon />
          {t('PDF File')}
        </button>
        <button onClick={() => setFormat('docx')}>
          <Radio checked={format === 'docx'} />
          <DocIcon />
          {t('DOC File')}
        </button>
      </div>
      {tabNames.length > 0 && (
        <div className={classes.text}>
          <Trans>
            Information on the <strong>General</strong> tab is included in this export, please select additional tabs to
            export. Additional tabs are optional.
          </Trans>
        </div>
      )}
      {tabNames.length > 0 && (
        <div className={cx(classes.switch, classes.all)}>
          <Switch
            value={tabs.length === tabNames.length}
            onChange={() => setTabs(tabs.length === tabNames.length ? [] : [...tabNames])}
          />
          {tabs.length === tabNames.length ? t('Unselect All') : t('Select All')}
        </div>
      )}
      {tabNames.map((tab) => (
        <div className={classes.switch} key={tab}>
          <Switch value={tabs.includes(tab)} onChange={() => switchTab(tab)} />
          {config[tab === 'invitedRequester' ? 'event' : tab].displayCollectionName}
        </div>
      ))}
      <div className={classes.controls}>
        <Button className={classes.webBtn} role={'flat'} onClick={() => onExport(tabs, 'pdf', true)}>
          <BrowserIcon />
          {t('Open PDF in Browser')}
        </Button>
        <Button role={'primary'} onClick={() => onExport(tabs, format)}>
          {t('Export')}
        </Button>
        <Button role={'secondary'} onClick={onClose}>
          {t('Cancel')}
        </Button>
      </div>
    </Dialog>
  );
};
