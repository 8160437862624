import { Trans } from 'react-i18next';
import axios from 'axios';
import { ServerErrorType } from 'providers/ErrorProvider';

const customValidationErrorCode = 1;

const parseCustomError = (error: any) => {
  const errorCode = error.response.data?.error?.['@Microsoft.PowerApps.CDS.ErrorDetails.SubErrorCode'];
  let message = error.response.data?.error?.message;

  if (Number.parseInt(errorCode) === customValidationErrorCode) {
    try {
      const errors = JSON.parse(message);
      message = errors.map((v: any) => <p>{v.Message}</p>);
    } catch {
      //Do nothing here
    }
    return <>{message}</>;
  }
  return <Trans>Something went wrong</Trans>;
};

export const parseError = (error: any): JSX.Element => {
  try {
    if (axios.isAxiosError(error) || error.response) {
      switch (error.response.data?.error?.code) {
        case '0x8004480d':
          return (
            <Trans>
              You cannot send email as the selected user. The selected user has not allowed this or you do not have
              sufficient privileges to do so. Contact your system administrator for assistance
            </Trans>
          );
        case '0x80048306':
          return (
            <Trans>
              You do not have access to change record(s). Please, contact your regional seeker response coordinator or
              Inquiry Services liaison.
            </Trans>
          );
        case '0x80060882':
          return <Trans>Record was changed. Please, refresh the page to see updates.</Trans>;
        case '0x80040220':
          return (
            <Trans>
              You do not have access to delete this record. Please, contact your regional seeker response coordinator or
              Inquiry Services liaison.
            </Trans>
          );
        default:
          return parseCustomError(error);
      }
    }
  } catch (e) {
    console.error(e);
  }
  return <Trans>Something went wrong</Trans>;
};

export const parseSaveFormError = (error: any, isEdit = false): JSX.Element => {
  if (error.response) {
    if (error.response.status === ServerErrorType.Errr403) {
      return isEdit ? (
        <Trans>You don't have access to Edit this record</Trans>
      ) : (
        <Trans>You don't have access to Create this record</Trans>
      );
    }

    switch (error.response.data?.error?.code) {
      case '0x80072562':
        return <Trans>Can't update current record. This is a Microsoft managed record.</Trans>;
      case '0x80048408':
        return <Trans>Action cannot be completed because of inactive status, please refresh the page.</Trans>;
      default:
        return parseCustomError(error);
    }
  }
  return <Trans>Something went wrong</Trans>;
};
