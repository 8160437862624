import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useApi } from 'domain/api';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { useCallback, useMemo, useState } from 'react';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import * as emailMetadata from 'config/EntityMetadata/email';
import { ReactComponent as MailIcon } from 'components/Actions/icons/mail.svg';
import { ReactComponent as ResendEmailIcon } from './icons/resendemail.svg';
import { ReactComponent as MailAllIcon } from 'components/Actions/icons/mail_all.svg';
import { config as emailConfig, getFormConfig, validate, validation } from 'schemas/email/index';
import { Loader } from 'components/Loader';
import { createPortal } from 'react-dom';
import { useSystemUserId, useTableConfig } from 'lib/helpers';
import { removePageFromQuery, useMetaData } from 'lib/hooks';
import { FetchQuery, Privilege, TEntityName } from 'lib/types/api';
import { NotificationPopup } from 'components/NotificationPopup';
import { parseError } from 'lib/errorParser';
import { Form } from 'components/ListPage/components/Form';
import { useEmail } from 'schemas/email/hooks';
import { useLoader } from 'providers/LoaderProvider';
import { useSecurity } from 'providers/AuthProvider';

const isFormInvalid = (data: Record<string, any>) =>
  !!validate(data)._general || Object.entries(validation).some(([key, fn]) => fn(data[key], data));

const useTryToSendEmail = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();
  const api = useApi();
  const { addNotification, addActionFailed } = useNotifications();
  const { url } = useMetaData('email');

  const tryToSendEmail = useCallback(
    async ({ selectedItems: [data], reload }: { selectedItems: Record<string, any>[]; reload: () => void }) => {
      if (isFormInvalid(data)) {
        document.getElementById('action_edit')?.click();
        setTimeout(() => {
          document.querySelectorAll('button[type=submit]').forEach((btn) => {
            if (btn.innerHTML === t('Send')) {
              (btn as HTMLButtonElement).click();
            }
          });
        }, 300);
      } else {
        setLoading(true);
        if (data.statuscode === emailMetadata.statuscode.Failed) {
          await api.request<Record<string, any>>({
            url: `${url}(${id})`,
            data: {
              statuscode: emailMetadata.statuscode.Draft,
            },
            method: 'patch',
          });
        }
        api
          .sendEmail(id)
          .then(() => {
            addNotification({
              type: NotificationType.SUCCESS,
              title: t('Email was sent'),
            });
            reload();
          })
          .catch((e) => {
            addActionFailed(e?.response?.data?.error?.message || <Trans>Something went wrong</Trans>);
          })
          .finally(() => setLoading(false));
      }
    },
    [addActionFailed, addNotification, api, id, t, url]
  );

  return { tryToSendEmail, loading };
};

export const useSendDraftEmail = () => {
  const { t } = useTranslation();

  const { tryToSendEmail, loading } = useTryToSendEmail();

  const sendAction: Action = useMemo(
    () => ({
      title: t('Send Email'),
      name: 'sendEmail',
      onClick: tryToSendEmail,
      display: ({ data }) => Number(data.statuscode) === emailMetadata.statuscode.Draft,
      Icon: MailIcon,
      type: ActionType.CUSTOM_ACTION,
      actionType: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SinglePage,
      allowedDevices: AllowedDevices.All,
    }),
    [t, tryToSendEmail]
  );

  const content = useMemo(
    () => (loading ? createPortal(<Loader fullScreenOverlay />, document.body) : null),
    [loading]
  );

  return { action: sendAction, content };
};

const recipientConfig = {
  requester: 'bahai_inquirer',
  resource: 'systemuser',
  contact: 'bahai_contact_ims',
  member: 'contact',
};

type RecipientType = keyof typeof recipientConfig;
export type WarningMessage = { label: string; content: string | JSX.Element };
export type SendEmailValidateFunction = (data: Record<string, any>) => WarningMessage | undefined;

export type EmailRecipient = Partial<WarningMessage> & {
  id: string;
  type?: 'requester' | 'resource' | 'contact';
  email?: string;
};

export const EmailForm = ({ onClose, initialValues }: { onClose: () => void; initialValues: Record<string, any> }) => {
  const { getActionControls, onSubmit } = useEmail();
  const { entityConfig: emailMetaData } = useMetaData('email');
  const config = useTableConfig(emailMetaData.fields, emailConfig, 'email');
  return (
    <Form
      entityName={'email'}
      onClose={onClose}
      postAction={onClose}
      config={config}
      initialValues={initialValues}
      getFormConfig={getFormConfig}
      validate={validate}
      validation={validation}
      getActionControls={getActionControls}
      onSubmit={onSubmit}
    />
  );
};

export const useFilterValidEmailRecipients = () => {
  const { addWarning } = useNotifications();
  const { t } = useTranslation();

  return useCallback(
    (
      records: Record<string, any>[],
      validateRecipients: SendEmailValidateFunction,
      recipientMapper: (data: Record<string, any>) => EmailRecipient,
      displayCollectionName: string
    ) => {
      const { validRecipients, errors } = records.reduce<{
        errors: {
          label: string;
          content: JSX.Element | string;
        }[];
        validRecipients: EmailRecipient[];
      }>(
        (acc, item) => {
          if (!validateRecipients(item)) {
            return { ...acc, validRecipients: acc.validRecipients.concat(recipientMapper(item)) };
          } else {
            return {
              ...acc,
              errors: acc.errors.concat(
                validateRecipients(item) as {
                  label: string;
                  content: JSX.Element | string;
                }
              ),
            };
          }
        },
        { errors: [], validRecipients: [] }
      );

      const uniqueErrors = errors.filter((v1, index) => index === errors.findIndex((v2) => v2.label === v1.label));

      if (uniqueErrors.length) {
        addWarning({
          title:
            validRecipients.length === 0
              ? t('{{ displayCollectionName }} can’t be added as Email recipients', { displayCollectionName })
              : t('Some {{ displayCollectionName }} can’t be added as Email recipients', { displayCollectionName }),
          content: (
            <>
              <Trans>Please see details below.</Trans>
              <br />
              <NotificationPopup
                label={t('Show Details')}
                header={t('Info')}
                description={t('The {{displayCollectionName}} listed below were not added as Email recipients', {
                  displayCollectionName,
                })}
                errors={uniqueErrors}
              />
            </>
          ),
        });
      }
      return validRecipients.filter((v1, index) => {
        return (
          index ===
          validRecipients.findIndex((v2) => {
            return v2.id === v1.id;
          })
        );
      });
    },
    [addWarning, t]
  );
};

export const useSendEmailFromList = (
  recipientType: RecipientType = 'requester',
  validateRecipients: SendEmailValidateFunction,
  tableName: TEntityName,
  recipientMapper: (data: Record<string, any>) => EmailRecipient,
  regarding?: string,
  sendToSelectedTitle?: string,
  sendToAllTitle?: string
) => {
  const { t } = useTranslation();
  const { addActionFailed } = useNotifications();
  const filterRecipients = useFilterValidEmailRecipients();
  const userId = useSystemUserId();
  const { url: recipientsListUrl } = useMetaData(tableName);
  const { displayCollectionName } = useMetaData(recipientType);

  const { request } = useApi();

  const { content, setInitialValues } = useSendEmail();
  const { showLoader, hideLoader } = useLoader();

  const sendEmail = useCallback(
    async (dataOrQuery: Record<string, any>[] | FetchQuery) => {
      try {
        showLoader();

        const getRecords = () =>
          new Promise<Record<string, any>[]>((resolve) => {
            if (Array.isArray(dataOrQuery)) {
              resolve(dataOrQuery);
            } else {
              request<{ value: Record<string, any>[] }>({
                url: recipientsListUrl,
                query: removePageFromQuery(dataOrQuery),
              }).then((resp) => resolve(resp.data.value));
            }
          });

        const recipients = filterRecipients(
          await getRecords(),
          validateRecipients,
          recipientMapper,
          displayCollectionName
        );

        const formatedRecipients = recipients.map(
          ({ id, email }) =>
            `${email ? 'undefined' : recipientConfig[recipientType]}<|>${
              email ? 'undefined' : id
            }<|>undefined<|>${email}`
        );
        if (recipients.length)
          setInitialValues({
            directioncode: true,
            from: 'systemuser<|>' + userId,
            bcc: Array.from(new Set(formatedRecipients)),
            regardingobjectid: regarding,
          });
      } catch (e) {
        addActionFailed(parseError(e));
      } finally {
        hideLoader();
      }
    },
    [
      showLoader,
      filterRecipients,
      validateRecipients,
      recipientMapper,
      displayCollectionName,
      setInitialValues,
      userId,
      regarding,
      request,
      recipientsListUrl,
      recipientType,
      addActionFailed,
      hideLoader,
    ]
  );

  const { isGranted } = useSecurity();

  const actions = useMemo(
    () => [
      {
        title: sendToAllTitle ?? t('Email {{ displayCollectionName }}', { displayCollectionName }),
        name: 'sendToAll',
        onClick: ({ query }) => sendEmail(query),
        Icon: MailAllIcon,
        order: -1,
        type: ActionType.CUSTOM_ACTION,
        actionContext: ActionContext.ListPageAndSubGid,
        allowedDevices: AllowedDevices.All,
        display: ({ selectedItemsCount, data }) =>
          selectedItemsCount === 0 && data.length > 0 && isGranted('email', Privilege.Create),
      } as Action,
      {
        title: sendToSelectedTitle ?? t('Email Selected {{ displayCollectionName }}', { displayCollectionName }),
        name: 'sendToSelected',
        onClick: ({ selectedItems }) => sendEmail(selectedItems),
        Icon: MailIcon,
        order: -1,
        type: ActionType.CUSTOM_ACTION,
        actionContext: ActionContext.ListPageAndSubGid,
        onSelectionOnly: true,
        allowedDevices: AllowedDevices.All,
        display: ({ selectedItemsCount }) => !!selectedItemsCount && isGranted('email', Privilege.Create),
      } as Action,
    ],
    [t, displayCollectionName, sendToAllTitle, sendToSelectedTitle, sendEmail, isGranted]
  );

  return { actions, content };
};

export const useSendEmail = () => {
  const [initialValues, setInitialValues] = useState<Record<string, any> | null>(null);
  const onClose = useCallback(() => setInitialValues(null), []);

  const content = useMemo(
    () => (initialValues ? <EmailForm {...{ onClose, initialValues }} /> : null),
    [initialValues, onClose]
  );
  return { content, setInitialValues };
};

export const useReSendEmail = () => {
  const { t } = useTranslation();

  const { tryToSendEmail, loading } = useTryToSendEmail();

  const resendAction: Action = useMemo(
    () => ({
      title: t('Resend Email'),
      name: 'reSendEmail',
      onClick: tryToSendEmail,
      display: ({ data }) => Number(data.statuscode) === emailMetadata.statuscode.Failed,
      Icon: ResendEmailIcon,
      type: ActionType.CUSTOM_ACTION,
      actionType: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SinglePage,
      allowedDevices: AllowedDevices.All,
    }),
    [t, tryToSendEmail]
  );

  const content = useMemo(
    () => (loading ? createPortal(<Loader fullScreenOverlay />, document.body) : null),
    [loading]
  );

  return { action: resendAction, content };
};
