import entities, { Fields } from 'config';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { FieldValidator } from 'final-form';
import { createFormAutocomplete } from 'components/Form/Autocomplete';
import { createEmailFormAutocomplete } from 'components/Form/EmailAutocomplete';
import * as rules from 'lib/rules';
import { AutocompleteType } from 'components/AutoComplete/hooks';
import { HTMLEmailComponent, PartyListComponent, PolymorphicComponent, ToolTipEmailComponent } from 'lib/components';
import { TemplateTextEditor } from 'components/Form/TemplateTextEditor';
import * as emailMetadata from 'config/EntityMetadata/email';
import { Trans } from 'react-i18next';
import { ParticipationTypeMask } from 'config/EntityMetadata/email';
import { Attachments } from 'components/Form/Attachments';
import { bahai_inquirerstatus, statecode } from 'config/EntityMetadata/bahai_inquirer';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';

export type Keys = Fields<(typeof entities.email.columns)[number]>;
export const columns = entities.email.columns;

export const defaultMobileColumns: Keys[] = ['bahai_to', 'createdon', 'statuscode'];

export const isActive = (data: Record<string, any>) => data.statecode === emailMetadata.statecode.Open;

export const isNotEditable = (data: Record<string, any>) =>
  !isActive(data) && <Trans>Email Status should be Draft or Failed</Trans>;

export const isNotRemovable = (data: Record<string, any>) =>
  data.statecode === emailMetadata.statecode.Completed && (
    <Trans>Email Status must be Draft, Canceled or Failed to delete.</Trans>
  );

export const config: AdditionalConfigType<Keys | 'activityid'> = {
  bahai_id: {
    component: ({ data }) => <HistoryLink to={r.email({ id: data.activityid || 0 })}>{data.bahai_id}</HistoryLink>,
  },
  from: {
    isRequired: true,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'resource',
          links: {
            businessunit: {
              from: 'businessunitid',
              to: 'businessunitid',
              fields: [],
            },
          },
          filters: [
            {
              logicOperator: 'and',
              condition: [
                { attribute: 'isdisabled', operator: 'eq', value: '0' },
                { attribute: 'businessunit.parentbusinessunitid', operator: 'not-null' },
              ],
            },
          ],
        },
      ],

      type: AutocompleteType.PartyList,
    }),
    component: PartyListComponent({ targetEntityName: 'email', participationTypeMask: ParticipationTypeMask.from }),
    hiddenForTable: true,
  },
  to: {
    field: createEmailFormAutocomplete({
      entities: [
        {
          entityName: 'resource',
          links: {
            businessunit: {
              from: 'businessunitid',
              to: 'businessunitid',
              fields: [],
            },
          },
          filters: [
            {
              logicOperator: 'and',
              condition: [
                { attribute: 'isdisabled', operator: 'eq', value: '0' },
                { attribute: 'businessunit.parentbusinessunitid', operator: 'not-null' },
              ],
            },
          ],
        },
        {
          entityName: 'contact',
          filters: [
            {
              logicOperator: 'or',
              condition: [
                { attribute: 'bahai_emailaddress1', operator: 'not-null' },
                { attribute: 'bahai_emailaddress2', operator: 'not-null' },
              ],
            },
          ],
        },
        {
          entityName: 'member',
          filters: [
            {
              logicOperator: 'or',
              condition: [
                { attribute: 'emailaddress1', operator: 'not-null' },
                { attribute: 'emailaddress2', operator: 'not-null' },
              ],
            },
          ],
        },
        {
          entityName: 'requester',
          filters: [
            {
              logicOperator: 'or',
              condition: [
                { attribute: 'bahai_emailaddress1', operator: 'not-null' },
                { attribute: 'bahai_emailaddress2', operator: 'not-null' },
              ],
            },
            {
              logicOperator: 'and',
              condition: [
                { attribute: 'statecode', operator: 'eq', value: String(statecode.Active) },
                {
                  attribute: 'bahai_statuscode',
                  operator: 'ne',
                  value: String(bahai_inquirerstatus.DoNotContact),
                },
              ],
            },
          ],
        },
      ],
      isMultiple: true,
      type: AutocompleteType.PartyList,
    }),
    fieldProps: ({ classes }) => ({ className: classes.long }),
    component: PartyListComponent({ targetEntityName: 'email', participationTypeMask: ParticipationTypeMask.to }),
    hiddenForTable: true,
  },
  cc: {
    field: createEmailFormAutocomplete({
      entities: [
        {
          entityName: 'resource',
          links: {
            businessunit: {
              from: 'businessunitid',
              to: 'businessunitid',
              fields: [],
            },
          },
          filters: [
            {
              logicOperator: 'and',
              condition: [
                { attribute: 'isdisabled', operator: 'eq', value: '0' },
                { attribute: 'businessunit.parentbusinessunitid', operator: 'not-null' },
              ],
            },
          ],
        },
        {
          entityName: 'contact',
          filters: [
            {
              logicOperator: 'or',
              condition: [
                { attribute: 'bahai_emailaddress1', operator: 'not-null' },
                { attribute: 'bahai_emailaddress2', operator: 'not-null' },
              ],
            },
          ],
        },
        {
          entityName: 'member',
          filters: [
            {
              logicOperator: 'or',
              condition: [
                { attribute: 'emailaddress1', operator: 'not-null' },
                { attribute: 'emailaddress2', operator: 'not-null' },
              ],
            },
          ],
        },
        {
          entityName: 'requester',
          filters: [
            {
              logicOperator: 'or',
              condition: [
                { attribute: 'bahai_emailaddress1', operator: 'not-null' },
                { attribute: 'bahai_emailaddress2', operator: 'not-null' },
              ],
            },
            {
              logicOperator: 'and',
              condition: [
                { attribute: 'statecode', operator: 'eq', value: String(statecode.Active) },
                {
                  attribute: 'bahai_statuscode',
                  operator: 'ne',
                  value: String(bahai_inquirerstatus.DoNotContact),
                },
              ],
            },
          ],
        },
      ],
      isMultiple: true,
      type: AutocompleteType.PartyList,
    }),
    fieldProps: ({ classes }) => ({ className: classes.long }),
    component: PartyListComponent({ targetEntityName: 'email', participationTypeMask: ParticipationTypeMask.cc }),
    hiddenForTable: true,
  },
  bcc: {
    field: createEmailFormAutocomplete({
      entities: [
        {
          entityName: 'resource',
          links: {
            businessunit: {
              from: 'businessunitid',
              to: 'businessunitid',
              fields: [],
            },
          },
          filters: [
            {
              logicOperator: 'and',
              condition: [
                { attribute: 'isdisabled', operator: 'eq', value: '0' },
                { attribute: 'businessunit.parentbusinessunitid', operator: 'not-null' },
              ],
            },
          ],
        },
        {
          entityName: 'contact',
          filters: [
            {
              logicOperator: 'or',
              condition: [
                { attribute: 'bahai_emailaddress1', operator: 'not-null' },
                { attribute: 'bahai_emailaddress2', operator: 'not-null' },
              ],
            },
          ],
        },
        {
          entityName: 'member',
          filters: [
            {
              logicOperator: 'or',
              condition: [
                { attribute: 'emailaddress1', operator: 'not-null' },
                { attribute: 'emailaddress2', operator: 'not-null' },
              ],
            },
          ],
        },
        {
          entityName: 'requester',
          filters: [
            {
              logicOperator: 'or',
              condition: [
                { attribute: 'bahai_emailaddress1', operator: 'not-null' },
                { attribute: 'bahai_emailaddress2', operator: 'not-null' },
              ],
            },
            {
              logicOperator: 'and',
              condition: [
                { attribute: 'statecode', operator: 'eq', value: String(statecode.Active) },
                {
                  attribute: 'bahai_statuscode',
                  operator: 'ne',
                  value: String(bahai_inquirerstatus.DoNotContact),
                },
              ],
            },
          ],
        },
      ],
      isMultiple: true,
      type: AutocompleteType.PartyList,
    }),
    fieldProps: ({ classes }) => ({ className: classes.long }),
    component: PartyListComponent({ targetEntityName: 'email', participationTypeMask: ParticipationTypeMask.bcc }),
    hiddenForTable: true,
  },
  regardingobjectid: {
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'event',
        },
        {
          entityName: 'inquiry',
        },
        {
          entityName: 'group',
        },
      ],

      type: AutocompleteType.Polymorphic,
    }),
    fieldProps: ({ classes }) => ({ className: classes.long }),
    component: PolymorphicComponent,
  },
  subject: {
    isRequired: true,
    searchable: true,
    fieldProps: ({ classes }) => ({
      className: classes.long,
    }),
  },
  bahai_rawdescription: {
    component: ToolTipEmailComponent('description'),
  },
  description: {
    isRequired: true,
    hiddenForTable: true,
    field: TemplateTextEditor,
    component: HTMLEmailComponent('bahai_rawdescription', 300),
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 5000,
    }),
  },
  bahai_from: {
    searchable: true,
    component: PartyListComponent({ targetEntityName: 'email', participationTypeMask: ParticipationTypeMask.from }),
    filterAs: 'from',
    sortable: false,
  },
  bahai_to: {
    searchable: true,
    component: PartyListComponent({ targetEntityName: 'email', participationTypeMask: ParticipationTypeMask.to }),
    filterAs: 'to',
    sortable: false,
  },
  bahai_cc: {
    searchable: true,
    component: PartyListComponent({ targetEntityName: 'email', participationTypeMask: ParticipationTypeMask.cc }),
    filterAs: 'cc',
    sortable: false,
  },
  bahai_bcc: {
    searchable: true,
    component: PartyListComponent({ targetEntityName: 'email', participationTypeMask: ParticipationTypeMask.bcc }),
    filterAs: 'bcc',
    sortable: false,
  },
  statecode: {
    hiddenForTable: true,
  },
  bahai_appointmentid: {
    field: Attachments,
    hiddenForTable: true,
    fieldProps: ({ classes }) => ({ className: classes.long }),
    component: ({ data, name }) => (
      <Attachments
        readonly={true}
        input={{
          value: (data as Record<string, any>)._bahai_appointmentid_value || (data as Record<string, any>).activityid,
          name,
          onBlur: () => null,
          onFocus: () => null,
          onChange: () => null,
        }}
        meta={{}}
      />
    ),
  },
};

export const getDetailsConfig: FormConfigGetter<Keys> = () => [
  [<Trans>Email Settings</Trans>, ['from', 'to', 'cc', 'bcc', 'regardingobjectid', 'createdon', 'createdby'] as Keys[]],
  ['', ['description'] as Keys[]],
  ['', ['bahai_appointmentid'] as Keys[]],
];

export const getFormConfig: FormConfigGetter<Keys> = () => [
  [
    <Trans>Email Settings</Trans>,
    ['from', 'to', 'cc', 'bcc', 'subject', 'description', 'bahai_appointmentid', 'regardingobjectid'] as Keys[],
  ],
];

export const statusFields = () => ['directioncode', 'statuscode'];

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  from: rules.required,
  description: rules.compose([rules.required, rules.maxLengthEscapeTags(5000)]),
  subject: rules.compose([rules.required, rules.maxLength(256)]),
};

export const validate = (values: Record<string, any>) => ({
  _general: ['to', 'cc', 'bcc'].some((key) => values[key] && values[key].length > 0)
    ? undefined
    : [<Trans>To, Cc or Bcc is required</Trans>],
});
