import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from 'domain/api';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { ReactComponent as FinishIcon } from './icons/complete.svg';
import { Modal } from 'components/Modal';
import { useNotifications } from 'providers/NotificationsProvider';
import { StatusCode } from 'config/EntityMetadata/bahai_coverage';

export const useFinishCoverage = (id: string) => {
  const { finishCoverage } = useApi();
  const { t } = useTranslation();
  const { addActionFailed, addActionCompleted } = useNotifications();

  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const close = useCallback(() => {
    setLoading(false);
    setDisplay(false);
  }, []);

  const reloadRef = useRef<() => void>(() => undefined);
  const finish = useCallback(() => {
    setLoading(true);
    finishCoverage(id)
      .then(() => {
        addActionCompleted(t('Coverage was finished'));
        if (reloadRef.current) reloadRef.current();
      })
      .catch((e) => {
        addActionFailed(e?.response?.data?.error?.message || t('Something went wrong'));
      })
      .finally(close);
  }, [addActionCompleted, addActionFailed, close, finishCoverage, id, t]);

  const content = useMemo(
    () =>
      display ? (
        <Modal
          title={t('Finish Coverage')}
          portal={false}
          onClose={close}
          loading={loading}
          fixedSize={true}
          header={t('Would you like to Finish this Coverage?')}
          controls={[
            { title: t('Finish'), type: 'submit', role: 'primary', onClick: finish },
            { title: t('Cancel'), onClick: close },
          ]}
        />
      ) : null,
    [close, display, finish, loading, t]
  );

  const action: Action = useMemo(
    () => ({
      title: t('Finish'),
      name: 'finish',
      display: ({ data, isEditAllowed }) => Number(data.statuscode) === StatusCode.InProgress && isEditAllowed,
      onClick: ({ reload }) => {
        reloadRef.current = reload;
        setDisplay(true);
      },
      Icon: FinishIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SinglePage,
      allowedDevices: AllowedDevices.All,
    }),
    [t]
  );

  return { action, content };
};
