import { TLinkEntity } from 'components/ListPage';
import { useApi } from 'domain/api';
import { ApiFilter } from 'lib';
import { useState, useContext, useMemo, useCallback, useEffect } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { bahai_type } from 'config/EntityMetadata/businessunit';
import { USER_ROLES, TEAMS } from 'lib/const';

export const useResourceFilters = () => {
  const { request } = useApi();
  const { roles = [], teams = [], systemuserid } = useContext(AuthContext);

  const [filtersLoading, setFiltersLoading] = useState(true);
  const [resourceFilters, setResourceFilters] = useState<Array<ApiFilter>>();

  const resourceLinks: TLinkEntity = useMemo(
    () => ({
      userroles: {
        from: 'systemuserid',
        to: 'systemuserid',
        fields: [],
        links: {
          role: {
            from: 'roleid',
            to: 'roleid',
            fields: [],
          },
        },
      },

      teammembership: {
        from: 'systemuserid',
        to: 'systemuserid',
        fields: [],
        links: {
          team: {
            from: 'teamid',
            to: 'teamid',
            fields: [],
          },
        },
      },
      businessunit: {
        from: 'businessunitid',
        to: 'businessunitid',
        fields: [],
      },
    }),
    []
  );

  const getParentBusinessUnints = useCallback(
    async (userId: string, businessUnitTypes: number[]) => {
      const {
        data: { _businessunitid_value: businessUnitId },
      } = await request<{ _businessunitid_value: string }>({
        url: `systemusers(${userId})?$select=_businessunitid_value`,
      });

      const query = `<fetch version="1.0" output-format="xml-platform" mapping="logical" no-lock="true" >
  <entity name="businessunit" >
    <attribute name="businessunitid" />
    <attribute name="bahai_type" />
    <filter type="or">
      <condition attribute="businessunitid" operator="above" value="${businessUnitId}" />
      <condition attribute="businessunitid" operator="eq" value="${businessUnitId}" />
    </filter>
  </entity>
</fetch>`;

      const {
        data: { value: businessUnits },
      } = await request<{ value: [{ businessunitid: string; bahai_type: number }] }>({
        url: `businessunits`,
        params: {
          fetchXml: encodeURIComponent(query),
        },
      });

      const businessUnitIds = new Array<string | undefined>();

      businessUnitTypes.forEach((type) => {
        const businessUnit = businessUnits.find((bu) => bu.bahai_type === type);
        businessUnitIds.push(businessUnit?.businessunitid);
      });

      return businessUnitIds;
    },
    [request]
  );

  const getResourceFilters = useCallback(async () => {
    const filters: Array<ApiFilter> = [
      {
        condition: [{ attribute: 'systemuserid', operator: 'ne', value: systemuserid }],
      },
    ];

    if (roles.includes(USER_ROLES.RegionalIMSCoordinator)) {
      filters.push({
        logicOperator: 'or',
        condition: [
          { attribute: 'role.name', operator: 'eq', value: USER_ROLES.RegionalIMSCoordinator },
          { attribute: 'team.name', operator: 'eq', value: TEAMS.IQS },
        ],
      });
    } else if (roles.includes(USER_ROLES.SubRegionalIMSCoordinator)) {
      const [regionId] = await getParentBusinessUnints(systemuserid, [bahai_type.Region]);

      filters.push({
        logicOperator: 'or',
        filter: [
          {
            logicOperator: 'and',
            condition: [{ attribute: 'businessunitid', operator: 'eq', value: regionId }],
            filter: [
              {
                logicOperator: 'or',
                condition: [
                  { attribute: 'role.name', operator: 'eq', value: USER_ROLES.SubRegionalIMSCoordinator },
                  { attribute: 'role.name', operator: 'eq', value: USER_ROLES.RegionalIMSCoordinator },
                ],
              },
            ],
          },
        ],
        condition: [{ attribute: 'team.name', operator: 'eq', value: TEAMS.IQS }],
      });
    } else if (roles.includes(USER_ROLES.ClusterGroupIMSCoordinator)) {
      const [subRegionId, regionId] = await getParentBusinessUnints(systemuserid, [
        bahai_type.SubRegion,
        bahai_type.Region,
      ]);

      filters.push({
        logicOperator: 'or',
        filter: [
          ...(subRegionId !== undefined
            ? [
                {
                  logicOperator: 'and',
                  condition: [
                    { attribute: 'role.name', operator: 'eq', value: USER_ROLES.SubRegionalIMSCoordinator },
                    { attribute: 'businessunitid', operator: 'eq', value: subRegionId },
                  ],
                } as ApiFilter,
              ]
            : []),
          {
            logicOperator: 'and',
            condition: [{ attribute: 'businessunitid', operator: 'eq', value: regionId }],
            filter: [
              {
                logicOperator: 'or',
                condition: [
                  { attribute: 'role.name', operator: 'eq', value: USER_ROLES.SubRegionalIMSCoordinator },
                  { attribute: 'role.name', operator: 'eq', value: USER_ROLES.RegionalIMSCoordinator },
                ],
              },
            ],
          },
        ],
        condition: [{ attribute: 'team.name', operator: 'eq', value: TEAMS.IQS }],
      });
    } else if (roles.includes(USER_ROLES.ClusterIMSCoordinator)) {
      const [clusterGroupId, subRegionId, regionId] = await getParentBusinessUnints(systemuserid, [
        bahai_type.ClusterGroup,
        bahai_type.SubRegion,
        bahai_type.Region,
      ]);

      filters.push({
        logicOperator: 'or',
        filter: [
          ...(clusterGroupId !== undefined
            ? [
                {
                  logicOperator: 'and',
                  condition: [
                    { attribute: 'role.name', operator: 'eq', value: USER_ROLES.ClusterGroupIMSCoordinator },
                    { attribute: 'businessunitid', operator: 'eq', value: clusterGroupId },
                  ],
                } as ApiFilter,
              ]
            : []),
          ...(subRegionId !== undefined
            ? [
                {
                  logicOperator: 'and',
                  condition: [
                    { attribute: 'role.name', operator: 'eq', value: USER_ROLES.SubRegionalIMSCoordinator },
                    { attribute: 'businessunitid', operator: 'eq', value: subRegionId },
                  ],
                } as ApiFilter,
              ]
            : []),
          {
            logicOperator: 'and',
            condition: [{ attribute: 'businessunitid', operator: 'eq', value: regionId }],
            filter: [
              {
                logicOperator: 'or',
                condition: [
                  { attribute: 'role.name', operator: 'eq', value: USER_ROLES.SubRegionalIMSCoordinator },
                  { attribute: 'role.name', operator: 'eq', value: USER_ROLES.RegionalIMSCoordinator },
                ],
              },
            ],
          },
        ],
        condition: [{ attribute: 'team.name', operator: 'eq', value: TEAMS.IQS }],
      });
    } else if (roles.includes(USER_ROLES.LocalityIMSCoordinator)) {
      const [clusterId, clusterGroupId, subRegionId, regionId] = await getParentBusinessUnints(systemuserid, [
        bahai_type.Cluster,
        bahai_type.ClusterGroup,
        bahai_type.SubRegion,
        bahai_type.Region,
      ]);

      filters.push({
        logicOperator: 'or',
        filter: [
          ...(clusterId !== undefined
            ? [
                {
                  logicOperator: 'and',
                  condition: [
                    { attribute: 'role.name', operator: 'eq', value: USER_ROLES.ClusterIMSCoordinator },
                    { attribute: 'businessunitid', operator: 'eq', value: clusterId },
                  ],
                } as ApiFilter,
              ]
            : []),
          ...(clusterGroupId !== undefined
            ? [
                {
                  logicOperator: 'and',
                  condition: [
                    { attribute: 'role.name', operator: 'eq', value: USER_ROLES.ClusterGroupIMSCoordinator },
                    { attribute: 'businessunitid', operator: 'eq', value: clusterGroupId },
                  ],
                } as ApiFilter,
              ]
            : []),
          ...(subRegionId !== undefined
            ? [
                {
                  logicOperator: 'and',
                  condition: [
                    { attribute: 'role.name', operator: 'eq', value: USER_ROLES.SubRegionalIMSCoordinator },
                    { attribute: 'businessunitid', operator: 'eq', value: subRegionId },
                  ],
                } as ApiFilter,
              ]
            : []),
          {
            logicOperator: 'and',
            condition: [{ attribute: 'businessunitid', operator: 'eq', value: regionId }],
            filter: [
              {
                logicOperator: 'or',
                condition: [
                  { attribute: 'role.name', operator: 'eq', value: USER_ROLES.SubRegionalIMSCoordinator },
                  { attribute: 'role.name', operator: 'eq', value: USER_ROLES.RegionalIMSCoordinator },
                ],
              },
            ],
          },
        ],
        condition: [{ attribute: 'team.name', operator: 'eq', value: TEAMS.IQS }],
      });
    } else if (
      teams.includes(TEAMS.NSASecretariat) ||
      teams.includes(TEAMS.CommunityAdministration) ||
      teams.includes(TEAMS.Membership) ||
      teams.includes(TEAMS.AssemblyDevelopment)
    ) {
      filters.push({
        condition: [{ attribute: 'role.name', operator: 'eq', value: USER_ROLES.RegionalIMSCoordinator }],
      });
    } else if (teams.includes(TEAMS.IQS)) {
      filters.push({
        logicOperator: 'or',
        condition: [
          { attribute: 'role.name', operator: 'eq', value: USER_ROLES.ITAdministrator },
          { attribute: 'role.name', operator: 'eq', value: USER_ROLES.SupportAdministrator },
          { attribute: 'role.name', operator: 'eq', value: USER_ROLES.InquiryServiceAdministrator },
          { attribute: 'role.name', operator: 'eq', value: USER_ROLES.RegionalIMSCoordinator },
          { attribute: 'team.name', operator: 'eq', value: TEAMS.NSASecretariat },
          { attribute: 'team.name', operator: 'eq', value: TEAMS.CommunityAdministration },
          { attribute: 'team.name', operator: 'eq', value: TEAMS.Membership },
          { attribute: 'team.name', operator: 'eq', value: TEAMS.IQS },
          { attribute: 'team.name', operator: 'eq', value: TEAMS.AssemblyDevelopment },
        ],
      });
    }

    filters.push({
      logicOperator: 'and',
      condition: [
        { attribute: 'isdisabled', operator: 'eq', value: '0' },
        { attribute: 'businessunit.parentbusinessunitid', operator: 'not-null' },
        { attribute: 'businessunit.name', operator: 'ne', value: 'Fund Development' },
      ],
    });

    return filters;
  }, [getParentBusinessUnints, roles, teams, systemuserid]);

  useEffect(() => {
    getResourceFilters().then((filters) => {
      setResourceFilters(filters);
      setFiltersLoading(false);
    });
  }, [getResourceFilters]);

  return { resourceLinks, resourceFilters, filtersLoading };
};
