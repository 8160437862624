import ListPage, { TListPage } from 'components/ListPage';
import * as requesterBaseConfig from 'schemas/requester';
import * as resourceBaseConfig from 'schemas/resource';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { ReactComponent as AddIcon } from './icons/add.svg';
import { Dialog } from 'components/Dialog';
import classes from './invite.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckedIcon } from './icons/checked.svg';
import { ReactComponent as UncheckedIcon } from './icons/unchecked.svg';
import * as inquirerMetadata from 'config/EntityMetadata/bahai_inquirer';

import { Button } from 'components/Button';
import { useAddParticipantToEvent } from 'domain/operations';
import { removePageFromQuery, useMetaData } from 'lib/hooks';
import * as eventMetadata from 'config/EntityMetadata/bahai_event_ims';
import { useNotifications } from 'providers/NotificationsProvider';
import { FetchQuery, TEntityName } from 'lib';
import { ScreenContext } from 'providers/ScreenProvider';

const configs = {
  requester: requesterBaseConfig,
  resource: resourceBaseConfig,
};

export const useInviteParticipant = (id: string, participantType: 'requester' | 'resource') => {
  const { t } = useTranslation();
  const [closeOnFinish, setCloseOnFinish] = useState(false);
  const toggleCloseOnFinish = useCallback(() => setCloseOnFinish((v) => !v), []);

  const { isMobile } = useContext(ScreenContext);

  const getActions = useCallback(
    (baseActions: Array<Action>): Array<Action> =>
      baseActions.filter((v) => ['refresh', 'selectAll', 'unselectAll'].includes(v.name as string)),
    []
  );

  const request = useAddParticipantToEvent(id, participantType);

  const [isVisible, setIsVisible] = useState(false);
  const show = useCallback(() => setIsVisible(true), []);
  const hide = useCallback(() => {
    if (invited.current > 0) reloadRef.current();
    invited.current = 0;
    setIsVisible(false);
  }, []);

  const invited = useRef(0);

  const [loading, setLoading] = useState(false);

  const invite = useCallback(
    ({ selectedItems, query, reload }: { selectedItems: Record<string, any>[]; query: any; reload: () => void }) => {
      setLoading(true);
      request(selectedItems.length > 0 ? selectedItems : removePageFromQuery(query as FetchQuery))
        .then(() => {
          invited.current++;
          setLoading(true);
          if (closeOnFinish) {
            hide();
          } else {
            reload();
          }
        })
        .finally(() => setLoading(false));
    },
    [closeOnFinish, hide, request]
  );

  const footer = useMemo(
    () =>
      ({
        selectedItems,
        query,
        reload,
        data,
        loading,
      }: {
        selectedItems: Record<string, any>[];
        query: any;
        data: Array<any>;
        reload: () => void;
        loading: boolean;
      }) => (
        <div className={classes.footer}>
          <div className={classes.keepWrapper}>
            <button onClick={toggleCloseOnFinish} className={classes.checkBoxWrapper} type="button">
              {closeOnFinish ? <UncheckedIcon /> : <CheckedIcon />}
            </button>
            <div className={classes.subHeader}>{t('Keep window open to continue adding participants')}</div>
          </div>
          <div className={classes.controls}>
            <Button
              disabled={data.length === 0 || loading}
              type="button"
              role="primary"
              onClick={() => invite({ selectedItems, query, reload })}
            >
              {selectedItems.length > 0 ? t('Add Selected') : t('Add All')}
            </Button>
            <Button type="button" role="flat" onClick={hide}>
              {t('Cancel')}
            </Button>
          </div>
        </div>
      ),
    [closeOnFinish, hide, invite, t, toggleCloseOnFinish]
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const reloadRef = useRef(() => {});

  const { displayCollectionName, PrimaryIdAttribute } = useMetaData(participantType);
  const { addActionUncompleted } = useNotifications();

  const getAction = useMemo(
    () =>
      (data: Record<string, any>): Action => ({
        title: displayCollectionName,
        name: 'invite',
        onClick: ({ reload }) => {
          if (Number(data.statecode) === eventMetadata.statecode.Inactive) {
            addActionUncompleted(t('Event State is Inactive'));
          } else {
            reloadRef.current = reload;
            show();
          }
        },
        order: 23,
        Icon: AddIcon,
        type: ActionType.CUSTOM_ACTION,
        actionContext: ActionContext.SubGid,
        allowedDevices: AllowedDevices.All,
        display: ({ selectedItemsCount }) => selectedItemsCount === 0,
        alwaysKeepTitle: false,
      }),
    [addActionUncompleted, displayCollectionName, show, t]
  );

  const participantTable: TEntityName = useMemo(
    () => (participantType === 'requester' ? 'invitedRequester' : 'invitedResource'),
    [participantType]
  );

  const tableProps: TListPage & { columns: readonly string[] } = useMemo(() => {
    const { links, ...participantConfig } = configs[participantType];
    return {
      hiddenFilters: [
        {
          condition: [
            {
              operator: 'null',
              entityname: participantTable,
              attribute: 'bahai_eventid',
            },
          ],
        },
        {
          condition:
            participantType === 'requester'
              ? [
                  {
                    operator: 'eq',
                    attribute: 'statecode',
                    value: String(inquirerMetadata.statecode.Active),
                  },
                  {
                    attribute: 'bahai_statuscode',
                    operator: 'ne',
                    value: String(inquirerMetadata.bahai_inquirerstatus.DoNotContact),
                  },
                ]
              : [
                  {
                    operator: 'eq',
                    attribute: 'isdisabled',
                    value: '0',
                  },
                ],
        },
      ],
      entityName: participantType,
      links: {
        [participantTable]: {
          from: participantType === 'requester' ? PrimaryIdAttribute : 'bahai_systemuserid',
          to: PrimaryIdAttribute,
          fields: [],
          condition: [{ operator: 'eq', attribute: 'bahai_eventid', value: id }],
        },
        ...links,
      },
      getActions,
      isSubgrid: true,
      isCreateHidden: true,
      displayViews: false,
      ...participantConfig,
    };
  }, [PrimaryIdAttribute, getActions, id, participantTable, participantType]);

  const content = useMemo(
    () =>
      isVisible ? (
        <Dialog
          showZoom
          loading={loading}
          className={classes.dialog}
          fullScreen={isMobile}
          onClose={hide}
          centered
          styles={{ zIndex: 4 }}
          collapsedLabel={t('Invite {{ displayCollectionName }}', {
            displayCollectionName,
          })}
        >
          <div className={classes.content}>
            <h2 className={classes.header}>{t('Invite {{displayCollectionName}}', { displayCollectionName })}</h2>
            <h3 className={classes.subHeader}>
              {t(
                'Inactive {{ displayCollectionName }} and those already added to the Event are not shown in the list',
                {
                  displayCollectionName,
                }
              )}
            </h3>
            <ListPage hideFullScreen dialog={participantType} systemView={'Default'} {...tableProps}>
              {footer}
            </ListPage>
          </div>
        </Dialog>
      ) : null,
    [displayCollectionName, footer, hide, isMobile, isVisible, loading, participantType, t, tableProps]
  );

  return { getAction, content };
};
