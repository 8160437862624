import { generatePath, matchPath } from 'react-router';
import config from 'config';

type RouteParamsRequired<T> = (params: T) => string;
type RouteParamsOptional<T> = (params?: T) => string;

type RouteBuilder<T = undefined> = T extends undefined ? RouteParamsOptional<T> : RouteParamsRequired<T>;

export type AppRoute<T> = RouteBuilder<T> & {
  routeName: string;
  path: string;
};

const route = <T extends Record<string, string | number>>(routeName: string, path = '/') =>
  Object.assign((params: T) => generatePath(path, params), {
    routeName,
    path,
  }) as AppRoute<T>;

export const routes = {
  home: route('dashboard', '/dashboard'),
  requesterList: route('requesterList', '/requester'),
  requester: route<{ id: number | string; tab?: string }>('requester', '/requester/:id/:tab?'),
  inquiryList: route('inquiryList', '/inquiry'),
  inquiry: route<{ id: number | string; tab?: string }>('inquiry', '/inquiry/:id/:tab?'),
  eventList: route('eventList', '/event'),
  event: route<{ id: number | string }>('event', '/event/:id/:tab?'),
  emailList: route('emailList', '/email'),
  email: route<{ id: number | string }>('email', '/email/:id'),
  interaction: route<{ id: number | string }>('interaction', '/interaction/:id/:tab?'),
  resourceList: route('resourceList', '/resource'),
  resource: route<{ id: number | string; tab?: string }>('resource', '/resource/:id/:tab?'),
  contactList: route('contactList', '/contact'),
  contact: route<{ id: number | string; tab?: string }>('contact', '/contact/:id/:tab?'),
  reminder: route<{ id: number | string; tab?: string }>('reminder', '/reminder/:id'),
  coverage: route<{ id: number | string }>('coverage', '/coverage/:id'),
  template: route<{ id: number | string }>('template', '/template/:id'),
};

export type TRouteName = keyof typeof routes;

export const mathRouteName = (location: string) =>
  (Object.keys(routes) as Array<TRouteName>).find((key) =>
    matchPath(location, { path: routes[key]?.path, exact: true })
  );

export const getRouteByLogicalName = (name: string) => {
  const entityName = Object.keys(config).find((key) => config[key as keyof typeof config]?.name === name);
  return entityName ? routes[entityName as keyof typeof routes] : undefined;
};
