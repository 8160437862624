import ListPage, { TListPage } from 'components/ListPage';
import * as baseConfig from 'schemas/areaagency';
import { EmailRecipient, SendEmailValidateFunction, useSendEmailFromList } from 'schemas/email/actions';
import { useCallback } from 'react';
import { Action } from 'components/Actions';
import { Trans } from 'react-i18next';
import { areaagencyrole } from 'config/EntityMetadata/bahai_areaagency';

const AreaAgencyList = (props: Partial<TListPage>) => {
  const areaAgencyToRecipientMapper = (data: Record<string, any>): EmailRecipient => {
    return {
      id: data.bahai_roleemail ? data.bahai_roleemail : data._bahai_contactid_value,
      email: data.bahai_roleemail,
    };
  };

  const getValidateAreaAgencyForEmailReceive =
    (prefix = ''): SendEmailValidateFunction =>
    (data) => {
      if (
        data[`${prefix}bahai_rolecode`] === areaagencyrole.NationalInquiryServicesTeam &&
        !data[`${prefix}bahai_roleemail`]
      ) {
        return {
          label: 'National Inquiry Services Team',
          content: <Trans>This record is not linked to any Member</Trans>,
        };
      }
      if (
        !data[`${prefix}bahai_roleemail`] &&
        !data[`${prefix}bahai_emailaddress1`] &&
        !data[`${prefix}bahai_emailaddress1`]
      ) {
        return {
          label: data[`${prefix}bahai_name`],
          content: <Trans>Member doesn’t have an email address</Trans>,
        };
      }
    };

  const { actions: sendEmailActions, content } = useSendEmailFromList(
    'member',
    getValidateAreaAgencyForEmailReceive(),
    'contact',
    areaAgencyToRecipientMapper,
    undefined,
    'Email Selected'
  );

  const getActions = useCallback(
    (baseActions: Action[]) => {
      return [...baseActions, ...[sendEmailActions.find((x) => x.name === 'sendToSelected') as Action]];
    },
    [sendEmailActions]
  );

  return (
    <>
      {content}
      <ListPage
        entityName="areaagency"
        allowRowClick={false}
        getActions={getActions}
        {...baseConfig}
        isRemoveHidden={true}
        {...props}
      />
    </>
  );
};

export default AreaAgencyList;
