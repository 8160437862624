import { useCallback } from 'react';
import { SinglePage } from 'components/SinglePage';
import { useCompleteReminder, useNotifyReminder } from 'schemas/reminder/actions';
import { useSecurity } from 'providers/AuthProvider';
import { Privilege } from 'lib';
import { Action } from 'components/Actions';
import * as baseConfig from 'schemas/reminder';

export const Reminder = () => {
  const { isGranted } = useSecurity();
  const { completeAction, content } = useCompleteReminder();
  const { action: notifyAction, content: notifyContent } = useNotifyReminder();

  const getActions = useCallback(
    (baseActions: Action[]) => [
      ...baseActions,
      ...(isGranted('reminder', Privilege.Write) ? [notifyAction, completeAction] : []),
    ],
    [completeAction, isGranted, notifyAction]
  );

  return (
    <>
      {content}
      {notifyContent}
      <SinglePage entityName="reminder" displayAssign={false} {...baseConfig} getActions={getActions} />
    </>
  );
};
