import { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './more.module.scss';
import { ScreenContext } from 'providers/ScreenProvider';

const BASE_HEIGHT = 60;

export type TShowMoreProps = {
  maxHeight?: number;
  showOnDescTop?: boolean;
  children?: ReactNode;
};

export const ShowMore: FC<TShowMoreProps> = ({ children, maxHeight = BASE_HEIGHT, showOnDescTop = false }) => {
  const { t } = useTranslation();
  const { isDescTop } = useContext(ScreenContext);
  const [overSized, setOverSized] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const toggle = useCallback(() => setCollapsed((v) => !v), [setCollapsed]);

  const ref = useRef<HTMLDivElement>(null);

  const check = useCallback(() => {
    if (ref.current) setOverSized(ref.current.scrollHeight > maxHeight);
  }, [maxHeight]);

  const timer = useRef<ReturnType<typeof setInterval>>();
  useEffect(() => {
    check();
    window.addEventListener('resize', check);
    timer.current = setInterval(check, 300);
    return () => {
      if (timer.current) clearInterval(timer.current);
      window.removeEventListener('resize', check);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isDescTop && !showOnDescTop) return <>{children}</>;

  return (
    <div className={classes.root}>
      <div style={collapsed ? { maxHeight } : {}} className={classes.content} ref={ref}>
        {children}
      </div>
      {overSized && (
        <button className={classes.btn} type="button" onClick={toggle}>
          {collapsed ? t('Show More') : t('Show Less')}
        </button>
      )}
    </div>
  );
};
