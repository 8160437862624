import { useFormChanges, useMetaData } from 'lib/hooks';
import { useApi } from 'domain/api';
import { useFormState } from 'react-final-form';
import { FieldType, TEntityName } from 'lib';
import { useCallback, useEffect, useRef } from 'react';
import { parseDate } from 'lib/adapter';

export const ChainImprover = ({
  chains,
  replace = '',
  entityName,
  bothDirectionClean = false,
  forceClearFields = [],
}: {
  chains: Array<string[]>;
  replace?: string | RegExp;
  entityName: TEntityName;
  bothDirectionClean?: boolean;
  forceClearFields?: string[];
}) => {
  const { values } = useFormState();
  const { changes, update } = useFormChanges(chains.flat());
  const { request } = useApi();
  const { getJoinUrl, getFieldType, getFieldDefinition } = useMetaData(entityName);

  const getParentValues = useCallback(
    async (field: string, value: string, chain: string[]) => {
      if (!value) return [];
      const { data } = await request<Record<string, string>>({
        url: `${getJoinUrl(field)}(${value})`,
      });
      return [undefined, ...chain.slice(1).map((key) => data[`_${key}_value`])];
    },
    [getJoinUrl, request]
  );

  const ignoreNextUpdate = useRef<boolean>(false);

  const updateRelated = useCallback(
    async (changes: Record<string, any>) => {
      const field = Object.keys(changes)[0];
      if (!field) return;
      if (ignoreNextUpdate.current) {
        ignoreNextUpdate.current = false;
        return;
      }
      const currentChain = chains.find((v) => v.includes(field)) as string[];
      const index = currentChain.indexOf(field);
      const parentValues = await getParentValues(
        field,
        changes[field],
        currentChain.map((v) => v.replace(replace, ''))
      );
      const changeSet = currentChain.reduce(
        (acc, name, i) => {
          if (index === i) return acc;
          if (i < index || ((bothDirectionClean || forceClearFields.includes(field)) && !changes[field])) {
            return { ...acc, [name]: undefined };
          } else {
            return changes[field] ? { ...acc, [name]: parentValues[i] || '' } : acc;
          }
        },
        {} as Record<string, any>
      );
      if (Object.keys(changeSet).some((key) => changeSet[key] !== values[key])) {
        ignoreNextUpdate.current = true;
        update(changeSet);
      }
    },
    [bothDirectionClean, chains, forceClearFields, getParentValues, replace, update, values]
  );

  const copyDates = useCallback(
    (changes: Record<string, string>) => {
      const field = Object.keys(changes)[0];
      const { format } = getFieldDefinition(field);
      if (!field) return;
      const currentChain = chains.find((v) => v.includes(field)) as string[];
      const index = currentChain.indexOf(field);
      const inheritedValue = values[field];
      if (isNaN(parseDate(values[field], format !== 'DateOnly').getTime())) return;
      const changeSet = currentChain.reduce(
        (acc, name) => {
          if (index !== 0) {
            return acc;
          } else {
            return changes[field]
              ? { ...acc, [name]: index === 0 && field !== name && !values[name] ? inheritedValue : values[name] }
              : acc;
          }
        },
        {} as Record<string, any>
      );
      if (Object.keys(changeSet).some((key) => changeSet[key] !== values[key])) {
        update(changeSet);
      }
    },
    [chains, getFieldDefinition, update, values]
  );

  const updateFields = useCallback(
    (changes: Record<string, string>) => {
      const field = Object.keys(changes)[0];
      if (!field) return;
      const currentChain = chains.find((v) => v.includes(field)) as string[];
      const index = currentChain.indexOf(field);
      const inheritedValue = values[field];
      const changeSet = currentChain.reduce(
        (acc, name) => {
          if (index !== 0) {
            return acc;
          } else {
            return changes[field]
              ? { ...acc, [name]: index === 0 && field != name && !values[name] ? inheritedValue : values[name] }
              : acc;
          }
        },
        {} as Record<string, any>
      );
      if (Object.keys(changeSet).some((key) => changeSet[key] !== values[key])) {
        update(changeSet);
      }
    },
    [chains, update, values]
  );

  useEffect(() => {
    if (Object.keys(changes).length > 0) {
      const fieldType = getFieldType(Object.keys(changes)[0]);
      switch (fieldType) {
        case FieldType.Lookup:
          updateRelated(changes).then();
          break;
        case FieldType.DateTime:
          copyDates(changes);
          break;
        default:
          updateFields(changes);
      }
    }
  }, [changes, copyDates, getFieldType, updateFields, updateRelated]);

  return null;
};

const clearRequiredBase = (changes: Record<string, any>) => !!Object.values(changes).length;

export const ClearImprover = ({
  sensitiveFields,
  fieldsToClear,
  clearRequired = clearRequiredBase,
}: {
  sensitiveFields: string[];
  fieldsToClear: string[];
  clearRequired?: (changes: Record<string, any>) => boolean;
}) => {
  const { changes, update } = useFormChanges(sensitiveFields);

  useEffect(() => {
    if (clearRequired(changes)) {
      update(Object.fromEntries(fieldsToClear.map((key) => [key, null])));
    }
  }, [changes, clearRequired, fieldsToClear, update]);
  return null;
};
