import { Field, Form as FinalForm } from 'react-final-form';
import { Input } from 'components/Form';
import { useTranslation } from 'react-i18next';
import classes from './viewForm.module.scss';
import { Modal } from 'components/Modal';
import * as rules from 'lib/rules';
import { createPortal } from 'react-dom';

export type ViewFormData = {
  name: string;
  description?: string;
};

export const ViewForm = ({
  initialValues,
  onFormSubmit,
  onClose,
  loading,
}: {
  initialValues?: Record<string, string>;
  onFormSubmit: (data: ViewFormData) => void;
  onClose: () => void;
  loading?: boolean;
}) => {
  const { t } = useTranslation();

  return createPortal(
    <FinalForm
      onSubmit={onFormSubmit}
      initialValues={initialValues || {}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            title={initialValues ? t('Edit View') : t('Setup View')}
            onClose={onClose}
            loading={loading}
            portal={false}
            controls={[
              {
                title: initialValues ? t('Save') : t('Create'),
                type: 'submit',
                role: 'primary',
              },
              { title: t('Cancel'), onClick: onClose },
            ]}
          >
            <div className={classes.form}>
              <Field
                className={classes.field}
                name="name"
                component={Input}
                label={t('Name')}
                required
                validate={rules.compose([rules.required, rules.maxLength(100)])}
                maxLength={100}
              />
              <Field
                name="description"
                className={classes.field}
                component={Input}
                label={t('Description')}
                inputType="area"
                validate={rules.maxLength(500)}
                maxLength={500}
              />
            </div>
          </Modal>
        </form>
      )}
    />,
    document.body
  );
};
