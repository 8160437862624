import ListPage, { TListPage } from 'components/ListPage';
import * as baseConfig from 'schemas/template';
import { useState } from 'react';
import { useSystemUserId } from 'lib/helpers';
import { Trans } from 'react-i18next';
import { getWarningImprover } from 'components/ListPage/components/Form';

const TemplateList = (props: Partial<TListPage>) => {
  const userId = useSystemUserId();
  const [ownerId, setOwnerId] = useState();

  props.getInitialValues &&
    props.getInitialValues().then((data: Record<string, any>) => {
      setOwnerId(data.ownerid.split('<|>')[1]);
    });

  const WarningsImprover = getWarningImprover([], (_, setWarnings) => {
    if (userId !== ownerId) {
      setWarnings([
        <Trans>
          You are in someone else`s Resource record. Please note that this Template will be assigned to this Resource
          after saving
        </Trans>,
      ]);
    }
  });

  return (
    <ListPage
      entityName="template"
      {...baseConfig}
      isConfirmationMessageNeeded={() => true}
      isConfirmationMessageRequired={() => false}
      {...props}
      onSubmit={props.onSubmit}
      WarningsImprover={WarningsImprover}
    />
  );
};
export default TemplateList;
