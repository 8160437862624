import { CSSProperties, FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import classes from './dialog.module.scss';
import { createPortal } from 'react-dom';
import { ReactComponent as DismissIcon } from 'components/icons/dismiss.svg';
import { ReactComponent as ZoomIcon } from 'components/Dialog/icons/zoom-in.svg';
import { ReactComponent as ZoomOutIcon } from 'components/Dialog/icons/zoom-out.svg';
import { ReactComponent as CollapseIcon } from 'components/Dialog/icons/collapse.svg';
import cx from 'classnames';
import { Loader } from 'components/Loader';
import { ScreenContext } from 'providers/ScreenProvider';
import useDragging from 'components/Dragging';

export const Dialog: FC<{
  className?: string;
  onClose: () => void;
  centered?: boolean;
  styles?: CSSProperties;
  loading?: boolean;
  fullScreen?: boolean;
  showZoom?: boolean;
  isDraggingAllowed?: boolean;
  collapsedLabel?: string;
  children: ReactNode;
}> = ({
  onClose,
  children,
  centered = true,
  fullScreen = false,
  styles,
  loading = false,
  showZoom = false,
  className,
  isDraggingAllowed = false,
  collapsedLabel,
}) => {
  const [ownStyles, setOwnStyles] = useState<CSSProperties>(styles || {});
  const [isFullScreen, setIsFullScreen] = useState(fullScreen);
  const toggleFullScreen = useCallback(() => setIsFullScreen((v) => !v), []);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const collapse = useCallback(() => {
    setIsCollapsed(true);
  }, []);
  const expand = useCallback(() => {
    setIsCollapsed(false);
  }, []);

  const { isMobile } = useContext(ScreenContext);
  const ref = useRef<HTMLDivElement>(null);
  const dragProps = useDragging(ref, isDraggingAllowed);

  useEffect(() => {
    if (ref.current) {
      const { left, width } = ref.current.getBoundingClientRect();
      if (left + width > window.innerWidth) {
        setOwnStyles(({ top }) => ({ top, right: '5px' }));
      }
    }
  }, []);

  const element = document.getElementById('root');

  if (!element) return null;

  return createPortal(
    <>
      {isCollapsed ? (
        <>
          {showZoom && !isMobile && (
            <button type="button" className={classes.collapsed} onClick={expand}>
              {collapsedLabel}
            </button>
          )}
          <div className={classes.overlay} />
        </>
      ) : (
        <>
          <div className={classes.overlay} />
          <div
            ref={ref}
            className={cx(classes.root, { [classes.centered]: centered, [classes.fullScreen]: isFullScreen })}
            style={{ ...ownStyles, ...dragProps.style }}
          >
            <div className={classes.contentWrapper}>
              <div className={cx(classes.popup, className, { [classes.fullScreen]: isFullScreen })}>
                {loading && (
                  <div className={classes.loader}>
                    <Loader />
                  </div>
                )}
                <div className={classes.buttons}>
                  {showZoom && !isMobile && (
                    <>
                      <button type="button" className={classes.close} onClick={collapse}>
                        <CollapseIcon className={classes.icon} />
                      </button>
                      <button type="button" className={classes.close} onClick={toggleFullScreen}>
                        {isFullScreen ? (
                          <ZoomOutIcon className={classes.icon} />
                        ) : (
                          <ZoomIcon className={classes.icon} />
                        )}
                      </button>
                    </>
                  )}
                  <button type="button" className={classes.close} onClick={onClose}>
                    <DismissIcon className={classes.icon} />
                  </button>
                </div>
                {children}
              </div>
            </div>
          </div>
        </>
      )}
    </>,
    element
  );
};
