import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMetaData, useRemove } from 'lib/hooks';
import { TLinkEntity } from 'components/ListPage';
import { TEntityName } from 'lib';
import { useRecord } from 'lib/record';
import { useParams } from 'react-router-dom';
import { useApi } from 'domain/api';
import { useNotifications } from 'providers/NotificationsProvider';
import { useTranslation } from 'react-i18next';
import { parseSaveFormError } from 'lib/errorParser';

type SinglePagePropsType = {
  id?: string;
  entityName: TEntityName;
  links?: TLinkEntity;
};

export const useSinglePageApi = ({ id, entityName, links }: SinglePagePropsType) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({} as Record<string, any>);

  const { getData, getQuery, getFormValues } = useRecord(entityName);
  const reload = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    setData(await getData(id, links));
    setLoading(false);
  }, [getData, id, links]);

  useEffect(() => {
    reload().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { remove, removeWithConfirmation, loading: removeLoading } = useRemove(entityName);

  const query = useMemo(() => (id ? getQuery(id, links) : undefined), [getQuery, id, links]);

  const initialValues = useMemo(() => getFormValues(data), [getFormValues, data]);

  return {
    data,
    initialValues,
    reload,
    remove,
    removeWithConfirmation,
    loading: loading || removeLoading,
    query,
    getData,
  };
};

export const useImage = (entityName: TEntityName, data?: Record<string, any>) => {
  const {
    PrimaryImageAttribute,
    entityConfig: { url },
  } = useMetaData(entityName);
  const { request } = useApi();
  const { id } = useParams<{ id: string }>();

  const baseImage = useMemo(() => {
    if (!PrimaryImageAttribute || !data) return;
    return data[PrimaryImageAttribute] ? 'data:image/jpg;base64,' + data[PrimaryImageAttribute] : undefined;
  }, [PrimaryImageAttribute, data]);

  const [image, setImage] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setImage(baseImage);
  }, [baseImage]);

  const { t } = useTranslation();
  const { addError } = useNotifications();
  const onChange = useCallback(
    (image: string) => {
      setLoading(true);
      request<Record<string, any>>({
        url: id ? `${url}(${id})` : url,
        data: {
          [PrimaryImageAttribute]: image.replace(/^.+,/, ''),
        },
        method: 'patch',
      })
        .then(() => setImage(image))
        .catch((e) =>
          addError({
            title: t('Image Upload Error'),
            content: parseSaveFormError(e, !!id),
          })
        )
        .finally(() => setLoading(false));
    },
    [request, id, url, PrimaryImageAttribute, addError, t]
  );

  const onRemove = useCallback(() => {
    setLoading(true);
    request<Record<string, any>>({
      url: id ? `${url}(${id})` : url,
      data: {
        [PrimaryImageAttribute]: null,
      },
      method: 'patch',
    })
      .then(() => setImage(undefined))
      .catch(() => {
        addError({
          title: 'Image Delete Error',
          content: t('Delete Image for this User is not allowed'),
        });
      })
      .finally(() => setLoading(false));
  }, [request, id, url, PrimaryImageAttribute, addError, t]);

  return { onChange, onRemove, image, loading };
};
