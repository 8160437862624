import { SinglePage, Tab } from 'components/SinglePage';
import * as config from 'schemas/interaction';
import { Improver } from 'pages/InteractionList';
import DocumentList from 'pages/DocumentList';
import { useTranslation, Trans } from 'react-i18next';
import { config as documentConfig } from 'schemas/document';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import * as interactionMetadata from 'config/EntityMetadata/bahai_communication';

export const Interaction = () => {
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();

  const isNotCreatable = (data: Record<string, any>) =>
    !!config.isNotEditable(data) && <Trans>Interaction State is Inactive</Trans>;

  const getLockMessage = useCallback(
    (data: Record<config.Keys, any>) => {
      switch (true) {
        case !config.isActive(data):
          return {
            message: <Trans>Record State is Inactive</Trans>,
          };
        case data.bahai_communicationtypecode === interactionMetadata.bahai_communicationtypecode.Email:
          return {
            message: t(
              'Please be aware that Interaction Type = “Email” does not send an email to the receivers and should be used only for historical record'
            ),
            forceDisplay: true,
            icon: 'info',
            styles: {
              backgroundColor: '#ffffff',
            },
          };
        default:
          return {};
      }
    },
    [t]
  );

  return (
    <SinglePage
      entityName="interaction"
      FormImprover={Improver}
      displayAssign={true}
      getLockMessage={getLockMessage}
      {...config}
      additionalTabs={
        [
          {
            label: t('Documents'),
            tab: 'document',
            content: (data: Record<string, any>, reload: () => void) => (
              <DocumentList
                config={documentConfig}
                onItemCreated={reload}
                onItemsRemoved={reload}
                parentEntityName="interaction"
                parentId={id}
                isNotCreatable={isNotCreatable(data)}
                isNotEditable={config.isNotEditable(data)}
                isNotRemovable={() => config.isNotRemovable(data)}
              />
            ),
          },
        ].filter((v) => v.tab !== 'document') as Tab[]
      }
    />
  );
};
