import { useTranslation, Trans } from 'react-i18next';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { useCallback, useMemo, useState, useRef, SVGProps, ChangeEventHandler } from 'react';
import { ReactComponent as DownloadIcon } from 'pages/DocumentList/icons/download.svg';
import { ReactComponent as EditIcon } from 'components/Actions/icons/edit.svg';
import { ReactComponent as UploadIcon } from 'components/Actions/icons/add.svg';
import { Form } from 'components/ListPage/components/Form';
import { getFormConfig } from 'schemas/document';
import { useTableConfig } from 'lib/helpers';
import { useMetaData } from 'lib/hooks';
import { config as additionalConfig } from 'schemas/document';
import { useApi } from 'domain/api';
import { DATETIME_OUTPUT_FORMAT, TEntityName } from 'lib';
import { useNotifications, NotificationType } from 'providers/NotificationsProvider';
import classes from 'pages/Common/pages.common.module.scss';
import { Loader } from 'components/Loader';
import { format } from 'date-fns';

type TSharepointConfiguration = {
  DocumentLibrariesNames: { Key: string; Value: string }[];
  TypeUniqueIdentifiers: { Key: string; Value: string }[];
};

export const useDownload = () => {
  const { t } = useTranslation();

  const download: Action = useMemo(
    () => ({
      title: t('Download'),
      name: 'download',
      onClick: ({ selectedItems }) => {
        const document = selectedItems[0];
        const baseUrl = getSiteAddress(document.absoluteurl);
        const downloadUrl = `${baseUrl}/_layouts/download.aspx?UniqueId=${document.sharepointdocumentid}`;

        const element = window.document.createElement('a');
        element.setAttribute('href', downloadUrl);
        element.style.display = 'none';
        element.click();
      },
      Icon: DownloadIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SubGid,
      allowedDevices: AllowedDevices.All,
      display: ({ selectedItemsCount }) => selectedItemsCount === 1,
    }),
    [t]
  );

  return download;
};

export const useEditDescription = (parentEntityName: TEntityName) => {
  const { t } = useTranslation();
  const [document, setDocument] = useState<Record<string, any>>();
  const { entityConfig } = useMetaData('document');
  const { logicalName: parentLogicalName } = useMetaData(parentEntityName);
  const config = useTableConfig(entityConfig.fields, additionalConfig, 'document');
  const { getEnvVariable, request } = useApi();
  const reloadList = useRef<() => void>();
  const { addActionUncompleted, addActionFailed, addNotification } = useNotifications();

  const getAction = useCallback(
    (isNotEditable?: JSX.Element | false): Action => ({
      title: t('Edit'),
      name: 'edit',
      onClick: ({ selectedItems, reload }) => {
        if (isNotEditable) {
          addActionUncompleted(isNotEditable);
          return;
        }
        reloadList.current = reload;
        setDocument(selectedItems[0]);
      },
      Icon: EditIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SubGid,
      allowedDevices: AllowedDevices.All,
      display: ({ selectedItemsCount }) => selectedItemsCount === 1,
    }),
    [t, addActionUncompleted]
  );

  const updateDescription = useCallback(
    async (
      description: string,
      documentId: number,
      documentAbsoluteUrl: string,
      documentName: string,
      parentLogicalName: string
    ) => {
      try {
        const sharePointConfigurationJson = await getEnvVariable('bahai_sharepointconfiguration');
        const sharePointConfiguration = JSON.parse(sharePointConfigurationJson) as TSharepointConfiguration;

        const documentLibraryName =
          sharePointConfiguration.DocumentLibrariesNames.find((c) => c.Key === parentLogicalName)?.Value ?? '';
        const typeUniqueIdentifier =
          sharePointConfiguration.TypeUniqueIdentifiers.find((c) => c.Key === parentLogicalName)?.Value ?? '';

        const updateDescriptionFlowUri = await getEnvVariable('bahai_updatesharepointdocumentdescriptionuri');
        const siteAddress = getSiteAddress(documentAbsoluteUrl);

        await request({
          url: updateDescriptionFlowUri,
          method: 'post',
          data: {
            siteAddress,
            documentLibraryName,
            documentId,
            typeUniqueIdentifier,
            description,
          },
          authorize: false,
        });

        addNotification({ type: NotificationType.SUCCESS, title: t('Your changes have been saved') });
      } catch (e: any) {
        if (e?.response?.status === 423) {
          const emails = e.response.data.error.message.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9]+)/gi);
          if (emails?.length > 0) {
            addActionFailed(
              t('The "{{ documentName }}" document is locked by the "{{ email }}" user', {
                documentName,
                email: emails[0],
              })
            );
          }
        } else {
          throw { _general: [<Trans>Something went wrong</Trans>] };
        }
      }
    },
    [getEnvVariable, addActionFailed, addNotification, request, t]
  );

  const hideEditPopup = useCallback(() => {
    setDocument(undefined);
  }, []);

  const onSubmit = useCallback(
    async ({ data, postAction }: { data: Record<string, any>; postAction: () => void }) => {
      await updateDescription(
        data.bahai_description,
        document?.documentid,
        document?.absoluteurl,
        document?.fullname,
        parentLogicalName
      );

      reloadList.current && reloadList.current();
      postAction();
    },
    [document?.documentid, document?.absoluteurl, document?.fullname, parentLogicalName, updateDescription]
  );

  const content = useMemo(() => {
    return (
      <>
        {document && (
          <Form
            entityName="document"
            onClose={hideEditPopup}
            postAction={hideEditPopup}
            config={config}
            getFormConfig={getFormConfig}
            id={document.sharepointdocumentid}
            initialValues={document}
            onSubmit={onSubmit}
            customDisplayName="Document"
          />
        )}
      </>
    );
  }, [document, hideEditPopup, config, onSubmit]);

  return { getAction, content };
};

export const useUpload = (parentEntityName: TEntityName, parentId: string) => {
  const { t } = useTranslation();
  const { uploadDocument, request } = useApi();
  const { logicalName: parentLogicalName, PrimaryIdAttribute: parentIdAttribute, url } = useMetaData(parentEntityName);
  const reloadList = useRef<() => void>();
  const fileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const { addActionCompleted, addActionFailed, addActionUncompleted } = useNotifications();

  const getAction = useCallback(
    (isNotCreatable?: JSX.Element | false): Action => ({
      title: t('Document'),
      name: 'documents',
      onClick: ({ reload }) => {
        if (isNotCreatable) {
          addActionUncompleted(isNotCreatable);
          return;
        }
        reloadList.current = reload;
        fileRef.current?.click();
      },
      Icon: (props: SVGProps<any>) => <UploadIcon style={{ fill: 'var(--themePrimary)' }} {...props} />,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SubGid,
      allowedDevices: AllowedDevices.All,
    }),
    [t, fileRef, addActionUncompleted]
  );

  const upload = useCallback(
    async (file: File, content: string, addDateTime = false) => {
      try {
        setLoading(true);

        let name = file.name;
        if (addDateTime) {
          const dt = format(new Date(), DATETIME_OUTPUT_FORMAT);
          name = dt + ' ' + name;
        }

        await uploadDocument(name, content, parentLogicalName, parentIdAttribute, parentId);
        await request<Record<string, any>>({
          url: `${url}(${parentId})`,
          data: { bahai_isattachmentcreated: true },
          method: 'patch',
        });
        reloadList.current && reloadList.current();
        addActionCompleted(t('Your document has been uploaded'));
      } catch (error: any) {
        if (!addDateTime && error.response?.data?.error?.message === 'File Already Exists') {
          await upload(file, content, true);
        } else {
          addActionFailed(t('Something went wrong'));
        }
      } finally {
        setLoading(false);
      }
    },
    [
      url,
      parentLogicalName,
      parentIdAttribute,
      parentId,
      addActionCompleted,
      addActionFailed,
      request,
      uploadDocument,
      t,
    ]
  );

  const onFileChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const file = e.target.files?.[0] as File;
      e.target.value = '';

      if (file.size > 50000000) {
        addActionUncompleted(t('File size is more than 50 MB, please use Share Point directly to upload this file'));
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        const content = (reader.result as string).replace('data:', '').replace(/^.+,/, '');
        upload(file, content);
      };
    },
    [upload, addActionUncompleted, t]
  );

  const content = useMemo(() => {
    return loading ? (
      <div className={classes.loader}>
        <Loader />
      </div>
    ) : (
      <input style={{ display: 'none' }} ref={fileRef} type="file" onChange={onFileChange} />
    );
  }, [loading, onFileChange]);

  return { getAction, content };
};

const getSiteAddress = (absoluteDocUrl: string) => {
  const sites = 'sites/';
  const sitesPosition = absoluteDocUrl.indexOf(sites);
  const nextSlashPosition = absoluteDocUrl.indexOf('/', sitesPosition + sites.length);

  return absoluteDocUrl.substring(0, absoluteDocUrl.indexOf('/', nextSlashPosition));
};
