import { useMetaData } from 'lib/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useAutocompleteStorage } from 'components/AutoComplete/hooks';
import { DATETIME_OUTPUT_FORMAT, FieldType, isDateTime, TEntityName } from 'lib';
import { useTranslation } from 'react-i18next';
import { TCellComponent, TConfig } from 'components/Table';
import classes from './value.module.scss';
import singlePageClasses from '../../SinglePage/singlePage.module.scss';
import { dateAdapter, isUSA, timeAdapter, TimeZone, toISO } from 'lib/adapter';
import { AppRoute, getRouteByLogicalName } from 'domain/routes';
import { HistoryLink } from 'components/HistoryLink';
import cx from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';

export const ReadOnlyValue = ({
  name,
  data,
  entity,
  defaultValue = '---',
  config,
  context,
  isAudit = false,
}: {
  name: string;
  entity: TEntityName;
  data: Record<string, any>;
  defaultValue?: string;
  config?: Record<string, TConfig<Record<string, any>>>;
  context?: string;
  isAudit?: boolean;
}) => {
  const value = useMemo(() => data[name], [data, name]);
  const { t } = useTranslation();
  const {
    entityConfig,
    getFieldDefinition,
    hiddenFields,
    getLookupRoute,
    config: entitiesConfig,
  } = useMetaData(entity);
  const { type, format } = useMemo(() => getFieldDefinition(name), [getFieldDefinition, name]);

  const { request } = useAutocompleteStorage(entityConfig.fields[name]?.targets);
  const [overflow, setOverFlow] = useState(false);

  const [displayValue, setDisplayValue] = useState<string | JSX.Element>('');

  useEffect(() => {
    if (!value && (FieldType.Boolean !== type || isAudit)) return;

    const [fieldName, entityName = entity] = name.split('.').reverse();
    const options = new Map(entitiesConfig[entityName as TEntityName].fields[fieldName].options);

    switch (type) {
      case FieldType.Owner:
        request([value.replace(',', '<|>')], true).then((resp) => {
          if (value.split(',')[0] !== 'systemuser') {
            setDisplayValue(Object.values(resp).join(', '));
          } else {
            const route = getRouteByLogicalName('systemuser') as AppRoute<{ id: string }>;
            setDisplayValue(
              <HistoryLink to={route({ id: value.split(',')[1] })}>{Object.values(resp).join('')}</HistoryLink>
            );
          }
        });
        break;
      case FieldType.Lookup:
        if (name === 'regardingobjectid') {
          request([(value as string).replace(',', '<|>')], true).then((resp) => {
            const route = getRouteByLogicalName(value.split(',')[0]) as AppRoute<{ id: string }>;
            if (value && route) {
              setDisplayValue(
                <HistoryLink to={route({ id: value.split(',')[1] })}>{Object.values(resp).join('')}</HistoryLink>
              );
            } else {
              setDisplayValue(Object.values(resp).join(', '));
            }
          });
        } else {
          request(
            (value as string).split(',').filter((_, index) => (isAudit ? !!index && index % 2 !== 0 : true))
          ).then((resp) => {
            const route = getLookupRoute(name) as AppRoute<any>;
            if ((value.includes(',') && !isAudit) || !route) {
              setDisplayValue(Object.values(resp).join(', '));
            } else {
              setDisplayValue(
                Object.keys(resp)[0] ? (
                  <HistoryLink to={route({ id: Object.keys(resp)[0] })}>{Object.values(resp).join('')}</HistoryLink>
                ) : (
                  t('[DELETED OBJECT]')
                )
              );
            }
          });
        }
        break;
      case FieldType.DateTime:
        if (isAudit && isDateTime(format) && isUSA(value)) {
          setDisplayValue(
            formatInTimeZone(toISO(value), TimeZone.getInstance().getTimeZone(), DATETIME_OUTPUT_FORMAT).slice(
              0,
              fieldName === 'bahai_originationdate' ? 10 : undefined
            )
          );
        } else {
          setDisplayValue(
            <>
              {isDateTime(format)
                ? timeAdapter(data, name, defaultValue)
                : (dateAdapter(data, name, defaultValue) as string).slice(hiddenFields.includes(name) ? -4 : 0)}
            </>
          );
        }

        break;
      case FieldType.Virtual:
      case FieldType.Picklist:
        setDisplayValue(
          `${value}`
            .replace('[', '')
            .replace(']', '')
            .split(',')
            .map((key) => options.get(key))
            .join(', ')
        );
        break;
      case FieldType.Boolean:
        if (
          typeof value === 'string' &&
          entityConfig.fields[name].options.some((v) => v[0] === (value || 'false').toLowerCase())
        ) {
          setDisplayValue(new Map(entityConfig.fields[name].options).get((value || 'false').toLowerCase()) || '');
        } else {
          setDisplayValue(value ? t('Yes') : t('No'));
        }
        break;
      case FieldType.Status:
      case FieldType.State:
        if (entityConfig.fields[name].options.some((v) => v[0] === (String(value) || 'false').toLowerCase())) {
          setDisplayValue(new Map(entityConfig.fields[name].options).get((value || 'false').toLowerCase()) || '');
        } else {
          setDisplayValue(value ? t('Active') : t('Inactive'));
        }
        break;
      default:
        if (name === 'bahai_importantnote' && context === 'MERGE') {
          setDisplayValue(`${value}`.replace(/<.+?>/g, ' ').replaceAll('&nbsp;', ''));
        } else if (config?.[name]?.component) {
          const Component = config[name].component as TCellComponent<Record<string, any>>;
          setOverFlow(true);
          setDisplayValue(
            <Component data={data} name={name} defaultValue={'---'} classes={singlePageClasses} context={context} />
          );
        } else {
          setDisplayValue(value);
        }
    }
  }, [
    config,
    context,
    data,
    defaultValue,
    entityConfig,
    format,
    getLookupRoute,
    isAudit,
    name,
    request,
    t,
    type,
    value,
    entitiesConfig,
    entity,
    hiddenFields,
    entityConfig.fields,
  ]);

  return (
    <div className={cx(classes.value, { [classes.notOverflow]: !isAudit && !overflow })}>{displayValue || '---'}</div>
  );
};
