import entities, { Fields } from 'config';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import * as rules from 'lib/rules';
import { TextEditor } from 'components/Form/TextEditor';
import { FieldValidator } from 'final-form';
import { AdditionalConfigType, DATE_OUTPUT_FORMAT, FieldType, FormConfigGetter } from 'lib';
import {
  AreaComponent,
  AreaTooltipComponent,
  EmailComponent,
  HTMLComponent,
  ToolTipTextComponent,
  PhoneComponent,
  UrlComponent,
  DateOnlyComponent,
} from 'lib/components';
import { createFormAutocomplete } from 'components/Form/Autocomplete';
import { Trans } from 'react-i18next';
import * as inquirerMetadata from 'config/EntityMetadata/bahai_inquirer';
import { TSinglePage } from 'components/SinglePage';
import { getWarningImprover } from 'components/ListPage/components/Form';
import { age, statecode } from 'config/EntityMetadata/bahai_inquirer';
import { parse, subYears } from 'date-fns';
import { TLinkEntity } from 'components/ListPage';
import { bahai_preferredlanguage } from 'config/EntityMetadata/common';
import { timeAdapter } from 'lib/adapter';
import { bahai_type } from 'config/EntityMetadata/businessunit';

export const columns = [
  ...entities.requester.columns,
  'locality.bahai_name',
  'cluster.bahai_name',
  'reminder.bahai_reminderdatetime',
  'cluster.bahai_stageofgrowth',
  'locality.bahai_localitytype',
] as const;

export type Keys = Fields<(typeof columns)[number]> | '_bahai_currentreminder_value';

export const defaultMobileColumns: Keys[] = ['bahai_name', 'bahai_statuscode', 'bahai_originationdate'];

export const links = {
  locality: {
    to: 'bahai_localityid',
    from: 'businessunitid',
    fields: ['bahai_name', 'bahai_localitytype'],
    condition: [],
  },
  cluster: {
    to: 'bahai_clusterid',
    from: 'businessunitid',
    fields: ['bahai_name', 'bahai_stageofgrowth'],
    condition: [],
  },
  reminder: {
    to: 'bahai_currentreminder',
    from: 'activityid',
    fields: ['description', 'bahai_reminderdatetime'],
    condition: [],
  },
} as TLinkEntity;

const parentMemberSearchBy = { searchBy: ['bahai_idn'] };

const notChildBirthDate = subYears(new Date(), 15);
export const isChild = ({ bahai_estimatedagerange, bahai_birthdate }: Record<string, any>) =>
  [age.Junior, age.Child].includes(Number(bahai_estimatedagerange)) ||
  (bahai_birthdate ? parse(bahai_birthdate, DATE_OUTPUT_FORMAT, new Date()) > notChildBirthDate : false);

export const config: AdditionalConfigType<
  Keys | 'bahai_inquirerid' | '_bahai_inquirerid_value' | 'requester.bahai_id'
> = {
  bahai_id: {
    component: ({ data, context }) => (
      <HistoryLink
        target={context === 'MERGE' ? '_blank' : '_self'}
        to={r.requester({ id: data.bahai_inquirerid || data._bahai_inquirerid_value || 0 })}
      >
        {data.bahai_id || data['requester.bahai_id']}
      </HistoryLink>
    ),
  },
  bahai_profileimage: { hiddenForTable: true },
  bahai_birthdate: {
    fieldProps: () => ({
      showTime: false,
      canSelectFutureDate: false,
    }),
    isRequired: ({ registration }) => !!registration,
  },
  bahai_name: {
    searchable: true,
    component: ({ data }) => (
      <HistoryLink to={r.requester({ id: data.bahai_inquirerid || 0 })}>{data.bahai_name}</HistoryLink>
    ),
  },
  bahai_contactmethodcode: {
    isRequired: true,
    sortable: false,
    fieldProps: ({ classes, context }) => (context === 'SINGLE_PAGE' ? {} : { className: classes.long }),
    //field: PreferableContactMethod,
  },
  bahai_lastname: { isRequired: true },
  bahai_firstname: { isRequired: ({ registration }) => !!registration },
  bahai_gendercode: { isRequired: ({ registration }) => !!registration },
  bahai_preferredlanguagecode: { isRequired: true },
  bahai_otherlanguage: {
    fieldProps: () => ({
      maxLength: 100,
    }),
  },
  bahai_otherethnicity: {
    fieldProps: () => ({
      maxLength: 25,
    }),
  },
  bahai_cellphone: {
    component: PhoneComponent,
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  bahai_homephone: {
    component: PhoneComponent,
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  bahai_workphone: {
    component: PhoneComponent,
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  bahai_emailaddress1: {
    searchable: true,
    component: EmailComponent,
    isRequired: ({ registration }) => !!registration,
  },
  bahai_emailaddress2: { searchable: true, component: EmailComponent },
  bahai_websiteurl: { component: UrlComponent },
  bahai_socialmediaurl: { component: UrlComponent },
  bahai_rawimportantnote: {
    component: ToolTipTextComponent('requester.bahai_importantnote'),
  },
  bahai_importantnote: {
    hiddenForTable: true,
    type: FieldType.String,
    fieldProps: ({ classes }) => ({ className: classes.long }),
    field: TextEditor,
    component: HTMLComponent('bahai_rawimportantnote'),
  },
  bahai_isoutofusa: {
    hiddenForTable: true,
    hiddenForView: true,
    fieldProps: ({ classes }) => ({ className: classes.long }),
  },
  bahai_mailingisoutofusa: {
    hiddenForTable: true,
    hiddenForView: true,
    fieldProps: ({ classes }) => ({ className: classes.long }),
  },
  bahai_usedifferentmailingaddress: {
    hiddenForTable: true,
    hiddenForView: true,
    fieldProps: ({ classes }) => ({ className: classes.long }),
  },
  bahai_compositeaddress: {
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
    component: AreaComponent,
  },
  bahai_mailingcompositeaddress: {
    isRequired: true,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
    component: AreaComponent,
  },
  bahai_mailingpostalcodeid: {
    isRequired: true,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'bahai_postalcode',
          filters: {
            bahai_countryidname: 'USA',
            bahai_cityid: 'bahai_mailingcityid',
            bahai_stateid: 'bahai_mailingstateid',
          },
        },
      ],
      dependencies: ['bahai_mailingstateid', 'bahai_mailingcityid'],
    }),
  },
  bahai_mailingstateid: {
    isRequired: true,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'bahai_state',
          filters: { bahai_countryidname: 'USA' },
        },
      ],
    }),
  },
  bahai_mailingcityid: {
    isRequired: true,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'bahai_city',
          filters: { bahai_stateid: 'bahai_mailingstateid', bahai_countryidname: 'USA' },
        },
      ],
      dependencies: ['bahai_mailingstateid'],
    }),
  },
  bahai_postalcodeid: {
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'bahai_postalcode',
          filters: { bahai_countryidname: 'USA', bahai_stateid: 'bahai_stateid', bahai_cityid: 'bahai_cityid' },
        },
      ],
      dependencies: ['bahai_stateid', 'bahai_cityid'],
    }),
    isRequired: true,
  },
  bahai_cityid: {
    isRequired: true,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'bahai_city',
          filters: { bahai_countryidname: 'USA', bahai_stateid: 'bahai_stateid' },
        },
      ],
      dependencies: ['bahai_stateid'],
    }),
  },
  bahai_stateid: {
    isRequired: true,
    field: createFormAutocomplete({
      entities: [{ entityName: 'bahai_state', filters: { bahai_countryidname: 'USA' } }],
    }),
  },
  bahai_addressline1: { hiddenForTable: true, isRequired: ({ registration }) => !!registration },
  bahai_addressline2: { hiddenForTable: true },
  bahai_mailingaddressline1: { hiddenForTable: true },
  bahai_mailingaddressline2: { hiddenForTable: true },
  bahai_originationdate: { component: DateOnlyComponent, fieldProps: () => ({ canSelectFutureDate: false }) },
  bahai_additionalnote: {
    component: HTMLComponent('requester.bahai_additionalnote', 60, true),
    fieldProps: ({ classes }) => ({
      className: classes.long,
    }),
  },
  bahai_inquirycomment: {
    fieldProps: ({ classes }) => ({
      className: classes.long,
    }),
    component: AreaTooltipComponent,
  },
  bahai_birthcountryid: { isRequired: ({ registration }) => !!registration },
  bahai_birthcityid: {
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'bahai_city',
          filters: { bahai_countryid: 'bahai_birthcountryid', bahai_stateid: 'bahai_birthstateid' },
        },
      ],
      dependencies: ['bahai_birthstateid', 'bahai_birthcountryid'],
    }),
  },
  bahai_birthstateid: {
    field: createFormAutocomplete({
      entities: [{ entityName: 'bahai_state', filters: { bahai_countryid: 'bahai_birthcountryid' } }],
      dependencies: ['bahai_birthcountryid'],
    }),
  },

  bahai_localitycalculationstatuscode: {
    hiddenForTable: true,
  },
  bahai_permissionnote: {
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
    component: AreaTooltipComponent,
  },
  bahai_declarationdate: { fieldProps: () => ({ canSelectFutureDate: false }) },
  bahai_dateofpermissiongiven: {
    fieldProps: () => ({ canSelectFutureDate: false }),
    isRequired: ({ registration, ...values }) => !!registration && isChild(values),
  },
  bahai_typeofpermission: {
    isRequired: ({ registration, ...values }) => !!registration && isChild(values),
  },
  bahai_parentmemberid: {
    isDisabled: ({ bahai_parentrequesterid }) => !!bahai_parentrequesterid,
    fieldProps: () => parentMemberSearchBy,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'member',
          filters: [
            {
              attribute: 'statecode',
              operator: 'eq',
              value: String(statecode.Active),
            },
            {
              logicOperator: 'or',
              condition: [
                {
                  attribute: 'birthdate',
                  operator: 'le',
                  value: notChildBirthDate.toISOString(),
                },
                {
                  attribute: 'birthdate',
                  operator: 'null',
                },
              ],
            },
            {
              logicOperator: 'or',
              condition: [
                {
                  attribute: 'bahai_agegroupcode',
                  operator: 'ne',
                  value: '200630002',
                },
                {
                  attribute: 'bahai_agegroupcode',
                  operator: 'null',
                },
              ],
            },
          ],
        },
      ],
    }),
  },
  bahai_estimatedagerange: {
    isDisabled: (_, data) => !!data?.bahai_birthdate,
  },
  bahai_parentrequesterid: {
    isDisabled: ({ bahai_parentmemberid }) => !!bahai_parentmemberid,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'bahai_inquirer',
          filters: [
            {
              attribute: 'statecode',
              operator: 'eq',
              value: String(statecode.Active),
            },
            {
              attribute: 'bahai_inquirerid',
              operator: 'ne',
              value: 'RECORD_ID',
            },
            {
              logicOperator: 'or',
              condition: [
                {
                  attribute: 'bahai_birthdate',
                  operator: 'le',
                  value: notChildBirthDate.toISOString(),
                },
                {
                  attribute: 'bahai_birthdate',
                  operator: 'null',
                },
              ],
            },
            {
              logicOperator: 'or',
              condition: [
                {
                  attribute: 'bahai_estimatedagerange',
                  operator: 'not-in',
                  value: [String(age.Junior), String(age.Child)],
                },
                {
                  attribute: 'bahai_estimatedagerange',
                  operator: 'null',
                },
              ],
            },
          ],
        },
      ],
      dependencies: ['RECORD_ID'],
    }),
  },
  'locality.bahai_name': {
    label: <Trans>Locality Name</Trans>,
  },
  'cluster.bahai_name': {
    label: <Trans>Cluster Name</Trans>,
  },
  'cluster.bahai_stageofgrowth': {
    label: <Trans>Stage of Growth</Trans>,
  },
  'locality.bahai_localitytype': {
    label: <Trans>Community Type</Trans>,
  },

  bahai_sourcecode: {
    isDisabled: ({ bahai_sourcecode }) =>
      [inquirerMetadata.source_code.BahaiUs, inquirerMetadata.source_code.BahaiTeachingOrg].includes(
        Number(bahai_sourcecode)
      ),
    isRequired: true,
    fieldProps: ({ values: { bahai_sourcecode } }) => ({
      filterValues: [inquirerMetadata.source_code.BahaiUs, inquirerMetadata.source_code.BahaiTeachingOrg].includes(
        Number(bahai_sourcecode)
      )
        ? undefined
        : (v: [string]) =>
            ![inquirerMetadata.source_code.BahaiUs, inquirerMetadata.source_code.BahaiTeachingOrg].includes(
              Number(v[0])
            ),
    }),
  },
  bahai_sourcedetails: {
    isDisabled: ({ bahai_sourcecode }) =>
      [inquirerMetadata.source_code.BahaiUs, inquirerMetadata.source_code.BahaiTeachingOrg].includes(
        Number(bahai_sourcecode)
      ),
  },
  bahai_seekerid: {
    fieldProps: ({ classes }) => ({ className: classes.long }),
  },
  bahai_historicalseekerresponsedata: {
    fieldProps: ({ classes }) => ({ className: classes.long }),
    component: HTMLComponent(),
  },
  bahai_ethnicity: {
    sortable: false,
  },
  bahai_race: { sortable: false },
  'reminder.bahai_reminderdatetime': {
    component: ({ data, defaultValue }) =>
      data['_bahai_currentreminder_value'] ? (
        <HistoryLink to={r.reminder({ id: data['_bahai_currentreminder_value'] })}>
          {timeAdapter(data, 'reminder.bahai_reminderdatetime', defaultValue)}
        </HistoryLink>
      ) : (
        <>{defaultValue}</>
      ),
  },
  bahai_currentreminder: { hiddenForTable: true, hiddenForView: true },
  bahai_lastexportcreator: { hiddenForTable: true },
};

export const getDetailsConfig: FormConfigGetter<Keys> = (data) => [
  [
    <Trans>Personal Data</Trans>,
    [
      'bahai_contactid',
      'bahai_title',
      'bahai_firstname',
      'bahai_lastname',
      'bahai_nickname',
      'bahai_middlename',
      'bahai_suffix',
      'bahai_gendercode',
      'bahai_preferredlanguagecode',
      ...((data.bahai_preferredlanguagecode === bahai_preferredlanguage.OtherLanguage
        ? ['bahai_otherlanguage']
        : []) as Keys[]),
      'bahai_estimatedagerange',
      'bahai_birthdate',
      'bahai_birthcountryid',
      'bahai_birthstateid',
      'bahai_birthcityid',
      'bahai_race',
      'bahai_ethnicity',
      ...((data.bahai_ethnicity?.includes(String(inquirerMetadata.bahai_ethnicity.OtherEthnicity))
        ? ['bahai_otherethnicity']
        : []) as Keys[]),
      'bahai_importantnote',
      'bahai_additionalnote',
      'bahai_inquirycomment',
      'bahai_originationdate',
      'bahai_declarationdate',
      'bahai_sourcecode',
      'bahai_sourcedetails',
      'bahai_mergeddatetime',
      'bahai_mergedwith',
    ] as Keys[],
  ],
  [
    <Trans>Contact Data</Trans>,
    [
      'bahai_contactmethodcode',
      'bahai_emailaddress1',
      'bahai_emailaddress2',
      'bahai_cellphone',
      'bahai_workphone',
      'bahai_homephone',
      'bahai_socialmediaurl',
      'bahai_websiteurl',
    ] as Keys[],
  ],
  [
    <Trans>Main Address</Trans>,
    [
      'bahai_isoutofusa',
      'bahai_compositeaddress',
      'bahai_localityid',
      'locality.bahai_name',
      'locality.bahai_localitytype',
      'bahai_clusterid',
      'cluster.bahai_name',
      'cluster.bahai_stageofgrowth',
      'bahai_regionid',
    ] as Keys[],
  ],

  [
    <Trans>Mailing Address</Trans>,
    [
      'bahai_usedifferentmailingaddress',
      ...(data.bahai_usedifferentmailingaddress
        ? [
            ...(data.bahai_mailingisoutofusa
              ? (['bahai_mailingcompositeaddress'] as Keys[])
              : (['bahai_mailingcompositeaddress'] as Keys[])),
          ]
        : (['bahai_mailingcompositeaddress'] as Keys[])),
    ] as Keys[],
  ],
  [
    <Trans>Parent Permission</Trans>,
    [
      'bahai_parentrequesterid',
      'bahai_parentmemberid',
      'bahai_dateofpermissiongiven',
      'bahai_typeofpermission',
      'bahai_permissionnote',
    ] as Keys[],
    (data) => !isChild(data),
  ],

  [
    <Trans>Historical Seeker Response System Data</Trans>,
    ['bahai_seekerid', 'bahai_historicalseekerresponsedata'],
    () => true,
  ],
];

export const getFormConfig: FormConfigGetter<Keys> = (data) => [
  [
    <Trans>Personal Data</Trans>,
    [
      'bahai_title',
      'bahai_firstname',
      'bahai_lastname',
      'bahai_nickname',
      'bahai_middlename',
      'bahai_suffix',
      'bahai_gendercode',
      'bahai_preferredlanguagecode',
      ...((Number(data.bahai_preferredlanguagecode) === bahai_preferredlanguage.OtherLanguage
        ? ['bahai_otherlanguage']
        : []) as Keys[]),
      'bahai_birthdate',
      ...((data.bahai_birthdate ? [] : ['bahai_estimatedagerange']) as Keys[]),
      'bahai_birthcountryid',
      'bahai_birthstateid',
      'bahai_birthcityid',
      'bahai_race',
      'bahai_ethnicity',
      ...((data.bahai_ethnicity?.includes(String(inquirerMetadata.bahai_ethnicity.OtherEthnicity))
        ? ['bahai_otherethnicity']
        : []) as Keys[]),
      'bahai_declarationdate',
      'bahai_importantnote',
      'bahai_sourcecode',
      'bahai_sourcedetails',
    ] as Keys[],
  ],
  [
    <Trans>Contact Data</Trans>,
    [
      'bahai_emailaddress1',
      'bahai_emailaddress2',
      'bahai_cellphone',
      'bahai_workphone',
      'bahai_homephone',
      'bahai_socialmediaurl',
      'bahai_websiteurl',
      'bahai_contactmethodcode',
    ] as Keys[],
  ],
  [
    <Trans>Main Address</Trans>,
    [
      'bahai_isoutofusa',
      ...(data.bahai_isoutofusa
        ? (['bahai_compositeaddress'] as Keys[])
        : ([
            'bahai_postalcodeid',
            'bahai_stateid',
            'bahai_cityid',
            'bahai_addressline1',
            'bahai_addressline2',
          ] as Keys[])),
    ] as Keys[],
  ],
  [
    <Trans>Mailing Address</Trans>,
    [
      'bahai_usedifferentmailingaddress',
      ...((data.bahai_usedifferentmailingaddress
        ? [
            'bahai_mailingisoutofusa',
            ...((data.bahai_mailingisoutofusa
              ? ['bahai_mailingcompositeaddress']
              : [
                  'bahai_mailingpostalcodeid',
                  'bahai_mailingstateid',
                  'bahai_mailingcityid',
                  'bahai_mailingaddressline1',
                  'bahai_mailingaddressline2',
                ]) as Keys[]),
          ]
        : []) as Keys[]),
    ] as Keys[],
  ],
  [
    <Trans>Parent Permission</Trans>,
    [
      'bahai_parentrequesterid',
      'bahai_parentmemberid',
      'bahai_dateofpermissiongiven',
      'bahai_typeofpermission',
      'bahai_permissionnote',
    ] as Keys[],
  ],
];

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_firstname: rules.compose([
    rules.maxLength(30),
    rules.conditionalRequired(({ registration }) => !!registration),
  ]),
  bahai_middlename: rules.maxLength(25),
  bahai_lastname: rules.compose([rules.required, rules.maxLength(25)]),
  bahai_nickname: rules.maxLength(100),
  bahai_preferredlanguagecode: rules.required,
  bahai_contactmethodcode: rules.required,
  bahai_gendercode: rules.conditionalRequired(({ registration }) => !!registration),

  bahai_emailaddress1: rules.compose([
    rules.email,
    rules.maxLength(200),
    rules.conditionalRequired((values) => values.bahai_emailaddress2, <Trans>Please add primary email first</Trans>),
    rules.conditionalRequired(({ registration }) => !!registration),
  ]),
  bahai_emailaddress2: rules.compose([
    rules.email,
    rules.maxLength(200),
    rules.alternativeEmail('bahai_emailaddress1'),
  ]),

  bahai_cellphone: rules.phone,
  bahai_workphone: rules.phone,
  bahai_homephone: rules.phone,

  bahai_socialmediaurl: rules.maxLength(200),
  bahai_websiteurl: rules.maxLength(200),

  bahai_postalcodeid: rules.compose([
    rules.groupRequired([
      'bahai_postalcodeid',
      'bahai_stateid',
      'bahai_cityid',
      'bahai_addressline1',
      'bahai_addressline2',
    ]),
    rules.conditionalRequired((values) => !values.bahai_isoutofusa),
  ]),
  bahai_stateid: rules.compose([
    rules.groupRequired([
      'bahai_postalcodeid',
      'bahai_stateid',
      'bahai_cityid',
      'bahai_addressline1',
      'bahai_addressline2',
    ]),
    rules.conditionalRequired((values) => !values.bahai_isoutofusa),
  ]),
  bahai_cityid: rules.compose([
    rules.groupRequired([
      'bahai_postalcodeid',
      'bahai_stateid',
      'bahai_cityid',
      'bahai_addressline1',
      'bahai_addressline2',
    ]),
    rules.conditionalRequired((values) => !values.bahai_isoutofusa),
  ]),
  bahai_addressline1: rules.compose([
    rules.maxLength(40),
    rules.conditionalRequired(({ registration }) => !!registration),
  ]),
  bahai_addressline2: rules.compose([rules.maxLength(40)]),
  bahai_compositeaddress: rules.conditionalRule((values) => values.bahai_compositeaddress, rules.length(10, 2000)),

  bahai_mailingpostalcodeid: rules.conditionalRule(
    (values) => values.bahai_usedifferentmailingaddress && !values.bahai_mailingisoutofusa,
    rules.required
  ),
  bahai_mailingstateid: rules.conditionalRule(
    (values) => values.bahai_usedifferentmailingaddress && !values.bahai_mailingisoutofusa,
    rules.required
  ),
  bahai_mailingcityid: rules.conditionalRule(
    (values) => values.bahai_usedifferentmailingaddress && !values.bahai_mailingisoutofusa,
    rules.required
  ),

  bahai_mailingaddressline1: rules.compose([rules.maxLength(40)]),
  bahai_mailingaddressline2: rules.compose([rules.maxLength(40)]),
  bahai_mailingcompositeaddress: rules.conditionalRule(
    (values) => values.bahai_usedifferentmailingaddress && values.bahai_mailingisoutofusa,
    rules.compose([rules.required, rules.length(10, 2000)])
  ),
  bahai_birthdate: rules.compose([rules.pastDate, rules.conditionalRequired(({ registration }) => !!registration)]),
  bahai_birthcountryid: rules.conditionalRequired(({ registration }) => !!registration),
  bahai_importantnote: rules.maxLengthEscapeTags(2000),
  bahai_otherethnicity: rules.maxLength(25),
  bahai_otherlanguage: rules.maxLength(100),
  bahai_typeofpermission: rules.conditionalRequired(
    ({ registration, ...values }) => !!registration && isChild(values),
    <Trans>Not required to send to an LSA</Trans>
  ),
  bahai_dateofpermissiongiven: rules.compose([
    rules.conditionalRequired(
      ({ registration, ...values }) => !!registration && isChild(values),
      <Trans>Not required to send to an LSA</Trans>
    ),
    rules.pastDate,
  ]),
  bahai_isoutofusa: rules.conditionalRule(
    ({ registration }) => !!registration,
    (value) => (value ? <Trans>International registration is Impossible</Trans> : undefined)
  ),
  bahai_declarationdate: rules.compose([rules.pastDate]),
  bahai_sourcecode: rules.required,
  bahai_sourcedetails: rules.maxLength(100),
  bahai_parentrequesterid: rules.notCurrentId(<Trans>Current Requester can't be selected as Parent</Trans>),
};

export const isActive = (data: Record<Keys, any>) => Number(data.statecode) === inquirerMetadata.statecode.Active;

export const isDoNotContact = (data: Record<Keys, any>) =>
  Number(data.bahai_statuscode) === inquirerMetadata.bahai_inquirerstatus.DoNotContact;

const verification = (data: Record<Keys, any>) =>
  (Number(data.bahai_statuscode) === inquirerMetadata.bahai_inquirerstatus.Verified &&
    Number(data.bahai_statusdetailscode) ===
      inquirerMetadata.bahai_inquirerstatusdetails.PendingEnrollmentwithMembership) ||
  (Number(data.bahai_statuscode) === inquirerMetadata.bahai_inquirerstatus.NeedsVerification &&
    Number(data.bahai_statusdetailscode) === inquirerMetadata.bahai_inquirerstatusdetails.PendingEnrollmentwithLSA);

export const isLocalityCalculation = (data: Record<string, any>) =>
  [
    inquirerMetadata.bahai_localitycalculationstatuscode.InProgress,
    inquirerMetadata.bahai_localitycalculationstatuscode.PendingCalculation,
  ].includes(Number(data.bahai_localitycalculationstatuscode));

export const LocalityWarning = (
  <Trans>Locality is being calculated. You can proceed working with record after Locality is determined.</Trans>
);

export const ClasterWarning = (
  <Trans>Cluster is being calculated. You can proceed working with record after Cluster is determined.</Trans>
);

export const isNotEditable = (data: Record<Keys, any>, checkForIsActive = true) => {
  switch (true) {
    case isLocalityCalculation(data):
      return LocalityWarning;
    case verification(data):
      return <Trans>Requester is in Enrollment</Trans>;
    case !isActive(data) && checkForIsActive:
      return <Trans>Requester State is Inactive</Trans>;
    default:
      return false;
  }
};

export const statusFields: Required<TSinglePage>['statusFields'] = () => [
  'bahai_inquirertypecode',
  'bahai_statuscode',
  'bahai_statusdetailscode',
  'reminder.bahai_reminderdatetime',
];

export const isNotRemovable = (data: Record<Keys, any>) => {
  switch (true) {
    case Number(data.bahai_statuscode) !== inquirerMetadata.bahai_inquirerstatus.Archive:
      return <Trans>Requester Status must be Archived to delete it</Trans>;
    case isLocalityCalculation(data):
      return LocalityWarning;
    default:
      return false;
  }
};

export const isConfirmationMessageNeeded = () => true;
export const isConfirmationMessageRequired = () => true;

const clearSensitiveFields = ['bahai_addressline1'];
const changeSensitiveFields = [
  'bahai_postalcodeid',
  'bahai_stateid',
  'bahai_cityid',
  'bahai_addressline1',
  ...clearSensitiveFields,
];

export const getWarningsImprover = (businessunittype: string) =>
  getWarningImprover(changeSensitiveFields, (values, setWarnings) => {
    if (
      Number(businessunittype) === bahai_type.Other ||
      changeSensitiveFields.some((key) => !!values[key]) ||
      clearSensitiveFields.some((key) => Object.keys(values).includes(key))
    ) {
      setWarnings([<Trans>Please note that you may lose access to the Record after saving.</Trans>]);
    }
  });
