import { useCallback, useContext, useMemo } from 'react';
import { PortalFilter } from 'lib';
import { TableContext } from 'providers/TableProvider';
import { UserSettingsContext } from 'providers/UserSettingsProvider';
import { ScreenContext } from 'providers/ScreenProvider';

export type Direction = 'asc' | 'desc';

export type Sort = {
  name: string;
  direction: Direction;
};

export const sortToString = (sort: Sort[]) =>
  sort.map(({ name, direction }) => `${name} ${direction}`).join(',') || undefined;

const PAGINATION_NUMBERS_QUANTITY = 5;

export const useFilters = () => {
  const {
    queryParams: { filters },
    updateQueryParams,
  } = useContext(TableContext);
  const apply = useCallback((filters: PortalFilter[]) => updateQueryParams({ page: 1, filters }), [updateQueryParams]);

  const addFilter = useCallback(
    (filter: PortalFilter) => {
      const newFilters = filters.filter((v) => v.attribute !== filter.attribute).concat(filter);
      apply(newFilters);
    },
    [apply, filters]
  );

  const removeFilter = useCallback(
    (field: string) => {
      const newFilters = filters.filter((v) => v.attribute !== field);
      if (newFilters.length !== filters.length) {
        apply(newFilters);
      }
    },
    [apply, filters]
  );

  const clearOtherFilters = useCallback(
    (field?: string) => updateQueryParams({ filters: field ? filters.filter((v) => v.attribute === field) : [] }),
    [filters, updateQueryParams]
  );

  return { filters, addFilter, removeFilter, clearOtherFilters, apply };
};

export const usePagination = (pages = 1) => {
  const { queryParams: { page } = { page: 1 }, updateQueryParams } = useContext(TableContext);

  const { pageSize, setPageSize } = useContext(UserSettingsContext);

  const { isMobile } = useContext(ScreenContext);

  const pagesOnList = useMemo(() => (isMobile ? 3 : PAGINATION_NUMBERS_QUANTITY), [isMobile]);

  const pagesList = useMemo(() => {
    if (pages <= pagesOnList) return Array.from(Array(pages).keys()).map((v) => v + 1);
    const half = Math.floor(pagesOnList / 2);
    let firstNumber = 1;
    if (page > half) firstNumber = pages - page >= half ? page - half : pages - pagesOnList + 1;
    return Array.from(Array(pagesOnList).keys()).map((v) => v + firstNumber);
  }, [page, pages, pagesOnList]);

  const goToPage = useCallback((page: number) => updateQueryParams({ page }), [updateQueryParams]);

  const firstPage = useCallback(() => goToPage(1), [goToPage]);
  const lastPage = useCallback(() => goToPage(pages), [goToPage, pages]);

  return { pagesList, page, firstPage, lastPage, goToPage, pageSize, setPageSize };
};

export const useSorting = () => {
  const {
    queryParams: { sorting },
    updateQueryParams,
  } = useContext(TableContext);
  const apply = useCallback((sorting: Sort[]) => updateQueryParams({ sorting }), [updateQueryParams]);

  const addSort = useCallback(
    (sortParams: Sort) => {
      const index = sorting.findIndex(({ name }) => sortParams.name === name);
      if (index > -1) {
        const newSorting = sorting.filter((v) => v.name !== sortParams.name);
        newSorting.splice(index, 0, sortParams);
        apply(newSorting);
      } else if (sorting.length < 5) {
        apply([...sorting, sortParams]);
      }
    },
    [apply, sorting]
  );

  const sortingRecord = useMemo(
    () =>
      sorting.reduce(
        (acc, { name, direction }, index) => ({ ...acc, [name]: { direction, index } }),
        {} as Record<string, { direction: Direction; index: number }>
      ),
    [sorting]
  );

  const clearByName = useCallback(
    (name: string, clearOther = false) => {
      apply(sorting.filter((v) => (clearOther ? name === v.name : v.name !== name)));
    },
    [apply, sorting]
  );

  const sortByName = useCallback(
    (name: string, targetSorting?: Direction) => {
      addSort({ name, direction: targetSorting || (sortingRecord[name]?.direction === 'asc' ? 'desc' : 'asc') });
    },
    [sortingRecord, addSort]
  );

  return { sorting: sortingRecord, sortByName, sortingArray: sorting, addSort, clearByName, apply };
};
