import { ContextType, Component } from 'react';
import { ErrorPage } from './ErrorPage';
import { ReactComponent as Error500Icon } from './icons/Error500.svg';
import { ReactComponent as Error400Icon } from './icons/Error400.svg';
import { ReactComponent as Error401Icon } from './icons/Error401.svg';
import { ReactComponent as Error404Icon } from './icons/Error404.svg';
import { ReactComponent as ErrorSWWIcon } from './icons/SomethingWentWrong.svg';
import { Trans } from 'react-i18next';
import { ErrorContext, ServerErrorType } from 'providers/ErrorProvider';

class ErrorBoundary extends Component<{
  children: (showMenu: boolean, error: JSX.Element | undefined, showHeader: boolean) => JSX.Element;
  background: string;
}> {
  static contextType = ErrorContext;

  constructor(props: any) {
    super(props);
  }

  componentDidCatch() {
    if (!(this.context as ContextType<typeof ErrorContext>).error) {
      (this.context as ContextType<typeof ErrorContext>).addError(ServerErrorType.ErrorDefault);
    }
  }

  static getDerivedStateFromError() {
    return {};
  }

  render() {
    const errorsWithMenu = [ServerErrorType.Errr400, ServerErrorType.Errr500];

    const contextError = (this.context as ContextType<typeof ErrorContext>).error;

    let error = undefined as JSX.Element | undefined;
    const showMenu = !!contextError && errorsWithMenu.includes(contextError);
    const showHeader = contextError !== ServerErrorType.Errr401;
    const bg = showMenu ? this.props.background : undefined;

    switch (contextError) {
      case ServerErrorType.Errr400:
        error = (
          <ErrorPage
            title={<Trans>Bad Request</Trans>}
            description={<Trans>The server can’t or won’t process the request</Trans>}
            CustomIcon={Error400Icon}
            showRefresh={true}
            background={bg}
          />
        );
        break;
      case ServerErrorType.Errr401:
        error = (
          <ErrorPage
            title={<Trans>Unauthorized</Trans>}
            description={<Trans>The page is not publicly available</Trans>}
            CustomIcon={Error401Icon}
            showRefresh={true}
            background={bg}
          />
        );
        break;
      case ServerErrorType.Errr403:
      case ServerErrorType.Errr404:
        error = (
          <ErrorPage
            title={<Trans>You don't have access to this record</Trans>}
            description={
              <Trans>The requested record was not found or you do not have sufficient permissions to view it</Trans>
            }
            CustomIcon={Error404Icon}
            background={bg}
          />
        );
        break;
      case ServerErrorType.Errr500:
        error = <ErrorPage title={<Trans>Internal Server Error</Trans>} showRefresh={true} CustomIcon={Error500Icon} />;
        break;
      case ServerErrorType.ErrorDefault:
        error = (
          <ErrorPage
            title={<Trans>Something went wrong</Trans>}
            description={<Trans>Sorry, something went wrong there. Please try again</Trans>}
            CustomIcon={ErrorSWWIcon}
            showRefresh={true}
            background={bg}
          />
        );
        break;
    }

    return this.props.children(error ? showMenu : true, error, showHeader);
  }
}

export default ErrorBoundary;
