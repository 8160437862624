import ListPage, { TListPage } from 'components/ListPage';
import { useTranslation } from 'react-i18next';
import * as baseConfig from 'schemas/resourceEvents';

const EventsTab = (props: Partial<TListPage>) => {
  const { t } = useTranslation();

  return (
    <ListPage
      entityName="invitedResource"
      placeholderText={t('There are not any Events yet')}
      systemView={'Default For Resource'}
      displayViews={false}
      {...baseConfig}
      {...props}
      allowRowClick={false}
    />
  );
};

export default EventsTab;
