import ListPage from 'components/ListPage';
import * as baseConfig from 'schemas/contact';
import { useCallback } from 'react';
import { bahai_preferredlanguage } from 'config/EntityMetadata/common';
import { useSendEmailFromList, SendEmailValidateFunction } from 'schemas/email/actions';
import { contactToRecipientMapper } from 'pages/Event/components/ParicipantTab';
import { Action } from 'components/Actions';
import { statecode } from 'config/EntityMetadata/bahai_contact_ims';
import { Trans } from 'react-i18next';

export const useContactInitialValues = () => {
  return useCallback(async () => {
    return {
      bahai_preferredlanguagecode: bahai_preferredlanguage.English,
    };
  }, []);
};

export const getValidateContacsForEmailReceive =
  (prefix = ''): SendEmailValidateFunction =>
  (data) => {
    if (Number(data[`${prefix}statecode`]) === statecode.Inactive)
      return {
        label: data[`${prefix}bahai_name`],
        content: <Trans>Contact State is Inactive</Trans>,
      };
    if (!data[`${prefix}bahai_emailaddress1`] && !data[`${prefix}bahai_emailaddress2`]) {
      return {
        label: data[`${prefix}bahai_name`],
        content: <Trans>Contact doesn’t have an email address</Trans>,
      };
    }
  };

const ContactList = () => {
  const { actions: sendEmailActions, content } = useSendEmailFromList(
    'contact',
    getValidateContacsForEmailReceive(),
    'contact',
    contactToRecipientMapper
  );

  const getActions = useCallback(
    (baseActions: Action[]) => {
      return [...baseActions, ...sendEmailActions];
    },
    [sendEmailActions]
  );
  const { validate } = baseConfig.useContactMethodValidation();
  const getInitialValues = useContactInitialValues();

  return (
    <>
      {content}
      <ListPage
        entityName="contact"
        isExportAllowed
        {...{
          ...baseConfig,
          validate,
          getInitialValues,
          getActions,
        }}
      />
    </>
  );
};

export default ContactList;
