import { useMemo, useCallback } from 'react';
import { Step } from 'react-joyride';
import tableClasses from 'components/Table/table.module.scss';
import { useTranslation } from 'react-i18next';
import panelColumnsClasses from 'components/ListPage/components/ColumnsPanel/panel.module.scss';
import selectClasses from 'components/Select/select.module.scss';
import singlePageClasses from 'components/SinglePage/singlePage.module.scss';
import listHeaderClasses from 'components/ListPage/components/Header/header.module.scss';

const waitForElement = (selector: string, waitForHideElement = false) => {
  let interval: ReturnType<typeof setInterval>;
  return new Promise<boolean>((resolve) => {
    interval = setInterval(() => {
      if (!waitForHideElement) {
        if (document.querySelector(selector)) {
          clearInterval(interval);
          resolve(true);
        }
      } else {
        if (!document.querySelector(selector)) {
          clearInterval(interval);
          resolve(true);
        }
      }
    }, 500);
  });
};

const waitForLink = (link: string) => {
  let interval: ReturnType<typeof setInterval>;
  return new Promise<boolean>((resolve) => {
    interval = setInterval(() => {
      if (window.location.pathname.includes(link)) {
        clearInterval(interval);
        resolve(true);
      }
    }, 500);
  });
};

export type OnboardingStep = Step & {
  disableWindowClicks?: boolean;
  removePointerEventsOnClose?: boolean;
  name?: string;
  before?: (hideSpotLight: () => void) => Promise<boolean | undefined | number>;
  end?: () => void;
};

export const useSteps = () => {
  const { t } = useTranslation();

  const defaultStepProps = useMemo(
    () => ({
      disableBeacon: true,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: false,
      showProgress: false,
      hideBackButton: true,
      showSkipButton: false,
    }),
    []
  );

  const isInteractionsTabEnable = useCallback(() => {
    const interactionsTabs = JSON.parse(localStorage.getItem('tabs') as string)?.inquiry?.find(
      (item: Record<string, any>) => item.label === `Interactions`
    );
    return interactionsTabs === undefined || interactionsTabs.visible === true;
  }, []);

  const arrayOfLinks = useMemo(() => [`#searchIcon > svg:nth-child(1) > path:nth-child(1)`], []);
  const mouseDownInTheEnd = (event: Event) => {
    event.stopImmediatePropagation();
  };

  const selectClickableElements = useCallback(
    () => [
      document.querySelector(`.${listHeaderClasses.withBorder}`) as HTMLElement,
      document.querySelector(`.${singlePageClasses.headerBox}`) as HTMLElement,
      document.querySelector(`.${singlePageClasses.tabBtnWrapper}`) as HTMLElement,
      ...document.querySelectorAll<HTMLElement>(`.${singlePageClasses.blockWrapper}`),
    ],
    []
  );

  const steps: OnboardingStep[] = useMemo(
    () => [
      {
        ...defaultStepProps,
        content: ``,
        target: `#technical_id_for_tour`,
      },
      {
        ...defaultStepProps,
        content: t('Click here to see Inquiries received'),
        target: `#inquiryList`,
        spotlightClicks: true,
        placement: 'right',
        hideFooter: true,
        spotlightPadding: -2,
        styles: {
          spotlight: {
            borderRadius: '10px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('Click on an Inquiry ID number to see details of that particular Inquiry'),
        target: `div[data-is-row-active="true"]`,
        before: async (hideSpotLight) => {
          await waitForLink(`/inquiry`);
          await waitForElement(`#table_body, #loader>svg`);
          const inquiryRecord = document.querySelector(`div[data-is-row-active="true"]`) as HTMLElement;
          if (inquiryRecord) {
            const coordsOfInquiryColumn = inquiryRecord?.getBoundingClientRect() as DOMRect;
            const documentElement = document.querySelector(`.${tableClasses.table}`) as HTMLElement;
            const isAreIntoScreen =
              coordsOfInquiryColumn?.top >= 0 &&
              coordsOfInquiryColumn?.left >= 0 &&
              coordsOfInquiryColumn?.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
              coordsOfInquiryColumn?.right <= (window.innerWidth || document.documentElement.clientWidth);
            if (!isAreIntoScreen) {
              documentElement.scroll({
                top: coordsOfInquiryColumn?.top - 200,
                left: coordsOfInquiryColumn?.left - 200,
                behavior: 'smooth',
              });
            }

            (document.querySelector(`a[href*='/inquiry/']`) as HTMLElement).addEventListener('click', hideSpotLight);
            return true;
          } else {
            return 10;
          }
        },
        spotlightClicks: true,
        placement: 'bottom',
        hideFooter: true,
        disableScrolling: false,
      },
      {
        ...defaultStepProps,
        content: t('Scroll down to see additional information'),
        target: `#single_page_root`,
        before: async () => {
          await waitForElement(`#single_page_data`);
          const toDisableClics = selectClickableElements();
          toDisableClics.forEach((x) => (x.style.pointerEvents = 'none'));
          return true;
        },
        spotlightClicks: true,
        placement: 'bottom',
        hideFooter: true,
        removePointerEventsOnClose: true,
      },
      {
        ...defaultStepProps,
        content: t('Click on Interactions to log follow-up'),
        target: "[class*='tabBtn'] [href*='interaction']",
        before: async () =>
          new Promise((resolve) => {
            const handleScroll = (e: Event) => {
              const target = e.target as HTMLElement;
              if (Math.floor(target.scrollHeight - target.scrollTop) <= target.clientHeight) {
                resolve(true);
              }
            };
            const dataPanel = document.querySelector(`#single_page_data`) as HTMLElement;
            if (dataPanel?.scrollHeight <= dataPanel?.clientHeight && isInteractionsTabEnable()) resolve(true);
            dataPanel?.addEventListener(`scroll`, handleScroll);
          }),

        spotlightClicks: true,
        placement: 'bottom',
        hideFooter: true,
        spotlightPadding: 5,
      },
      {
        ...defaultStepProps,
        content: t('Click on +Interaction to create a new Interaction for the current Inquiry'),
        target: '#action_create',
        before: async () => {
          const toEnableClicks = selectClickableElements();
          toEnableClicks.forEach((x) => (x.style.pointerEvents = ''));
          await waitForElement(`#action_create`);
          (document.querySelector(`#single_page_root`) as HTMLElement).style.width = '100%';
          return new Promise<boolean>((resolve) => {
            setTimeout(() => {
              resolve(true);
            }, 1000);
          });
        },
        spotlightClicks: true,
        placement: 'bottom',
        hideFooter: true,
        disableScrollParentFix: true,

        spotlightPadding: 5,
      },
      {
        ...defaultStepProps,
        content: t(
          'To create new Interaction fill in all required fields on the form. Click Next to skip this step for now'
        ),
        target: `#panel`,
        before: async () => waitForElement(`#panel`),
        spotlightClicks: true,
        disableOverlay: false,
        placement: `left`,
        spotlightPadding: -5,
      },
      {
        ...defaultStepProps,
        content: t('To create new record use Save. Click Next to skip this step for now'),
        target: `#button_save`,
        styles: {
          spotlight: {
            borderRadius: '8px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('Close the form to cancel creation of the new Interaction'),
        target: `#button_panel_close`,
        spotlightClicks: true,
        placement: 'bottom',
        hideFooter: true,
      },
      {
        ...defaultStepProps,
        content: t('Click Edit if you want to make any changes to the record. Click Next to skip this step for now'),
        target: '#action_edit',
        before: async (hideSpotLight) => {
          (document.querySelector(`#button_panel_close`) as HTMLElement).addEventListener('click', hideSpotLight);
          return waitForElement('#panel', true);
        },
        spotlightPadding: 5,
        name: `InteractionsEdit`,
      },
      {
        ...defaultStepProps,
        content: t('Click here to Change the Status'),
        target: '#action_changeStatus',

        spotlightClicks: true,
        disableScrollParentFix: true,
        placement: 'bottom',
        hideFooter: true,
        spotlightPadding: 5,
      },
      {
        ...defaultStepProps,
        content: t('Cancel status changes'),
        target: `#button_changeStatus_cancel`,
        before: async () => waitForElement('#button_changeStatus_cancel'),
        spotlightClicks: true,
        placement: 'bottom',
        disableScrollParentFix: true,
        hideFooter: true,
        spotlightPadding: 8,
        styles: {
          spotlight: {
            borderRadius: '8px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t(
          'Click here to see all Seekers, Registrants and records for other types of individuals, called “Requesters”'
        ),
        target: `#requesterList`,
        before: async (hideSpotLight) => {
          (document.querySelector(`#button_changeStatus_cancel`) as HTMLElement).addEventListener(
            'click',
            hideSpotLight
          );
          return waitForElement('#button_changeStatus_cancel', true);
        },
        spotlightClicks: true,
        disableScrollParentFix: true,
        placement: 'right',
        hideFooter: true,
        spotlightPadding: -2,
        styles: {
          spotlight: {
            borderRadius: '10px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('Here you can send an Email to selected Requesters. Click Next to skip this step for now'),
        target: '#action_sendToSelected',
        spotlightPadding: 5,
        before: async () => {
          await waitForLink(`/requester`);
          await waitForElement(`#list_page_footer`).then(() => {
            const checkbox1 = document.querySelector(
              `#table_body>div:nth-child(1) .${tableClasses.rowSticky}`
            ) as HTMLInputElement;
            const checkbox3 = document.querySelector(
              `#table_body>div:nth-child(3) .${tableClasses.rowSticky}`
            ) as HTMLInputElement;
            const checkbox4 = document.querySelector(
              `#table_body>div:nth-child(4) .${tableClasses.rowSticky}`
            ) as HTMLInputElement;
            if (checkbox1) setTimeout(() => checkbox1.click(), 400);
            if (checkbox3) setTimeout(() => checkbox3.click(), 500);
            if (checkbox4) setTimeout(() => checkbox4.click(), 600);
          });
          setTimeout(() => {
            const moreIcon = document.querySelector<HTMLInputElement>(`#moreIcon`);
            if (moreIcon) {
              moreIcon?.click();
            }
          }, 500);
          return waitForElement(`#action_sendToSelected`);
        },
      },
      {
        ...defaultStepProps,
        content: t('See info about Resources here. Most Resources have access to IMS'),
        target: `#resourceList`,
        spotlightClicks: true,
        disableScrollParentFix: true,
        placement: 'bottom',
        hideFooter: true,
        spotlightPadding: -2,
        styles: {
          spotlight: {
            borderRadius: '10px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('See info about Contacts here. Contacts do not have access to IMS'),
        target: `#contactList`,
        before: async () => waitForLink('/resource'),
        spotlightClicks: true,
        placement: 'bottom',
        hideFooter: true,
        spotlightPadding: -2,
        styles: {
          spotlight: {
            borderRadius: '10px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t(
          'Click Resource Site to see Inquiry Services Resources for training materials and other helpful information. Click Next to skip this step for now'
        ),
        target: `#resourceLink`,
        before: async () =>
          waitForLink('/contact').then(() => {
            const link = document.querySelector(`#inquiryList`);
            (link as HTMLElement).click();
            return true;
          }),
        spotlightPadding: -2,
        spotlightClicks: false,
        styles: {
          spotlight: {
            borderRadius: '10px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('To Search, go here. Click Next to skip this step for now'),
        target: `#searchGroup`,
        placement: `left`,
        spotlightPadding: 3,
      },
      {
        ...defaultStepProps,
        content: t('Hover over the (!) icon to see Search options'),
        target: `#searchIcon`,
        spotlightPadding: 20,
        before: async () => {
          const hover = document.querySelector(`#searchIcon`) as HTMLInputElement;
          const warningIcon = document.querySelector(arrayOfLinks[0]) as HTMLInputElement;
          const eventOver = new MouseEvent('mouseover');
          hover.dispatchEvent(eventOver);
          warningIcon.setAttribute('fill', `var(--themePrimary)`);
          return true;
        },
        placement: 'left',
        spotlightClicks: false,
        offset: 100,
        styles: {
          options: {
            zIndex: 1,
          },
        },
      },

      {
        ...defaultStepProps,
        content: t('To clear the Search press the X'),
        target: `#dismissIcon`,
        spotlightPadding: 20,
        before: async () => {
          const hover = document.querySelector(`#searchIcon`) as HTMLInputElement;
          const warningIcon = document.querySelector(arrayOfLinks[0]) as HTMLInputElement;
          const eventOut = new MouseEvent('mouseleave');
          hover.dispatchEvent(eventOut);
          warningIcon.setAttribute('fill', ``);
          const input = document.querySelector(`#searchGroup>input`);
          const setter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
          setter?.call(input, 'Smith');
          const event = new Event('input', { bubbles: true });
          input?.dispatchEvent(event);
          return true;
        },
        placement: 'bottom',
        spotlightClicks: true,
        hideFooter: true,
      },

      {
        ...defaultStepProps,
        content: t('Click here to set up filters. Click Next to skip this step for now'),
        target: '#action_filters',
        before: async () => {
          return waitForElement('input[value="Smith"]', true);
        },
        spotlightPadding: -1,
      },
      {
        ...defaultStepProps,
        content: t('Please select Default view to continue'),
        target: `#first_system_view`,
        before: async (hideSpotLight) => {
          if (!document.querySelector('#column_bahai_typecode')) {
            setTimeout(() => document.querySelector<HTMLElement>(`#button_view`)?.click());
            await waitForElement(`#first_system_view`);
            (document.querySelector(`#first_system_view`) as HTMLElement).addEventListener('click', hideSpotLight);
            return true;
          } else {
            return 1;
          }
        },
        spotlightPadding: 5,
        disableWindowClicks: true,
        spotlightClicks: true,
        hideFooter: true,
      },
      {
        ...defaultStepProps,
        content: t('Click on the Dropdown Arrow to the right of any Column header'),
        target: `#column_bahai_typecode > div:nth-child(2) > svg:nth-child(1)`,
        before: async () => waitForElement(`#first_system_view`, true),
        spotlightClicks: true,
        placement: 'bottom',
        hideFooter: true,
        spotlightPadding: 6,
        styles: {
          spotlight: {
            borderRadius: '8px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('And click here to set up filters'),
        target: '#bahai_typecode_filterBy',
        before: async () => waitForElement(`#bahai_typecode_filterBy`),
        spotlightClicks: true,
        placement: 'right',
        hideFooter: true,
        spotlightPadding: 0,
        disableWindowClicks: true,
        styles: {
          options: {
            zIndex: 1000,
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('Enter what you are filtering for and press Apply'),
        target: `#button_filter_apply`,
        before: async (hideSpotLight) => {
          (document.querySelector(`#bahai_typecode_filterBy`) as HTMLElement).addEventListener('click', hideSpotLight);
          return waitForElement('#button_filter_apply').then(() => {
            const select = document.querySelector<HTMLElement>(`.select_wrapper:nth-child(1) > div:nth-child(1)`);
            select?.click();
            setTimeout(() => {
              const items = document.querySelectorAll(`#option-list`);
              const item = items[0].firstChild;

              if (!item?.parentElement?.querySelector(`.${selectClasses.selected}`)) {
                const event = new Event('mousedown', { cancelable: true, bubbles: true });
                item?.dispatchEvent(event);
              }
              select?.click();
            }, 100);
            return true;
          });
        },
        spotlightClicks: true,
        placement: 'right',
        hideFooter: true,
        spotlightPadding: 8,
        styles: {
          spotlight: {
            borderRadius: '8px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('Click here to remove the filter from this column. Click Next to skip this step for now'),
        target: `#bahai_typecode_clearFilter`,
        before: async (hideSpotLight) => {
          waitForElement('#button_filter_apply', true).then(() => {
            const colHeader =
              document.querySelector('#column_bahai_typecode') ?? document.querySelector(`.${tableClasses.th}`);
            (colHeader as HTMLElement).click();
          });
          (document.querySelector(`#button_filter_apply`) as HTMLElement).addEventListener('click', hideSpotLight);
          return waitForElement(`#bahai_typecode_clearFilter`);
        },
        spotlightClicks: false,
        placement: 'right',
        spotlightPadding: 0,
        disableWindowClicks: true,
        styles: {
          options: {
            zIndex: 1000,
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('Click Filters to add more filters'),
        target: '#action_filters',
        spotlightClicks: true,
        placement: 'right',
        spotlightPadding: 0,
        disableWindowClicks: true,
        hideFooter: true,
        styles: {
          options: {
            zIndex: 1000,
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('Click here to remove all filters'),
        target: `#button_filters_clear`,
        before: async () => {
          return waitForElement('#button_filters_clear');
        },
        spotlightClicks: true,
        placement: 'bottom',
        hideFooter: true,
        styles: {
          spotlight: {
            borderRadius: '8px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('To customize your view, click on Edit Columns'),
        target: '#action_editColumns',
        before: async (hideSpotLight) => {
          (document.querySelector(`#button_filters_clear`) as HTMLElement).addEventListener('click', hideSpotLight);
          return waitForElement(`#button_filters_clear`, true);
        },
        spotlightClicks: true,
        placement: 'bottom',
        hideFooter: true,
        spotlightPadding: 0,
      },
      {
        ...defaultStepProps,
        content: t('Turn on or off fields you want to see in the grid view'),
        target: `.${panelColumnsClasses.cell}:nth-child(2) > button:nth-child(1)`,
        before: async () => waitForElement(`#button_panel_close`),
        spotlightClicks: true,
        placement: 'top',
        hideFooter: true,
        spotlightPadding: 3,
        styles: {
          spotlight: {
            borderRadius: '8px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('Pin to “freeze” the column when scrolling'),
        target: `.${panelColumnsClasses.cell}:nth-child(3) > button:nth-child(2)`,
        before: async () =>
          new Promise((resolve) => {
            const target = document.querySelector(
              `.${panelColumnsClasses.cell}:nth-child(2) > button:nth-child(1)` as string
            );
            target?.addEventListener(`click`, () => resolve(true));
          }),
        spotlightClicks: true,
        placement: 'top',
        hideFooter: true,
        spotlightPadding: 2,
        styles: {
          spotlight: {
            borderRadius: '8px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('Click on Apply to save changes to your View'),
        target: `#button_columns_apply`,
        before: async () =>
          new Promise((resolve) => {
            const target = document.querySelector(
              `.${panelColumnsClasses.cell}:nth-child(3) > button:nth-child(2)` as string
            );
            target?.addEventListener(`click`, () => resolve(true));
          }),
        spotlightClicks: true,
        placement: 'top',
        hideFooter: true,
        styles: {
          spotlight: {
            borderRadius: '8px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t('Click here to see system and custom Views'),
        target: `#button_view`,
        before: async (hideSpotLight) => {
          (document.querySelector(`#button_columns_apply`) as HTMLElement).addEventListener('click', hideSpotLight);
          return waitForElement(`#button_columns_apply`, true);
        },
        spotlightClicks: true,
        placement: 'bottom',
        hideFooter: true,
      },
      {
        ...defaultStepProps,
        content: t('To save changes for the current View or create a new one. Click Next to skip this step for now'),
        target: `#button_view_save`,
        before: async () => waitForElement(`#viewList`),
        spotlightClicks: false,
        placement: 'bottom',
        disableWindowClicks: true,
        styles: {
          spotlight: {
            borderRadius: '8px',
          },
        },
      },
      {
        ...defaultStepProps,
        content: t(
          'Still have questions? Click Help to create a help request ticket. Click Next to skip this step for now'
        ),
        target: `#header_link`,
        spotlightPadding: -2,
      },
      {
        ...defaultStepProps,
        content: t('Click here if you want to see the Tutorial again. Click Last to skip this step for now'),
        target: `#button_start_tutorial`,
        before: async () => {
          const userButton = document.querySelector(`#header_user`) as HTMLDivElement;
          userButton?.click();
          document.addEventListener(`mousedown`, mouseDownInTheEnd, true);
          return true;
        },
        end: () => {
          document.removeEventListener(`mousedown`, mouseDownInTheEnd, true);
        },
        spotlightClicks: false,
        placement: 'bottom',
        spotlightPadding: -1,
        styles: {
          options: {
            zIndex: 1000,
          },
        },
      },
    ],
    [defaultStepProps, t, arrayOfLinks, isInteractionsTabEnable, selectClickableElements]
  );
  return steps;
};
