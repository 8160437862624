import Header, { HEADER_CONTEXT } from 'components/ListPage/components/Header';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AdditionalConfigType, FormConfigGetter, Privilege, TEntityName } from 'lib';
import {
  Form as PopupForm,
  FormConfigType,
  TActionControlsProps,
  TSubmitProps,
} from 'components/ListPage/components/Form';
import { useImage, useSinglePageApi } from 'components/SinglePage/hooks';
import { NavLink, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { useMetaData } from 'lib/hooks';
import classes from './singlePage.module.scss';
import { devLog, useTableConfig } from 'lib/helpers';
import { FieldValidator, FormApi } from 'final-form';
import { FormProps } from 'react-final-form';
import { Loader } from 'components/Loader';
import cx from 'classnames';
import { TLinkEntity } from 'components/ListPage';
import { AppRoute, mathRouteName, routes, TRouteName } from 'domain/routes';
import { RemoveModal } from 'components/RemoveModal';
import { Control } from 'components/Panel';
import { Action, ActionContext, HeaderAction, useActions } from 'components/Actions';
import { parseError } from 'lib/errorParser';
import { useNotifications } from 'providers/NotificationsProvider';
import { TConfig } from 'components/Table';
import { Image } from 'components/Image';
import { AlertBar } from 'components/AlertBar';
import { useColors } from 'providers/EntityMatcher';
import { useSecurity } from 'providers/AuthProvider';
import { useApi } from 'domain/api';
import { AssignModal } from 'components/AssignModal';
import { TabConfig, TabsPanel } from 'components/SinglePage/components/TabsPanel';
import { useExportToWord } from 'components/ListPage/hook';
import { ShowMore } from 'components/ShowMore';
import { useServerError } from 'providers/ErrorProvider';

export type Tab = {
  tab: TEntityName;
  suffix?: string;
  label: string;
  content: (data: Record<string, any>, reload: () => void) => JSX.Element;
  isDefault?: boolean;
};

export type TSinglePage = {
  config?: AdditionalConfigType<string>;
  getFormConfig: FormConfigGetter<string>;
  getDetailsConfig?: FormConfigGetter<string>;
  validate?: FormProps['validate'];
  validation?: Partial<Record<string, FieldValidator<any>>>;
  renderHeader?: (data: Record<string, any>) => string | JSX.Element;
  FormImprover?: () => JSX.Element | null;
  links?: TLinkEntity;
  entityName: TEntityName;
  getActionControls?: (props: TActionControlsProps) => Control[];
  onSubmit?: (props: TSubmitProps) => Promise<any>;
  additionalTabs?: Array<Tab>;
  displayEdit?: boolean;
  displayRemove?: boolean;
  displayAssign?: boolean;
  isNotEditable: (data: Record<string, any>) => JSX.Element | false;
  isActive: (data: Record<string, any>) => boolean;
  isNotRemovable?: (data: Record<string, any>) => JSX.Element | boolean | string;
  preSaveUpdate?: (data: Record<string, any>) => Record<string, any>;
  isConfirmationMessageNeeded?: (data: Record<string, any>) => boolean;
  isConfirmationMessageRequired?: (data: Record<string, any>) => boolean;
  getActions?: (baseActions: Action[]) => Array<Action>;
  statusFields?: (data: Record<string, any>) => string[];
  getLockMessage?: (data: Record<string, any>) => {
    message?: string | JSX.Element;
    isWarning?: boolean;
    forceDisplay?: boolean;
    styles?: Record<string, any>;
  };
  WarningsImprover?: FC<{ setWarnings: (warnings: Array<string | JSX.Element>) => void }>;
  formHelper?: JSX.Element;
  isWordExportAllowed?: boolean;
  hideBahaiId?: boolean;
  hideOwner?: boolean;
};

export const getLockMessageDefault = () => ({ message: <Trans>Record State is Inactive</Trans> });

export const StatusBlock = ({
  fields,
  config,
  data,
  leftSide,
  className,
}: {
  fields: string[];
  config: Record<string, TConfig<Record<string, any>>>;
  data: Record<string, any>;
  leftSide?: boolean;
  className?: string;
}) => (
  <div className={cx(classes.statusFieldsWrapper, { [classes.leftSide]: leftSide }, className)}>
    {fields
      .map((name) => config[name])
      .map(({ name, fieldProps = () => undefined, label, component: Rc }) => (
        <div key={name} className={fieldProps({ classes, values: data, context: 'SINGLE_PAGE' })?.className}>
          <div className={classes.value}>
            {Rc ? (
              <Rc data={data} name={name} defaultValue="---" classes={classes} context={`STATUS_BLOCK`} />
            ) : (
              config[name].adapter(data, name, '---')
            )}
          </div>
          <div className={classes.label}>{label}</div>
        </div>
      ))}
  </div>
);

export const DataBlock = ({
  viewConfig,
  data,
  config,
  hideBahaiId,
}: {
  viewConfig: FormConfigType<any>;
  data: Record<string, any>;
  config: Record<string, TConfig<Record<string, any>>>;
  hideBahaiId?: boolean;
}) => {
  const filteredConfig = useMemo(
    () =>
      viewConfig.filter(([_, fields, hideIfEmpty]) => {
        if (typeof hideIfEmpty !== 'function') return true;
        if (!hideIfEmpty(data)) return true;
        return fields
          .filter((name) => config?.[name]?.hiddenForView !== true)
          .some((name) => config[name].adapter(data, name, '---') !== '---');
      }),
    [config, data, viewConfig]
  );

  return (
    <div className={classes.data} id="single_page_data">
      {filteredConfig.map(([blockHeader, fields], index) => (
        <div key={index} className={classes.blockWrapper}>
          <div className={classes.dataBlock} key={fields.join()}>
            <div className={classes.dataHeader}>{blockHeader}</div>
            {index === 0 && data.bahai_id && !hideBahaiId && (
              <div>
                <div className={classes.label}>IDN</div>
                <div className={classes.value}>{data.bahai_id}</div>
              </div>
            )}
            {fields
              .filter((name) => config?.[name]?.hiddenForView !== true)
              .map((name) => config[name])
              .map(({ name, fieldProps = () => null, label, component: Rc }) => (
                <div key={name} className={fieldProps({ classes, values: data, context: 'SINGLE_PAGE' })?.className}>
                  <div className={classes.label}>{label}</div>
                  <div className={classes.value}>
                    {Rc ? (
                      <Rc data={data} name={name} defaultValue="---" classes={classes} />
                    ) : (
                      config[name].adapter(data, name, '---')
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const useTabsSettings = (entity: string, baseTabsConfig: TabConfig[] = []) => {
  const [settings, setSettings] = useState<Record<string, TabConfig[]>>(
    () => JSON.parse(localStorage.getItem('tabs') || '{}') || ({} as Record<string, TabConfig[]>)
  );

  const allOrderedTabs = useMemo(
    () =>
      baseTabsConfig
        .map((v) => v.label)
        .sort()
        .join(','),
    [baseTabsConfig]
  );

  // Filter tabs if some not Granted already
  const availableTabs = useMemo(() => baseTabsConfig.map((v) => v.label), [baseTabsConfig]);

  const tabs: TabConfig[] = useMemo(
    () => settings[entity]?.filter((v) => availableTabs.includes(v.label)) || baseTabsConfig,
    [availableTabs, baseTabsConfig, entity, settings]
  );

  const storedTabsNames = useMemo(() => tabs.map((v) => v.label), [tabs]);

  const savedOrderedTabs = useMemo(() => storedTabsNames.sort().join(','), [storedTabsNames]);

  const update = useCallback(
    (config: TabConfig[]) => {
      setSettings((v) => ({
        ...v,
        [entity]: config,
      }));
    },
    [entity]
  );

  useEffect(() => {
    if (savedOrderedTabs !== allOrderedTabs) {
      const newTabs = tabs.filter((v) => availableTabs.includes(v.label));
      const names = newTabs.map((v) => v.label);
      baseTabsConfig.forEach((v, index) => {
        if (!names.includes(v.label)) {
          newTabs.splice(index, 0, { ...v });
        }
      });
      update(newTabs);
    }
  }, [allOrderedTabs, availableTabs, baseTabsConfig, savedOrderedTabs, storedTabsNames, tabs, update]);

  useEffect(() => {
    localStorage.setItem('tabs', JSON.stringify(settings));
  }, [settings]);

  const { pathname } = useLocation();

  useEffect(() => {
    const currentTab = baseTabsConfig.find(
      ({ name, label }) => pathname.endsWith('/' + name) && !tabs.find((v) => v.label === label)?.visible
    );
    if (currentTab) {
      update(tabs.map((v) => (v.label === currentTab.label ? { ...v, visible: true } : v)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tabs, update };
};

export const SinglePage = ({
  config: additionalConfig = {},
  getFormConfig,
  getDetailsConfig,
  renderHeader,
  links,
  entityName,
  getActionControls,
  onSubmit,
  additionalTabs = [],
  isNotEditable = () => false,
  displayEdit = true,
  displayRemove = true,
  isActive = () => true,
  isNotRemovable = () => false,
  isConfirmationMessageNeeded = () => false,
  isConfirmationMessageRequired = () => true,
  getActions = (v) => v,
  statusFields: getStatusFields = () => [],
  getLockMessage = getLockMessageDefault,
  displayAssign = false,
  formHelper: helper,
  isWordExportAllowed = false,
  hideBahaiId = false,
  hideOwner,
  ...formProps
}: TSinglePage) => {
  const {
    push,
    location: { pathname, state: locationState },
  } = useHistory<{ from: string }>();

  const from = useRef('/');

  useEffect(() => {
    if (locationState?.from) from.current = locationState.from;
    devLog(`Come from "${from.current}"`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { addActionCompleted, addActionFailed, addActionUncompleted } = useNotifications();
  const [privileges, setPrivileges] = useState([] as Privilege[]);

  const { getACL } = useApi();

  const [edit, setEdit] = useState(false);
  const closeEdit = useCallback(() => setEdit(false), [setEdit]);

  const {
    url,
    entityConfig,
    displayName,
    hiddenFields,
    PrimaryNameAttribute,
    PrimaryImageAttribute,
    OwnershipType,
    ObjectTypeCode,
  } = useMetaData(entityName);
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();

  const routeName = useMemo(() => mathRouteName(pathname) as TRouteName, [pathname]);

  const config = useTableConfig(entityConfig.fields, additionalConfig, entityName, links);
  const { reload, data, initialValues, remove, removeWithConfirmation, loading, query } = useSinglePageApi({
    id,
    links,
    entityName,
  });

  useEffect(() => {
    getACL(id, url, OwnershipType, ObjectTypeCode).then(setPrivileges);
  }, [ObjectTypeCode, OwnershipType, getACL, id, url, data._ownerid_value]);

  const [removeVisible, setRemoveVisible] = useState(false);
  const closeRemove = useCallback(() => setRemoveVisible(false), [setRemoveVisible]);

  const objectTitle = useMemo(() => ` ${data[PrimaryNameAttribute] || data.bahai_id}`, [PrimaryNameAttribute, data]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  const removeHeader = useMemo(
    () =>
      data ? (
        <Trans>
          Do you want to delete
          <strong className={classes.strong}>{objectTitle}</strong>? This is an irreversible action
        </Trans>
      ) : undefined,
    [data, objectTitle]
  );
  const addServerError = useServerError();

  const onRemove = (formData: { Message?: string }, form: FormApi<{ Message: string }>) => {
    const { valid } = form.getState();
    if (valid)
      (formData.Message ? removeWithConfirmation(formData.Message, data) : remove(data))
        .then(() => {
          addActionCompleted(t('{{displayName}} was deleted', { displayName }));
          push(from.current || '/');
        })
        .catch((e) => {
          if (e.response?.status === 404) {
            addServerError(e.response?.status);
          } else {
            addActionFailed(parseError(e));
            setRemoveVisible(false);
          }
        });
  };

  const onUpdate = useCallback(() => {
    reload().then(() => {
      setEdit(false);
    });
  }, [reload]);

  const viewConfig: FormConfigType<string> = useMemo(
    () =>
      (getDetailsConfig || getFormConfig)(data || {}, true).map(([label, fields, hideEmptyFn = () => false]) => [
        label,
        fields.filter((v) => !hiddenFields.includes(v)),
        hideEmptyFn,
      ]),
    [getDetailsConfig, getFormConfig, data, hiddenFields]
  );

  const [assignVisible, setAssignVisible] = useState(false);
  const closeAssign = useCallback(
    (needReload?: boolean) => {
      setAssignVisible(false);
      if (needReload) reload().then();
    },
    [reload]
  );

  const [loadingAbsolute, setLoadingAbsolute] = useState(false);
  const exportToWordAction = useExportToWord(isWordExportAllowed, setLoadingAbsolute, entityName);

  const baseActions = useActions({
    displayName,
    onReload: reload,
    onRemove: ({ selectedItems: [data] }) => {
      if (isNotRemovable(data)) {
        addActionUncompleted(isNotRemovable(data) as JSX.Element);
      } else {
        setRemoveVisible(true);
      }
    },
    onAssign: ({ selectedItems: [data] }) => {
      if (isNotEditable(data)) {
        addActionUncompleted(isNotEditable(data) as JSX.Element);
      } else {
        setAssignVisible(true);
      }
    },
    onEdit: ({ selectedItems: [data] }) => {
      if (isNotEditable(data)) {
        addActionUncompleted(isNotEditable(data) as JSX.Element);
      } else {
        setEdit(true);
      }
    },
  });

  const defaultActions = useMemo(() => [...baseActions, exportToWordAction], [baseActions, exportToWordAction]);
  const actions: Array<HeaderAction> = useMemo(
    () =>
      getActions(defaultActions)
        .filter(
          ({ display = () => true }) =>
            /*type === ActionType.COMMON || type === ActionType.CREATE ||*/ !loading &&
            display({
              isCreateAllowed: false,
              isRemoveAllowed: displayRemove && privileges.includes(Privilege.Delete),
              isEditAllowed: displayEdit && privileges.includes(Privilege.Write),
              isAssignAllowed: !!displayAssign && privileges.includes(Privilege.Assign),
              data: data || {},
              context: ActionContext.SinglePage,
              entityName,
            })
        )
        .map((props) => ({
          ...props,
          onClick: () =>
            props.onClick({ context: ActionContext.SinglePage, selectedItems: [data || {}], query, reload }),
        })),
    [
      getActions,
      defaultActions,
      loading,
      displayRemove,
      privileges,
      displayEdit,
      displayAssign,
      data,
      entityName,
      query,
      reload,
    ]
  );

  const imageProps = useImage(entityName, data);
  const {
    colors: { subBg },
  } = useColors();
  const { forceDisplay, ...lockProps } = getLockMessage(data);

  const { isGranted } = useSecurity();
  const statusFields = useMemo(
    () => [...(config.ownerid && !hideOwner ? ['ownerid'] : []), ...getStatusFields(data)],
    [config.ownerid, data, getStatusFields, hideOwner]
  );

  const baseTabsConfig: TabConfig[] = useMemo(
    () =>
      (additionalTabs || [])
        .filter(({ tab }) => isGranted(tab, Privilege.Read) || tab === 'historylog')
        .map(({ label, tab, isDefault: visible }) => ({ name: tab, label, visible })),
    [additionalTabs, isGranted]
  );

  const { tabs, update: updateTabsConfig } = useTabsSettings(entityName, baseTabsConfig);

  const setTabsConfig = useCallback(
    (tabsConfig: TabConfig[]) => {
      updateTabsConfig(tabsConfig);
      setTabsSettingsVisible(false);
    },
    [updateTabsConfig]
  );

  const visibleTabs: Tab[] = useMemo(
    () =>
      tabs
        .filter((v) => v.visible)
        .map(({ label }) => additionalTabs?.find((v) => v.label === label))
        .filter((v) => !!v) as Tab[],
    [additionalTabs, tabs]
  );

  const [tabsSettingsVisible, setTabsSettingsVisible] = useState(false);
  const toggleTabsSettings = useCallback(() => setTabsSettingsVisible((v) => !v), []);

  // can be changed to any or all actions if needed
  useEffect(() => {
    if (!loading && window.location.hash === '#edit') {
      window.history.replaceState({}, document.title, window.location.href.split('#')[0]);
      document.getElementById('action_edit')?.click();
    }
  }, [loading]);

  if (loading)
    return (
      <div className={classes.loader}>
        <Loader />
      </div>
    );

  if (!data) return null;

  return (
    <>
      {loadingAbsolute && (
        <div className={classes.loaderAbsolute}>
          <Loader />
        </div>
      )}
      <div className={classes.root} style={{ backgroundColor: subBg }} id="single_page_root">
        {edit && (
          <PopupForm
            onClose={closeEdit}
            postAction={onUpdate}
            config={config}
            context="FORM_EDIT"
            data={data}
            {...{ id, getFormConfig, initialValues, getActionControls, onSubmit, entityName, helper }}
            {...formProps}
          />
        )}
        {tabsSettingsVisible && (
          <TabsPanel
            initialConfig={baseTabsConfig}
            setTabsConfig={setTabsConfig}
            tabs={tabs}
            onClose={() => setTabsSettingsVisible(false)}
          />
        )}
        {removeVisible && (
          <RemoveModal
            header={removeHeader}
            onClose={closeRemove}
            {...{
              isConfirmationMessageNeeded: isConfirmationMessageNeeded(data),
              isConfirmationMessageRequired: isConfirmationMessageRequired(data),
              onRemove,
              entityName: displayName,
            }}
          />
        )}
        {assignVisible && <AssignModal entityName={entityName} onClose={closeAssign} id={id} />}
        <Header headerContext={HEADER_CONTEXT.FORM} actions={actions} />
        {(!isActive(data || {}) || forceDisplay) && <AlertBar {...lockProps} />}
        <div className={cx(classes.content, { [classes.isDisabled]: !isActive(data || {}) })}>
          <div className={classes.headerBox}>
            <div className={classes.infoWrapper}>
              {PrimaryImageAttribute && <Image {...imageProps} isEditable={isActive(data)} />}
              <div className={classes.rightBlock}>
                <div className={classes.header}>{renderHeader ? renderHeader(data) : data[PrimaryNameAttribute]}</div>
              </div>
            </div>
            <ShowMore maxHeight={0}>
              <StatusBlock config={config} data={data} fields={statusFields} />
            </ShowMore>
          </div>
          <div className={classes.tabBtnWrapper}>
            {[{ label: t('General') } as Tab, ...visibleTabs].map(({ label, tab, suffix = '' }) => (
              <NavLink
                to={(routes[routeName] as AppRoute<{ id: string; tab?: string }>)({
                  id,
                  tab: tab ? tab + suffix : undefined,
                })}
                exact
                key={label}
                className={classes.tabBtn}
                activeClassName={classes.selected}
              >
                {label}{' '}
              </NavLink>
            ))}
            {additionalTabs?.length > 0 && (
              <button
                className={cx(classes.tabBtn, { [classes.selected]: tabsSettingsVisible })}
                onClick={toggleTabsSettings}
              >
                {t('More')}
              </button>
            )}
          </div>
          <Switch>
            <Route path={(routes[routeName] as AppRoute<{ id: string }>)({ id })} exact>
              <DataBlock hideBahaiId={hideBahaiId} viewConfig={viewConfig} data={data} config={config} />
            </Route>
            {visibleTabs.map(({ tab, suffix = '', content }) => (
              <Route
                key={tab}
                path={(routes[routeName] as AppRoute<{ id: string; tab?: string }>)({
                  id,
                  tab: tab ? tab + suffix : undefined,
                })}
                exact
              >
                <div className={classes.tabsWrapper}>{content(data, reload)}</div>
              </Route>
            ))}
          </Switch>
        </div>
      </div>
    </>
  );
};

export default SinglePage;
