import { createContext, FC, ReactNode, useCallback, useContext, useState } from 'react';

export enum NotificationType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export type Notification = {
  title?: ReactNode;
  content?: ReactNode;
  type?: NotificationType;
};

export type ContentFunction = (hide: () => void) => JSX.Element | null;

export const NotificationsContext = createContext(
  {} as {
    notifications: Array<Notification & { key: number }>;
    addNotification: (notification: Notification | string) => void;
    removeNotification: (notification: Notification) => void;
    addContent: (fn: ContentFunction) => void;
  }
);

export const useNotifications = () => {
  const { addNotification, ...rest } = useContext(NotificationsContext);

  const addActionUncompleted = useCallback(
    (content: string | ReactNode) => addNotification({ title: 'Action cannot be completed', content }),
    [addNotification]
  );

  const addActionFailed = useCallback(
    (content: string | ReactNode) => addNotification({ title: 'Action Failed', type: NotificationType.ERROR, content }),
    [addNotification]
  );

  const addActionCompleted = useCallback(
    (content: string | ReactNode) =>
      addNotification({ title: 'Action Completed', type: NotificationType.SUCCESS, content }),
    [addNotification]
  );

  const addError = useCallback(
    (notification: Notification | string) => {
      addNotification(
        typeof notification === 'string'
          ? { title: notification, type: NotificationType.ERROR }
          : {
              ...notification,
              type: NotificationType.ERROR,
            }
      );
    },
    [addNotification]
  );

  const addWarning = useCallback(
    (notification: Notification | string) => {
      addNotification(
        typeof notification === 'string'
          ? { title: notification, type: NotificationType.WARNING }
          : {
              ...notification,
              type: NotificationType.WARNING,
            }
      );
    },
    [addNotification]
  );

  return {
    addNotification,
    addActionUncompleted,
    addWarning,
    addError,
    addActionCompleted,
    addActionFailed,
    ...rest,
  };
};

export const NotificationsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Array<Notification & { key: number }>>([]);
  const [content, setContent] = useState<Array<{ key: number; fn: ContentFunction }>>([]);

  const addNotification = useCallback((notification: Notification | string) => {
    const key = Date.now();
    setNotifications((list) =>
      list.concat(typeof notification === 'string' ? { title: notification, key } : { ...notification, key })
    );
  }, []);

  const removeNotification = useCallback((notification: Notification) => {
    setNotifications((list) => list.filter((v) => v !== notification));
  }, []);

  const addContent = useCallback((fn: ContentFunction) => {
    setContent((prev) => prev.concat({ key: Date.now(), fn }));
  }, []);

  const removeContent = useCallback((key: number) => {
    setContent((prev) => prev.filter((v) => v.key !== key));
  }, []);

  return (
    <NotificationsContext.Provider value={{ notifications, addNotification, removeNotification, addContent }}>
      {children}
      {content.map(({ fn, key }) => fn(() => removeContent(key)))}
    </NotificationsContext.Provider>
  );
};
