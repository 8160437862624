import { AxiosResponse, AxiosRequestConfig } from 'axios';
import convert from 'xml-js';
import { useCallback, useContext, useMemo } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { SavedQuery, ViewSettings, TEntityName, TStatusConfig, TBulkActionResult, Privilege } from 'lib';
import { API_URL } from 'domain/authConfig';
import { FetchQuery } from 'lib';
import config from 'config/index';
import { useServerError } from 'providers/ErrorProvider';
import { Keys } from 'schemas/requester';
import { ParticipantType } from 'schemas/event';
import { AccessRights } from 'config/EntityMetadata/common';
import { createAxiosInstance, formatXmlQuery } from 'domain/api/setup';

export const baseURL = API_URL + '/api/data/v9.2/';

export const duplicateCheckKeys: Keys[] = [
  'bahai_firstname',
  'bahai_lastname',
  'bahai_nickname',
  'bahai_emailaddress1',
  'bahai_emailaddress2',
  'bahai_cellphone',
  'bahai_homephone',
  'bahai_workphone',
  'bahai_websiteurl',
  'bahai_socialmediaurl',
];

export type Params<T = Record<string, any>> = {
  url: string;
  query?: FetchQuery;
  method?: 'get' | 'post' | 'patch' | 'delete';
  data?: T;
  params?: Record<string, any>;
  eTag?: string;
  onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
  onDownloadProgress?: AxiosRequestConfig['onDownloadProgress'];
  signal?: AxiosRequestConfig['signal'];
  authorize?: boolean;
};

export const useApi = () => {
  const {
    getToken,
    user: { localAccountId },
    systemuserid,
  } = useContext(AuthContext);

  const addServerError = useServerError();

  const axios = useMemo(() => createAxiosInstance(addServerError), [addServerError]);

  const request = useCallback(
    async <R, T = Record<string, any>>(props: Params<T>, accessToken?: string): Promise<AxiosResponse<R>> => {
      const { url, query, method = 'get', data, eTag, params = {}, authorize = true, ...rest } = props;

      const token = authorize ? accessToken ?? (await getToken()) : undefined;

      return axios.request<R>({
        url,
        method,
        headers: {
          ...(token ? { Authorization: 'Bearer ' + token } : {}),
          Prefer: 'odata.include-annotations="*"',
          ...(eTag ? { 'If-Match': eTag } : {}),
        },
        params: {
          ...params,
          ...(query
            ? {
                fetchXml: formatXmlQuery(query),
              }
            : {}),
        },
        data,
        ...rest,
      } as AxiosRequestConfig);
    },
    [getToken, axios]
  );

  const autocompleteRequest = useCallback(
    <T,>(props: Params<{ value: T[] }>) => request<{ value: T[] }>(props),
    [request]
  );

  const getSystemUserId = useCallback(
    () =>
      request<{ value: [{ systemuserid: string }] }>({
        url: 'systemusers',
        params: {
          $select: 'systemuserid',
          $filter: `azureactivedirectoryobjectid eq '${localAccountId}'`,
        },
      }),
    [localAccountId, request]
  );

  const requesterIsDraft = useCallback(
    async (id: string) => {
      const {
        data: { bahai_isdraft },
      } = await request<{ bahai_isdraft: boolean }>({
        url: `bahai_inquirers(${id})`,
        params: { $select: 'bahai_isdraft' },
      });
      return !!bahai_isdraft;
    },
    [request]
  );

  const getJoins = useCallback(
    (names: string[], token?: string) =>
      request<{
        value: {
          ReferencedEntity: string;
          ReferencingEntity: string;
          ReferencingAttribute: string;
        }[];
      }>(
        {
          url: `RelationshipDefinitions/Microsoft.Dynamics.CRM.OneToManyRelationshipMetadata`,
          params: {
            $select: 'ReferencingEntity,ReferencingAttribute,ReferencedEntity',
            $filter: names.map((name) => `ReferencingEntity eq '${name}'`).join(' or '),
          },
        },
        token
      ),
    [request]
  );

  const findOneBy = useCallback(
    <T extends string>(url: string, field: string, by: T, value: string) =>
      request<{ value: Record<T, string>[] }>({
        url,
        params: {
          $select: field,
          $filter: `${by} eq '${value}'`,
        },
      }),
    [request]
  );

  const getSystemViews = useCallback(
    (name: string, systemView?: string) => {
      const entityConfig = Object.keys(config).find((item) => config[item as TEntityName].name === name);
      const viewName = systemView || (config[entityConfig as TEntityName] as any)?.systemView || 'Default';

      return request<{ value: SavedQuery[] }>({
        url: 'savedqueries',
        params: {
          $select: 'savedqueryid,name,isdefault,layoutxml,fetchxml,description',
          $filter: `returnedtypecode eq '${name}' and name eq '${viewName}' and (not endswith(description, '(FUND)'))`,
        },
      });
    },
    [request]
  );

  const getUserViews = useCallback(
    (name: string) =>
      request<{ value: ViewSettings[] }>({
        url: 'bahai_portaluserqueries',
        params: {
          $filter: `bahai_tablelogicalname eq '${name}' and ownerid/ownerid eq ${systemuserid}`,
          $expand: `ownerid`,
        },
      }),
    [request, systemuserid]
  );

  const getUserTemplates = useCallback(
    async (search = '', myTemplatesOnly = false) => {
      const ownerFilter = myTemplatesOnly
        ? `contains(owninguser/fullname,'${search}') or`
        : `ownerid/ownerid eq ${systemuserid} and`;
      return request<{ value: Array<{ bahai_body: string; bahai_name: string; bahai_subject: string }> }>({
        url: 'bahai_templates',
        params: {
          $select: 'bahai_body,bahai_name,bahai_subject,bahai_id,_ownerid_value',
          $filter: `${ownerFilter} (contains(bahai_name,'${search}') or contains(bahai_id,'${search}'))`,
          $orderby: 'bahai_name',
        },
      });
    },

    [request, systemuserid]
  );

  const confirmEmail = useCallback(
    () =>
      request<{ GmailAuthenticationLink: string }>({
        url: `systemusers(${systemuserid})/Microsoft.Dynamics.CRM.bahai_getgmailauthenticationlink`,
        method: 'post',
      }),
    [request, systemuserid]
  );

  const updateOrCreateView = useCallback(
    (data: Partial<ViewSettings>, id?: string) =>
      request({
        url: 'bahai_portaluserqueries' + (id ? `(${id})` : ''),
        method: id ? 'patch' : 'post',
        data,
      }),
    [request]
  );

  const getViewById = useCallback(
    (id: string) =>
      request<ViewSettings>({
        url: `bahai_portaluserqueries(${id})`,
        method: 'get',
      }),
    [request]
  );

  const deleteView = useCallback(
    (id: string) =>
      request({
        url: `bahai_portaluserqueries(${id})`,
        method: 'delete',
      }),
    [request]
  );

  const getBusinessUnitName = useCallback(async () => {
    try {
      const {
        data: { BusinessUnitId },
      } = await request<{ BusinessUnitId: string }>({
        url: `WhoAmI`,
      });
      const {
        data: { name, bahai_name },
      } = await request<{ name: string; bahai_name: string }>({
        url: `businessunits(${BusinessUnitId})`,
      });
      return bahai_name || name;
    } catch (e) {
      return 'Unknown Unit';
    }
  }, [request]);

  const getUserSettings = useCallback(
    () =>
      request<{ value: Array<{ bahai_usersettingsid: string; bahai_body: string }> }>({
        url: `bahai_usersettingses`,
        params: {
          $filter: `ownerid/ownerid eq ${systemuserid}`,
          $expand: `ownerid`,
        },
      }),
    [request, systemuserid]
  );

  const deleteUserSettings = useCallback(
    (id: string) =>
      request({
        url: `bahai_usersettingses(${id})`,
        method: 'delete',
      }),
    [request]
  );

  const addUserSettings = useCallback(
    (bahai_body: string) =>
      request({
        url: `bahai_usersettingses`,
        method: 'post',
        data: { bahai_body },
      }),
    [request]
  );

  const updateUserSettings = useCallback(
    (id: string, bahai_body: string) =>
      request({
        url: `bahai_usersettingses(${id})`,
        method: 'patch',
        data: { bahai_body },
      }),
    [request]
  );

  const mergeRequesters = useCallback(
    (source: string, target: string) =>
      request({
        url: 'bahai_mergerequesters',
        method: 'post',
        data: {
          Primary: { bahai_inquirerid: target, '@odata.type': 'Microsoft.Dynamics.CRM.bahai_inquirer' },
          Secondary: {
            bahai_inquirerid: source,
            '@odata.type': 'Microsoft.Dynamics.CRM.bahai_inquirer',
          },
        },
      }),
    [request]
  );

  const sendEmail = useCallback(
    (id: string) =>
      request({
        url: `emails(${id})/Microsoft.Dynamics.CRM.SendEmail`,
        method: 'post',
        data: {
          IssueSend: 'true',
        },
      }),
    [request]
  );

  const inviteParticipantsToEvent = useCallback(
    async (EventId: string, participants: { id: string; name: string }[], type: ParticipantType) => {
      const data = {
        EventId,
        PotentialParticipants: JSON.stringify([
          {
            Key: '',
            Value: participants.map((x) => ({
              Id: x.id,
              LogicalName: type === 'requester' ? 'bahai_inquirer' : 'systemuser',
              Name: x.name,
            })),
          },
        ]),
      };

      const response = await request<{ Message: string }>({
        url: `bahai_invitepotentialparticipantstoevent`,
        method: 'post',
        data,
      });

      const result = JSON.parse(response.data.Message) as TBulkActionResult;
      return result.Messages;
    },
    [request]
  );

  const addRequestersToGroup = useCallback(
    async (groupId: string, requesters: { id: string; name: string }[]) => {
      const data = {
        SelectedGroup: JSON.stringify({
          Id: groupId,
          LogicalName: 'bahai_group',
        }),
        SelectedRequesters: JSON.stringify(
          requesters.map((x) => ({
            Id: x.id,
            LogicalName: 'bahai_inquirer',
            Name: x.name,
          }))
        ),
      };

      return await request<{ Message: string; RequestersNotAddedToGroup: string }>({
        url: `bahai_addinquirerstogroup`,
        method: 'post',
        data,
      });
    },
    [request]
  );

  const notifyInvitedResources = useCallback(
    async (eventId: string, paticipantsIds: string[]) => {
      const data = {
        Event: {
          bahai_event_imsid: eventId,
          ['@odata.type']: 'Microsoft.Dynamics.CRM.bahai_event_ims',
        },
        InvitedResources: JSON.stringify(
          paticipantsIds.map((x) => ({
            Id: x,
            LogicalName: 'systemuser',
          }))
        ),
      };

      return await request({
        url: `bahai_sendemailnotificationstoresourcesexcludedfromeventparticipants`,
        method: 'post',
        data,
      });
    },
    [request]
  );

  const notifyInvitedRequesters = useCallback(
    async (eventId: string, paticipantsIds: string[]) => {
      const data = {
        Event: {
          bahai_event_imsid: eventId,
          ['@odata.type']: 'Microsoft.Dynamics.CRM.bahai_event_ims',
        },
        EventParticipants: JSON.stringify(
          paticipantsIds.map((x) => ({
            Id: x,
          }))
        ),
      };

      return await request({
        url: `bahai_sendemailnotificationstoinquirersexcludedfromeventparticipants`,
        method: 'post',
        data,
      });
    },
    [request]
  );

  const updateEventStatusAndSendNotification = useCallback(
    async (actionName: string, eventId: string, message: string) => {
      const data = {
        Record: {
          bahai_event_imsid: eventId,
          ['@odata.type']: 'Microsoft.Dynamics.CRM.bahai_event_ims',
        },
        Message: message,
      };

      return await request({
        url: actionName,
        method: 'post',
        data,
      });
    },
    [request]
  );

  const exportToExcel = useCallback(
    (savedqueryid: string, FetchXml: string, LayoutXml: string, values = [] as string[]) =>
      request({
        url: 'ExportToExcel',
        method: 'post',
        data: {
          View: {
            '@odata.type': 'Microsoft.Dynamics.CRM.savedquery',
            savedqueryid,
          },
          FetchXml,
          LayoutXml,
          QueryApi: '',
          QueryParameters: {
            Arguments: {
              Count: values.length ? 1 : 0,
              IsReadOnly: true,
              Keys: values.length ? ['selectedRecords'] : [],
              Values: values.length
                ? [
                    {
                      Type: 'System.String',
                      Value: values.join(','),
                    },
                  ]
                : [],
            },
          },
        },
      }),
    [request]
  );

  const exportToWord = useCallback(
    (entityCode: number, templateId: string, values = [] as string[]) => {
      return request({
        url: 'ExportWordDocument',
        method: 'post',
        data: {
          SelectedTemplate: {
            '@odata.type': 'Microsoft.Dynamics.CRM.documenttemplate',
            documenttemplateid: templateId,
          },
          EntityTypeCode: entityCode,
          SelectedRecords: `[${values.map((item: string) => `"${item}"`).join(',')}]`,
        },
      });
    },
    [request]
  );

  const getWordTemplates = useCallback(
    (entityCode: string) => {
      return request({
        url: 'documenttemplate',
        params: {
          $select: 'documenttemplateid,name',
          $filter: `associatedentitytypecode eq '${entityCode}' and documenttype eq '2' and status eq '0'`,
        },
      });
    },
    [request]
  );

  const getRequesterDuplicates = useCallback(
    async (record: Record<string, any>, excludeId?: string) => {
      const data = Object.fromEntries(
        Object.entries(record).filter(([key]) => duplicateCheckKeys.includes(key as Keys))
      );
      try {
        const {
          data: { DuplicateRequesters, DuplicateMembers },
        } = await request<{ DuplicateRequesters: string; DuplicateMembers: string }>({
          url: 'bahai_checkrequestersforduplicates',
          method: 'post',
          data: {
            Requester: JSON.stringify({
              LogicalName: 'bahai_inquirer',
              Attributes: Object.entries(data).map(([key, value]) => ({
                key,
                value: value || '',
              })),
            }),
          },
        });
        return {
          requesters: (JSON.parse(DuplicateRequesters) as string[]).filter((v) => v !== excludeId),
          members: JSON.parse(DuplicateMembers) as string[],
        };
      } catch (_) {
        return { requesters: [] as string[], members: [] as string[] };
      }
    },
    [request]
  );

  const getAvailableStatuses = useCallback(
    async (id: string, logicalName: string, ignoreLocalityCalculation = false) => {
      const data = {
        Record: JSON.stringify({
          Id: id,
          LogicalName: logicalName,
        }),
        IgnoreLocalityCalculation: ignoreLocalityCalculation,
      };

      const response = await request<{ Statuses: string; Message: string; MessageType: string }>({
        url: `bahai_getavailablestatuses`,
        method: 'post',
        data,
      });
      return {
        Statuses: JSON.parse(response.data.Statuses) as TStatusConfig[],
        Message: response.data.Message,
        MessageType: response.data.MessageType,
      };
    },
    [request]
  );

  const sendInvitations = useCallback(
    async (eventId: string, paticipantsIds: string[], LogicalName: string) => {
      const data = {
        EventId: eventId,
        EventParticipants: JSON.stringify(
          paticipantsIds.map((x) => ({
            Id: x,
            LogicalName,
          }))
        ),
      };

      const response = await request<{ Message: string }>({
        url: `bahai_sendinvitationtoeventparticipants`,
        method: 'post',
        data,
      });

      return JSON.parse(response.data.Message) as { Message: string; Rows: { Name: string }[] }[];
    },
    [request]
  );

  const getEnvVariable = useCallback(
    async (varName: string) => {
      const response = await request<{
        value: [{ defaultvalue: string; environmentvariabledefinition_environmentvariablevalue: { value: string }[] }];
      }>({
        url: 'environmentvariabledefinitions',
        params: {
          $select: 'displayname,defaultvalue',
          $expand: 'environmentvariabledefinition_environmentvariablevalue($select=value)',
          $filter: `schemaname eq '${varName}'`,
        },
      });

      const defaultValue = response.data.value[0].defaultvalue;
      const variableValue = response.data.value[0].environmentvariabledefinition_environmentvariablevalue[0].value;
      return variableValue || defaultValue;
    },
    [request]
  );

  const deleteDocument = useCallback(
    (
      documentid: number,
      filetype: string,
      locationid: string,
      sharepointdocumentid: string,
      parentLogicalName: string,
      parentIdAttribute: string,
      parentId: string
    ) =>
      request({
        url: 'DeleteDocument',
        method: 'post',
        data: {
          Entities: [
            {
              '@odata.type': 'Microsoft.Dynamics.CRM.sharepointdocument',
              documentid,
              filetype,
              locationid,
              sharepointdocumentid,
            },
          ],
          ParentEntityReference: {
            '@odata.type': `Microsoft.Dynamics.CRM.${parentLogicalName}`,
            [parentIdAttribute]: parentId,
          },
        },
      }),
    [request]
  );

  const uploadDocument = useCallback(
    (fileName: string, content: string, parentLogicalName: string, parentIdAttribute: string, parentId: string) =>
      request({
        url: 'UploadDocument',
        method: 'post',
        data: {
          Content: content,
          Entity: {
            '@odata.type': 'Microsoft.Dynamics.CRM.sharepointdocument',
            locationid: '',
            title: fileName,
          },
          FolderPath: '',
          OverwriteExisting: false,
          ParentEntityReference: {
            '@odata.type': `Microsoft.Dynamics.CRM.${parentLogicalName}`,
            [parentIdAttribute]: parentId,
          },
        },
      }),
    [request]
  );

  const getACL = useCallback(
    async (id: string, logicalName: string, OwnershipType: string, ObjectTypeCode: number) => {
      try {
        if (OwnershipType == 'BusinessOwned') {
          const userAccessQuery = convert.js2xml(
            {
              fetch: {
                _attributes: {
                  version: '1.0',
                  'output-format': 'xml-platform',
                  returntotalrecordcount: true,
                  'no-lock': true,
                  distinct: true,
                },
                entity: {
                  _attributes: {
                    name: 'role',
                  },
                  'link-entity': [
                    {
                      _attributes: {
                        name: 'systemuserroles',
                        from: 'roleid',
                        to: 'roleid',
                      },
                      filter: [
                        {
                          _attributes: { type: 'and' },
                          condition: {
                            _attributes: {
                              attribute: 'systemuserid',
                              operator: 'eq',
                              value: systemuserid,
                            },
                          },
                        },
                      ],
                    },
                    {
                      _attributes: {
                        name: 'roleprivileges',
                        from: 'roleid',
                        to: 'parentrootroleid',
                      },
                      'link-entity': {
                        _attributes: {
                          name: 'privilege',
                          from: 'privilegeid',
                          to: 'privilegeid',
                          alias: `privilege`,
                        },
                        attribute: {
                          _attributes: { name: 'accessright' },
                        },
                        'link-entity': {
                          _attributes: {
                            name: 'privilegeobjecttypecodes',
                            from: 'privilegeid',
                            to: 'privilegeid',
                          },
                          filter: [
                            {
                              _attributes: { type: 'and' },
                              condition: {
                                _attributes: { attribute: 'objecttypecode', operator: 'eq', value: ObjectTypeCode },
                              },
                            },
                          ],
                        },
                      },
                    },
                  ],
                },
              },
            },
            {
              compact: true,
              attributeValueFn: (value) => {
                return value
                  .replace(/&(?!quot;)/g, '&amp;')
                  .replace(/</g, '&lt;')
                  .replace(/</g, '&lt;')
                  .replace(/'/g, '&apos;');
              },
            }
          );

          const response = await request<{ value: any[] }>({
            url: 'roles',
            params: {
              fetchXml: encodeURIComponent(userAccessQuery),
            },
          });

          const privilegesResponseMapper = (data: AccessRights): Privilege => {
            switch (data) {
              case AccessRights.ReadAccess:
                return Privilege.Read;
              case AccessRights.WriteAccess:
                return Privilege.Write;
              case AccessRights.AppendAccess:
                return Privilege.Append;
              case AccessRights.AppendToAccess:
                return Privilege.AppendTo;
              case AccessRights.AssignAccess:
                return Privilege.Assign;
              case AccessRights.CreateAccess:
                return Privilege.Create;
              case AccessRights.DeleteAccess:
                return Privilege.Delete;
              case AccessRights.ShareAccess:
                return Privilege.Share;
              case AccessRights.None:
              default:
                return Privilege.None;
            }
          };

          // todo add BU privilages check
          return response.data.value.map((item) =>
            privilegesResponseMapper(item['privilege.accessright'] as AccessRights)
          );
        } else {
          const {
            data: { AccessRights },
          } = await request<{ AccessRights: string }>({
            url: `systemusers(${systemuserid})/Microsoft.Dynamics.CRM.RetrievePrincipalAccess(Target=@Target)`,
            params: {
              '@Target': `{"@odata.id":"${logicalName}(${id})"}`,
            },
          });
          return AccessRights.replaceAll('Access', '').split(', ') as Privilege[];
        }
      } catch (e) {
        return [] as Privilege[];
      }
    },
    [request, systemuserid]
  );

  const getRoleName = useCallback(async () => {
    const {
      data: { name },
    } = await request<{ name: string }>({
      url: `systemusers`,
      params: {
        $filter: `systemuserid eq ${systemuserid}`,
        $select: 'fullname,systemuserid,systemuserroles_association&$expand=systemuserroles_association($select=name)',
      },
    });
    return name;
  }, [request, systemuserid]);

  const assign = useCallback(
    (targetLogicalName: string, target: any, assigneeLogicalName: string, assignee: any) =>
      request({
        url: `Assign`,
        data: {
          Target: {
            '@odata.type': `Microsoft.Dynamics.CRM.${targetLogicalName}`,
            ...target,
          },
          Assignee: {
            '@odata.type': `Microsoft.Dynamics.CRM.${assigneeLogicalName}`,
            ...assignee,
          },
        },
        method: 'post',
      }),
    [request]
  );

  const finishCoverage = useCallback(
    (id: string) =>
      request({
        url: `bahai_coverages(${id})/Microsoft.Dynamics.CRM.bahai_finishcoverage`,
        method: 'post',
      }),
    [request]
  );

  const reminderNotify = useCallback(
    (ReminderId: string) =>
      request({
        url: 'bahai_sendremindernotificationsaboutcreation',
        data: { ReminderId },
        method: 'post',
      }),
    [request]
  );

  return {
    request,
    getRoleName,
    autocompleteRequest,
    getJoins,
    findOneBy,
    getSystemViews,
    getUserViews,
    getUserTemplates,
    updateOrCreateView,
    getSystemUserId,
    getUserSettings,
    addUserSettings,
    updateUserSettings,
    deleteUserSettings,
    deleteView,
    getViewById,
    sendEmail,
    inviteParticipantsToEvent,
    addRequestersToGroup,
    notifyInvitedRequesters,
    notifyInvitedResources,
    updateEventStatusAndSendNotification,
    exportToExcel,
    exportToWord,
    getWordTemplates,
    getRequesterDuplicates,
    mergeRequesters,
    sendInvitations,
    reminderNotify,
    getACL,
    getAvailableStatuses,
    getBusinessUnitName,
    deleteDocument,
    getEnvVariable,
    uploadDocument,
    assign,
    finishCoverage,
    confirmEmail,
    requesterIsDraft,
  };
};
