import React, { Suspense, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes as r, TRouteName } from 'domain/routes';
import classes from './index.module.css';
import Menu from 'components/Menu';
import ErrorBoundary from 'components/ErrorBoundary';
import EventList from 'pages/EventList';
import Event from 'pages/Event';
import RequesterList from 'pages/RequesterList';
import Header from 'components/Header';
import { Requester } from 'pages/Requester';
import InquiryList from 'pages/InquiryList';
import { Inquiry } from 'pages/Inquiry';
import { Interaction } from 'pages/Interaction';
import EmailList from 'pages/EmailList';
import { Email } from 'pages/Email';
import { SnackBarNotifications } from 'components/SnackBarNotifications';
import { ErrorProvider } from 'providers/ErrorProvider';
import { ResourceList } from 'pages/ResourceList';
import { Resource } from 'pages/Resource';
import { DashBoard } from 'components/DashBoard';
import ContactList from 'pages/ContactList';
import { Contact } from 'pages/Contact';
import { getEntityNameFromRoute, useColors } from 'providers/EntityMatcher';
import { Privilege } from 'lib';
import { useSecurity } from 'providers/AuthProvider';
import { Reminder } from 'pages/Reminder';
import { Coverage } from 'pages/Coverage';
import { Template } from 'pages/Template';

const pages: Array<{ route: TRouteName; component: (props: any) => JSX.Element }> = [
  { route: 'home', component: DashBoard },
  { route: 'eventList', component: EventList },
  { route: 'event', component: Event },
  { route: 'requesterList', component: RequesterList },
  { route: 'requester', component: Requester },
  { route: 'inquiryList', component: InquiryList },
  { route: 'inquiry', component: Inquiry },
  { route: 'interaction', component: Interaction },
  { route: 'emailList', component: EmailList },
  { route: 'email', component: Email },
  { route: 'resourceList', component: ResourceList },
  { route: 'resource', component: Resource },
  { route: 'contactList', component: ContactList },
  { route: 'contact', component: Contact },
  { route: 'reminder', component: Reminder },
  { route: 'coverage', component: Coverage },
  { route: 'template', component: Template },
];

const App = () => {
  const {
    colors: { bg },
  } = useColors();
  const { isGranted } = useSecurity();

  const allowedPages = useMemo(
    () => pages.filter(({ route }) => route === 'home' || isGranted(getEntityNameFromRoute(route), Privilege.Read)),
    [isGranted]
  );

  return (
    <>
      <ErrorProvider>
        <ErrorBoundary background={bg}>
          {(showMenu: boolean, error: JSX.Element | undefined, showHeader) => (
            <>
              {showHeader && <Header />}
              <div className={classes.wrapper} style={{ backgroundColor: bg }}>
                {!error && <SnackBarNotifications />}
                {showMenu && showHeader && <Menu />}
                {error ? (
                  error
                ) : (
                  <Suspense fallback={<div>Loading...</div>}>
                    <div className={classes.page}>
                      <Switch>
                        {allowedPages.map(({ route, ...props }) => (
                          <Route key={r[route].path as string} path={r[route].path as string} exact={true} {...props} />
                        ))}
                        <Route path="/" exact={true}>
                          <Redirect to={r.home.path} />
                        </Route>
                      </Switch>
                    </div>
                  </Suspense>
                )}
              </div>
            </>
          )}
        </ErrorBoundary>
      </ErrorProvider>
    </>
  );
};

export { App };
