import { SinglePage } from 'components/SinglePage';
import * as baseConfig from 'schemas/coverage';
import { useSecurity } from 'providers/AuthProvider';
import { useCallback } from 'react';
import { Action } from 'components/Actions';
import { Privilege } from 'lib';
import { useFinishCoverage } from 'schemas/coverage/actions';
import { useParams } from 'react-router-dom';

export const Coverage = () => {
  const { id } = useParams<{ id: string }>();
  const { isGranted } = useSecurity();
  const { action, content } = useFinishCoverage(id || '');

  const getActions = useCallback(
    (baseActions: Action[]) => [...baseActions, ...(isGranted('reminder', Privilege.Write) ? [action] : [])],
    [action, isGranted]
  );

  return (
    <>
      {content}
      <SinglePage
        hideBahaiId
        hideOwner
        entityName="coverage"
        displayAssign={false}
        {...baseConfig}
        getActions={getActions}
      />
    </>
  );
};
