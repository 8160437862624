import { TAdapter } from 'components/Table';
import { FieldType, isDateTime } from 'lib/types';
import { DATE_OUTPUT_FORMAT, DATETIME_OUTPUT_FORMAT, ISO_DATETIME_FORMAT } from './const';
import { parseISO, parse } from 'date-fns';
import { formatInTimeZone, fromZonedTime, toDate } from 'date-fns-tz';
import { devLog } from 'lib/helpers';

export class TimeZone {
  private static instance: TimeZone;
  private timeZone = localStorage.getItem('timeZone') || Intl.DateTimeFormat().resolvedOptions().timeZone;
  static getInstance() {
    if (!TimeZone.instance) {
      TimeZone.instance = new TimeZone();
    }
    return TimeZone.instance;
  }

  private constructor() {
    devLog('Current User time zone ', this.getTimeZone());
  }

  getTimeZone() {
    return this.timeZone;
  }

  setTimeZone(timeZone: string) {
    this.timeZone = timeZone;
    devLog('New time zone ', this.timeZone);
  }
}

export const parseDate = (dateString: string, useTime?: boolean) => {
  try {
    return parseISO(useTime ? toISO(dateString) : toISODate(dateString));
  } catch (e) {
    console.error(e);
  }
  return new Date();
};

export const exprUSAOutput = /(?<month>\d\d)\/(?<day>\d\d)\/(?<year>\d\d\d\d).+(?<time>\d\d:\d\d [aApP][mM]).*/;
const exprUSA = /(?<month>\d\d)\/(?<day>\d\d)\/(?<year>\d\d\d\d).+(?<time>\d\d:\d\d:\d\d).*/;
const exprISO = /(?<year>\d\d\d\d)-(?<month>\d\d)-(?<day>\d\d)T(?<time>\d\d:\d\d:\d\d)Z.*/;
const exprISODate = /(?<year>\d\d\d\d)-(?<month>\d\d)-(?<day>\d\d).*/;
export const exprUSADate = /(?<month>\d\d)\/(?<day>\d\d)\/(?<year>\d\d\d\d).*/;

const isISO = (dateString: string) => exprISO.test(dateString);
export const isUSA = (dateString: string) => exprUSA.test(dateString);
export const isUSAOutput = (dateString: string) => exprUSAOutput.test(dateString);

export const isUSADate = (dateString: string) => exprUSADate.test(dateString);
const isISODate = (dateString: string) => exprISODate.test(dateString);

export const toISODate = (dateString: string) => {
  if (isISODate(dateString)) return dateString;
  if (isUSADate(dateString)) return dateString.replace(exprUSADate, '$<year>-$<month>-$<day>');
  throw new Error(`Cant recognise Date from "${dateString}"`);
};

export const getFormattedNow = () =>
  formatInTimeZone(new Date(), TimeZone.getInstance().getTimeZone(), DATETIME_OUTPUT_FORMAT);

export const toISO = (date: string | Date) => {
  try {
    if (typeof date !== 'string') return formatInTimeZone(date, 'UTC', ISO_DATETIME_FORMAT);
    if (isISO(date)) return date;
    if (isUSAOutput(date))
      return formatInTimeZone(
        fromZonedTime(parse(date, DATETIME_OUTPUT_FORMAT, new Date()), TimeZone.getInstance().getTimeZone()),
        'UTC',
        ISO_DATETIME_FORMAT
      );
    if (isUSA(date))
      return date.replace(
        /(?<month>\d\d)\/(?<day>\d\d)\/(?<year>\d\d\d\d).+(?<time>\d\d:\d\d:\d\d).*/,
        '$<year>-$<month>-$<day>T$<time>Z'
      );
  } catch (e) {
    devLog(`Cant recognise DateTime from "${date}"`);
  }
  throw new Error(`Cant recognise DateTime from "${date}"`);
};

export const dateToOutputString = (date: Date, useTime = true) => {
  try {
    return formatInTimeZone(
      date,
      TimeZone.getInstance().getTimeZone(),
      useTime ? DATETIME_OUTPUT_FORMAT : DATE_OUTPUT_FORMAT
    );
  } catch (e) {
    //devLog(e);
    return '';
  }
};

export const toDisplayDate = (dateString: string, useTime = false) => {
  if (!dateString) return '';
  if (useTime) {
    return dateToOutputString(stringToDate(dateString, useTime));
  } else {
    return dateString.replace(isISODate(dateString) ? exprISODate : exprUSADate, '$<month>/$<day>/$<year>');
  }
};

export const toIsoDate = (dateString: string, useTime = false) => {
  if (!dateString) return '';
  if (useTime) {
    if (isUSA(dateString) || isUSAOutput(dateString))
      return formatInTimeZone(toISO(dateString), 'UTC', ISO_DATETIME_FORMAT);
    if (isISO(dateString)) return dateString;
  } else {
    if (isISODate(dateString)) return dateString;
    if (isUSADate(dateString)) return dateString.replace(exprUSA, '$<year>-$<month>-$<day>');
  }
  throw new Error(`Unknown Date "${dateString}"`);
};

export const stringToDate = (dateString: string, useTime = false) => {
  if (useTime) {
    if (isUSA(dateString)) return fromZonedTime(parseISO(toISO(dateString)), TimeZone.getInstance().getTimeZone());
    if (isISO(dateString)) return parseISO(dateString);
    if (isUSAOutput(dateString))
      return fromZonedTime(parse(dateString, DATETIME_OUTPUT_FORMAT, new Date()), TimeZone.getInstance().getTimeZone());
  } else {
    return toDate(toISODate(dateString), { timeZone: TimeZone.getInstance().getTimeZone() });
  }
  throw new Error(`Unknown Date "${dateString}"`);
};

const stringAdapter: TAdapter = (item, name, defaultValue) => {
  return item[name] || defaultValue;
};

const numberAdapter: TAdapter = (item, name, defaultValue) => {
  return typeof item[name] === 'number' ? item[name] : defaultValue;
};

export const dateAdapter: TAdapter = (item, name, defaultValue) => toDisplayDate(item[name]) || defaultValue;

export const timeAdapter: TAdapter = (item, name, defaultValue) => toDisplayDate(item[name], true) || defaultValue;

const entityAdapter: TAdapter = (item, name, defaultValue) => {
  const titleKey = (name.includes('.') ? name : `_${name}_value`) + `@OData.Community.Display.V1.FormattedValue`;
  return item[titleKey] || defaultValue;
};

const otherAdapter: TAdapter = (item, name, defaultValue) => {
  const titleKey = `${name}@OData.Community.Display.V1.FormattedValue`;
  return item[titleKey] || defaultValue;
};

// TODO: convert to hook and get DATE_FORMAT from user settings
export const getDefaultAdapter = (type: FieldType, format?: string): TAdapter => {
  switch (type) {
    case FieldType.Number:
      return numberAdapter;
    case FieldType.DateTime:
      return isDateTime(format) ? timeAdapter : dateAdapter;
    case FieldType.Owner:
    case FieldType.Lookup:
      return entityAdapter;
    case FieldType.String:
    case FieldType.Memo:
      return stringAdapter;
    default:
      return otherAdapter;
  }
};
