import { AdditionalConfigType, FormConfigGetter, TEntityName } from 'lib';
import { AreaComponent, UrlComponent, PolymorphicComponent, AreaTooltipComponent } from 'lib/components';
import * as rules from 'lib/rules';
import { FieldValidator } from 'final-form';
import { Trans } from 'react-i18next';
import * as eventMetadata from 'config/EntityMetadata/bahai_event_ims';
import { AutocompleteType } from 'components/AutoComplete/hooks';
import { createFormAutocomplete } from 'components/Form/Autocomplete';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import { ChainImprover } from 'lib/improvers';
import { isLocalityCalculation, LocalityWarning } from 'schemas/requester';
import { TLinkEntity } from 'components/ListPage';
import { getLockMessage, getWarningsImprover } from 'schemas/inquiry';
import entities, { Fields } from 'config';

export const columns = [
  ...entities.event.columns,
  'locality.bahai_name',
  'cluster.bahai_name',
  'cluster.bahai_stageofgrowth',
  'locality.bahai_localitytype',
] as const;
export type Keys = Fields<(typeof columns)[number] | 'bahai_event_imsid' | '_bahai_eventid_value' | 'event.bahai_id'>;

export const defaultMobileColumns: Keys[] = ['bahai_title', 'bahai_startdatetime', 'bahai_designatedhostobjectid'];

export { getLockMessage, getWarningsImprover };

export const links = {
  locality: {
    to: 'bahai_localityid',
    from: 'businessunitid',
    fields: ['bahai_name', 'bahai_localitytype'],
    condition: [],
  },
  cluster: {
    to: 'bahai_clusterid',
    from: 'businessunitid',
    fields: ['bahai_name', 'bahai_stageofgrowth'],
    condition: [],
  },
} as TLinkEntity;

export const config: AdditionalConfigType<Keys> = {
  bahai_id: {
    searchable: true,
    component: ({ data }) => (
      <HistoryLink to={r.event({ id: data.bahai_event_imsid || data._bahai_eventid_value || 0 })}>
        {data.bahai_id || data['event.bahai_id']}
      </HistoryLink>
    ),
  },
  bahai_title: {
    isRequired: true,
    hiddenForView: true,
    searchable: true,
    fieldProps: ({ classes }) => ({ className: classes.long }),
    component: ({ data }) => (
      <HistoryLink to={r.event({ id: data.bahai_event_imsid || 0 })}>{data.bahai_title}</HistoryLink>
    ),
  },
  bahai_description: {
    searchable: true,
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({ className: classes.long, inputType: 'area', maxLength: 2000 }),
  },
  bahai_startdatetime: {
    isRequired: true,
    fieldProps: () => ({ showTime: true, canSelectPastDate: false }),
  },
  bahai_finishdatetime: {
    isRequired: true,
    fieldProps: () => ({ showTime: true, canSelectPastDate: false }),
  },
  bahai_categoryid: {
    isRequired: true,
  },
  bahai_designatedcontactobjectid: {
    isRequired: true,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'resource',
          links: {
            businessunit: {
              from: 'businessunitid',
              to: 'businessunitid',
              fields: [],
            },
          },
          filters: [
            {
              logicOperator: 'and',
              condition: [
                { attribute: 'isdisabled', operator: 'eq', value: '0' },
                { attribute: 'islicensed', operator: 'eq', value: '1' },
                { attribute: 'businessunit.parentbusinessunitid', operator: 'not-null' },
              ],
            },
          ],
        },
        {
          entityName: 'contact',
          filters: [{ attribute: 'statecode', operator: 'eq', value: '0' }],
        },
      ],

      type: AutocompleteType.Polymorphic,
    }),
    component: PolymorphicComponent,
  },
  bahai_designatedhostobjectid: {
    isRequired: true,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'resource',
          links: {
            businessunit: {
              from: 'businessunitid',
              to: 'businessunitid',
              fields: [],
            },
          },
          filters: [
            {
              logicOperator: 'and',
              condition: [
                { attribute: 'isdisabled', operator: 'eq', value: '0' },
                { attribute: 'islicensed', operator: 'eq', value: '1' },
                { attribute: 'businessunit.parentbusinessunitid', operator: 'not-null' },
              ],
            },
          ],
        },
        {
          entityName: 'contact',
          filters: [{ attribute: 'statecode', operator: 'eq', value: '0' }],
        },
      ],

      type: AutocompleteType.Polymorphic,
    }),
    component: PolymorphicComponent,
  },
  bahai_postalcodeid: {
    field: createFormAutocomplete({
      entities: [{ entityName: 'bahai_postalcode', filters: ['bahai_cityid', 'bahai_stateid'] }],
      dependencies: ['bahai_cityid', 'bahai_stateid'],
    }),
    isRequired: ({ bahai_typecode }) =>
      [eventMetadata.bahai_typecode.InPerson, eventMetadata.bahai_typecode.Hybrid].includes(Number(bahai_typecode)),
  },
  bahai_cityid: {
    field: createFormAutocomplete({
      entities: [{ entityName: 'bahai_city', filters: ['bahai_stateid'] }],
      dependencies: ['bahai_stateid'],
    }),
    isRequired: ({ bahai_typecode }) =>
      [eventMetadata.bahai_typecode.InPerson, eventMetadata.bahai_typecode.Hybrid].includes(Number(bahai_typecode)),
  },

  bahai_addressline1: {
    isRequired: ({ bahai_typecode }) =>
      [eventMetadata.bahai_typecode.InPerson, eventMetadata.bahai_typecode.Hybrid].includes(Number(bahai_typecode)),
  },
  bahai_stateid: {
    isRequired: ({ bahai_typecode }) =>
      [eventMetadata.bahai_typecode.InPerson, eventMetadata.bahai_typecode.Hybrid].includes(Number(bahai_typecode)),
  },
  bahai_compositeaddress: {
    searchable: true,
    fieldProps: ({ classes }) => ({ className: classes.long }),
    component: AreaComponent,
  },
  bahai_confirmationmessage: {
    hiddenForView: true,
    hiddenForTable: true,
  },
  ownerid: {
    searchable: true,
  },
  bahai_typecode: {
    isRequired: true,
  },
  bahai_videoconferencingurl: {
    component: UrlComponent,
    isRequired: ({ bahai_typecode }) =>
      [eventMetadata.bahai_typecode.Virtual, eventMetadata.bahai_typecode.Hybrid].includes(Number(bahai_typecode)),
  },
  bahai_localitycalculationstatuscode: {
    hiddenForTable: true,
  },
  'locality.bahai_name': {
    label: <Trans>Locality Name</Trans>,
  },
  'cluster.bahai_name': {
    label: <Trans>Cluster Name</Trans>,
  },
  'cluster.bahai_stageofgrowth': {
    label: <Trans>Stage of Growth</Trans>,
  },
  'locality.bahai_localitytype': {
    label: <Trans>Community Type</Trans>,
  },
};

export const FormImprover = () => (
  <>
    <ChainImprover
      chains={[['bahai_postalcodeid', 'bahai_cityid', 'bahai_stateid']]}
      replace="custom"
      entityName="event"
      forceClearFields={['bahai_postalcodeid']}
    />
    <ChainImprover
      chains={[
        ['bahai_startdatetime', 'bahai_finishdatetime'],
        ['bahai_clusterid', 'bahai_regionid'],
      ]}
      entityName="event"
    />
  </>
);

export const getDetailsConfig: FormConfigGetter<Keys> = () => [
  [
    <Trans>Main Data</Trans>,
    [
      'ownerid',
      'bahai_title',
      'bahai_description',
      'bahai_startdatetime',
      'bahai_finishdatetime',
      'bahai_categoryid',
      'bahai_subcategoryid',
      'bahai_designatedcontactobjectid',
      'bahai_designatedhostobjectid',
      'createdby',
    ] as Keys[],
  ],
  [
    <Trans>Location</Trans>,
    [
      'bahai_typecode',
      'bahai_videoconferencingurl',
      'bahai_room',
      'bahai_building',
      'bahai_compositeaddress',
      'bahai_localityid',
      'locality.bahai_name',
      'locality.bahai_localitytype',
      'bahai_clusterid',
      'cluster.bahai_name',
      'cluster.bahai_stageofgrowth',
      'bahai_regionid',
    ] as Keys[],
  ],
];

export const getFormConfig: FormConfigGetter<Keys> = () => [
  [
    <Trans>Main Data</Trans>,
    [
      'bahai_title',
      'bahai_description',
      'bahai_startdatetime',
      'bahai_finishdatetime',
      'bahai_categoryid',
      'bahai_subcategoryid',
      'bahai_designatedcontactobjectid',
      'bahai_designatedhostobjectid',
    ] as Keys[],
  ],
  [
    <Trans>Location</Trans>,
    [
      'bahai_typecode',
      'bahai_videoconferencingurl',
      'bahai_room',
      'bahai_building',
      'bahai_postalcodeid',
      'bahai_cityid',
      'bahai_stateid',
      'bahai_addressline1',
      'bahai_addressline2',
    ] as Keys[],
  ],
];

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_title: rules.compose([rules.required, rules.maxLength(256)]),

  bahai_startdatetime: rules.compose([rules.required, rules.futureDateDirtyOnly]),
  bahai_finishdatetime: rules.compose([
    rules.required,
    rules.futureDateDirtyOnly,
    rules.lessThanDate((values) => values.bahai_startdatetime, <Trans>Can’t be before Start Date/Time</Trans>),
  ]),

  bahai_typecode: rules.required,
  bahai_videoconferencingurl: rules.compose([
    rules.conditionalRule(
      (values) =>
        [eventMetadata.bahai_typecode.Virtual, eventMetadata.bahai_typecode.Hybrid].includes(
          Number(values.bahai_typecode)
        ),
      rules.required
    ),
    rules.maxLength(1000),
  ]),

  bahai_categoryid: rules.compose([rules.required]),

  bahai_designatedcontactobjectid: rules.compose([rules.required]),
  bahai_designatedhostobjectid: rules.compose([rules.required]),

  bahai_room: rules.compose([rules.maxLength(30)]),
  bahai_building: rules.compose([rules.maxLength(30)]),

  bahai_addressline1: rules.compose([
    rules.conditionalRule(
      (values) =>
        [eventMetadata.bahai_typecode.InPerson, eventMetadata.bahai_typecode.Hybrid].includes(
          Number(values.bahai_typecode)
        ),
      rules.required
    ),
    rules.maxLength(40),
  ]),
  bahai_addressline2: rules.compose([rules.maxLength(40)]),

  bahai_postalcodeid: rules.conditionalRule(
    (values) =>
      [eventMetadata.bahai_typecode.InPerson, eventMetadata.bahai_typecode.Hybrid].includes(
        Number(values.bahai_typecode)
      ),
    rules.required
  ),
  bahai_cityid: rules.conditionalRule(
    (values) =>
      [eventMetadata.bahai_typecode.InPerson, eventMetadata.bahai_typecode.Hybrid].includes(
        Number(values.bahai_typecode)
      ),
    rules.required
  ),
  bahai_stateid: rules.conditionalRule(
    (values) =>
      [eventMetadata.bahai_typecode.InPerson, eventMetadata.bahai_typecode.Hybrid].includes(
        Number(values.bahai_typecode)
      ),
    rules.required
  ),
};

export { isLocalityCalculation };

export const isActive = (data: Record<Keys, any>) => data.statecode === eventMetadata.statecode.Active;

export const isNotEditable = (data: Record<Keys, any>) => {
  if (!isActive(data)) return <Trans>Event State is Inactive</Trans>;
  if (isLocalityCalculation(data)) return LocalityWarning;
  return false;
};

export const isNotRemovable = (data: Record<Keys, any>) => {
  if (isLocalityCalculation(data)) return LocalityWarning;
  return false;
};

export const isConfirmationMessageNeeded = () => true;
export const isConfirmationMessageRequired = () => true;

export const statusFields = () => ['statuscode'];

export type ParticipantType = 'requester' | 'resource';

export const participationEntities = {
  requester: 'invitedRequester',
  resource: 'invitedResource',
} as Record<ParticipantType, TEntityName>;
