import { FileRejection, useDropzone } from 'react-dropzone';
import { MouseEvent, useCallback } from 'react';
import { ReactComponent as DeleteIcon } from 'components/Image/icons/remove.svg';
import { ReactComponent as ImageIcon } from 'components/Image/icons/image.svg';
import { ReactComponent as UploadIcon } from 'components/Image/icons/upload.svg';
import classes from 'components/Image/image.module.scss';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'components/IconButton';
import { Loader } from 'components/Loader';
import { useNotifications } from 'providers/NotificationsProvider';

const MB = 1024 * 1024;
const maxFileSize = 10;
const formats = ['jpeg', 'gif', 'bmp', 'png'];

export const Image = ({
  image,
  onChange,
  onRemove,
  loading = false,
  isEditable = true,
}: {
  image?: string;
  loading: boolean;
  onChange: (file: string) => void;
  onRemove: () => void;
  isEditable?: boolean;
}) => {
  const { t } = useTranslation();
  const { addError } = useNotifications();

  const onDrop = useCallback(
    (acceptedFiles: File[], rejected: FileRejection[]) => {
      const reader = new FileReader();
      if (acceptedFiles[0]) {
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onloadend = function () {
          onChange(reader.result as string);
        };
      }
      if (rejected[0]?.errors.length > 0) {
        const [error] = rejected[0].errors;
        switch (error.code) {
          case 'file-invalid-type':
            addError({
              title: t('Image Upload Error'),
              content: t('File type must be one of ') + formats.join(', ') + '.',
            });
            break;
          case 'file-too-large':
            addError({
              title: t('Image Upload Error'),
              content: t('File size is more than {{maxFileSize}} MB', { maxFileSize }),
            });
            break;
          default:
            addError({ title: t('Image Upload Error'), content: error.message });
        }
      }
    },
    [addError, onChange, t]
  );

  const onRemoveClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      onRemove();
    },
    [onRemove]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: maxFileSize * MB,
    accept: Object.fromEntries(formats.map((v) => ['image/' + v, [`.${v}`]])),
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.flex}>
        <div className={cx(classes.root)} {...getRootProps()}>
          {image ? (
            <div className={classes.placeholder}>{loading ? <Loader /> : <img alt="image" src={image} />}</div>
          ) : (
            <>
              <div className={classes.placeholder}>{loading ? <Loader /> : <ImageIcon />}</div>
            </>
          )}
          {isEditable && (
            <>
              <input {...getInputProps()} />
              <UploadIcon className={classes.upload} />
            </>
          )}
        </div>
        {!!image && isEditable && (
          <IconButton className={classes.btn} iconOnly onClick={onRemoveClick} Icon={DeleteIcon} />
        )}
      </div>
    </div>
  );
};
