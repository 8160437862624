import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useApi } from 'domain/api';
import { removePageFromQuery, useMetaData } from 'lib/hooks';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { useRecord } from 'lib/record';
import * as requestersConfig from 'schemas/eventRequesters';
import * as resourcesConfig from 'schemas/eventResources';
import { useListQuery } from 'components/ListPage/hook';
import { useSystemUserId, useTableConfig } from 'lib/helpers';
import {
  EmailRecipient,
  SendEmailValidateFunction,
  useFilterValidEmailRecipients,
  useSendEmail,
} from 'schemas/email/actions';
import { ReactComponent as MailIcon } from 'components/Actions/icons/mail.svg';
import {
  contactToRecipientMapper,
  requesterToRecipientMapper,
  resourceToRecipientMapper,
} from 'pages/Event/components/ParicipantTab';
import { getValidateRequesterForEmailReceive } from 'pages/RequesterList';
import { useLoader } from 'providers/LoaderProvider';

export const useGetRequestersFromEvent = (eventId: string) => {
  const { request } = useApi();
  const {
    entityConfig: { fields, url },
  } = useMetaData('invitedRequester');
  const config = useTableConfig(fields, requestersConfig.config, 'invitedRequester', requestersConfig.links);
  const query = useListQuery({
    config,
    links: requestersConfig.links,
    entityName: 'invitedRequester',
    hiddenFilters: [{ condition: [{ operator: 'eq', attribute: 'bahai_eventid', value: eventId }] }],
  });

  return useCallback(
    () => request<{ value: Record<string, any>[] }>({ url, query: removePageFromQuery(query) }),
    [query, request, url]
  );
};

export const useGetResourcesFromEvent = (eventId: string) => {
  const { request } = useApi();
  const {
    entityConfig: { fields, url },
  } = useMetaData('invitedResource');
  const config = useTableConfig(fields, resourcesConfig.config, 'invitedResource', resourcesConfig.links);
  const query = useListQuery({
    config,
    links: resourcesConfig.links,
    entityName: 'invitedResource',
    hiddenFilters: [{ condition: [{ operator: 'eq', attribute: 'bahai_eventid', value: eventId }] }],
  });

  return useCallback(
    () => request<{ value: Record<string, any>[] }>({ url, query: removePageFromQuery(query) }),
    [query, request, url]
  );
};

export const useSendEmailToAll = (eventId: string) => {
  const { content, setInitialValues } = useSendEmail();
  const { t } = useTranslation();

  const getRequesters = useGetRequestersFromEvent(eventId);
  const getResources = useGetResourcesFromEvent(eventId);
  const userId = useSystemUserId();
  const contact = useRecord('contact');
  const resource = useRecord('resource');

  const filterRecipients = useFilterValidEmailRecipients();

  const getInitialValues = useCallback(
    async (data: Record<string, any>) => {
      const designatedcontact = data['_bahai_designatedcontactobjectid_value'].split('<|>');
      const designatedhost = data['_bahai_designatedhostobjectid_value'].split('<|>');
      const host = {
        type: designatedcontact[0],
        ...(await (designatedcontact[0] === 'systemuser'
          ? resource.getData(designatedcontact[1])
          : contact.getData(designatedcontact[1]))),
      };
      const contactPerson = {
        type: designatedhost[0],
        ...(await (designatedhost[0] === 'systemuser'
          ? resource.getData(designatedhost[1])
          : contact.getData(designatedhost[1]))),
      };

      const requesters = (await getRequesters()).data.value.map((v) => ({ type: 'requester', ...v }));
      const resources = (await getResources()).data.value.map((v) => ({ type: 'resource', ...v }));

      const validateRecipients: SendEmailValidateFunction = (data) => {
        switch (data.type) {
          case 'requester':
            return getValidateRequesterForEmailReceive('requester.')(data);
          case 'contact':
          case 'bahai_contact_ims':
            if (!data.bahai_emailaddress1 && !data.bahai_emailaddress2) {
              return {
                label: data.bahai_name,
                content: <Trans>Contact doesn`t have an email address</Trans>,
              };
            }
        }
      };

      const mapper = (data: Record<string, any>): EmailRecipient => {
        switch (data.type) {
          case 'requester':
            return requesterToRecipientMapper(data);
          case 'resource':
          case 'systemuser':
            return resourceToRecipientMapper(data);
          default:
            return contactToRecipientMapper(data);
        }
      };

      const recipients = filterRecipients(
        [host, contactPerson, ...requesters, ...resources],
        validateRecipients,
        mapper,
        t('People')
      );

      const prefixes = {
        requester: 'bahai_inquirer',
        resource: 'systemuser',
        contact: 'bahai_contact_ims',
      };

      setInitialValues({
        directioncode: true,
        from: 'systemuser<|>' + userId,
        bcc: [...recipients.map(({ id, type = 'contact' }) => prefixes[type] + '<|>' + id)],
        regardingobjectid: 'bahai_event_ims<|>' + eventId,
      });
    },

    [contact, eventId, filterRecipients, getRequesters, getResources, resource, setInitialValues, t, userId]
  );

  const { showLoader, hideLoader } = useLoader();
  const createEmail = useCallback(
    (data: Record<string, any>) => {
      showLoader();
      getInitialValues(data).finally(hideLoader);
    },
    [getInitialValues, hideLoader, showLoader]
  );

  const action: Action = useMemo(
    () =>
      ({
        title: t('Send Email'),
        name: 'sendEmail',
        onClick: ({ selectedItems }) => createEmail(selectedItems[0]),
        Icon: MailIcon,
        type: ActionType.CUSTOM_ACTION,
        actionContext: ActionContext.SinglePage,
        allowedDevices: AllowedDevices.All,
      }) as Action,
    [createEmail, t]
  );

  return { action, content };
};
