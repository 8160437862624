import { Trans, useTranslation } from 'react-i18next';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { useCallback } from 'react';
import { TActionControlsProps, TSubmitProps } from 'components/ListPage/components/Form';
import { HistoryLink } from 'components/HistoryLink';
import { routes } from 'domain/routes';
import { Control } from 'components/Panel';
import { useRecord } from 'lib/record';
import { useApi } from 'domain/api';
import * as emailMetadata from 'config/EntityMetadata/email';
import { useMetaData } from 'lib/hooks';
import { parseSaveFormError } from 'lib/errorParser';

export const useEmail = () => {
  const { t } = useTranslation();
  const { addNotification, addActionFailed } = useNotifications();
  const { save } = useRecord('email');
  const { sendEmail, request } = useApi();
  const { url } = useMetaData('email');

  const ensureEmailIsDraft = useCallback(
    async (data: Record<string, any>, id?: string) => {
      if (data.statuscode === emailMetadata.statuscode.Failed) {
        await request<Record<string, any>>({
          url: `${url}(${id})`,
          data: {
            statuscode: emailMetadata.statuscode.Draft,
          },
          method: 'patch',
        });
        data.statuscode = emailMetadata.statuscode.Draft;
      }
    },
    [request, url]
  );

  const saveAsDraft = useCallback(
    async ({
      data,
      errors,
      postAction,
      id,
      form,
    }: Pick<TActionControlsProps, 'data' | 'errors' | 'postAction' | 'id' | 'form'>) => {
      try {
        const { _general: _, ...errorList } = errors || {};
        const nonRequiredErrors = Object.entries(errorList)
          .filter((v) => !!v[1])
          .filter((v) => v[1]?.props?.children !== 'Required');

        if (nonRequiredErrors.length > 0) {
          nonRequiredErrors.forEach((v) => form.blur(v[0]));
          return;
        }

        await ensureEmailIsDraft(data, id);
        const resp = await save(data, id);
        if (resp.status > 204) {
          addActionFailed(<Trans>Something went wrong while saving Email</Trans>);
        } else {
          const recordId = id || resp?.headers?.location?.match(/\((.+)\)/)?.[1];
          if (!id) {
            addNotification({
              title: <Trans>Record was created</Trans>,
              type: NotificationType.SUCCESS,
              content: recordId ? (
                <Trans>
                  Please, go to <HistoryLink to={routes.email({ id: recordId })}>hyperlink</HistoryLink> to see
                </Trans>
              ) : undefined,
            });
          } else {
            addNotification({ type: NotificationType.SUCCESS, title: <Trans>Your changes have been saved</Trans> });
          }
          postAction(id);
        }
      } catch (error) {
        addActionFailed(parseSaveFormError(error, !!id));
      }
    },
    [addActionFailed, addNotification, save, ensureEmailIsDraft]
  );

  const onSubmit = useCallback(
    async ({ data, id, postAction }: TSubmitProps) => {
      try {
        await ensureEmailIsDraft(data, id);
        const resp = await save(data, id);
        if (resp.status > 204) {
          throw { _general: [<Trans>Something went wrong while saving Email</Trans>] };
        } else {
          const recordId = id || (resp?.headers?.location?.match(/\((.+)\)/)?.[1] as string);
          const sendResp = await sendEmail(recordId);
          if (sendResp.status > 204) {
            return { _general: [<Trans>Something went wrong while sending Email</Trans>] };
          }
          addNotification({
            title: <Trans>Email was sent</Trans>,
            type: NotificationType.SUCCESS,
            content: recordId ? (
              <Trans>
                Please, go to <HistoryLink to={routes.email({ id: recordId })}>hyperlink</HistoryLink> to see
              </Trans>
            ) : undefined,
          });
        }
        postAction(id);
      } catch (error) {
        throw { _general: [parseSaveFormError(error, !!id)] };
      }
    },
    [addNotification, save, sendEmail, ensureEmailIsDraft]
  );

  const getActionControls = useCallback(
    ({ loading, refresh, setLoading, ...rest }: TActionControlsProps) => [
      {
        title: t('Send'),
        role: 'primary',
        disabled: loading,
        type: 'submit',
        onMouseDown: refresh,
      } as Control,
      {
        title: t('Save'),
        onClick: () => {
          setLoading(true);
          saveAsDraft(rest).finally(() => setLoading(false));
        },
      } as Control,
    ],
    [saveAsDraft, t]
  );

  return { onSubmit, getActionControls };
};
