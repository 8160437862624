import { useMemo } from 'react';
import { config as inquiryConfig, Keys, links as inquiryLinks } from 'schemas/inquiry';
import * as inquiryMetadata from 'config/EntityMetadata/bahai_inquiry';
import { SimpleTable, SimpleTableProps } from 'components/SimpleTable';

export const columns: Record<Keys, number> = {
  bahai_id: 150,
  bahai_typecode: 150,
  bahai_statuscode: 100,
  bahai_comment: 200,
  bahai_sourcecode: 100,
  bahai_sourcedetails: 200,
  createdon: 160,
  bahai_regionid: 200,
  bahai_localityid: 150,
  'locality.bahai_name': 200,
  bahai_clusterid: 150,
  'cluster.bahai_name': 200,
  bahai_note: 200,
  bahai_additionalnote: 200,
};

export const InquiryPanel = ({ mainId, duplicateId }: { mainId: string; duplicateId?: string }) => {
  const inquiryProps = useMemo(
    () =>
      ({
        props: { sorting: [{ name: 'createdon', direction: 'desc' }] },
        links: {
          ...inquiryLinks,
        },
        hiddenFilters: [
          {
            filter: [
              {
                logicOperator: 'or',
                condition: [{ attribute: 'bahai_inquirerid', operator: 'eq', value: mainId }],
                ...(duplicateId
                  ? {
                      filter: [
                        {
                          condition: [
                            {
                              attribute: 'bahai_statuscode',
                              operator: 'ne',
                              value: String(inquiryMetadata.bahai_inquirystatus.Concluded),
                            },
                            { attribute: 'bahai_inquirerid', operator: 'eq', value: duplicateId },
                          ],
                        },
                      ],
                    }
                  : {}),
              },
            ],
          },
        ],
      }) as Partial<SimpleTableProps>,
    [duplicateId, mainId]
  );

  return (
    <SimpleTable showControls={false} columns={columns} config={inquiryConfig} entityName="inquiry" {...inquiryProps} />
  );
};
