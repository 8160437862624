import { routes as r, TRouteName } from 'domain/routes';
import { NavLink } from 'react-router-dom';
import classes from './menu.module.scss';
import cx from 'classnames';
import { ReactNode, SVGProps, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ReactComponent as DismissIcon } from 'components/icons/dismiss.svg';
import { ReactComponent as HomeIcon } from './icons/home.svg';
import { ReactComponent as EventIcon } from './icons/event.svg';
import { ReactComponent as MailIcon } from './icons/email.svg';
import { ReactComponent as MenuIcon } from './icons/menu.svg';
import { ReactComponent as RequesterIcon } from './icons/requester.svg';
import { ReactComponent as InquiryIcon } from './icons/inquiry.svg';
import { ReactComponent as ContactIcon } from './icons/contact.svg';
import { ReactComponent as GlobeIcon } from './icons/globe.svg';
import { ReactComponent as ResourceIcon } from './icons/resource.svg';
import { ScreenContext } from 'providers/ScreenProvider';
import { IconButton } from 'components/IconButton';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorContext } from 'providers/ErrorProvider';
import { LogoBlock } from 'components/Header';
import styled, { css } from 'styled-components';
import { colorsConfig, getEntityNameFromRoute } from 'providers/EntityMatcher';
import { Privilege, TEntityName } from 'lib';
import { useSecurity } from 'providers/AuthProvider';
import { createPortal } from 'react-dom';

const version = process.env.REACT_APP_VERSION;

const items: Record<string, [(iconProps: SVGProps<SVGSVGElement>) => ReactNode, JSX.Element, boolean | undefined]> = {
  home: [HomeIcon, <Trans>Dashboard</Trans>, true],
  inquiryList: [InquiryIcon, <Trans>Inquiries</Trans>, false],
  requesterList: [RequesterIcon, <Trans>Requesters</Trans>, false],
  eventList: [EventIcon, <Trans>Events</Trans>, false],
  resourceList: [ResourceIcon, <Trans>Resources</Trans>, false],
  emailList: [MailIcon, <Trans>Emails</Trans>, false],
  contactList: [ContactIcon, <Trans>Contacts</Trans>, false],
};
const Item = styled(NavLink)<{ $icon?: string; $bg?: string }>(
  ({ $icon = '#D81B60', $bg = '#F8BBD0' }) => css`
    &:hover:not(.active) {
      background-color: ${$bg};
      span {
        color: ${$icon};
      }
      path {
        fill: ${$icon};
      }
    }
    &.active {
      background-color: ${$bg};
      span {
        color: ${$icon};
      }
      path {
        fill: ${$icon};
      }
      .${classes.linkText} {
        &:before {
          content: '';
          position: absolute;
          top: 8px;
          left: 5px;
          height: calc(100% - 16px);
          width: 2px;
          background-color: ${$icon};
          z-index: 1;
        }
      }
      &:before {
        content: '';
        position: absolute;
        background-color: transparent;
        top: 100%;
        left: 96px;
        height: 32px;
        width: 16px;
        border-top-right-radius: 16px;
        box-shadow: 0 -16px 0 0 ${$bg};
        z-index: 1;
      }
      &:after {
        content: '';
        position: absolute;
        background-color: transparent;
        top: -32px;
        left: 96px;
        height: 32px;
        width: 16px;
        border-bottom-right-radius: 16px;
        box-shadow: 0 16px 0 0 ${$bg};
        z-index: 1;
      }
    }
  `
);

const Menu = () => {
  const { t } = useTranslation();
  const { isMobile } = useContext(ScreenContext);
  const { isGranted } = useSecurity();
  const [collapsedMobile, setCollapsedMobile] = useState(true);

  const mobileToggle = useCallback(() => setCollapsedMobile((v) => !v), [setCollapsedMobile]);

  useEffect(() => {
    isMobile && setCollapsedMobile(true);
  }, [isMobile]);

  const errorContext = useContext(ErrorContext);

  const menuClick = useCallback(() => {
    isMobile && setCollapsedMobile(true);
    errorContext.clearError();
  }, [isMobile, errorContext, setCollapsedMobile]);

  const elements = useMemo(
    () => (
      <>
        {Object.entries(items)
          .filter(([name]) => name === 'home' || isGranted(getEntityNameFromRoute(name), Privilege.Read))
          .map(([name, [Icon, label, exact]]) => (
            <Item
              id={name}
              className={cx(classes.link, { [classes.collapsed]: collapsedMobile && isMobile })}
              activeClassName={'active'}
              key={name}
              to={r[name as TRouteName] ? r[name as TRouteName].path : '/'}
              exact={exact}
              onClick={menuClick}
              $icon={colorsConfig[name.replace('List', '') as TEntityName]?.color}
              $bg={colorsConfig[name.replace('List', '') as TEntityName]?.bg}
            >
              <Icon className={classes.icon} />
              <span className={classes.linkText}>{label}</span>
            </Item>
          ))}
        <a
          className={cx(classes.link, classes.resources)}
          href="https://sites.google.com/bahai.us/inquiry-services-resource-site"
          target="_blank"
          id="resourceLink"
          rel="noreferrer"
        >
          <GlobeIcon className={classes.icon} />
          <span className={classes.linkText}>{t('Resource Site')}</span>
        </a>
        <div className={classes.version}>
          <div>Ver {version}</div>
        </div>
      </>
    ),
    [collapsedMobile, isGranted, isMobile, menuClick, t]
  );

  const [header, setHeader] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setHeader(document.getElementById('header'));
  }, []);

  if (isMobile) {
    return (
      <div className={classes.mobile}>
        {header &&
          createPortal(
            <IconButton className={classes.mobileBtn} Icon={MenuIcon} onClick={mobileToggle}>
              <Trans>Menu</Trans>
            </IconButton>,
            header
          )}
        {!collapsedMobile && (
          <div className={classes.mobileHolder}>
            <div className={classes.wrapper}>
              <button type="button" className={classes.close} onClick={mobileToggle}>
                <DismissIcon />
              </button>
              <div className={classes.logoWrapper}>
                <LogoBlock />
              </div>
              <div className={classes.elements}>{elements}</div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return <aside className={classes.root}>{elements}</aside>;
};

export default Menu;
