import ListPage, { TListPage } from 'components/ListPage';
import * as baseConfig from 'schemas/inquiry';
import { useCallback, useEffect } from 'react';
import { useFormChanges } from 'lib/hooks';
import { useApi } from 'domain/api';
import { useFormState } from 'react-final-form';
import { ChainImprover } from 'lib/improvers';
import { RequesterFormProvider } from 'schemas/inquiry/components/RequesterFormProvider';
import { useRecord } from 'lib/record';
import { validate } from 'schemas/inquiry';
import { useInquiry } from 'schemas/inquiry/hooks';

export const addressFields = [
  'bahai_isoutofusa',
  'bahai_postalcodeid',
  'bahai_stateid',
  'bahai_cityid',
  'bahai_addressline1',
  'bahai_addressline2',
  'bahai_compositeaddress',
] as const;

const chains = [['bahai_postalcodeid', 'bahai_cityid', 'bahai_stateid']];

const RequesterAddressImprover = () => {
  const { request } = useApi();
  const { changes, update } = useFormChanges(['bahai_inquirerid', 'bahai_isaddress']);
  const { values, initialValues } = useFormState();
  const { getFormValues } = useRecord('requester');

  const updateAddressFromRequester = useCallback(async () => {
    const { data } = await request<Partial<Record<string, any>>>({
      url: `bahai_inquirers(${changes.bahai_inquirerid || values.bahai_inquirerid})`,
    });

    const formValues = getFormValues(data);
    update(Object.fromEntries(addressFields.map((key) => [key, formValues[key] ?? null])));
  }, [request, changes.bahai_inquirerid, values.bahai_inquirerid, getFormValues, update]);

  useEffect(() => {
    // REQUESTER CHANGED
    if (Object.keys(changes).includes('bahai_inquirerid')) {
      if (changes.bahai_inquirerid) {
        updateAddressFromRequester().then();
      }
    }
  }, [changes, request, update, values, initialValues, updateAddressFromRequester]);
  return null;
};

export const Improver = () => (
  <>
    <RequesterAddressImprover />
    <ChainImprover chains={chains} forceClearFields={['bahai_postalcodeid']} entityName="inquiry" />
  </>
);

const InquiryList = (props: Partial<TListPage>) => {
  const { onSubmit } = useInquiry();
  return (
    <RequesterFormProvider>
      <ListPage
        entityName="inquiry"
        {...{ ...baseConfig, validate, onSubmit }}
        isExportAllowed
        FormImprover={Improver}
        isRemoveHidden={false}
        {...props}
      />
    </RequesterFormProvider>
  );
};

export default InquiryList;
