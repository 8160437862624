import { FC, ReactNode, useCallback, useMemo } from 'react';
import * as Base from './Components';
import { SelectionType, TableProps, TBodyProps, TRowComponent, WithClasses } from './types';
import cx from 'classnames';
import defaultClasses from './table.module.scss';
import objectHash from 'object-hash';

const TBodyDefault = <Data extends Record<string, any>>({
  data,
  TBodyComponent,
  Row,
  classes,
  cellSizes,
  columns,
  selectionProps,
  onRowDblClick,
  ...props
}: TBodyProps<Data>) => {
  const keyGen = useCallback(
    (data: Data) => {
      return objectHash({ data, cellSizes, columns });
    },
    [cellSizes, columns]
  );

  const rowSelectionProps = useCallback(
    (index: number) => {
      if (selectionProps) {
        return {
          selected: selectionProps.selected.includes(index),
          toggle: () => selectionProps.toggle(index),
        };
      }
    },
    [selectionProps]
  );

  return (
    <TBodyComponent classes={classes}>
      {data.map((rowData, index) => (
        <Row
          onRowDblClick={onRowDblClick ? () => onRowDblClick(rowData) : undefined}
          key={keyGen({ ...rowData, pinnedColumns: props.pinnedColumns, cellSizes })}
          classes={classes}
          cellSizes={cellSizes}
          columns={columns}
          data={rowData}
          {...props}
          selectionProps={rowSelectionProps(index)}
        />
      ))}
    </TBodyComponent>
  );
};

const TheadBase: FC<{ children: ReactNode } & WithClasses> = ({ children, classes }) => (
  <div className={cx(classes.thead)}>{children}</div>
);

const TBodyBase: FC<{ children: ReactNode } & WithClasses> = ({ children, classes }) => (
  <div className={classes.tbody} id="table_body">
    {children}
  </div>
);

const RowBase: TRowComponent = ({ children, classes, selected, ...props }) => {
  return (
    <div className={cx(classes.row, { [classes.selected]: selected })} {...props}>
      {children}
    </div>
  );
};

const DataTable = <Data extends Record<string, any>>({
  data,
  columns,
  classes: overrideClasses = {},
  THead = Base.THead,
  TheadComponent = TheadBase,
  Th = Base.Th,
  TBody = TBodyDefault,
  TBodyComponent = TBodyBase,
  Row = Base.Row,
  RowComponent = RowBase,
  Cell = Base.Cell,
  CellComponent,
  config,
  onSubmit,
  cellSizes,
  onCellResize,
  pinnedColumns = [],
  pinColumn,
  defaultValue = '---',
  selected,
  setSelected,
  placeholder,
  onRowDblClick,
  tableName,
  selectionType = SelectionType.CHECK_BOX,
  showControls = true,
  context = 'TABLE',
  uniqueKey,
  fixedRows = true,
}: TableProps<Data>) => {
  const classes = useMemo(() => ({ ...defaultClasses, ...overrideClasses }), [overrideClasses]);

  const theadSelectionProps = useMemo(() => {
    if (selected && setSelected)
      return {
        selected: selected.length === data.length,
        toggle: () => setSelected(selected.length === data.length ? [] : Array.from(data.keys())),
        selectionType,
      };
  }, [selected, setSelected, data, selectionType]);

  const tbodySelectionProps = useMemo(() => {
    if (selected && setSelected)
      return {
        selected,
        toggle: (index: number) =>
          setSelected(selected.includes(index) ? selected.filter((v) => v !== index) : selected.concat(index)),
      };
  }, [selected, setSelected]);

  return (
    <div className={cx(classes.table, { [classes.autoRows]: !fixedRows })}>
      <THead
        classes={classes}
        columns={columns}
        config={config}
        Th={Th}
        TheadComponent={TheadComponent}
        cellSizes={cellSizes}
        onCellResize={onCellResize}
        pinnedColumns={pinnedColumns}
        pinColumn={pinColumn}
        selectionProps={theadSelectionProps}
        isEmpty={data.length === 0}
        tableName={tableName}
        showControls={showControls}
      />
      {placeholder || (
        <>
          <TBody
            TBodyComponent={TBodyComponent}
            Cell={Cell}
            Row={Row}
            RowComponent={RowComponent}
            CellComponent={CellComponent}
            columns={columns}
            data={data}
            config={config}
            classes={classes}
            onSubmit={onSubmit}
            cellSizes={cellSizes}
            defaultValue={defaultValue}
            selectionProps={tbodySelectionProps}
            pinnedColumns={pinnedColumns}
            onRowDblClick={onRowDblClick}
            selectionType={selectionType}
            context={context}
            uniqueKey={uniqueKey}
          />
        </>
      )}
    </div>
  );
};

export * from './types';
export default DataTable;
