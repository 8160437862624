import { SinglePage, Tab } from 'components/SinglePage';
import * as baseConfig from 'schemas/event';
import { getWarningsImprover } from 'schemas/event';
import { useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useCallback, useContext } from 'react';
import { Action } from 'components/Actions';
import { useSendEmailToAll } from 'schemas/event/actions';
import { ParticipantTab } from 'pages/Event/components/ParicipantTab';
import { getValidateRequesterForEmailReceive } from 'pages/RequesterList';
import DocumentList from 'pages/DocumentList';
import { config as documentConfig } from 'schemas/document';
import { Privilege } from 'lib';
import { useSecurity } from 'providers/AuthProvider';
import { useChangeStatus } from 'components/ChangeStatus';
import { Loader } from 'components/Loader';
import classes from 'pages/Common/pages.common.module.scss';
import { AuthContext } from 'providers/AuthProvider';

const Event = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const { isGranted } = useSecurity();

  const {
    changeStatus: changeStatusAction,
    content: changeStatusContent,
    pageLoading: changeStatusLoading,
  } = useChangeStatus({
    entityName: 'event',
    statusFieldName: 'statuscode',
    additionalNotesFieldName: 'bahai_confirmationmessage',
  });

  const { action: sendToAll, content: sendContent } = useSendEmailToAll(id);
  const getPageActions = useCallback(
    (baseActions: Action[]) => [
      ...baseActions,
      changeStatusAction,
      ...(isGranted('email', Privilege.Create) ? [sendToAll] : []),
    ],
    [changeStatusAction, isGranted, sendToAll]
  );

  const isNotCreatable = (data: Record<string, any>) =>
    !!baseConfig.isNotEditable(data) && <Trans>Event State is Inactive</Trans>;

  const isDocumentNotRemovable = (data: Record<string, any>) =>
    !baseConfig.isActive(data) && <Trans>Event State should be Active to delete it</Trans>;
  const { businessUnitType } = useContext(AuthContext);
  const WarningsImprover = getWarningsImprover(businessUnitType);

  return (
    <>
      {changeStatusLoading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      {sendContent}
      <SinglePage
        entityName="event"
        getActions={getPageActions}
        {...{
          ...baseConfig,
          WarningsImprover,
        }}
        displayAssign={true}
        additionalTabs={
          [
            {
              label: t('Invited Requesters'),
              tab: 'invitedRequester',
              isDefault: true,
              content: (data: Record<string, any>) => (
                <ParticipantTab
                  data={data}
                  participantType="requester"
                  sendEmailValidation={getValidateRequesterForEmailReceive('requester.')}
                  id={id}
                />
              ),
            },
            {
              label: t('Invited Resources'),
              tab: 'invitedResource',
              isDefault: true,
              content: (data: Record<string, any>) => (
                <ParticipantTab sendEmailValidation={() => undefined} data={data} participantType="resource" id={id} />
              ),
            },
            {
              label: t('Documents'),
              tab: 'document',
              content: (data: Record<string, any>, reload: () => void) => (
                <DocumentList
                  config={documentConfig}
                  onItemCreated={reload}
                  onItemsRemoved={reload}
                  parentEntityName="event"
                  parentId={id}
                  isNotCreatable={isNotCreatable(data)}
                  isNotEditable={baseConfig.isNotEditable(data)}
                  isNotRemovable={() => isDocumentNotRemovable(data)}
                />
              ),
            },
          ].filter((v) => v.tab !== 'document') as Tab[]
        }
      />
      {changeStatusContent}
    </>
  );
};

export default Event;
