import { IconButton } from 'components/IconButton';
import { ReactComponent as PlusIcon } from './plus.svg';
import { useContext } from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { RequesterFormContext } from 'schemas/inquiry/components/RequesterFormProvider';
import classes from './addButton.module.scss';

export const RequesterAddButton = () => {
  const { show } = useContext(RequesterFormContext);
  const { change } = useForm();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <IconButton className={classes.btn} Icon={PlusIcon} onMouseDown={() => show(change)}>
        {t('New Requester')}
      </IconButton>
    </div>
  );
};
