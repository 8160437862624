export const ISO_DATE_FORMAT = 'yyyy-MM-dd';
export const ISO_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";
export const DATE_OUTPUT_FORMAT = 'MM/dd/yyyy';
export const DATE_PLACEHOLDER = 'MM/DD/YYYY';
export const DATETIME_OUTPUT_FORMAT = `MM/dd/yyyy hh:mm a`;
export const DATETIME_PLACEHOLDER = 'MM/DD/YYYY hh:mm AM/PM';
export const PHONE_OUTPUT_FORMAT = '(___) ___-____';

export const enum USER_ROLES {
  RegionalIMSCoordinator = '[IMS] Regional IMS Coordinator',
  SubRegionalIMSCoordinator = '[IMS] Sub-Regional IMS Coordinator',
  ClusterGroupIMSCoordinator = '[IMS] Cluster Group IMS Coordinator',
  ClusterIMSCoordinator = '[IMS] Cluster IMS Coordinator',
  LocalityIMSCoordinator = '[IMS] Locality IMS Coordinator',
  CommunityAdministrationTeamMember = '[IMS] Community Administration Team Member',
  NationalInquiryServicesTeamMember = '[IMS] National Inquiry Services Team Member',
  MembershipTeamMember = '[IMS] Membership Team Member',
  NSASecretariatTeamMember = '[IMS] NSA Secretariat Team Member',
  AssemblyDevelopmentTeamMember = '[IMS] Assembly Development Team Member',
  ITAdministrator = 'IT Administrator',
  InquiryServiceAdministrator = '[IMS] Inquiry Service Administrator',
  SupportAdministrator = 'Support Administrator',
  SystemAdministrator = 'System Administrator',
}

export const enum TEAMS {
  IQS = 'IQS',
  Membership = 'Membership',
  NSASecretariat = 'NSA Secretariat',
  AssemblyDevelopment = 'Assembly Development',
  CommunityAdministration = 'Community Administration',
}
