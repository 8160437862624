import classes from './menuButton.module.scss';
import { IconButton, IconButtonProps } from 'components/IconButton';
import { FC, MouseEventHandler, useCallback, useContext, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { createPortal } from 'react-dom';
import { ScreenContext } from 'providers/ScreenProvider';

type MenuButtonProps = Omit<IconButtonProps, 'onClick' | 'onMouseDown' | 'onBlur'> & {
  title?: string | JSX.Element;
};

export const MenuButton: FC<MenuButtonProps> = ({ title, children, className, ...props }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu: MouseEventHandler = useCallback(() => setMenuVisible((v) => !v), [setMenuVisible]);

  const ref = useRef<HTMLDivElement>(null);

  const close = (e: Event) => {
    if (ref.current && ref.current.contains(e.target as Node)) return;
    if (e.type === 'scroll' && (e.target as HTMLDivElement)?.classList?.contains('dontHideOnScroll')) {
      return;
    }
    if ((e.target as HTMLDivElement)?.classList?.contains('tool-tip')) return;
    setTimeout(() => setMenuVisible(false), 100);
  };

  useEffect(() => {
    if (menuVisible) {
      window.addEventListener('click', close);
      window.addEventListener('scroll', close, true);
    }
    return () => {
      window.removeEventListener('click', close);
      window.removeEventListener('scroll', close, true);
    };
  }, [menuVisible]);

  const [ownStyles, setOwnStyles] = useState({});

  const childrenWrapper = useRef<HTMLDivElement>(null);

  const adjustMenuPosition = () => {
    if (childrenWrapper.current && ref.current) {
      const { left, top, height: btnHeight } = ref.current.getBoundingClientRect();
      const { width, height: baseHeight } = childrenWrapper.current.getBoundingClientRect();
      const height = Math.min(baseHeight, window.document.body.offsetHeight - top - btnHeight - 10);
      setOwnStyles({
        maxHeight: Math.max(top + btnHeight, window.document.body.offsetHeight - top - btnHeight) - 10 + 'px',
        top: `${
          top + btnHeight + height > window.document.body.offsetHeight
            ? document.body.offsetHeight - height - 10
            : top + btnHeight
        }px`,
        left: `${left + width > window.document.body.offsetWidth ? document.body.offsetWidth - width - 10 : left}px`,
      });
    }
  };

  const { orientation } = useContext(ScreenContext);
  useEffect(() => {
    window.addEventListener('resize', adjustMenuPosition, true);
    return () => {
      window.removeEventListener('resize', adjustMenuPosition, true);
    };
  }, []);

  useEffect(() => {
    adjustMenuPosition();
  }, [orientation]);

  useEffect(() => {
    adjustMenuPosition();
  }, [menuVisible]);

  return (
    <div ref={ref} className={cx(classes.root, className)}>
      <IconButton className={classes.button} onClick={toggleMenu} {...props}>
        {title}
      </IconButton>
      {menuVisible &&
        createPortal(
          <div style={ownStyles} ref={childrenWrapper} className={cx(classes.menu)}>
            {children}
          </div>,
          document.body
        )}
    </div>
  );
};
