import { SinglePage } from 'components/SinglePage';
import * as baseConfig from 'schemas/template';

export const Template = () => (
  <SinglePage
    hideBahaiId
    entityName="template"
    displayAssign={true}
    isNotEditable={() => false}
    isNotRemovable={() => false}
    {...baseConfig}
    isConfirmationMessageNeeded={() => true}
    isConfirmationMessageRequired={() => false}
  />
);
