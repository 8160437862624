import ListPage from 'components/ListPage';
import * as config from 'schemas/resource';
import { useSendEmailFromList } from 'schemas/email/actions';
import { useCallback, useContext, useMemo } from 'react';
import { Action } from 'components/Actions';
import { resourceToRecipientMapper } from 'pages/Event/components/ParicipantTab';
import { ChainImprover } from 'lib/improvers';
import { USER_ROLES } from 'lib/const';
import { AuthContext } from 'providers/AuthProvider';

const chains = [['bahai_postalcodeid', 'bahai_cityid', 'bahai_stateid']];

export const Improver = () => (
  <>
    <ChainImprover chains={chains} forceClearFields={['bahai_postalcodeid']} entityName="resource" />
  </>
);

export const ResourceList = () => {
  const { roles } = useContext(AuthContext);

  const isExportAllowed = useMemo(() => {
    const rolesAllowedForExport = [
      USER_ROLES.RegionalIMSCoordinator,
      USER_ROLES.CommunityAdministrationTeamMember,
      USER_ROLES.NationalInquiryServicesTeamMember,
      USER_ROLES.MembershipTeamMember,
      USER_ROLES.NSASecretariatTeamMember,
      USER_ROLES.AssemblyDevelopmentTeamMember,
      USER_ROLES.ITAdministrator,
      USER_ROLES.InquiryServiceAdministrator,
      USER_ROLES.SupportAdministrator,
      USER_ROLES.SystemAdministrator,
    ];
    return roles.some((item) => rolesAllowedForExport.includes(item as USER_ROLES));
  }, [roles]);

  const { actions: sendEmailActions, content } = useSendEmailFromList(
    'resource',
    () => undefined,
    'resource',
    resourceToRecipientMapper
  );

  const getActions = useCallback(
    (baseActions: Action[]) => {
      return [...baseActions, ...sendEmailActions];
    },
    [sendEmailActions]
  );
  return (
    <>
      {content}
      <ListPage
        isCreateHidden
        isRemoveHidden
        entityName="resource"
        {...{ ...config, getActions }}
        isExportAllowed={isExportAllowed}
      />
    </>
  );
};
