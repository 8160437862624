import entities from 'config';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { ChangeSet } from 'schemas/historylog/components/ChangeSet';
import { Trans } from 'react-i18next';
import classes from './style.module.scss';

export type Keys = (typeof entities.historylog.columns)[number];

export const columns = {
  bahai_changeddatetime: 200,
  bahai_entitycode: 200,
  bahai_eventname: 150,
  bahai_recordid: 300,
  bahai_changedata: 900,
  bahai_changedby: 200,
};

export const config: AdditionalConfigType<Keys> = {
  bahai_eventname: {
    hideFilters: true,
  },
  bahai_changedata: {
    sortable: false,
    component: ChangeSet,
    label: (
      <div className={classes.field}>
        <div>
          <Trans>Field</Trans>
        </div>
        <div>
          <Trans>Old Value</Trans>
        </div>
        <div>
          <Trans>New Value</Trans>
        </div>
      </div>
    ),
  },
  bahai_recordid: {
    hideFilters: true,
  },
};

export const getFormConfig: FormConfigGetter<Keys> = () => [];
