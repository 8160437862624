import { FC, ReactNode, useMemo } from 'react';
import classes from './notification.module.scss';
import cx from 'classnames';
import { IconButton } from 'components/IconButton';
import { ReactComponent as DismissIcon } from 'components/icons/dismiss.svg';
import { ReactComponent as ErrorIcon } from './icons/error.svg';
import { ReactComponent as SuccessIcon } from './icons/success.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';

const icons = {
  error: ErrorIcon,
  warning: WarningIcon,
  info: InfoIcon,
  success: SuccessIcon,
};

export const Notification: FC<{
  type?: 'error' | 'warning' | 'success' | 'info';
  onHide?: () => void;
  children?: ReactNode;
}> = ({ children, onHide, type = 'error' }) => {
  const Icon = useMemo(() => icons[type], [type]);

  return (
    <div className={cx(classes.root, classes[type])}>
      <Icon className={classes.icon} />
      <div>{children}</div>
      {onHide && <IconButton onClick={onHide} Icon={DismissIcon} />}
    </div>
  );
};
