import classes from './radio.module.scss';
import cx from 'classnames';

export const Radio = ({ checked }: { checked?: boolean }) => {
  return (
    <div className={cx(classes.root, { [classes.active]: checked })}>
      {checked && <span className={classes.circle} />}
    </div>
  );
};
