import { TInputProps } from 'components/Form/types';
import { SelectEmailTemplate } from 'schemas/email/components/SelectEmailTemplate';
import { TextEditor } from 'components/Form/TextEditor';

const TemplateTextEditor = ({ ...props }: TInputProps) => {
  return (
    <>
      <SelectEmailTemplate />
      <TextEditor {...props} />
    </>
  );
};

export { TemplateTextEditor };
