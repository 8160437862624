import { FieldType, TEntityName, TStatusConfig } from 'lib';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from 'domain/api';
import { useMetaData } from 'lib/hooks';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { ReactComponent as EditStatusIcon } from 'schemas/requester/status.svg';
import { createSelect } from 'components/Form/Select';
import { Field, Form } from 'react-final-form';
import * as rules from 'lib/rules';
import { Input } from 'components/Form';
import { Modal } from 'components/Modal';
import classes from './status.module.scss';
import { ClearImprover } from 'lib/improvers';
import { parseError } from 'lib/errorParser';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { useRecord } from 'lib/record';
import { TLinkEntity } from 'components/ListPage';
import { Notification } from 'components/Notification';

export const useChangeStatus = ({
  entityName,
  statusFieldName,
  statusDetailsFieldName,
  additionalNotesFieldName,
  links,
  successMessage,
}: {
  entityName: TEntityName;
  statusFieldName: string;
  statusDetailsFieldName?: string;
  additionalNotesFieldName: string;
  links?: TLinkEntity;
  successMessage?: string;
}) => {
  const [data, setData] = useState<Record<string, any>>();
  const [statuses, setStatuses] = useState<TStatusConfig[]>();

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const reloadRef = useRef<() => void>(() => undefined);
  const { t } = useTranslation();
  const { request, getAvailableStatuses } = useApi();
  const {
    PrimaryIdAttribute,
    entityConfig: { url, fields, logicalName },
    getFieldType,
  } = useMetaData(entityName);

  const { getData, replaceEntityValues } = useRecord(entityName);

  const eTag = useMemo(() => data?.['@odata.etag'], [data]);

  const { addNotification, addActionFailed, addActionUncompleted } = useNotifications();

  const checkFormData = useCallback(
    (data: Record<string, any>) => {
      if (!additionalNotesFieldName) return data;
      const { [additionalNotesFieldName]: note, ...restData } = data;
      return note ? data : restData;
    },
    [additionalNotesFieldName]
  );

  const submit = useCallback(
    async (formData: Record<string, any>) => {
      setLoading(true);
      try {
        const {
          NativeStatusCode: statuscode,
          NativeStateCode: statecode,
          DataToUpdate: statusData = {},
          AvailableStatusDetails,
        } = statuses?.find((x) => `${x.StatusCode}` === formData[statusFieldName]) as TStatusConfig;

        const { DataToUpdate: detailsData = {} } = statusDetailsFieldName
          ? AvailableStatusDetails?.find((v) => `${v.Code}` === formData[statusDetailsFieldName]) || {}
          : {};

        const extraData = { statuscode, statecode };

        await request<Record<string, any>>({
          url: `${url}(${data?.[PrimaryIdAttribute]})`,
          data: {
            ...(statusDetailsFieldName ? { [statusDetailsFieldName]: null } : {}),
            ...checkFormData(replaceEntityValues(formData, {}, true)),
            ...extraData,
            ...statusData,
            ...detailsData,
          },
          method: 'patch',
          eTag,
        });
        setStatuses(undefined);
        addNotification({ type: NotificationType.SUCCESS, title: successMessage || t('Your changes have been saved') });
        reloadRef.current();
      } catch (e) {
        addActionFailed(parseError(e));
      } finally {
        setLoading(false);
      }
    },
    [
      statuses,
      statusDetailsFieldName,
      request,
      url,
      data,
      PrimaryIdAttribute,
      replaceEntityValues,
      checkFormData,
      eTag,
      addNotification,
      successMessage,
      t,
      statusFieldName,
      addActionFailed,
    ]
  );

  const statusOptions = useMemo(
    () => new Map(statuses?.map((x) => [`${x.StatusCode}`, x.DisplayName]) ?? []),
    [statuses]
  );

  const changeStatus: Action = useMemo(
    () => ({
      title: t('Change Status'),
      name: 'changeStatus',
      onClick: ({ selectedItems: [data], reload }) => {
        reloadRef.current = reload;
        setData(undefined);
        setPageLoading(true);

        const getDataPromise = getData(data[PrimaryIdAttribute], links)
          .then((record) => setData(record))
          .catch((e) => addActionFailed(parseError(e)));

        const getStatusesPromise = getAvailableStatuses(data[PrimaryIdAttribute], logicalName)
          .then((response) => {
            if (response.Statuses.length > 0) {
              setStatuses(response.Statuses);
            } else {
              switch (response.MessageType) {
                case 'error':
                  addActionFailed(response.Message);
                  break;
                case 'info':
                  addActionUncompleted(response.Message);
                  break;
              }
            }
          })
          .catch((e) => addActionFailed(parseError(e)));

        Promise.all([getDataPromise, getStatusesPromise]).finally(() => setPageLoading(false));
      },
      display: ({ context, isEditAllowed }) => context === ActionContext.SinglePage && isEditAllowed,
      Icon: EditStatusIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SinglePage,
      allowedDevices: AllowedDevices.All,
    }),
    [t, getData, PrimaryIdAttribute, links, getAvailableStatuses, logicalName, addActionFailed, addActionUncompleted]
  );

  const StatusComponent = useMemo(() => createSelect(statusOptions, false), [statusOptions]);

  const formFields = useCallback(
    (values: Record<string, any>) => {
      const { AvailableStatusDetails, IsStatusDetailsAvailable, AdditionalFields, IsStatusDetailsRequired } =
        statuses?.find((x) => `${x.StatusCode}` === values[statusFieldName]) ?? {};

      const detailsOptions = new Map(AvailableStatusDetails?.map((x) => [`${x.Code}`, x.DisplayName]) ?? []);

      const isStatusChanged = `${values[statusFieldName]}` !== `${data?.[statusFieldName]}`;
      const { IsActive: isCurrentStatusActive } = statuses?.find((x) => x.StatusCode === data?.[statusFieldName]) ?? {};

      const isDetailsUpdateAllowed =
        (AvailableStatusDetails?.length ?? 0 > 0) && (isCurrentStatusActive || isStatusChanged);

      const additionalFields = AdditionalFields?.map((field) => {
        const validators = [];

        if (field.Required) {
          validators.push(rules.required);
        }
        if (field.Type === 'number') {
          validators.push(rules.valueRange(field.MinValue, field.MaxValue));
        }

        return (
          <Field
            name={field.LogicalName}
            component={Input}
            label={field.DisplayName}
            validate={rules.compose(validators)}
            required={field.Required}
          />
        );
      });

      const DetailsComponent = createSelect(detailsOptions);
      return (
        <>
          {IsStatusDetailsAvailable ? (
            <Field
              key={IsStatusDetailsRequired ? '1' : '0'}
              name={statusDetailsFieldName as string}
              component={DetailsComponent}
              label={fields[statusDetailsFieldName as string].label}
              disabled={!isDetailsUpdateAllowed}
              isClearable={false}
              validate={IsStatusDetailsRequired ? rules.required : undefined}
              required={IsStatusDetailsRequired}
            />
          ) : null}
          {additionalFields}
        </>
      );
    },
    [statuses, statusFieldName, data, statusDetailsFieldName, fields]
  );

  const initialValues = useMemo(
    () =>
      Object.fromEntries([
        ...[statusFieldName, statusDetailsFieldName]
          .filter((key) => (!!key && getFieldType(key) === FieldType.Picklist) || data?.[key as string])
          .map((key) => [key, data?.[key as string] ? `${data?.[key as string]}` : null]),
        [additionalNotesFieldName, null],
      ]),
    [statusFieldName, statusDetailsFieldName, additionalNotesFieldName, getFieldType, data]
  );

  const getWarning = useCallback(
    (values: Record<string, any>) => {
      const { Warning: statusWarning, AvailableStatusDetails } =
        statuses?.find((x) => `${x.StatusCode}` === values[statusFieldName]) ?? {};
      if (statusWarning) return statusWarning;
      const { Warning: detailsWarning = undefined } = statusDetailsFieldName
        ? AvailableStatusDetails?.find((v) => `${v.Code}` === values[statusDetailsFieldName]) || {}
        : {};
      if (detailsWarning) return detailsWarning;
    },
    [statusDetailsFieldName, statusFieldName, statuses]
  );

  const isDirty = useCallback(
    (fields: Record<string, boolean>) => [statusDetailsFieldName, statusFieldName].some((key) => key && fields[key]),
    [statusDetailsFieldName, statusFieldName]
  );

  const content = useMemo(() => {
    if (!statuses || !data) return null;
    return (
      <Form
        onSubmit={submit}
        initialValues={initialValues}
        render={({ handleSubmit, values, dirtyFields }) => (
          <form onSubmit={handleSubmit}>
            <Modal
              portal={false}
              title={t('Change Status', {
                entityName,
              })}
              loading={loading}
              onClose={() => setStatuses(undefined)}
              controls={[
                {
                  title: t('Save'),
                  id: 'button_changeStatus_save',
                  disabled: !isDirty(dirtyFields),
                  type: 'submit',
                  role: 'primary',
                },
                {
                  title: t('Cancel'),
                  id: 'button_changeStatus_cancel',
                  onClick: () => setStatuses(undefined),
                },
              ]}
            >
              <div className={classes.wrapper}>
                {!!getWarning(values) && (
                  <div className={classes.alertWrapper}>
                    <Notification type="warning">
                      <div dangerouslySetInnerHTML={{ __html: getWarning(values) as string }} />
                    </Notification>
                  </div>
                )}
                <Field
                  name={statusFieldName as string}
                  component={StatusComponent}
                  label={fields[statusFieldName as string].label}
                  validate={rules.required}
                  required
                  isClearable={false}
                />
                {formFields(values)}
                <Field
                  name={additionalNotesFieldName as string}
                  component={Input}
                  label={fields[additionalNotesFieldName as string]?.label}
                  maxLength={fields[additionalNotesFieldName as string]?.maxLength}
                  validate={rules.maxLength(512)}
                  inputType="area"
                  disabled={!isDirty(dirtyFields)}
                />
              </div>
            </Modal>
            <ClearImprover
              sensitiveFields={[statusFieldName]}
              fieldsToClear={[statusDetailsFieldName, additionalNotesFieldName].filter((v) => !!v) as string[]}
            />
          </form>
        )}
      />
    );
  }, [
    statuses,
    data,
    submit,
    initialValues,
    t,
    entityName,
    loading,
    isDirty,
    getWarning,
    statusFieldName,
    StatusComponent,
    fields,
    formFields,
    additionalNotesFieldName,
    statusDetailsFieldName,
  ]);

  return { changeStatus, content, pageLoading };
};
