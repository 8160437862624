import classes from './loader.module.scss';

export const Loader = ({ fullScreenOverlay = false }: { fullScreenOverlay?: boolean }) => {
  const loader = (
    <div className={classes.wrapper}>
      <div className={classes.ldsRing}>
        <div />
        <div />
        <div />
        <div />
      </div>
      <div className={classes.bg} />
    </div>
  );
  return fullScreenOverlay ? <div className={classes.overlay}>{loader}</div> : loader;
};
