import { FC, useState, useCallback, useMemo, useEffect } from 'react';
import { IconButton } from 'components/IconButton';
import { ReactComponent as ArrowUpIcon } from './icons/arrowUp.svg';
import { ReactComponent as ArrowDownIcon } from './icons/arrowDown.svg';
import classes from './date.module.scss';
import cx from 'classnames';
import { format, addDays, addMonths, getDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ISO_DATE_FORMAT } from 'lib';
import { dateToOutputString } from 'lib/adapter';

enum CalendarMode {
  Days,
  Years,
  Months,
}

const isSameDay = (a: Date, b: Date) => dateToOutputString(a, false) === dateToOutputString(b, false);
const isSameMonth = (a: Date, b: Date) =>
  dateToOutputString(a, false).slice(0, 2) === dateToOutputString(b, false).slice(0, 2);
const isSameYear = (a: Date, b: Date) =>
  dateToOutputString(a, false).slice(6, 10) === dateToOutputString(b, false).slice(6, 10);

export const isGreaterDay = (a: Date, b: Date) =>
  new Date(dateToOutputString(a, false)) > new Date(dateToOutputString(b, false));

const isDateDisabled = (date: Date, minDate: Date, maxDate: Date) =>
  isGreaterDay(minDate, date) || isGreaterDay(date, maxDate);

const isMonthDisabled = (month: number, year: number, minDate: Date, maxDate: Date) => {
  return (
    year < minDate.getFullYear() ||
    (year === minDate.getFullYear() && month < minDate.getMonth()) ||
    year > maxDate.getFullYear() ||
    (year === maxDate.getFullYear() && month > maxDate.getMonth())
  );
};

const isYearDisabled = (year: number, minDate: Date, maxDate: Date) => {
  return year < minDate.getFullYear() || year > maxDate.getFullYear();
};

const getMonthDatesRange = (date: Date): Date[] => {
  const result: any[] = [];
  const currYear = date.getFullYear();
  const currMonth = date.getMonth();

  const firstDayOfMonth = new Date(currYear, currMonth, 1);
  const lastDayOfMonth = new Date(currYear, currMonth + 1, 0);
  const firstDateOfRange = new Date(firstDayOfMonth.setDate(1 - firstDayOfMonth.getDay()));
  const lastDateOfRange = new Date(lastDayOfMonth.setDate(lastDayOfMonth.getDate() - lastDayOfMonth.getDay() + 6));

  while (firstDateOfRange <= lastDateOfRange) {
    result.push(new Date(firstDateOfRange));
    firstDateOfRange.setDate(firstDateOfRange.getDate() + 1);
  }

  return result;
};

const getYearsRange = (date: Date): number[] => {
  const result: number[] = [];
  const startYear = 1900;
  const yearsListIndex = Math.round((date.getFullYear() - startYear) / 12);
  let firstYear = startYear + 12 * yearsListIndex;

  for (let i = 0; i < 12; i++) {
    result[i] = firstYear;
    firstYear++;
  }

  return result;
};

type TCalendarProps = {
  onChange: (value: string) => void;
  minDate?: Date;
  maxDate?: Date;
  value: string;
};

export const Calendar: FC<TCalendarProps> = ({
  value,
  onChange,
  minDate = new Date(1, 1, 1),
  maxDate = new Date(9999, 1, 1),
}): JSX.Element => {
  const today = useMemo(() => new Date(), []);
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState(value ? new Date(value) : today);
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [mode, setMode] = useState(CalendarMode.Days);

  const canMoveToPrevRange = (mode: number, date: Date, minDate: Date, maxDate: Date) => {
    return !(mode === CalendarMode.Days
      ? isMonthDisabled(date.getMonth() - 1, date.getFullYear(), minDate, maxDate)
      : isYearDisabled(date.getFullYear() - 1, minDate, maxDate));
  };

  const canMoveToNextRange = (mode: number, date: Date, minDate: Date, maxDate: Date) => {
    return !(mode === CalendarMode.Days
      ? isMonthDisabled(date.getMonth() + 1, date.getFullYear(), minDate, maxDate)
      : isYearDisabled(date.getFullYear() + 1, minDate, maxDate));
  };

  const moveToPrevRange = useCallback(() => {
    if (!canMoveToPrevRange(mode, currentDate, minDate, maxDate)) return;

    const newDate = new Date(currentDate);
    switch (mode) {
      case CalendarMode.Days:
        newDate.setMonth(newDate.getMonth() - 1);
        break;
      case CalendarMode.Months:
        newDate.setFullYear(newDate.getFullYear() - 1);
        break;
      case CalendarMode.Years:
        newDate.setFullYear(newDate.getFullYear() - 12);
        break;
    }
    setCurrentDate(newDate);
  }, [currentDate, setCurrentDate, mode, minDate, maxDate]);

  const moveToNextRange = useCallback(() => {
    if (!canMoveToNextRange(mode, currentDate, minDate, maxDate)) return;

    const newDate = new Date(currentDate);
    switch (mode) {
      case CalendarMode.Days:
        newDate.setMonth(newDate.getMonth() + 1);
        break;
      case CalendarMode.Months:
        newDate.setFullYear(newDate.getFullYear() + 1);
        break;
      case CalendarMode.Years:
        newDate.setFullYear(newDate.getFullYear() + 12);
        break;
    }
    setCurrentDate(newDate);
  }, [currentDate, setCurrentDate, mode, minDate, maxDate]);

  const switchMode = useCallback(() => {
    switch (mode) {
      case CalendarMode.Days:
        setMode(CalendarMode.Months);
        break;
      case CalendarMode.Months:
        setMode(CalendarMode.Years);
        break;
      case CalendarMode.Years:
        setMode(CalendarMode.Months);
        break;
    }
  }, [setMode, mode]);

  const goToCurrentDate = useCallback(() => {
    setCurrentDate(today);
  }, [today]);

  const selectYear = useCallback(
    (value: number) => {
      if (isYearDisabled(value, minDate, maxDate)) return;
      const newDate = new Date(value, currentDate.getMonth(), 1);
      setCurrentDate(newDate);
      setMode(CalendarMode.Months);
    },
    [minDate, maxDate, currentDate]
  );

  const selectMonth = useCallback(
    (value: number) => {
      if (isMonthDisabled(value, currentDate.getFullYear(), minDate, maxDate)) return;
      const newDate = new Date(currentDate.getFullYear(), value, 1);
      setCurrentDate(newDate);
      setMode(CalendarMode.Days);
    },
    [currentDate, minDate, maxDate]
  );

  const selectDay = useCallback(
    (date: Date) => {
      if (isDateDisabled(date, minDate, maxDate)) return;
      onChange(format(date, ISO_DATE_FORMAT));
    },
    [minDate, maxDate, onChange]
  );

  useEffect(() => setSelectedDate(value ? new Date(value) : today), [today, value]);

  const monthDatesRange = useMemo(() => getMonthDatesRange(currentDate), [currentDate]);
  const yearsRange = useMemo(() => getYearsRange(currentDate), [currentDate]);
  const weekDaysNames = useMemo(
    () => Array.from(Array(7)).map((_v, i) => format(addDays(monthDatesRange[0], i), 'EEEEEE')),
    [monthDatesRange]
  );
  const monthsRange = useMemo(
    () => Array.from(Array(12)).map((_v, i) => format(addMonths(new Date(1, 0, 1), i), 'MMM')),
    []
  );

  return (
    <div className={classes.calendar}>
      <header>
        <button className={classes.headerButton} type="button" onClick={switchMode}>
          {mode === CalendarMode.Days && <>{format(currentDate, 'MMMM yyyy')}</>}
          {mode === CalendarMode.Months && <>{currentDate.getFullYear()}</>}
          {mode === CalendarMode.Years && (
            <>
              {yearsRange[0]}-{yearsRange[yearsRange.length - 1]}
            </>
          )}
        </button>
        <IconButton
          className={cx(classes.headerArrowUp, {
            [classes.disabled]: !canMoveToPrevRange(mode, currentDate, minDate, maxDate),
          })}
          onClick={moveToPrevRange}
          Icon={ArrowUpIcon}
        />
        <IconButton
          className={cx(classes.headerArrowDown, {
            [classes.disabled]: !canMoveToNextRange(mode, currentDate, minDate, maxDate),
          })}
          onClick={moveToNextRange}
          Icon={ArrowDownIcon}
        />
      </header>
      {mode === CalendarMode.Days && (
        <div className={classes.calendarDaysGrid}>
          {weekDaysNames.map((name) => (
            <div key={name} className={cx(classes.weekHeader, { [classes.sunday]: ['Sa', 'Su'].includes(name) })}>
              {name}
            </div>
          ))}
          {monthDatesRange.map((date: Date) => (
            <div
              key={date.toString()}
              className={cx(classes.day, {
                [classes.today]: isSameDay(date, today),
                [classes.selected]: isSameDay(date, selectedDate),
                [classes.disabled]: isDateDisabled(date, minDate, maxDate),
                [classes.sunday]: getDay(date) === 0 || getDay(date) === 6,
              })}
              onClick={() => selectDay(date)}
            >
              {date.getDate()}
            </div>
          ))}
        </div>
      )}
      {mode === CalendarMode.Months && (
        <div className={classes.calendarMonthsGrid}>
          {monthsRange.map((month: string, index: number) => (
            <div
              key={month}
              className={cx(classes.month, {
                [classes.disabled]: isMonthDisabled(index, currentDate.getFullYear(), minDate, maxDate),
                [classes.selected]: isSameMonth(new Date(currentDate.getFullYear(), index, 1), selectedDate),
                [classes.today]: isSameMonth(new Date(currentDate.getFullYear(), index, 1), today),
              })}
              onClick={() => selectMonth(index)}
            >
              {month.substring(0, 3)}
            </div>
          ))}
        </div>
      )}
      {mode === CalendarMode.Years && (
        <div className={classes.calendarYearsGrid}>
          {yearsRange.map((year: number) => (
            <div
              key={year}
              className={cx(classes.year, {
                [classes.disabled]: isYearDisabled(year, minDate, maxDate),
                [classes.selected]: isSameYear(new Date(year, 1, 1), selectedDate),
                [classes.today]: isSameYear(new Date(year, 1, 1), today),
              })}
              onClick={() => selectYear(year)}
            >
              {year}
            </div>
          ))}
        </div>
      )}
      <button
        className={cx(classes.goToCurrent, { [classes.disabled]: isSameMonth(currentDate, today) })}
        type="button"
        onClick={goToCurrentDate}
      >
        {t('Go To Current')}
      </button>
    </div>
  );
};
