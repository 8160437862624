import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { FieldValidator } from 'final-form';
import { Trans } from 'react-i18next';
import * as rules from 'lib/rules';
import { TSinglePage } from 'components/SinglePage';
import { StatusCode } from 'config/EntityMetadata/bahai_coverage';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import entities, { Fields } from 'config';
import { AreaTooltipComponent } from 'lib/components';
import { createFormAutocomplete } from 'components/Form/Autocomplete';
import { addDays } from 'date-fns';
import { parseDate, toISO } from 'lib/adapter';
import { Hidden } from 'components/Form/Hidden';

export const columns = entities.coverage.columns;
export type Keys = Fields<(typeof entities.coverage.columns)[number]>;

export const defaultMobileColumns: Keys[] = ['bahai_startdatetime', 'bahai_enddatetime'];

export const statusFields: Required<TSinglePage>['statusFields'] = () => [
  'bahai_coveringresourceid',
  'statuscode',
  'bahai_startdatetime',
  'bahai_enddatetime',
];

export const isActive = (data: Record<string, any>) => {
  return data.statuscode !== StatusCode.Finished;
};

export const isNotRemovable = (data: Record<string, any>) => {
  return data.statuscode !== StatusCode.Future && <Trans>Coverage Status must be Future to delete</Trans>;
};

export const isNotEditable = (data: Record<string, any>) =>
  !isActive(data) && <Trans>Coverage Status should be Future or In Progress to edit it</Trans>;

export const config: AdditionalConfigType<Keys | 'bahai_coverageid'> = {
  bahai_id: {
    component: ({ data, defaultValue }) => (
      <HistoryLink to={r.coverage({ id: data.bahai_coverageid || 0 })}>{data.bahai_id || defaultValue}</HistoryLink>
    ),
  },
  bahai_comment: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 512,
    }),
  },
  bahai_startdatetime: {
    isRequired: true,
    fieldProps: () => ({
      showTime: true,
      canSelectPastDate: false,
    }),
    isDisabled: (_, data) => Number(data?.statuscode) === StatusCode.InProgress,
  },
  bahai_coveringresourceid: {
    isRequired: true,
    isDisabled: (_, data) => Number(data?.statuscode) === StatusCode.InProgress,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'resource',
          links: {
            businessunit: {
              from: 'businessunitid',
              to: 'businessunitid',
              fields: [],
            },
          },
          filters: [
            {
              logicOperator: 'and',
              condition: [
                { attribute: 'isdisabled', operator: 'eq', value: '0' },
                { attribute: 'businessunit.parentbusinessunitid', operator: 'not-null' },
                { attribute: 'businessunit.name', operator: 'ne', value: 'Fund Development' },
                { attribute: 'systemuserid', operator: 'ne', value: 'ownerid' },
              ],
            },
          ],
        },
      ],
      dependencies: ['ownerid'],
    }),
  },
  bahai_coveragereason: { isRequired: true },
  bahai_enddatetime: {
    fieldProps: () => ({
      showTime: true,
      canSelectPastDate: false,
    }),
  },
  ownerid: {
    field: Hidden,
  },
};

export const getDetailsConfig: FormConfigGetter<Keys> = () => [
  [
    '',
    [
      'ownerid',
      'bahai_coveringresourceid',
      'bahai_startdatetime',
      'bahai_enddatetime',
      'bahai_coveragereason',
      'bahai_comment',
      'createdon',
      'createdby',
    ] as Keys[],
  ],
];

export const getFormConfig: FormConfigGetter<Keys> = () => [
  [
    '',
    [
      'bahai_coveringresourceid',
      'bahai_coveragereason',
      'bahai_startdatetime',
      'bahai_enddatetime',
      'bahai_comment',
    ] as Keys[],
  ],
];

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_coveringresourceid: rules.required,
  bahai_coveragereason: rules.required,
  bahai_enddatetime: rules.compose([
    rules.futureDate,
    rules.futureTime,
    rules.lessThanDate((values) => values.bahai_startdatetime, <Trans>Should be after Start Date</Trans>),
    rules.lessThanDate(
      (values) => {
        if (!parseDate(values.bahai_startdatetime, true)) return '';
        return toISO(addDays(parseDate(values.bahai_startdatetime, true), 1));
      },
      <Trans>Minimum replacement duration is 1 day</Trans>
    ),
  ]),
  bahai_startdatetime: (...props) => {
    if (props[2]?.data?.statuscode === StatusCode.InProgress) return undefined;
    return rules.compose([rules.required, rules.futureDate, rules.futureTime])(...props);
  },
};
