import entities from 'config';
import { config as eventConfig } from 'schemas/event';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { TLinkEntity } from 'components/ListPage';
import * as eventMetadata from 'config/EntityMetadata/bahai_event_ims';
import { Trans } from 'react-i18next';

const {
  invitedResource: { columns: invitedResourceColumns },
  event: { columns: eventColumns },
} = entities;

type Keys = (typeof invitedResourceColumns)[number];
type EventKeys = `event.${(typeof eventColumns)[number]}`;

export const defaultMobileColumns: Array<Keys | EventKeys> = [
  'bahai_eventid',
  'event.bahai_startdatetime',
  'event.bahai_designatedhostobjectid',
];

export const isNotRemovable = (data: Record<string, any>) =>
  Number(data['event.statecode']) !== eventMetadata.statecode.Active && (
    <Trans>Invitees can’t be removed from a Finished or Canceled Event</Trans>
  );

export const links = {
  event: {
    from: 'bahai_event_imsid',
    to: 'bahai_eventid',
    fields: eventColumns,
    condition: [],
  },
} as TLinkEntity;

export const columns = [...invitedResourceColumns, ...eventColumns.map((column) => `event.${column}`)];

export const config: AdditionalConfigType<Keys | EventKeys> = {
  bahai_systemuserid: {
    hiddenForTable: true,
  },
  bahai_eventid: {
    searchable: true,
  },
  ...Object.fromEntries(
    Object.entries(eventConfig).map(([key, props]) => ['event.' + key, { ...props, searchable: false }])
  ),
  'event.bahai_title': {
    hiddenForTable: true,
  },
};

export const getFormConfig: FormConfigGetter<Keys> = () => [];
