import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from 'domain/api';
import { useMetaData } from 'lib/hooks';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { ReactComponent as EditStatusIcon } from 'schemas/requester/status.svg';
import { createSelect } from 'components/Form/Select';
import { Field, Form } from 'react-final-form';
import * as rules from 'lib/rules';
import { Input } from 'components/Form';
import { Modal } from 'components/Modal';
import classes from 'components/ChangeStatus/status.module.scss';
import { parseError } from 'lib/errorParser';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { ClearImprover } from 'lib/improvers';
import { TEntityName } from 'lib';

export const useSimpleChangeStatus = ({
  entityName,
  statusFieldName,
  confirmationFieldName,
}: {
  entityName: TEntityName;
  statusFieldName: string;
  confirmationFieldName?: string;
}) => {
  const [items, setItems] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(false);
  const reloadRef = useRef<() => void>(() => undefined);
  const { t } = useTranslation();
  const { addNotification, addActionFailed } = useNotifications();
  const { request } = useApi();
  const {
    PrimaryIdAttribute,
    entityConfig: { url, fields },
  } = useMetaData(entityName);

  const changeStatusAction: Action = useMemo(
    () => ({
      title: t('Change Status'),
      name: 'changeStatus',
      onClick: ({ selectedItems, reload }) => {
        setItems(selectedItems);
        reloadRef.current = reload;
      },
      display: ({ selectedItemsCount, isEditAllowed, context }) =>
        selectedItemsCount !== 0 && (isEditAllowed || context !== ActionContext.SinglePage),
      Icon: EditStatusIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.All,
      allowedDevices: AllowedDevices.All,
    }),
    [t]
  );

  const save = useCallback(
    async ({ submit: _, ...data }: Record<string, any>) => {
      try {
        setLoading(true);
        await Promise.all(
          items.map(({ [PrimaryIdAttribute]: id }) =>
            request<Record<string, any>>({
              url: `${url}(${id})`,
              data,
              method: 'patch',
              eTag: '*',
            })
          )
        );

        setItems([]);
        addNotification({ type: NotificationType.SUCCESS, title: t('Your changes have been saved') });
        reloadRef.current();
      } catch (e) {
        addActionFailed(parseError(e));
      } finally {
        setLoading(false);
      }
    },
    [items, addNotification, t, PrimaryIdAttribute, request, url, addActionFailed]
  );

  const statusOptions = useMemo(() => {
    const usedStatuses = new Set(items.map((v) => Number(v[statusFieldName])));
    return new Map(
      [...fields[statusFieldName].options].filter(([k], _, arr) =>
        usedStatuses.size === arr.length ? true : !usedStatuses.has(Number(k))
      )
    );
  }, [fields, items, statusFieldName]);

  const StatusComponent = useMemo(() => createSelect(statusOptions, false), [statusOptions]);

  const initialValues = useMemo(
    () => Object.fromEntries([[statusFieldName, Number(Array.from(statusOptions.keys())[0])]]),
    [statusFieldName, statusOptions]
  );

  const content = useMemo(() => {
    if (!items.length) return null;
    return (
      <Form
        onSubmit={save}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal
              title={t('Change Status', {
                entityName,
              })}
              onClose={() => setItems([])}
              controls={[
                { title: t('Save'), type: 'submit', role: 'primary' },
                { title: t('Cancel'), type: 'button', onClick: () => setItems([]) },
              ]}
              loading={loading}
              portal={false}
            >
              <div className={classes.wrapper}>
                <Field
                  name={statusFieldName}
                  component={StatusComponent}
                  formatOnBlur={true}
                  label={t('Status')}
                  validate={rules.required}
                  required
                  isClearable={false}
                />
                {confirmationFieldName && (
                  <Field
                    name={confirmationFieldName}
                    component={Input}
                    label={t('Confirmation Text')}
                    maxLength={256}
                    validate={rules.confirmation}
                    inputType="area"
                    required
                  />
                )}
              </div>
            </Modal>
            {confirmationFieldName && (
              <ClearImprover sensitiveFields={[statusFieldName]} fieldsToClear={[confirmationFieldName]} />
            )}
          </form>
        )}
      />
    );
  }, [
    items.length,
    save,
    initialValues,
    t,
    entityName,
    loading,
    statusFieldName,
    StatusComponent,
    confirmationFieldName,
  ]);

  return { changeStatusAction, content };
};
