import { Loader } from 'components/Loader';
import { useTranslation } from 'react-i18next';
import classes from './placeholder.module.scss';

export const Placeholder = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <h3>{t('Authorization')}</h3>
      <div>
        <Loader />
      </div>
    </div>
  );
};
