import {
  ApiFilter,
  ApiFilterOperator,
  Condition,
  FieldType,
  PortalFilter,
  PortalFilterOperator,
  TEntityName,
  TFilterConfig,
  TFilterParser,
  TValueComponent,
} from 'lib/types';
import { FromToComponent } from 'components/Table/Filter/components';
import { Trans, useTranslation } from 'react-i18next';
import { useMetaData } from 'lib/hooks';
import { DateTimePicker as DatePicker } from 'components/DateTimePicker';
import { createFilterAutocomplete } from 'components/FilterAutocomplete';
import { createFilterSelect } from 'components/FilterSelect';
import { Input } from 'components/Input';
import { FC, useCallback, useMemo } from 'react';
import { AutocompleteType } from 'components/AutoComplete/hooks';
import * as emailMetadata from 'config/EntityMetadata/email';
import { getFormattedNow, parseDate as parseDateTime, TimeZone, toIsoDate } from 'lib/adapter';
import { TLinkEntity } from 'components/ListPage';
import { UNKNOWN_DEPENDENCY } from 'components/AutoComplete';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import { parse } from 'date-fns';
import { ISO_DATETIME_FORMAT } from 'lib/const';
import { TAppEntityMeta } from 'dynamics-meta';
import meta from 'domain/meta.json';

type ValueFn = (value?: string) => string;

export const addExtraProps =
  <T,>(Component: FC<T>, extraProps: Partial<T>) =>
  (props: T) => <Component {...props} {...extraProps} />;

const updateValue = (fn: ValueFn): TFilterParser => {
  return ({ attribute, operator, value }) => ({
    condition: [
      { attribute, value: fn(value), operator: filtersConfig[operator].api || (operator as ApiFilterOperator) },
    ],
  });
};

const defaultParser: TFilterParser = ({ attribute, operator, value }) => ({
  condition: [{ attribute, value, operator: filtersConfig[operator].api || (operator as ApiFilterOperator) }],
});

const eqParser: TFilterParser = (props) => {
  const { type, value, attribute, format } = props;
  switch (type) {
    case FieldType.DateTime:
      return {
        logicOperator: 'and',
        condition:
          format === 'DateOnly'
            ? [
                {
                  attribute,
                  operator: 'on' as ApiFilterOperator,
                  value,
                },
              ]
            : [
                {
                  attribute,
                  operator: 'ge' as ApiFilterOperator,
                  value: getUTCTime(value + ' 00:00:00'),
                },
                {
                  attribute,
                  operator: 'le' as ApiFilterOperator,
                  value: getUTCTime(value + ' 23:59:59'),
                },
              ].filter((v) => v.value),
      };
    case FieldType.Virtual:
    case FieldType.Picklist:
    case FieldType.Boolean:
      return {
        condition: [
          {
            attribute,
            operator: 'in',
            value: (value || '').split(',').filter((v) => !!v),
          },
        ],
      };
    case FieldType.Lookup:
    case FieldType.Owner:
      switch (true) {
        case props.attribute.endsWith('objectid') || type === FieldType.Owner:
          return {
            logicOperator: 'or',
            condition: (props.value || '')
              .split(',')
              .filter((v) => !!v)
              .map((value) => ({ attribute, operator: 'eq', value: value.split('<|>')[1] })),
          };
        case props.value?.includes(','):
          return {
            logicOperator: 'or',
            condition: (props.value || '').split(',').map((value) => ({ attribute, operator: 'eq', value })),
          };
        default:
          return defaultParser(props);
      }
    default:
      return defaultParser(props);
  }
};

const doesNotEqualParser: TFilterParser = (props) => {
  const { value, attribute, type } = props;
  switch (type) {
    case FieldType.DateTime:
      return {
        logicOperator: 'or',
        condition: [
          {
            attribute,
            operator: 'le' as ApiFilterOperator,
            value: getUTCTime(value + ' 00:00:00'),
          },
          {
            attribute,
            operator: 'ge' as ApiFilterOperator,
            value: getUTCTime(value + ' 23:59:59'),
          },
          { attribute, operator: 'null' as ApiFilterOperator },
        ].filter((v) => v.value),
      };
    case FieldType.Virtual:
    case FieldType.Picklist:
      return {
        condition: [
          {
            attribute,
            operator: 'not-in',
            value: (value || '').split(',').filter((v) => !!v),
          },
        ],
      };
    case FieldType.Lookup:
    case FieldType.Owner:
      if (attribute.endsWith('objectid') || type === FieldType.Owner) {
        return {
          logicOperator: 'and',
          condition: (value || '')
            .split(',')
            .filter((v) => !!v)
            .map((value) => ({ attribute, operator: 'ne', value: value.split('<|>')[1] })),
        };
      } else
        return {
          logicOperator: 'and',
          condition: (value || '')
            .split(',')
            .filter((v) => !!v)
            .map((value) => ({ attribute, operator: 'ne', value })),
        };
    default:
      return defaultParser(props);
  }
};

const containsParser: TFilterParser = (props) => {
  const { attribute, value } = props;
  let participationtypemask;
  switch (props.type) {
    case FieldType.Memo:
    case FieldType.String:
      return {
        condition: [
          {
            attribute,
            operator: 'like',
            value: `%${value?.replace('_', '[_]')}%`,
          },
        ],
      };
    case FieldType.Virtual:
      return {
        condition: [
          {
            attribute,
            operator: 'contain-values',
            value: (props.value || '').split(',').filter((v) => !!v),
          },
        ],
      };
    case FieldType.PartyList:
      participationtypemask = Object.entries(emailMetadata.ParticipationTypeMask)
        .find(([key]) => key === props.attribute)?.[1]
        .toString();
      return {
        logicOperator: 'and',
        condition: [
          {
            attribute: 'partyid',
            entityname: `activityparty${participationtypemask}`,
            operator: 'in',
            value: (props.value || '')
              .split(',')
              .filter((v) => !!v)
              .map((value) => value.split('<|>')[1]),
          },
        ],
      };
    case FieldType.Lookup:
      if (props.attribute.endsWith('objectid'))
        return {
          logicOperator: 'or',
          condition: (props.value || '')
            .split(',')
            .filter((v) => !!v)
            .map((value) => ({ attribute, operator: 'eq', value: value.split('<|>')[1] })),
        };
      else
        return {
          logicOperator: 'or',
          condition: (props.value || '')
            .split(',')
            .filter((v) => !!v)
            .map((value) => ({ attribute, operator: 'eq', value })),
        };
    default:
      return {
        logicOperator: 'or',
        condition: (props.value || '')
          .split(',')
          .filter((v) => !!v)
          .map((value) => ({ attribute, operator: 'eq', value })),
      };
  }
};

const doesNotContainParser: TFilterParser = ({ type, attribute, value }) => {
  let participationtypemask;
  switch (type) {
    case FieldType.Memo:
    case FieldType.String:
      return {
        condition: [
          {
            attribute,
            operator: 'not-like',
            value: `%${value?.replace('_', '[_]')}%`,
          },
        ],
      };
    case FieldType.Virtual:
      return {
        condition: [
          {
            attribute,
            operator: 'not-contain-values',
            value: (value || '').split(',').filter((v) => !!v),
          },
        ],
      };
    case FieldType.PartyList:
      participationtypemask = Object.entries(emailMetadata.ParticipationTypeMask)
        .find(([key]) => key === attribute)?.[1]
        .toString();
      return {
        logicOperator: 'and',
        condition: [
          {
            attribute: 'partyid',
            entityname: `activityparty${participationtypemask}`,
            operator: 'not-in',
            value: (value || '')
              .split(',')
              .filter((v) => !!v)
              .map((value) => value.split('<|>')[1]),
          },
        ],
      };
    case FieldType.Lookup:
      if (attribute.endsWith('objectid'))
        return {
          logicOperator: 'and',
          condition: (value || '')
            .split(',')
            .filter((v) => !!v)
            .map((value) => ({ attribute, operator: 'ne', value: value.split('<|>')[1] })),
        };
      else
        return {
          logicOperator: 'and',
          condition: (value || '')
            .split(',')
            .filter((v) => !!v)
            .map((value) => ({ attribute, operator: 'ne', value })),
        };
    default:
      return {
        logicOperator: 'and',
        condition: (value || '')
          .split(',')
          .filter((v) => !!v)
          .map((value) => ({ attribute, operator: 'ne', value })),
      };
  }
};

const isEmptyParser: TFilterParser = ({ type, attribute, textViewField }) => {
  return {
    condition: [
      {
        attribute: type === FieldType.PartyList ? textViewField ?? `bahai_${attribute}` : attribute,
        operator: 'null',
      },
    ],
  };
};

const isNotEmptyParser: TFilterParser = ({ type, attribute, textViewField }) => {
  return {
    condition: [
      {
        attribute: type === FieldType.PartyList ? textViewField ?? `bahai_${attribute}` : attribute,
        operator: 'not-null',
      },
    ],
  };
};

const getUTCTime = (dateString: string) => {
  return formatInTimeZone(
    fromZonedTime(parse(dateString, 'MM/dd/yyyy HH:mm:ss', new Date()), TimeZone.getInstance().getTimeZone()),
    'UTC',
    ISO_DATETIME_FORMAT
  );
};

const betweenParser: TFilterParser = ({ attribute, value = '', type, format }) => {
  let [from, to] = value.split(',').map((v) => (type === FieldType.DateTime && !!v ? toIsoDate(v) : v));
  if (
    from &&
    to &&
    ((type === FieldType.Integer && Number(from) > Number(to)) || (type !== FieldType.Integer && from > to))
  ) {
    [from, to] = [to, from];
  }
  if (format === 'DateOnly')
    return {
      logicOperator: 'and',
      condition: [
        {
          attribute,
          operator: 'on-or-after' as ApiFilterOperator,
          value: from,
        },
        {
          attribute,
          operator: 'on-or-before' as ApiFilterOperator,
          value: to,
        },
      ].filter((v) => v.value),
    };

  return {
    logicOperator: 'and',
    condition: [
      {
        attribute,
        operator: 'ge' as ApiFilterOperator,
        value: type === FieldType.DateTime && from ? getUTCTime(from + ' 00:00:00') : from,
      },
      {
        attribute,
        operator: 'le' as ApiFilterOperator,
        value: type === FieldType.DateTime && to ? getUTCTime(to + ' 23:59:59') : to,
      },
    ].filter((v) => v.value),
  };
};

const AllTypes = Object.values(FieldType);

// TODO: Add Translated Labels Here
export const filtersConfig: Record<PortalFilterOperator, Readonly<TFilterConfig>> = {
  equal: {
    types: [
      FieldType.String,
      FieldType.Number,
      FieldType.DateTime,
      FieldType.Memo,
      FieldType.Integer,
      FieldType.Picklist,
      FieldType.Virtual,
      FieldType.Lookup,
      FieldType.Owner,
      FieldType.Boolean,
    ],
    api: 'eq',
    parse: eqParser,
    guesser: ({ operator }) => operator === 'eq' || operator == 'on',
    label: <Trans>Equals</Trans>,
    valueUpdate: (type: FieldType, value?: string | string[]) => {
      if (type === FieldType.Boolean && ['0', '1'].includes(value as string)) {
        return value == '1' ? 'true' : 'false';
      }
      return '';
    },
  },
  empty: { types: AllTypes, hasArguments: false, parse: isEmptyParser, label: <Trans>Is Empty</Trans> },
  notEmpty: { types: AllTypes, hasArguments: false, parse: isNotEmptyParser, label: <Trans>Is not Empty</Trans> },
  contains: {
    types: [
      FieldType.String,
      FieldType.Virtual,
      FieldType.Picklist,
      FieldType.Boolean,
      FieldType.Lookup,
      FieldType.PartyList,
      FieldType.Status,
      FieldType.State,
      FieldType.Memo,
    ],
    parse: containsParser,
    guesser: ({ type, value, operator, filter }) => {
      switch (type) {
        case FieldType.Memo:
        case FieldType.String:
          return operator === 'like' && String(value).startsWith('%') && !String(value).endsWith('%');
        case FieldType.PartyList:
          return operator === 'in';
        default:
          return operator === 'contain-values' || (operator.includes('eq') && filter === 'or');
      }
    },
    label: <Trans>Contains</Trans>,
  },
  doesNotContain: {
    types: [
      FieldType.String,
      FieldType.Virtual,
      FieldType.Picklist,
      FieldType.Boolean,
      FieldType.Lookup,
      FieldType.PartyList,
      FieldType.Status,
      FieldType.State,
      FieldType.Memo,
    ],
    parse: doesNotContainParser,
    guesser: ({ type, value, operator, filter }) => {
      switch (type) {
        case FieldType.Memo:
        case FieldType.String:
          return operator === 'not-like' && String(value).startsWith('%') && !String(value).endsWith('%');
        case FieldType.PartyList:
          return operator === 'not-in';
        default:
          return operator === 'not-contain-values' || (operator.includes('ne') && filter === 'and');
      }
    },
    label: <Trans>Does not Contain</Trans>,
  },
  startsWith: {
    types: [FieldType.String, FieldType.Memo],
    api: 'like',
    parse: updateValue((v) => `${v?.replace('_', '[_]')}%`),
    guesser: ({ value, operator }) =>
      operator === 'like' && String(value).startsWith('%') && !String(value).endsWith('%'),
    label: <Trans>Starts with</Trans>,
  },
  endswith: {
    types: [FieldType.String, FieldType.Memo],
    api: 'like',
    parse: updateValue((v) => `%${v?.replace('_', '[_]')}`),
    guesser: ({ value, operator }) =>
      operator === 'like' && String(value).endsWith('%') && !String(value).startsWith('%'),
    label: <Trans>Ends with</Trans>,
  },
  today: {
    types: [FieldType.DateTime],
    api: 'today',
    hasArguments: false,
    parse: (props) => eqParser({ ...props, value: getFormattedNow().slice(0, 10) }),
    label: <Trans>Today</Trans>,
  },
  between: {
    types: [FieldType.DateTime],
    parse: betweenParser,
    formRender: FromToComponent,
    guesser: ({ operator, filter }) => filter === 'and' && (operator.includes('le') || operator.includes('ge')),
    label: <Trans>Between</Trans>,
  },
  range: {
    types: [FieldType.Number, FieldType.Integer],
    parse: betweenParser,
    formRender: FromToComponent,
    guesser: ({ operator, filter }) => filter === 'and' && (operator.includes('le') || operator.includes('ge')),
    label: <Trans>Range</Trans>,
  },
  doesNotEqual: {
    types: [
      FieldType.DateTime,
      FieldType.Integer,
      FieldType.Number,
      FieldType.Picklist,
      FieldType.Virtual,
      FieldType.Lookup,
      FieldType.Owner,
    ],
    api: 'ne',
    parse: doesNotEqualParser,
    guesser: ({ operator, filter, type }) =>
      type === FieldType.Picklist
        ? operator === 'ne'
        : filter === 'and' && (operator.includes('gt') || operator.includes('lt')),
    label: <Trans>Does not Equal</Trans>,
  },
} as const;

export const getFilterOptions = (type: FieldType): Record<string, JSX.Element> =>
  Object.fromEntries(
    Object.entries(filtersConfig)
      .filter((v) => v[1].types.includes(type))
      .map(([name, filter]) => [name, filter.label])
  );

export const useFilterComponent = (baseName: string, baseEntityName: TEntityName, operator: PortalFilterOperator) => {
  const [entityName, name] = baseName.includes('.') ? baseName.split('.') : [baseEntityName, baseName];
  const { entityConfig, getJoinLogicalName, getFieldDefinition } = useMetaData(entityName as TEntityName);
  const { t } = useTranslation();
  const { type, formatName } = getFieldDefinition(baseName);

  const validateInteger = useCallback(
    (value?: string) =>
      (value ?? '').split(',').some((x) => !Number.isInteger(Number(x))) ? t('Invalid Number') : undefined,
    [t]
  );

  const validateNumber = useCallback(
    (value?: string) =>
      (value ?? '').split(',').some((x) => isNaN(Number.parseFloat(x))) ? t('Invalid Number') : undefined,
    [t]
  );

  const date = useCallback(
    (v: unknown) =>
      !!v && (v as string).split(',').some((v: string) => v && isNaN(parseDateTime(v).getTime()))
        ? t('Invalid format MM/DD/YYYY')
        : undefined,
    [t]
  );

  return useMemo(() => {
    let logicalNames: string[] | TMultiReferenceEntity[] = [];
    switch (type) {
      case FieldType.DateTime:
        return { Component: DatePicker, validate: date };
      case FieldType.Owner:
        return {
          Component: createFilterAutocomplete(
            [
              {
                entityName: 'resource',
              },
              {
                entityName: 'team',
              },
            ],
            AutocompleteType.Polymorphic
          ),
        };
      case FieldType.Lookup:
        switch (name) {
          case 'regardingobjectid':
            return {
              Component: createFilterAutocomplete(
                [
                  {
                    entityName: 'inquiry',
                  },
                  {
                    entityName: 'group',
                  },
                  {
                    entityName: 'event',
                  },
                ],
                AutocompleteType.Polymorphic
              ),
            };
          case 'bahai_designatedhostobjectid':
          case 'bahai_designatedcontactobjectid':
            return {
              Component: createFilterAutocomplete(
                [
                  {
                    entityName: 'resource',
                  },
                  {
                    entityName: 'contact',
                  },
                ],
                AutocompleteType.Polymorphic
              ),
            };
          default:
            return {
              Component: createFilterAutocomplete([getJoinLogicalName(name)], AutocompleteType.Lookup),
            };
        }
      case FieldType.PartyList:
        switch (name) {
          case 'to':
          case 'cc':
          case 'bcc':
            logicalNames = [
              {
                entityName: 'resource',
              },
              {
                entityName: 'contact',
              },
              {
                entityName: 'member',
              },
              {
                entityName: 'requester',
              },
            ];
            break;
          default:
            logicalNames = [
              {
                entityName: 'resource',
              },
            ];
            break;
        }
        return { Component: createFilterAutocomplete(logicalNames, AutocompleteType.PartyList), validate: undefined };
      case FieldType.Virtual:
      case FieldType.Picklist:
      case FieldType.Status:
      case FieldType.State:
      case FieldType.Boolean:
        return {
          Component: createFilterSelect(new Map<string, string | JSX.Element>(entityConfig.fields[name].options)),
        };
      case FieldType.Integer:
        return { Component: Input, validate: validateInteger };
      case FieldType.Number:
        return { Component: Input, validate: validateNumber };
      default: {
        return formatName === 'Phone' && operator === 'equal'
          ? { Component: addExtraProps(Input, { inputType: 'phone' }) }
          : { Component: Input };
      }
    }
  }, [
    type,
    date,
    name,
    entityConfig.fields,
    validateInteger,
    validateNumber,
    getJoinLogicalName,
    formatName,
    operator,
  ]);
};

export type TFilter = keyof typeof filtersConfig;

// TODO: fix this
export const getFilter = (filter?: TFilter): Required<TFilterConfig> => {
  return {
    hasArguments: true,
    formRender: (Component: TValueComponent) => Component,
    ...(filter ? filtersConfig[filter] : ({} as TFilterConfig)),
  } as Required<TFilterConfig>;
};

export type TMultiReferenceEntity = {
  entityName: string | TEntityName;
  links?: TLinkEntity;
  filters?:
    | Array<Condition | ApiFilter | string>
    | Record<string, string | number | { operator: ApiFilterOperator; value?: string | number }>;
  customIdAttribute?: string;
  customNameAttribute?: string;
};

export const conditionToXML = (
  { value, attribute: field, ...rest }: Condition,
  replaceValues = {} as Record<string, any>
) => {
  const [attribute, entityname] = field.split('.').reverse();
  return {
    _attributes: {
      attribute,
      entityname,
      ...rest,
      ...(!Array.isArray(value)
        ? { value: typeof value === 'string' && replaceValues[value] !== undefined ? replaceValues[value] : value }
        : {}),
    },
    ...(Array.isArray(value) ? { value } : {}),
  };
};

export const apiFilterToXML = (
  { logicOperator, filter, condition }: ApiFilter,
  values?: Record<string, any>
): Record<string, any> | undefined => {
  if (!condition && !filter) return;
  if (filter)
    return {
      _attributes: { type: logicOperator || 'and' },
      ...(condition && condition.length
        ? {
            condition: condition
              .filter((v) => (values && typeof v.value === 'string' ? values[v.value] !== UNKNOWN_DEPENDENCY : v))
              .map((v) => conditionToXML(v, values)),
          }
        : {}),
      ...(filter && filter.length ? { filter: filter.map((v) => apiFilterToXML(v, values)) } : {}),
    };
  return {
    _attributes: { type: logicOperator || 'and' },
    ...(condition && condition.length
      ? {
          condition: condition
            .filter((v) => (values && typeof v.value === 'string' ? values[v.value] !== UNKNOWN_DEPENDENCY : v))
            .map((v) => conditionToXML(v, values)),
        }
      : {}),
  };
};

export const getXMLFilter = (filters?: Array<Condition | ApiFilter>, values?: Record<string, any>) => {
  if (!filters || filters.length === 0) return;
  return {
    _attributes: { type: 'and' },
    condition: (filters.filter((v) => !(v as ApiFilter).logicOperator) as Condition[])
      .filter((v) => (values && typeof v.value === 'string' ? values[v.value] !== UNKNOWN_DEPENDENCY : v))
      .map((v) => conditionToXML(v, values)),
    filter: (filters.filter((v) => (v as ApiFilter).logicOperator) as ApiFilter[]).map((v) =>
      apiFilterToXML(v, values)
    ),
  };
};

const getFieldTypeAndFormat = (name: string, entityName: TEntityName) => {
  const config = meta.meta as unknown as Record<TEntityName, TAppEntityMeta>;
  const [fieldName, entity] = name.split('.').reverse();
  const { type, format } = config[(entity as TEntityName) || entityName].fields[fieldName];
  return { type, format };
};

export const generateEntityFilters = (entityName: TEntityName, filters?: PortalFilter[]): any => {
  const portalToApiFilter = (filters: PortalFilter[]): ApiFilter[] =>
    filters.map(({ operator, attribute, value, textViewField }) =>
      (filtersConfig[operator].parse || defaultParser)({
        ...getFieldTypeAndFormat(attribute, entityName),
        value,
        attribute,
        operator,
        textViewField,
      })
    );

  if (!filters) return [];
  return portalToApiFilter(filters).map((v) => apiFilterToXML(v));
};
