import ListPage from 'components/ListPage';
import * as baseConfig from 'schemas/requester';
import { useRequesterValidation, useRequester } from 'schemas/requester/hooks';
import { useCallback, useContext } from 'react';
import { ChainImprover, ClearImprover } from 'lib/improvers';
import { Action } from 'components/Actions';
import { EmailRecipient, SendEmailValidateFunction, useSendEmailFromList } from 'schemas/email/actions';
import { bahai_ethnicity, statecode, bahai_inquirerstatus, source_code } from 'config/EntityMetadata/bahai_inquirer';
import { Trans } from 'react-i18next';
import { RegistrationHelper } from 'schemas/requester/components/RegistrationHelper';
import { bahai_preferredlanguage } from 'config/EntityMetadata/common';
import { AuthContext } from 'providers/AuthProvider';

export const requesterToRecipientMapper = (data: Record<string, any>): EmailRecipient => ({
  id: data.bahai_inquirerid,
});

const chain = ['bahai_postalcodeid', 'bahai_cityid', 'bahai_stateid'];
const mailingChain = chain.map((v) => v.replace('bahai_', 'bahai_mailing'));
const birthChain = ['bahai_birthcityid', 'bahai_birthstateid', 'bahai_birthcountryid'];
export const chains = [chain, mailingChain, birthChain];

export const RequesterImprover = () => (
  <>
    <ClearImprover
      sensitiveFields={['bahai_ethnicity']}
      fieldsToClear={['bahai_otherethnicity']}
      clearRequired={(changes) =>
        changes.bahai_ethnicity && changes.bahai_ethnicity.includes(String(bahai_ethnicity.OtherEthnicity))
      }
    />
    <ChainImprover
      chains={chains}
      replace={/mailing|birth/}
      entityName="requester"
      forceClearFields={['bahai_postalcodeid', 'bahai_mailingpostalcodeid']}
    />
  </>
);

export const useRequesterInitialValues = () => {
  return useCallback(async () => {
    return {
      bahai_preferredlanguagecode: bahai_preferredlanguage.English,
      bahai_sourcecode: String(source_code.Resource),
      bahai_isoutofusa: false,
      bahai_usedifferentmailingaddress: false,
      bahai_mailingisoutofusa: false,
    };
  }, []);
};

export const getValidateRequesterForEmailReceive =
  (prefix = ''): SendEmailValidateFunction =>
  (data) => {
    if (Number(data[`${prefix}statecode`]) === statecode.Inactive)
      return {
        label: data[`${prefix}bahai_name`],
        content: <Trans>Requester State is Inactive</Trans>,
      };
    if (Number(data[`${prefix}bahai_statuscode`]) === bahai_inquirerstatus.DoNotContact)
      return {
        label: data[`${prefix}bahai_name`],
        content: <Trans>Requester Status is Do Not Contact</Trans>,
      };
    if (!data[`${prefix}bahai_emailaddress1`] && !data[`${prefix}bahai_emailaddress2`]) {
      return {
        label: data[`${prefix}bahai_name`],
        content: <Trans>Requester doesn’t have an email address</Trans>,
      };
    }
  };

const RequesterList = () => {
  const { actions: sendEmailActions, content } = useSendEmailFromList(
    'requester',
    getValidateRequesterForEmailReceive(),
    'requester',
    requesterToRecipientMapper
  );

  const getActions = useCallback(
    (baseActions: Action[]) => {
      return [...baseActions, ...sendEmailActions];
    },
    [sendEmailActions]
  );

  const { validate } = useRequesterValidation();

  const getInitialValues = useRequesterInitialValues();

  const { onSubmit, getActionControls, duplicatesContent } = useRequester();

  const { businessUnitType } = useContext(AuthContext);
  const WarningsImprover = baseConfig.getWarningsImprover(businessUnitType);

  return (
    <>
      {duplicatesContent}
      <ListPage
        entityName="requester"
        {...{
          ...baseConfig,
          getActions,
          validate,
          getActionControls,
          getInitialValues,
          onSubmit,
          formHelper: <RegistrationHelper />,
          WarningsImprover,
        }}
        isExportAllowed
        FormImprover={RequesterImprover}
      />
      {content}
    </>
  );
};

export default RequesterList;
