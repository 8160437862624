import { localityFields } from './locality';

const readOnly = [
  'bahai_id',
  'bahai_name',
  'bahai_inquirycomment',
  'bahai_rawimportantnote',
  'bahai_profileimage',
  'statecode',
  'bahai_statuscode',
  'bahai_statusdetailscode',
  'bahai_lastinteraction',
  'bahai_seekerid',
  'bahai_historicalseekerresponsedata',
  'bahai_currentreminder',
  'bahai_countersignpersonid',
  'bahai_mergeddatetime',
  'bahai_mergedwith',
  'bahai_confirmationmessage',
  'bahai_lastexportcreator',
  'bahai_inquirertypecode',
  ...localityFields,
] as const;

const fields = [
  'bahai_addressline1',
  'bahai_addressline2',
  'bahai_birthdate',
  'bahai_cellphone',
  'bahai_cityid',
  'bahai_compositeaddress',
  'bahai_contactmethodcode',
  'bahai_emailaddress1',
  'bahai_emailaddress2',
  'bahai_firstname',
  'bahai_gendercode',
  'bahai_homephone',
  'bahai_lastname',
  'bahai_middlename',
  'bahai_nickname',
  'bahai_postalcodeid',
  'bahai_preferredlanguagecode',
  'bahai_otherlanguage',
  'bahai_stateid',
  'bahai_workphone',
  'bahai_websiteurl',
  'bahai_socialmediaurl',
  'bahai_isoutofusa',
  'bahai_importantnote',
  'bahai_mailingaddressline1',
  'bahai_mailingaddressline2',
  'bahai_mailingcityid',
  'bahai_mailingstateid',
  'bahai_mailingpostalcodeid',
  'bahai_mailingcompositeaddress',
  'bahai_mailingisoutofusa',
  'bahai_usedifferentmailingaddress',
  'bahai_additionalnote',
  'bahai_originationdate',
  'bahai_race',
  'bahai_ethnicity',
  'bahai_otherethnicity',
  'bahai_birthcountryid',
  'bahai_birthstateid',
  'bahai_birthcityid',
  'bahai_title',
  'bahai_suffix',
  'bahai_isdraft',
  'bahai_estimatedagerange',

  'bahai_parentrequesterid',
  'bahai_parentmemberid',
  'bahai_typeofpermission',
  'bahai_permissionnote',
  'bahai_dateofpermissiongiven',
  'bahai_declarationdate',
  'bahai_sourcecode',
  'bahai_sourcedetails',
  'bahai_contactid',
] as const;

export default {
  fields: fields,
  columns: [...fields, ...readOnly],
};
