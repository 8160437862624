import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useAutocomplete,
  useAutocompleteStorage,
  TUseAutocompleteProps,
  AutocompleteType,
} from 'components/AutoComplete/hooks';
import { Select, TSelectRenderProps } from 'components/Select';
import { TMultiReferenceEntity } from 'lib/filters';

export interface IAutoCompleteProps {
  entities: Array<string | TMultiReferenceEntity>;
  isMultiple: boolean;
  type: AutocompleteType;
  dependencies: Array<string>;
  searchBy?: string[];
}

type TAutocompleteProps = {
  name?: string;
  value: string | string[] | undefined;
  onChange: (value: string | string[] | undefined) => void;
  values?: Record<string, any>;
  data?: Record<string, any>;
} & TSelectRenderProps;

export const UNKNOWN_DEPENDENCY = 'UNKNOWN';

export const createEmailAutocomplete = ({
  entities,
  isMultiple = false,
  type = AutocompleteType.Lookup,
  dependencies = [],
  searchBy,
}: IAutoCompleteProps) => {
  return ({ value: notFormattedValue, onChange, values, data = {}, ...props }: TAutocompleteProps) => {
    const value = useMemo(() => {
      return notFormattedValue
        ? [notFormattedValue].flat().map((v) => {
            const parts = v.split('<|>');
            if (parts[0] === 'undefined') {
              return parts[3];
            }
            return parts.filter((_, index) => index < 2).join('<|>');
          })
        : [];
    }, [notFormattedValue]);
    const dependencyRef = useRef<Record<string, any>>({});

    const valuesWithId: Record<string, any> = useMemo(
      () => ({
        ...(values || {}),
        RECORD_ID: data.id || '',
      }),
      [data.id, values]
    );

    const dependencyValues = useMemo(() => {
      if (
        dependencies.some(
          (key) => dependencyRef.current[key] !== ((valuesWithId?.[key] || '').split('<|>').pop() || UNKNOWN_DEPENDENCY)
        )
      ) {
        dependencyRef.current = Object.fromEntries(
          dependencies.map((key) => [key, (valuesWithId?.[key] || '').split('<|>').pop() || UNKNOWN_DEPENDENCY])
        );
      }
      return dependencyRef.current;
    }, [valuesWithId]);

    const [search, setSearch] = useState('');
    const { options, loading } = useAutocomplete({
      entities,
      search,
      type,
      dependencies: dependencyValues,
      searchBy,
    } as TUseAutocompleteProps);

    const { request } = useAutocompleteStorage(entities, type);
    const [storage, setStorage] = useState(new Map<string, string>());

    const emailItems = value
      .filter((v) => !v.includes('<|>') && v.includes('@'))
      .map((v) => [v, v] as [string, string]);

    if (search.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      emailItems.push([search, search]);
    }

    const items = useMemo(
      () => new Map(Array.from(options).concat(Array.from(storage)).concat(emailItems)),
      [options, storage, emailItems]
    );

    // LoadValues
    useEffect(() => {
      const notLoadedValues = (value ? [value].flat() : []).filter((id) => !storage.has(id));
      if (notLoadedValues.length > 0) {
        if (notLoadedValues.some((id) => items.has(id))) {
          setStorage((acc) => {
            notLoadedValues.filter((id) => items.get(id)).forEach((id) => acc.set(id, items.get(id) as string));
            return acc;
          });
        } else {
          request(notLoadedValues).then((result) => {
            setStorage((acc) => new Map<string, string>([...Object.entries(result), ...acc.entries()]));
          });
        }
      } else {
        if (!value || value.length === 0) {
          if (storage.size > 0) setStorage(new Map<string, string>());
        }
      }
    }, [storage, items, setStorage, request, value]);

    // syncStorage
    useEffect(() => {
      if (value.some((id) => !storage.get(id)) && value.every((id) => items.get(id))) {
        setStorage(value.reduce((acc, id) => acc.set(id, items.get(id) as string), new Map<string, string>()));
      }
    }, [setStorage, storage, items, value, values]);

    const onSelectChange = useCallback(
      (value: string[]) => {
        const format = (v: string) =>
          !v.includes('<|>') && v.includes('@') ? `undefined<|>undefined<|>undefined<|>${v}` : v;

        onChange(isMultiple ? value.map((x) => format(x)) : format(value[0]));
      },
      [onChange]
    );

    return (
      <Select
        items={items}
        isMultiple={isMultiple}
        onSearch={setSearch}
        value={value}
        onChange={onSelectChange}
        loading={loading}
        {...props}
      />
    );
  };
};
