import classes from './calendar.module.scss';
import {
  format,
  parseISO,
  startOfMonth,
  addDays,
  endOfMonth,
  getDay,
  getWeeksInMonth,
  isSameMonth,
  addMonths,
  isBefore,
  isSameDay,
  endOfDay,
  startOfDay,
} from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { IconButton } from 'components/IconButton';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import { useListPageApi } from 'components/ListPage/hook';
import { useMetaData } from 'lib/hooks';
import { useSystemUserId, useTableConfig } from 'lib/helpers';
import { config as additionalConfig } from 'schemas/event';
import Tooltip from 'components/Tooltip';
import { useHistory } from 'react-router-dom';
import { ApiFilter } from 'lib';
import { Loader } from 'components/Loader';
import { TLinkEntity } from 'components/ListPage';

const getFirstDayInGrid = (startDate: Date) => addDays(startDate, getDay(startDate) * -1);
const getDateGrid = (startDate: Date) =>
  [...new Array(getWeeksInMonth(startDate) * 7)].map((_, index) => addDays(getFirstDayInGrid(startDate), index));

export const Calendar = ({
  children,
  fullSize,
}: {
  children?: ({ reload }: { reload: () => void }) => JSX.Element;
  fullSize?: boolean;
}) => {
  const today = useMemo(() => new Date(), []);
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [monthNumber, setMonthNumber] = useState(1);
  const userId = useSystemUserId();

  const { entityConfig, PrimaryIdAttribute } = useMetaData('event');
  const config = useTableConfig(entityConfig.fields, additionalConfig, 'event');
  const { push } = useHistory();

  const renderEvent = useCallback(
    (item: Record<string, any>, index: number) => (
      <div key={index} onMouseDown={() => push(`/event/${item[PrimaryIdAttribute]}`)} className={cx(classes.listItem)}>
        <div className={cx(classes.mobileRing)}>{'E' + index}</div>
        <div className={classes.listItemContent}>
          {['bahai_id', 'bahai_title', 'bahai_startdatetime']
            .map((key) => config[key])
            .map(({ name, component: Component, adapter }) => (
              <div key={name} className={classes.mobileLine}>
                {Component ? (
                  <Component key={name} data={item} name={name} defaultValue="---" classes={classes} context="TABLE" />
                ) : (
                  <div>{adapter(item, name, '---')}</div>
                )}
              </div>
            ))}
        </div>
      </div>
    ),
    [PrimaryIdAttribute, config, push]
  );

  const hiddenFilters: ApiFilter[] = useMemo(
    () => [
      {
        filter: [
          {
            logicOperator: 'or',
            condition: [
              { attribute: 'ownerid', operator: 'eq', value: userId },
              { attribute: 'bahai_designatedcontactobjectid', operator: 'eq', value: userId },
              { attribute: 'bahai_designatedhostobjectid', operator: 'eq', value: userId },
              { attribute: 'bahai_designatedhostobjectid', operator: 'eq', value: userId },
              { attribute: 'bahai_eventid', entityname: 'invitedResource', operator: 'not-null' },
            ],
          },
        ],
        condition: [
          { attribute: 'bahai_finishdatetime', operator: 'ge', value: format(startDate, 'yyyy-MM-dd 00:00:00') },
          {
            attribute: 'bahai_startdatetime',
            operator: 'le',
            value: format(endOfMonth(addMonths(startDate, monthNumber - 1)), 'yyyy-MM-dd 23:59:59'),
          },
        ],
      },
    ],
    [monthNumber, startDate, userId]
  );

  const links: TLinkEntity = useMemo(
    () => ({
      invitedResource: {
        to: 'bahai_event_imsid',
        from: 'bahai_eventid',
        fields: [],
        condition: [{ attribute: 'bahai_systemuserid', operator: 'eq', value: userId }],
      },
    }),
    [userId]
  );

  const { data, reload, loading } = useListPageApi({
    config,
    ready: true,
    entityName: 'event',
    hiddenFilters,
    links,
  });

  const dates = useMemo(
    () =>
      [...new Array(monthNumber)]
        .map((_, i) => addMonths(startDate, i))
        .map((startDate) => ({
          date: startDate,
          grid: getDateGrid(startDate).map((date) => ({
            date,
            events: data.filter(
              (v) =>
                isBefore(startOfDay(date), parseISO(v.bahai_finishdatetime)) &&
                isBefore(parseISO(v.bahai_startdatetime), endOfDay(date))
            ),
          })),
        })),
    [data, monthNumber, startDate]
  );

  const nextMonth = useCallback(() => setStartDate(addMonths(startDate, 1)), [startDate]);
  const prevMonth = useCallback(() => setStartDate(addMonths(startDate, -1)), [startDate]);

  const ref = useRef<HTMLDivElement | null>(null);

  const checkSize = useCallback(() => {
    const { width } = ref.current?.getBoundingClientRect() || { width: 360 };
    const month = Math.max(Math.floor((width - 64) / 216), 1);
    if (month !== monthNumber) {
      setMonthNumber(month);
    }
  }, [monthNumber]);

  useEffect(() => {
    if (ref.current) {
      checkSize();
      window.addEventListener('resize', checkSize);
      return () => {
        window.removeEventListener('resize', checkSize);
      };
    }
  }, [checkSize, fullSize]);

  return (
    <div className={classes.rootWrapper} ref={ref}>
      {children && children({ reload })}
      {loading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      <div className={classes.root}>
        <IconButton className={classes.arrow} iconOnly Icon={ArrowIcon} onClick={prevMonth} />
        <div className={classes.gridsWrapper}>
          {dates.map(({ date, grid }) => (
            <div key={format(date, 'yy-MM-dd')} className={classes.wrapper}>
              <div className={classes.header}>{format(date, 'LLLL yyyy')}</div>
              <div className={classes.grid}>
                {grid.slice(0, 7).map(({ date: day }, i) => (
                  <div
                    key={format(day, 'MM-dd')}
                    className={cx(classes.dayName, { [classes.sunday]: i === 0 || i === 6 })}
                  >
                    {format(day, 'EEEEEE')}
                  </div>
                ))}
                <>
                  {grid.map(({ date: day, events }, i) => (
                    <div
                      key={i}
                      className={cx(
                        classes.day,
                        { [classes.current]: isSameDay(day, today) && isSameMonth(date, day) },
                        { [classes.sunday]: i % 7 === 0 || i % 7 === 6 },
                        { [classes.dot]: isSameMonth(date, day) && !!events.length }
                      )}
                    >
                      {isSameMonth(date, day) ? (
                        events.length ? (
                          <Tooltip
                            display="block"
                            content={
                              <div className={classes.list}>
                                <div className={classes.listHeader}>{format(day, 'LLLL dd, yyyy · EEEE')}</div>
                                <span className={classes.listWrapper}>{events.map(renderEvent)}</span>
                              </div>
                            }
                            onClick
                          >
                            <div className={classes.dayText}>{format(day, 'dd')}</div>
                          </Tooltip>
                        ) : (
                          format(day, 'dd')
                        )
                      ) : null}
                    </div>
                  ))}
                </>
              </div>
            </div>
          ))}
        </div>
        <IconButton
          className={classes.arrow}
          iconOnly
          onClick={nextMonth}
          Icon={() => <ArrowIcon style={{ transform: 'rotate(180deg)' }} />}
        />
      </div>
    </div>
  );
};
