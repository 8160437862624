import { useMetaData } from 'lib/hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as rules from 'lib/rules';
import { FormProps } from 'react-final-form';
import { Modal } from 'components/Modal';
import { useApi } from 'domain/api';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { TActionControlsProps, TSubmitProps } from 'components/ListPage/components/Form';
import { bahai_inquirer_contactmethodcode as ContactMethod } from 'config/EntityMetadata/bahai_inquirer';
import { HistoryLink } from 'components/HistoryLink';
import { routes } from 'domain/routes';
import { Control } from 'components/Panel';
import { MergeRequester } from 'schemas/requester/components/MergeRequester';
import { Keys, isNotEditable, isChild } from './index';
import { useRecord } from 'lib/record';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { ReactComponent as MergeIcon } from './merge.svg';
import { parseSaveFormError } from 'lib/errorParser';

const mainAddressFields = ['bahai_postalcodeid', 'bahai_stateid', 'bahai_cityid'];

const checkMailingValid = (values: Record<string, any>) => {
  return values[`bahai_isoutofusa`] || mainAddressFields.every((v) => !!values[v]);
};

const contactMethodRelated = {
  [ContactMethod.Phone]: ['bahai_cellphone', 'bahai_workphone', 'bahai_homephone'],
  [ContactMethod.Email]: ['bahai_emailaddress1'],
  [ContactMethod.Mail]: ['bahai_mailingcompositeaddress'],
  [ContactMethod.Text]: ['bahai_cellphone', 'bahai_workphone', 'bahai_homephone'],
  [ContactMethod.SocialMedia]: ['bahai_socialmediaurl'],
  [ContactMethod.Website]: ['bahai_websiteurl'],
  [ContactMethod.VideoConference]: ['bahai_emailaddress1'],
  [ContactMethod.HomeVisit]: ['bahai_mailingcompositeaddress'],
};

export const useRequesterValidation = () => {
  const { t } = useTranslation();
  const { entityConfig } = useMetaData('requester');

  const contactMethodValidation = useCallback(
    ({ bahai_contactmethodcode: arrayOrStringValue, ...values }: Record<string | 'bahai_contactmethodcode', any>) => {
      const value = typeof arrayOrStringValue === 'string' ? arrayOrStringValue.split(',') : arrayOrStringValue;
      if (value && value.length) {
        return (value.map((v: string) => Number(v)) as ContactMethod[])
          .map((method) => ({ method, fields: contactMethodRelated[method] || [] }))
          .map(({ method, fields }) => {
            const names = fields.map((name) => entityConfig.fields[name].label).join(', ');
            const message =
              fields.length > 1
                ? t('Please fill out any of the next fields {{ names }}', {
                    names: ': ' + names,
                  })
                : t('Please fill {{ names }}', { names });
            switch (method) {
              case ContactMethod.Mail:
                return checkMailingValid(values) ? undefined : t('Please fill in Main address');
              case ContactMethod.HomeVisit:
                return values.bahai_isoutofusa || mainAddressFields.some((v) => !values[v])
                  ? t('Please fill in Main US address')
                  : undefined;
              default:
                if (fields.length === 0) return;
                return rules.relatedRequired(fields, message)(value, values);
            }
          })
          .filter((v?: string) => !!v)
          .filter((v: string, index: number, arr: string[]) => arr.indexOf(v) === index);
      }
      return [];
    },
    [entityConfig, t]
  );

  const checkMethods = useCallback(
    (values: Record<string, any>, keys: number[]) => {
      return keys
        .map((v) => contactMethodValidation({ ...values, bahai_contactmethodcode: [v] }))
        .some((v) => v.length === 0);
    },
    [contactMethodValidation]
  );

  const mainAddressValidation = useCallback(
    (values: Record<string, any>) => {
      return values.bahai_isoutofusa && !checkMethods(values, [ContactMethod.Phone, ContactMethod.Email])
        ? [t('Please, make sure that Primary Email or a phone number is added for Requester out of the US')]
        : [];
    },
    [checkMethods, t]
  );

  const nameValidation = useCallback(
    (values: Record<string, any>) =>
      [values.bahai_firstname, values.bahai_nickname].map((v: string) => (v || '').trim()).some((v) => !!v)
        ? []
        : [t('First Name or Nickname is required')],
    [t]
  );

  const registrationValidation = useCallback(
    (values: Record<string, any>) => {
      if (!values.registration) return [];
      return [
        !isChild(values) || ['bahai_parentrequesterid', 'bahai_parentmemberid'].some((v) => values[v])
          ? undefined
          : t('Parent Requester or Parent Member is required for Child Registration'),
        ['bahai_cellphone', 'bahai_homephone'].some((v) => values[v])
          ? undefined
          : t('Cell Phone or Home Phone is required'),
      ].filter((v) => !!v) as string[];
    },
    [t]
  );

  const validate: FormProps['validate'] = useCallback(
    (values: Record<string, any>) => {
      const errors = [
        ...mainAddressValidation(values),
        ...contactMethodValidation(values),
        ...nameValidation(values),
        ...registrationValidation(values),
      ];

      return errors.length === 0 ? undefined : { _general: errors };
    },
    [mainAddressValidation, contactMethodValidation, nameValidation, registrationValidation]
  );

  return { validate };
};

const useCheckDuplicates = (id?: string) => {
  const { t } = useTranslation();

  const { getRequesterDuplicates } = useApi();
  const [data, setData] = useState<Record<string, any> | null>(null);
  const [isMergeVisible, setIsMergeVisible] = useState(false);
  const closeMerge = useCallback(() => setIsMergeVisible(false), []);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [idList, setIdList] = useState<string[]>([]);
  const [isDraft, setIsDraft] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const resolver = useRef((_: boolean) => {});
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const closer = useRef((_: string) => {});

  const ignore = useCallback(() => {
    setIsConfirmationVisible(false);
    resolver.current(true);
  }, [resolver]);

  const startMerge = useCallback(() => {
    setIsConfirmationVisible(false);
    setIsMergeVisible(true);
    resolver.current(false);
  }, [resolver]);

  const checkDuplicates = useCallback(
    async (data: Record<string, any>, postAction: (id?: string) => void, isDraft = false) =>
      new Promise<boolean>((resolve) => {
        setIsDraft(isDraft);
        resolver.current = resolve;
        closer.current = postAction;
        getRequesterDuplicates(data, id)
          .then(({ requesters }) => {
            if (requesters.length > 0) {
              setIdList(requesters);
              setData(data);
              setIsConfirmationVisible(true);
            } else {
              resolve(true);
            }
          })
          .catch(() => {
            resolve(true);
          });
      }),
    [getRequesterDuplicates, id]
  );

  const confirmation = useMemo(
    () => (
      <Modal
        title={t('View Duplicates')}
        onClose={() => {
          setIsConfirmationVisible(false);
          resolver.current(false);
        }}
        controls={[
          {
            title: t('View Duplicates'),
            role: 'primary',
            onClick: startMerge,
          },
          {
            title: t('Save and Ignore'),
            onClick: ignore,
          },
        ]}
      >
        {t("The record you're about to create looks like a Duplicate.")}
      </Modal>
    ),
    [ignore, startMerge, t]
  );

  const merge = useMemo(() => {
    if (data)
      return (
        <MergeRequester
          closer={closer.current}
          id={id}
          formValues={data}
          idList={idList}
          onClose={closeMerge}
          isDraft={isDraft}
        />
      );
    return null;
  }, [closeMerge, data, id, idList, isDraft]);

  const content = useMemo(() => {
    if (isConfirmationVisible) return confirmation;
    if (isMergeVisible) return merge;
    return null;
  }, [confirmation, isConfirmationVisible, isMergeVisible, merge]);

  return {
    content,
    checkDuplicates,
  };
};

export const useMerge = (id: string) => {
  const { t } = useTranslation();
  const reloadRef = useRef<() => void>(() => undefined);
  const [data, setData] = useState<Record<string, any> | null>(null);
  const [loading, setLoading] = useState(true);

  const [idList, setIdList] = useState<string[]>([]);
  const { addActionUncompleted } = useNotifications();

  const { getRequesterDuplicates } = useApi();

  useEffect(() => {
    if (data && id) {
      getRequesterDuplicates(data, id).then(({ requesters }) => {
        setIdList(requesters);
        setLoading(false);
      });
    }
    return () => {
      setLoading(true);
      setIdList([]);
    };
  }, [data, getRequesterDuplicates, id]);

  const action: Action = useMemo(
    () => ({
      title: t('Duplicate Manager'),
      name: 'duplicateManager',
      onClick: ({ selectedItems: [data], reload }) => {
        const message = isNotEditable(data, false);
        if (message) {
          addActionUncompleted(message);
        } else {
          reloadRef.current = reload;
          setData(data);
        }
      },
      display: ({ context, isEditAllowed }) => context === ActionContext.SinglePage && isEditAllowed,
      Icon: MergeIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SinglePage,
      allowedDevices: AllowedDevices.All,
    }),
    [addActionUncompleted, t]
  );

  const onClose = useCallback(() => setData(null), []);

  const closer = useCallback(() => {
    reloadRef.current();
  }, []);

  const content = useMemo(
    () =>
      data && !loading ? (
        <MergeRequester id={id} idList={idList} onClose={onClose} closer={closer} isDraft={data.bahai_isdraft} />
      ) : null,
    [closer, data, id, idList, loading, onClose]
  );

  return { action, content };
};

const manageDraftParams = (data: Partial<Record<Keys, any>>, saveAsDraft = false): Partial<Record<Keys, any>> => ({
  ...data,
  bahai_isdraft: saveAsDraft,
});

export const useRequester = (id?: string) => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  const { save } = useRecord('requester');
  const { checkDuplicates, content: duplicatesContent } = useCheckDuplicates(id);

  const saveAsDraft = useCallback(
    async ({
      data,
      errors,
      postAction,
      id,
      form,
    }: Pick<TActionControlsProps, 'data' | 'errors' | 'postAction' | 'id' | 'form'>) => {
      const { _general: _, ...errorList } = errors || {};
      const nonRequiredErrors = Object.entries(errorList)
        .filter((v) => !!v)
        .filter((v) => v[1]?.props?.children !== 'Required');

      if (nonRequiredErrors.length > 0) {
        nonRequiredErrors.forEach((v) => form.blur(v[0]));
        return;
      }
      await new Promise<void>((resolve, reject) => {
        checkDuplicates(manageDraftParams(data, true), postAction, true).then((v) => (v ? resolve() : reject()));
      })
        .then(async () => {
          await save(manageDraftParams(data, true), id, ['bahai_originationdate', 'registration'])
            .then((resp) => {
              const recordId = id || resp?.headers?.location?.match(/\((.+)\)/)?.[1];
              if (!id) {
                addNotification({
                  title: <Trans>Record was created</Trans>,
                  type: NotificationType.SUCCESS,
                  content: recordId ? (
                    <Trans>
                      Please, go to <HistoryLink to={routes.requester({ id: recordId })}>hyperlink</HistoryLink> to see
                      it.
                    </Trans>
                  ) : undefined,
                });
              } else {
                addNotification({ type: NotificationType.SUCCESS, title: <Trans>Your changes have been saved</Trans> });
              }
              postAction(recordId);
            })
            .catch(() => {
              addNotification({ type: NotificationType.ERROR, title: <Trans>Something went wrong</Trans> });
            });
        })
        .catch(() => {
          return;
        });
    },
    [addNotification, checkDuplicates, save]
  );

  const onSubmit = useCallback(
    async ({ data, postAction, id }: TSubmitProps) => {
      try {
        if (await checkDuplicates(manageDraftParams(data, true), postAction)) {
          const resp = await save(manageDraftParams(data), id, ['bahai_originationdate', 'registration']);
          if (resp.status > 204) {
            // check this for throw
            return Promise.reject({ _general: [<Trans>Something went wrong</Trans>] });
          } else {
            const recordId = id || resp?.headers?.location?.match(/\((.+)\)/)?.[1];
            if (!id) {
              addNotification({
                title: <Trans>Record was created</Trans>,
                type: NotificationType.SUCCESS,
                content: recordId ? (
                  <Trans>
                    Please, go to <HistoryLink to={routes.requester({ id: recordId })}>hyperlink</HistoryLink> to see
                    it.
                  </Trans>
                ) : undefined,
              });
            } else {
              addNotification({ type: NotificationType.SUCCESS, title: <Trans>Your changes have been saved</Trans> });
            }
            postAction(recordId);
          }
        }
      } catch (error) {
        throw { _general: [parseSaveFormError(error, !!id)] };
      }
    },
    [addNotification, checkDuplicates, save]
  );

  const getActionControls = useCallback(
    ({ loading, refresh, data, setLoading, ...rest }: TActionControlsProps) => [
      {
        title: t('Save'),
        role: 'primary',
        disabled: loading,
        type: 'submit',
        onMouseDown: refresh,
      } as Control,
      ...(data.bahai_isdraft !== false
        ? [
            {
              title: t('Save as Draft'),
              onMouseDown: refresh,
              onClick: () => {
                setLoading(true);
                saveAsDraft({
                  data,
                  ...rest,
                }).finally(() => setLoading(false));
              },
            } as Control,
          ]
        : []),
    ],
    [saveAsDraft, t]
  );

  return { onSubmit, getActionControls, duplicatesContent };
};
