import { useCallback, useMemo, useState } from 'react';
import { ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { Form } from 'components/ListPage/components/Form';
import { config as templateConfig, getFormConfig, validation } from 'schemas/template/index';
import { useMetaData } from 'lib/hooks';
import { useTableConfig } from 'lib/helpers';
import { ReactComponent as AddIcon } from 'components/Actions/icons/add.svg';
import { useSystemUserId } from 'lib/helpers';
import { Action } from 'components/Actions';

export const useCreateTemplateFromList = () => {
  const [showContent, setShowContent] = useState(false);
  const onClose = useCallback(() => setShowContent(false), []);
  const { entityConfig: emailMetaData } = useMetaData('template');
  const config = useTableConfig(emailMetaData.fields, templateConfig, 'template');
  const userId = useSystemUserId();
  const content = useMemo(
    () =>
      showContent ? (
        <Form
          entityName={'template'}
          onClose={onClose}
          postAction={onClose}
          config={config}
          getFormConfig={getFormConfig}
          validation={validation}
          initialValues={{ ownerid: 'systemuser<|>' + userId }}
        />
      ) : null,
    [onClose, showContent, config, userId]
  );

  const createTemplateAction = useMemo(
    () =>
      ({
        actionType: ActionType.CUSTOM_ACTION,
        title: 'Template',
        name: 'createTemplate',
        Icon: AddIcon,
        onClick: () => setShowContent(true),
        display: ({ selectedItemsCount = 0, isCreateAllowed, context }) =>
          context === ActionContext.ListPage && isCreateAllowed && selectedItemsCount === 0,
        type: ActionType.CUSTOM_ACTION,
        allowedDevices: AllowedDevices.All,
        alwaysKeepTitle: true,
        actionContext: ActionContext.ListPageAndSubGid,
      }) as Action,
    []
  );

  return { createTemplateAction, content };
};
