import entities, { Fields, SystemFields } from 'config';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import { FieldValidator } from 'final-form';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { AreaComponent, AreaTooltipComponent, HTMLComponent } from 'lib/components';
import { TLinkEntity } from 'components/ListPage';
import * as rules from 'lib/rules';
import { RequesterAddButton } from './components/RequesterAddButton';
import { createFormAutocomplete } from 'components/Form/Autocomplete';
import {
  config as requesterConfig,
  isLocalityCalculation,
  LocalityWarning,
  getWarningsImprover,
} from 'schemas/requester';
import * as inquiryMetadata from 'config/EntityMetadata/bahai_inquiry';
import { Trans } from 'react-i18next';
import { bahai_inquirerstatus, source_code, statecode } from 'config/EntityMetadata/bahai_inquirer';
import * as inquirerMetadata from 'config/EntityMetadata/bahai_inquirer';
import { getLockMessageDefault } from 'components/SinglePage';
import { FormProps } from 'react-final-form';
import { timeAdapter } from 'lib/adapter';

const {
  inquiry: { columns: inquiryColumns },
  requester: { columns: requesterColumns },
} = entities;

export const columns = [
  ...inquiryColumns,
  'locality.bahai_name',
  'cluster.bahai_name',
  'reminder.bahai_reminderdatetime',
  'cluster.bahai_stageofgrowth',
  'locality.bahai_localitytype',
  ...requesterColumns.map((v) => 'requester.' + v),
] as const;

export type RequesterFields = `requester.${Fields<(typeof requesterColumns)[number]>}`;

export type Keys =
  | (typeof columns)[number]
  | SystemFields
  | 'bahai_inquiryid'
  | '_bahai_inquirerid_value'
  | 'reminder.subject'
  | 'reminder.description'
  | 'reminder.bahai_statuscode'
  | '_bahai_currentreminder_value'
  | RequesterFields;

export const defaultMobileColumns: Keys[] = ['requester.bahai_name', 'bahai_typecode', 'createdon'];

export { getWarningsImprover };

export const getLockMessage = (data: Record<string, any>) =>
  isLocalityCalculation(data)
    ? {
        message: (
          <Trans>
            Please, note that Locality is being calculated. The form will be unlocked and can be edited after Locality
            is determined.
          </Trans>
        ),
        forceDisplay: true,
        icon: 'clock',
      }
    : getLockMessageDefault();

export const links = {
  requester: requesterColumns,
  locality: {
    to: 'bahai_localityid',
    from: 'businessunitid',
    fields: ['bahai_name', 'bahai_localitytype'],
    condition: [],
  },
  cluster: {
    to: 'bahai_clusterid',
    from: 'businessunitid',
    fields: ['bahai_name', 'bahai_stageofgrowth'],
    condition: [],
  },
  reminder: {
    to: 'bahai_currentreminder',
    from: 'activityid',
    fields: ['description', 'bahai_reminderdatetime', 'bahai_statuscode', 'subject'],
    condition: [],
  },
} as TLinkEntity;

export const statusFields = (): Keys[] => ['bahai_statuscode', 'reminder.bahai_reminderdatetime'];

export { isLocalityCalculation };
export const isActive = (data: Record<string, any>) => Number(data.statecode) === inquiryMetadata.statecode.Active;

export const isNotEditable = (data: Record<Keys, any>) => {
  if (!isActive(data)) return <Trans>Inquiry State is Inactive</Trans>;
  if (isLocalityCalculation(data)) return LocalityWarning;
  return false;
};

export const isNotRemovable = (data: Record<Keys, any>) => {
  if (
    !(
      (data.bahai_statuscode === inquiryMetadata.bahai_inquirystatus.New ||
        data.bahai_statuscode === inquiryMetadata.bahai_inquirystatus.InProgress) &&
      (data.bahai_typecode.includes(inquiryMetadata.bahai_inquiry_typecode.TalkToABahai.toString()) ||
        data.bahai_typecode.includes(inquiryMetadata.bahai_inquiry_typecode.BecomeaBahai.toString()))
    )
  )
    return (
      <Trans>
        Inquiry Status should be New or In Progress and Inquiry Type should be Talk to a Baha'i or Become a Baha'i to
        delete it.
      </Trans>
    );
  return false;
};

export const isConfirmationMessageNeeded = () => true;
export const isConfirmationMessageRequired = () => false;

const addressPartRequired = (values: Record<string, any>) =>
  values.bahai_typecode.includes(inquiryMetadata.bahai_inquiry_typecode.RequestFreeLiterature.toString());

export const config: AdditionalConfigType<Keys> = {
  bahai_id: {
    searchable: true,
    component: ({ data }) => (
      <div data-is-row-active={`${isActive(data) ? 'true' : 'false'}`}>
        <HistoryLink to={r.inquiry({ id: data.bahai_inquiryid || 0 })}>{data.bahai_id}</HistoryLink>
      </div>
    ),
  },
  bahai_typecode: {
    sortable: false,
    isRequired: true,
    isDisabled: (_, data) => !!data?.id,
  },
  bahai_prioritycode: { isRequired: true },
  bahai_inquirerid: {
    searchable: true,
    isRequired: true,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'requester',
          filters: {
            statecode: statecode.Active,
            bahai_statuscode: {
              operator: 'ne',
              value: bahai_inquirerstatus.DoNotContact,
            },
          },
        },
      ],
    }),
    fieldProps: ({ classes }) => ({ createElement: <RequesterAddButton />, className: classes.long }),
  },
  'locality.bahai_name': {
    label: <Trans>Locality Name</Trans>,
  },
  'cluster.bahai_name': {
    label: <Trans>Cluster Name</Trans>,
  },
  bahai_comment: {
    searchable: true,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 850,
    }),
    component: AreaTooltipComponent,
  },
  bahai_note: {
    searchable: true,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 500,
    }),
    component: AreaTooltipComponent,
  },
  bahai_additionalnote: {
    component: HTMLComponent('bahai_additionalnote', 60, true),
    fieldProps: ({ classes }) => ({
      className: classes.long,
    }),
  },
  bahai_isoutofusa: {
    hiddenForTable: true,
    hiddenForView: true,
    fieldProps: ({ classes }) => ({
      className: classes.long,
    }),
  },
  bahai_postalcodeid: {
    isRequired: addressPartRequired,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'bahai_postalcode',
          filters: { bahai_countryidname: 'USA', bahai_stateid: 'bahai_stateid', bahai_cityid: 'bahai_cityid' },
        },
      ],
      dependencies: ['bahai_stateid', 'bahai_cityid'],
    }),
  },
  bahai_cityid: {
    isRequired: addressPartRequired,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'bahai_city',
          filters: { bahai_countryidname: 'USA', bahai_stateid: 'bahai_stateid' },
        },
      ],
      dependencies: ['bahai_stateid'],
    }),
  },
  bahai_stateid: {
    isRequired: addressPartRequired,
    field: createFormAutocomplete({
      entities: [{ entityName: 'bahai_state', filters: { bahai_countryidname: 'USA' } }],
    }),
  },
  bahai_addressline1: {
    isRequired: addressPartRequired,
    hiddenForTable: true,
  },
  bahai_addressline2: {
    hiddenForTable: true,
  },
  bahai_compositeaddress: {
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
    component: AreaComponent,
  },
  statecode: {
    hiddenForTable: true,
  },
  bahai_localitycalculationstatuscode: {
    hiddenForTable: true,
  },
  ...Object.fromEntries(
    Object.entries(requesterConfig).map(([key, props]) => ['requester.' + key, { ...props, searchable: false }])
  ),
  'cluster.bahai_stageofgrowth': {
    label: <Trans>Stage of Growth</Trans>,
  },
  'locality.bahai_localitytype': {
    label: <Trans>Community Type</Trans>,
  },
  'requester.bahai_name': {
    component: ({ data }) => (
      <HistoryLink to={r.requester({ id: data.bahai_inquirerid || data['_bahai_inquirerid_value'] || 0 })}>
        {data['requester.bahai_name']}
      </HistoryLink>
    ),
  },
  ownerid: {
    searchable: true,
  },
  bahai_sourcecode: {
    isRequired: true,
    isDisabled: ({ bahai_sourcecode }) =>
      [inquirerMetadata.source_code.BahaiUs, inquirerMetadata.source_code.BahaiTeachingOrg].includes(
        Number(bahai_sourcecode)
      ),
    fieldProps: ({ values: { bahai_sourcecode } }) => ({
      filterValues: [inquirerMetadata.source_code.BahaiUs, inquirerMetadata.source_code.BahaiTeachingOrg].includes(
        Number(bahai_sourcecode)
      )
        ? undefined
        : (v: [string]) =>
            ![inquirerMetadata.source_code.BahaiUs, inquirerMetadata.source_code.BahaiTeachingOrg].includes(
              Number(v[0])
            ),
    }),
  },
  bahai_sourcedetails: {
    isDisabled: ({ bahai_sourcecode }) =>
      [inquirerMetadata.source_code.BahaiUs, inquirerMetadata.source_code.BahaiTeachingOrg].includes(
        Number(bahai_sourcecode)
      ),
  },
  bahai_currentreminder: { hiddenForTable: true },
  'reminder.bahai_reminderdatetime': {
    component: ({ data: { _bahai_currentreminder_value: id, ...data }, defaultValue }) =>
      id ? (
        <HistoryLink to={r.reminder({ id })}>
          {timeAdapter(data, 'reminder.bahai_reminderdatetime', defaultValue)}
        </HistoryLink>
      ) : (
        <>{defaultValue}</>
      ),
  },
  'reminder.subject': {
    component: ({ data: { _bahai_currentreminder_value: id, 'reminder.subject': subject }, defaultValue }) =>
      id ? <HistoryLink to={r.reminder({ id })}>{subject || defaultValue}</HistoryLink> : <>{defaultValue}</>,
  },
  'reminder.description': {
    fieldProps: ({ classes }) => ({ className: classes.long }),
    component: AreaTooltipComponent,
  },
  bahai_lastexportcreator: { hiddenForTable: true },
};

export const getDetailsConfig: FormConfigGetter<Keys> = () => [
  [
    <Trans>Main Data</Trans>,
    [
      'bahai_typecode',
      'bahai_prioritycode',
      'bahai_inquirerid',
      'bahai_comment',
      'bahai_note',
      'bahai_additionalnote',
      'bahai_sourcecode',
      'bahai_sourcedetails',
      'createdon',
      'createdby',
    ] as Keys[],
  ],
  [
    <Trans>Address</Trans>,
    [
      'bahai_isoutofusa',
      'bahai_compositeaddress',
      'bahai_localityid',
      'locality.bahai_name',
      'locality.bahai_localitytype',
      'bahai_clusterid',
      'cluster.bahai_name',
      'cluster.bahai_stageofgrowth',
      'bahai_regionid',
    ] as Keys[],
  ],
  [
    <Trans>Requester’s Information</Trans>,
    [
      'requester.bahai_nickname',
      'requester.bahai_emailaddress1',
      'requester.bahai_cellphone',
      'requester.bahai_contactmethodcode',
      'requester.bahai_statuscode',
      'requester.bahai_statusdetailscode',
      'requester.bahai_estimatedagerange',
      'requester.bahai_importantnote',
      'requester.bahai_additionalnote',
    ] as RequesterFields[],
  ],
  [
    <Trans>Current Reminder</Trans>,
    ['reminder.subject', 'reminder.bahai_reminderdatetime', 'reminder.bahai_statuscode', 'reminder.description'],
  ],
];

export const getFormConfig: FormConfigGetter<Keys | RequesterFields> = (data) => [
  [
    <Trans>Main Data</Trans>,
    [
      'bahai_typecode',
      'bahai_prioritycode',
      'bahai_inquirerid',
      'bahai_comment',
      'bahai_note',
      'bahai_sourcecode',
      'bahai_sourcedetails',
    ] as Keys[],
  ],
  [
    <Trans>Address</Trans>,
    [
      'bahai_isoutofusa',
      ...(data.bahai_isoutofusa
        ? ['bahai_compositeaddress']
        : ['bahai_postalcodeid', 'bahai_stateid', 'bahai_cityid', 'bahai_addressline1', 'bahai_addressline2']),
    ] as Keys[],
  ],
];

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_typecode: rules.required,
  bahai_prioritycode: rules.required,
  bahai_inquirerid: rules.required,
  bahai_sourcecode: rules.required,

  bahai_postalcodeid: rules.compose([
    rules.conditionalRequired(addressPartRequired),
    rules.groupRequired([
      'bahai_postalcodeid',
      'bahai_stateid',
      'bahai_cityid',
      'bahai_addressline1',
      'bahai_addressline2',
    ]),
  ]),
  bahai_stateid: rules.compose([
    rules.conditionalRequired(addressPartRequired),
    rules.groupRequired([
      'bahai_postalcodeid',
      'bahai_stateid',
      'bahai_cityid',
      'bahai_addressline1',
      'bahai_addressline2',
    ]),
  ]),
  bahai_cityid: rules.compose([
    rules.conditionalRequired(addressPartRequired),
    rules.groupRequired([
      'bahai_postalcodeid',
      'bahai_stateid',
      'bahai_cityid',
      'bahai_addressline1',
      'bahai_addressline2',
    ]),
  ]),

  bahai_addressline1: rules.compose([rules.maxLength(40), rules.conditionalRequired(addressPartRequired)]),
  bahai_addressline2: rules.compose([rules.maxLength(40)]),
  bahai_compositeaddress: rules.conditionalRule((values) => values.bahai_compositeaddress, rules.length(10, 2000)),
  bahai_sourcedetails: rules.maxLength(100),
  bahai_comment: rules.maxLength(850),
  bahai_note: rules.maxLength(500),
};

export const getInitialValues = async () => ({
  bahai_typecode: [],
  bahai_prioritycode: inquiryMetadata.bahai_prioritycode.Medium.toString(),
  bahai_isoutofusa: false,
  bahai_sourcecode: String(source_code.Resource),
});

export const validate: FormProps['validate'] = (values) =>
  values.bahai_isoutofusa &&
  values.bahai_typecode.includes(inquiryMetadata.bahai_inquiry_typecode.RequestFreeLiterature.toString())
    ? {
        _general: [
          <Trans>
            International requests need to be handled in their home country. Please forward their request and
            information to the Secretariat at secretariat@usbnc.org.
          </Trans>,
        ],
      }
    : undefined;
