const columns = [
  'bahai_entitycode',
  'bahai_eventname',
  'bahai_changedata',
  'bahai_changeddatetime',
  'bahai_changedby',
  'bahai_recordid',
] as const;

export default { columns, fields: [] };
