import classes from './header.module.scss';
import { IconButton } from 'components/IconButton';
import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState, memo } from 'react';
import cx from 'classnames';
import { MenuButton } from 'components/MenuButton';
import { ReactComponent as MoreIcon } from 'components/ListPage/icons/more.svg';
import { ActionContext, AllowedDevices, HeaderAction } from 'components/Actions';
import { ScreenContext } from 'providers/ScreenProvider';
import { Divider } from 'components/Divider';

export enum HEADER_CONTEXT {
  HOMEPAGE,
  SUBGRID,
  FORM,
}

const Header = ({
  actions,
  headerContext,
  selectedItemsCount = 0,
}: {
  actions: Array<HeaderAction>;
  iconsOnly?: boolean;
  headerContext: HEADER_CONTEXT;
  maxItems?: number;
  selectedItemsCount?: number;
}) => {
  const { isMobile, isDescTop } = useContext(ScreenContext);

  const availableActions = useMemo(
    () =>
      actions
        .map((it) => it as HeaderAction)
        .filter(
          ({ allowedDevices = AllowedDevices.All }) =>
            (isMobile && allowedDevices === AllowedDevices.MOBILE_ONLY) ||
            (isDescTop && allowedDevices === AllowedDevices.DESKTOP_ONLY) ||
            allowedDevices === AllowedDevices.All
        )
        .filter(
          (it) =>
            ((it.actionContext === ActionContext.ListPage || it.actionContext === ActionContext.ListPageAndSubGid) &&
              headerContext === HEADER_CONTEXT.HOMEPAGE) ||
            ((it.actionContext === ActionContext.SubGid || it.actionContext === ActionContext.ListPageAndSubGid) &&
              headerContext === HEADER_CONTEXT.SUBGRID) ||
            (it.actionContext === ActionContext.SinglePage && headerContext === HEADER_CONTEXT.FORM) ||
            it.actionContext === ActionContext.All
        )
        .filter((action) => !action.onSelectionOnly || (action.onSelectionOnly && selectedItemsCount > 0))
        .sort((a, b) => (b.order || 0) - (a.order || 0)),
    [actions, headerContext, isDescTop, isMobile, selectedItemsCount]
  );

  const actionsList = useMemo(() => availableActions.map((v) => v.name).join(','), [availableActions]);

  const [visibleActionsCount, setVisibleActionsCount] = useState(availableActions.length);
  const visibleActions = useMemo(
    () => availableActions.slice(0, visibleActionsCount),
    [availableActions, visibleActionsCount]
  );

  const hiddenActions = useMemo(
    () => availableActions.filter((v) => !visibleActions.map((v) => v.name).includes(v.name)),
    [availableActions, visibleActions]
  );

  const renderAction = useCallback(
    ({ name, alwaysKeepTitle = true, onClick, Icon, title, addDivider }: HeaderAction, forceTitle = false) => (
      <Fragment key={name}>
        <IconButton
          id={name ? `action_${name}` : undefined}
          onClick={onClick}
          Icon={Icon}
          className={cx({
            [classes.menuButton]: forceTitle,
          })}
        >
          {(alwaysKeepTitle || forceTitle || !isMobile) && title}
        </IconButton>
        {addDivider && headerContext === HEADER_CONTEXT.SUBGRID && <Divider />}
      </Fragment>
    ),
    [headerContext, isMobile]
  );

  const ref = useRef<HTMLDivElement>(null);

  const [visible, setVisible] = useState(false);

  const timer = useRef<ReturnType<typeof setInterval>>();
  const checkSize = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      if (!ref.current) return;
      const { width } = ref.current.getBoundingClientRect();
      let availableWidth = Math.ceil(width);
      let items = 0;
      for (const el of ref.current.children) {
        const elWidth = (el as any as HTMLElement).offsetWidth;
        if (elWidth <= availableWidth) {
          availableWidth -= elWidth;
          items++;
        } else {
          if (availableWidth < 32) {
            items--;
          }
          break;
        }
      }
      setVisibleActionsCount(items);
      setVisible(true);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableActions, visibleActions, headerContext]);

  useEffect(() => {
    checkSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetActions = useCallback(() => {
    setVisibleActionsCount(availableActions.length);
    setVisible(false);
    checkSize();
  }, [availableActions, checkSize]);

  useEffect(() => {
    resetActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionsList]);

  useEffect(() => {
    if (ref.current) {
      window.addEventListener('resize', resetActions);
      return () => {
        window.removeEventListener('resize', resetActions);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkSize]);

  return (
    <div
      className={cx(classes.actions, {
        [classes.selection]: selectedItemsCount > 0,
        [classes.withBorder]: headerContext !== HEADER_CONTEXT.SUBGRID,
      })}
    >
      <div className={cx(classes.list, { [classes.hidden]: !visible })} ref={ref}>
        {visibleActions.map((action) => renderAction(action))}
      </div>
      {hiddenActions.length > 0 && (
        <MenuButton id="moreIcon" Icon={({ className }) => <MoreIcon className={className} />}>
          <div className={classes.menu}>{hiddenActions.map((v) => renderAction(v, true))}</div>
        </MenuButton>
      )}
    </div>
  );
};

export default memo(Header);
