import { TConfig } from 'components/Table';
import { useFilters } from 'components/Table/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getFilter, getFilterOptions, TEntityName, useFilterComponent, PortalFilter, PortalFilterOperator } from 'lib';
import { IconButton } from 'components/IconButton';
import { ReactComponent as AddIcon } from './icons/add.svg';
import { ReactComponent as DeleteIcon } from './icons/delete.svg';
import { useMetaData } from 'lib/hooks';
import { Select } from 'components/Select';
import { useTranslation } from 'react-i18next';
import classes from './filterPanel.module.scss';
import { Panel } from 'components/Panel';
import { isEmpty } from 'lib/rules';

const Filter = ({
  config,
  operator,
  value,
  unfilteredColumns,
  update,
  remove,
  entityName,
  getLabel,
  invalid,
}: {
  config: TConfig<any>;
  operator?: PortalFilterOperator;
  value?: any;
  invalid?: string | JSX.Element;
  unfilteredColumns: string[];
  update: (props: Partial<TempFilter>) => void;
  remove: () => void;
  entityName: TEntityName;
  getLabel: (key: string) => string;
}) => {
  const { t } = useTranslation();
  const { name, type } = config;

  const fieldOptions = useMemo(
    () => new Map([name, ...unfilteredColumns].map((name) => [name, getLabel(name) || name])),
    [getLabel, name, unfilteredColumns]
  );

  const filterOptions = useMemo(() => new Map(Object.entries(getFilterOptions(type))), [type]);
  const { Component, validate = () => undefined } = useFilterComponent(
    name,
    entityName,
    operator as PortalFilterOperator
  );

  const { hasArguments, formRender } = useMemo(() => getFilter(operator), [operator]);

  const FormComponent = useMemo(() => formRender(Component), [formRender, Component]);

  const updateValue = useCallback(
    (value: any) => {
      update({ attribute: name, operator, value: value, invalid: validate(value) });
    },
    [update, validate, name, operator]
  );

  const onBlur = useCallback(() => {
    if (typeof value === 'string') {
      update({
        attribute: name,
        operator,
        value: typeof value === 'string' ? value.trim() : value,
        invalid: validate(value.trim()),
      });
    }
  }, [name, operator, update, validate, value]);

  const updateField = useCallback(([attribute]: string[]) => update({ attribute }), [update]);
  const updateFilter = useCallback(
    ([operator]: string[]) => update({ attribute: name, operator: operator as PortalFilterOperator }),
    [name, update]
  );

  useEffect(() => {
    if (!operator) updateFilter([Array.from(filterOptions.keys())[0]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.filterBlock}>
      <IconButton className={classes.close} onClick={remove} Icon={DeleteIcon} />
      <div className={classes.field}>
        <Select isSearchable isClearable={false} items={fieldOptions} value={[name]} onChange={updateField} />
      </div>
      <div className={classes.filter}>
        <Select isClearable={false} items={filterOptions} value={operator ? [operator] : []} onChange={updateFilter} />
      </div>
      <div className={classes.values}>
        {hasArguments && (
          <FormComponent value={value} onChange={updateValue} onBlur={onBlur} placeholder={t('Please enter value')} />
        )}
        <div className={classes.error}>{invalid}</div>
      </div>
    </div>
  );
};

type TempFilter = {
  attribute: string;
  operator?: PortalFilterOperator;
  value?: string;
  invalid?: string | JSX.Element;
};

export const FiltersPanel = ({
  onClose,
  config,
  entityName,
}: {
  onClose: () => void;
  config: Record<string, TConfig<any>>;
  entityName: TEntityName;
}) => {
  const { filters: currentFilters, apply } = useFilters();
  const { t } = useTranslation();
  const [filters, setFilters] = useState(currentFilters as TempFilter[]);

  const { getLabel: getLabelBase, hiddenFields } = useMetaData(entityName);

  const getLabel = useCallback(
    (key: string) => {
      const fromConfig = config[key].label;
      if (fromConfig) return typeof fromConfig === 'object' ? (fromConfig.props.children as string) : fromConfig;
      return getLabelBase(key);
    },
    [config, getLabelBase]
  );

  const filteredColumns = useMemo(() => filters.map((v) => v.attribute), [filters]);
  const unfilteredColumns = useMemo(
    () =>
      Object.keys(config)
        .filter(
          (name) =>
            !filteredColumns.includes(config[name]?.filterAs || name) &&
            !config[name].hiddenForTable &&
            !config[name].hideFilters &&
            !hiddenFields.includes(name)
        )
        .sort((a, b) => getLabel(a).localeCompare(getLabel(b))),
    [config, filteredColumns, getLabel, hiddenFields]
  );

  const addFilter = useCallback(() => {
    if (unfilteredColumns.length > 0) {
      setFilters((v) => v.concat({ attribute: unfilteredColumns[0] }));
    }
  }, [unfilteredColumns, setFilters]);

  const onApply = useCallback(() => {
    const filtersToApply = (
      filters.filter((v) => !!v.operator && !isEmpty(v.value || !getFilter(v.operator).hasArguments)) as PortalFilter[]
    ).map((filter) => {
      const fieldConfig = config[filter.attribute];
      return { ...filter, textViewField: fieldConfig.textViewField };
    });
    apply(filtersToApply);
    onClose();
  }, [filters, apply, onClose, config]);

  const onClear = useCallback(() => {
    apply([]);
    onClose();
  }, [onClose, apply]);

  const removeFilter = useCallback((index: number) => setFilters((v) => v.filter((_, i) => i !== index)), [setFilters]);

  const update = useCallback(
    (index: number, newProps: Partial<TempFilter>) =>
      setFilters((v) => v.map((v, i) => (i === index ? (newProps as TempFilter) : v))),
    [setFilters]
  );

  const isApplyDisabled = useMemo(
    () =>
      filters.some(({ value, operator, invalid }) => (isEmpty(value) || !!invalid) && getFilter(operator).hasArguments),
    [filters]
  );

  return (
    <Panel
      onClose={onClose}
      controls={[
        { title: t('Apply'), disabled: isApplyDisabled, role: 'primary', onClick: onApply },
        { title: t('Clear Filters'), onClick: onClear, id: 'button_filters_clear' },
      ]}
      title={t('Filters')}
      visible={true}
    >
      <div className={classes.wrapper}>
        <div className={classes.content}>
          {filters.map(({ attribute, operator, ...props }, index) => {
            const fieldConfig = config[attribute as string];
            const fieldFinalConfig = fieldConfig.filterAs ? config[fieldConfig.filterAs] : fieldConfig;
            return (
              <Filter
                key={`${attribute}-${index}`}
                config={fieldFinalConfig}
                update={(newProps: Partial<PortalFilter>) => update(index, newProps)}
                remove={() => removeFilter(index)}
                {...{
                  ...props,
                  operator,
                  unfilteredColumns,
                  entityName,
                  getLabel,
                }}
              />
            );
          })}
          {Object.keys(filters).length === 0 && (
            <div className={classes.placeholder}>{t('No filters applied to this view')}</div>
          )}
          <IconButton bordered={true} onClick={addFilter} Icon={AddIcon}>
            {t('Add Filter')}
          </IconButton>
        </div>
      </div>
    </Panel>
  );
};
