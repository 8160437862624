import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import classes from './registration.module.scss';
import CheckBox from 'components/Table/CheckBox';
import { ReactComponent as CheckedIcon } from './icons/checked.svg';
import { ReactComponent as UncheckedIcon } from './icons/unchecked.svg';

export const RegistrationHelper = () => {
  const {
    values: { registration: checked },
  } = useFormState();
  const { change } = useForm();
  const { t } = useTranslation();

  const onChange = useCallback(() => change('registration', !checked), [change, checked]);

  return (
    <div className={classes.root} onClick={onChange}>
      <CheckBox className={classes.checkBox} {...{ checked, CheckedIcon, UncheckedIcon }} />
      {t('Show required fields for Registration')}
    </div>
  );
};
