import { useCallback, useMemo } from 'react';
import classes from './switch.module.scss';
import cx from 'classnames';
import { TInputProps } from 'components/Form/types';
import { Switch as SwitchBase } from 'components/Switch';
import { ValidationMessage } from 'components/Form/ValidationMessage';

export const Switch = ({
  label,
  meta,
  input: { name, value, onChange },
  className,
  readOnly = false,
  ...props
}: TInputProps) => {
  const errors = useMemo(() => (meta.touched ? meta.error || meta.submitError : undefined), [meta]);

  const onInputChange = useCallback(() => onChange(!value), [onChange, value]);

  return (
    <div
      className={cx(classes.wrapper, className, {
        [classes.disabled]: readOnly,
      })}
    >
      <div className={classes.flex}>
        <label className={cx(classes.label, { [classes.required]: props.required })} htmlFor={name}>
          {label}
        </label>
        <SwitchBase value={!!value} onChange={onInputChange} />
      </div>
      <div className={classes.bottomWrapper}>{!readOnly && <ValidationMessage error={errors} absolute={true} />}</div>
    </div>
  );
};
