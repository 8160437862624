import { forwardRef, PropsWithRef, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Content } from './Content';
import classes from './style.module.scss';
import { ScreenContext } from 'providers/ScreenProvider';

const DivComponent = forwardRef<HTMLDivElement, { children: JSX.Element }>((props, ref) => (
  <div className={classes.toolTip} ref={ref} {...props} />
));

const connectToolTip = (Component: (props: PropsWithRef<any>) => ReactNode) => {
  return ({ content, onClick, ...props }: any & { content: string | JSX.Element; onClick?: boolean }) => {
    const [visible, setVisible] = useState(false);
    const { isDescTop } = useContext(ScreenContext);

    const showToolTip = useCallback(() => {
      setVisible(true);
    }, []);

    const hideToolTip = useCallback((e: Event) => {
      if (ref.current && e.type === 'click' && ref.current.contains(e.target as Node)) return;
      setVisible(false);
    }, []);

    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const node = ref.current;
      if (node) {
        if (onClick || !isDescTop) {
          node.addEventListener('click', showToolTip);
          document.addEventListener('click', hideToolTip);
          return () => {
            node.removeEventListener('click', showToolTip);
            document.removeEventListener('click', hideToolTip);
          };
        } else {
          node.addEventListener('mouseover', showToolTip);
          node.addEventListener('mouseleave', hideToolTip);
          return () => {
            node.removeEventListener('mouseover', showToolTip);
            node.removeEventListener('mouseleave', hideToolTip);
          };
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (visible) {
        window.addEventListener('scroll', hideToolTip, true);
        return () => {
          window.removeEventListener('scroll', hideToolTip, true);
        };
      }
    }, [hideToolTip, visible]);

    return (
      <>
        <Component ref={ref} {...props} />
        {visible && ref.current && <Content content={content} node={ref.current} />}
      </>
    );
  };
};

const DivWithToolTip = connectToolTip(DivComponent);

export default DivWithToolTip;
