import { Form } from 'components/ListPage/components/Form';
import { createContext, FC, ReactNode, useCallback, useRef, useState } from 'react';
import { config as additionalConfig, getFormConfig, validation } from 'schemas/requester';
import { RequesterImprover, useRequesterInitialValues } from 'pages/RequesterList';
import { useRequester, useRequesterValidation } from 'schemas/requester/hooks';
import { useTableConfig } from 'lib/helpers';
import { useMetaData } from 'lib/hooks';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

export const RequesterFormContext = createContext({} as { show: (fn: (name: string, value: any) => void) => void });

export const RequesterFormProvider: FC<{ children: ReactNode }> = ({ children }): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const { validate } = useRequesterValidation();
  const getInitialValues = useRequesterInitialValues();
  const {
    entityConfig: { fields },
  } = useMetaData('requester');
  const config = useTableConfig(fields, additionalConfig, 'requester');
  const changeFn = useRef<(name: string, value: any) => void>();

  const [initialValues, setInitialValues] = useState<Record<string, any> | undefined>();

  const showForm = useCallback(
    (change: (name: string, value: any) => void) => {
      changeFn.current = change;
      getInitialValues().then((data) => {
        setInitialValues(data);
        setVisible(true);
      });
    },
    [getInitialValues]
  );

  const postAction = useCallback(
    (id?: string) => {
      if (changeFn.current && id) changeFn.current('bahai_inquirerid', id);
      setVisible(false);
    },
    [setVisible]
  );

  const { onSubmit, getActionControls, duplicatesContent } = useRequester();

  return (
    <>
      {visible &&
        initialValues &&
        createPortal(
          <>
            {duplicatesContent}
            <Form
              entityName="requester"
              onClose={() => setVisible(false)}
              FormImprover={RequesterImprover}
              helper={t('After saving, you will automatically be redirected back to "New Inquiry” form')}
              {...{
                postAction,
                config,
                getFormConfig,
                validate,
                validation,
                initialValues,
                onSubmit,
                getActionControls,
              }}
            />
          </>,
          document.body
        )}
      <RequesterFormContext.Provider value={{ show: showForm }}>{children}</RequesterFormContext.Provider>
    </>
  );
};
