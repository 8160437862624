import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Params, useApi } from 'domain/api';
import { MetaDataContext } from 'providers/MetaDataProvider';
import { ApiFilter, ApiFilterOperator, Condition, getXMLFilter, TEntityName, TMultiReferenceEntity } from 'lib';
import { AxiosResponse } from 'axios';
import { useGlobalMetaData } from 'lib/hooks';
import { useLinks } from 'components/ListPage/hook';
import { FieldType, TJoinParams } from 'dynamics-meta';

type TOption = {
  id: string;
  name: string;
  entityName: string;
};

export enum AutocompleteType {
  Lookup,
  PartyList,
  Polymorphic,
}

export type TUseAutocompleteProps = {
  entities: Array<TEntityName | TMultiReferenceEntity>;
  search: string;
  type: AutocompleteType;
  dependencies?: Record<string, any>;
  searchBy?: string[];
};

export const useAutocomplete = ({ entities, search, type, dependencies, searchBy }: TUseAutocompleteProps) => {
  const { getConfigByLogicalName } = useGlobalMetaData();
  const mounted = useRef(true);
  const { config, joinParams } = useContext(MetaDataContext);
  const [data, setData] = useState(new Map<string, string>());
  const [loading, setLoading] = useState(true);
  const { autocompleteRequest } = useApi();

  const getOperatorAndValue = useCallback(
    (logicalName: string, field: string, value: string) => {
      const fieldType = getConfigByLogicalName(logicalName)?.fields[field]?.type || FieldType.String;
      switch (fieldType) {
        case FieldType.Integer:
          return {
            operator: 'eq',
            value,
          };
        case FieldType.String:
          return {
            operator: 'like',
            value: `%${value}%`,
          };
        default:
          throw new Error(`Field ${field}:${fieldType} is not supported for search in Autocomplete`);
      }
    },
    [getConfigByLogicalName]
  );

  const formatFilters = useCallback(
    (
      filters:
        | Array<Condition | ApiFilter | string>
        | Record<string, string | number | { operator: ApiFilterOperator; value?: string | number }>
    ) => {
      if (Array.isArray(filters))
        return filters.map((v) =>
          typeof v === 'string' ? ({ attribute: v, operator: 'eq', value: v } as Condition) : v
        );
      return Object.entries(filters).map(
        ([attribute, propsOrValue]) =>
          ({
            attribute,
            ...(typeof propsOrValue === 'object' ? propsOrValue : { operator: 'eq', value: propsOrValue }),
          }) as Condition
      );
    },
    []
  );

  const getQueryParams = useCallback(
    (entity: string | TMultiReferenceEntity) => {
      const logicalName =
        typeof entity !== 'string'
          ? config[entity.entityName as TEntityName]?.logicalName || entity.entityName
          : entity;

      const {
        filters = [],
        links,
        customIdAttribute,
        customNameAttribute,
      } = typeof entity === 'string' ? ({} as TMultiReferenceEntity) : entity;

      if (!joinParams[logicalName]) console.error('Unknown Logical Name ', logicalName);
      const { PrimaryIdAttribute, PrimaryNameAttribute, LogicalCollectionName } = joinParams[logicalName];

      return {
        logicalName,
        PrimaryNameAttribute: customNameAttribute || PrimaryNameAttribute,
        LogicalCollectionName,
        PrimaryIdAttribute: customIdAttribute || PrimaryIdAttribute,
        filters: formatFilters(filters),
        links,
      };
    },
    [config, formatFilters, joinParams]
  );

  const generateLinkEntities = useLinks();

  const controller = useRef({} as Record<string, AbortController>);

  const getAutocompleteOptions = useCallback(
    async (
      entity: string | TMultiReferenceEntity,
      search = '',
      joinParams: Record<string, TJoinParams>,
      autocompleteRequest: <T>(
        props: Params<{
          value: T[];
        }>
      ) => Promise<
        AxiosResponse<{
          value: T[];
        }>
      >,
      dependencies?: Record<string, any>
    ): Promise<
      AxiosResponse<{
        value: TOption[];
      }>
    > => {
      const { logicalName, PrimaryNameAttribute, PrimaryIdAttribute, LogicalCollectionName, links, filters } =
        getQueryParams(entity);
      const query = {
        fetch: {
          _attributes: {
            version: '1.0',
            'output-format': 'xml-platform',
            returntotalrecordcount: true,
            'no-lock': true,
          },
          entity: {
            _attributes: {
              name: logicalName,
            },
            'link-entity': generateLinkEntities(links),
            attribute: [
              { _attributes: { name: PrimaryIdAttribute, alias: 'id' } },
              { _attributes: { name: PrimaryNameAttribute, alias: 'name' } },
            ].filter((v) => !v._attributes.name.includes('.')),
            order: {
              _attributes: {
                ...(PrimaryNameAttribute.includes('.') ? { entityname: PrimaryNameAttribute.split('.')[0] } : {}),
                attribute: PrimaryNameAttribute.includes('.')
                  ? PrimaryNameAttribute.split('.')[1]
                  : PrimaryNameAttribute,
              },
            },
            filter: {
              _attributes: { type: 'and' },
              filter: [
                {
                  _attributes: { type: 'or' },
                  condition: search
                    ? [PrimaryNameAttribute, ...(searchBy || [])].map((attribute) => ({
                        _attributes: {
                          attribute,
                          ...getOperatorAndValue(logicalName, attribute, search),
                        },
                      }))
                    : [],
                },
                getXMLFilter(filters, dependencies),
              ],
            },
          },
        },
      };
      if (controller.current[logicalName]) controller.current[logicalName].abort();
      controller.current[logicalName] = new AbortController();
      return autocompleteRequest<TOption>({
        url: LogicalCollectionName,
        query,
        signal: controller.current[logicalName].signal,
      });
    },
    [generateLinkEntities, getOperatorAndValue, getQueryParams, searchBy]
  );

  const reload = useCallback(async () => {
    setLoading(true);
    setData(new Map<string, string>());
    try {
      if ([AutocompleteType.PartyList, AutocompleteType.Polymorphic].includes(type)) {
        const value = (
          await Promise.all(
            (entities as TMultiReferenceEntity[]).map(async (entity) => {
              const entityConfig = config[entity.entityName as TEntityName];
              return (
                await getAutocompleteOptions(entity, search, joinParams, autocompleteRequest, dependencies)
              ).data.value.map(
                (v) =>
                  ({
                    id: `${entityConfig.logicalName}<|>${v.id}`,
                    name: `${v.name} (${joinParams[entityConfig.logicalName].DisplayName})`,
                    entityName: entityConfig.logicalName,
                  }) as TOption
              );
            })
          )
        ).flat();

        if (mounted.current) {
          setData(new Map(value.map((v) => [v.id, v.name])));
          setLoading(false);
        }
      } else {
        const {
          data: { value },
        } = await getAutocompleteOptions(entities[0], search, joinParams, autocompleteRequest, dependencies);
        if (mounted.current) {
          setData(new Map(value.map((v) => [v.id, v.name])));
          setLoading(false);
        }
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [type, entities, config, getAutocompleteOptions, search, joinParams, autocompleteRequest, dependencies]);

  useEffect(() => {
    const timer = setTimeout(() => reload(), 50);
    return () => clearTimeout(timer);
  }, [reload]);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return { options: data, loading };
};

export const useAutocompleteStorage = (
  entities: Array<string | TMultiReferenceEntity>,
  type = AutocompleteType.Lookup
) => {
  const getAutocompleteStorageOptions = async (
    logicalName: string,
    values: string[],
    joinParams: Record<string, TJoinParams>,
    autocompleteRequest: <T>(
      props: Params<{
        value: T[];
      }>
    ) => Promise<
      AxiosResponse<{
        value: T[];
      }>
    >
  ): Promise<
    AxiosResponse<{
      value: TOption[];
    }>
  > => {
    if (!joinParams[logicalName]) console.error('Unknown logicalName ', logicalName);
    const { PrimaryIdAttribute, PrimaryNameAttribute, LogicalCollectionName } = joinParams[logicalName];
    const query = {
      fetch: {
        _attributes: {
          version: '1.0',
          'output-format': 'xml-platform',
          returntotalrecordcount: true,
          'no-lock': true,
        },
        entity: {
          _attributes: {
            name: logicalName,
          },
          attribute: [
            { _attributes: { name: PrimaryIdAttribute, alias: 'id' } },
            { _attributes: { name: PrimaryNameAttribute, alias: 'name' } },
          ],
          order: {
            _attributes: { attribute: PrimaryNameAttribute },
          },
          filter: {
            _attributes: { type: 'or' },
            condition: values
              ? [
                  ...values.map((value) => ({
                    _attributes: {
                      attribute: PrimaryIdAttribute,
                      operator: 'eq',
                      value,
                    },
                  })),
                ]
              : [],
          },
        },
      },
    };
    return autocompleteRequest<TOption>({
      url: LogicalCollectionName,
      query,
    });
  };
  const { joinParams, config } = useContext(MetaDataContext);
  const { autocompleteRequest } = useApi();
  const request = useCallback(
    async (values: string[], forcePartyList = false) => {
      if ([AutocompleteType.PartyList, AutocompleteType.Polymorphic].includes(type) || forcePartyList) {
        const options: TOption[] = (
          await Promise.all(
            values.map(async (selectedValue) => {
              const [logicalName, id] = selectedValue.split('<|>');
              return (
                await getAutocompleteStorageOptions(logicalName, [id], joinParams, autocompleteRequest)
              ).data.value.map(
                (v) =>
                  ({
                    id: selectedValue,
                    name: `${v.name} (${joinParams[logicalName].DisplayName})`,
                    entityName: logicalName,
                  }) as TOption
              );
            })
          )
        ).flat();

        return Object.fromEntries(options.map((v) => [v.id, v.name]));
      } else {
        const logicalName =
          typeof entities[0] !== 'string'
            ? config[entities[0].entityName as TEntityName]?.logicalName || entities[0].entityName
            : entities[0];

        const {
          data: { value },
        } = await getAutocompleteStorageOptions(logicalName, values, joinParams, autocompleteRequest);

        return Object.fromEntries(value.map((v) => [v.id, v.name]));
      }
    },
    [type, joinParams, autocompleteRequest, entities, config]
  );

  return { request };
};
