import classes from './header.module.scss';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as LogoBig } from './logo-big.svg';
import { useCallback, useContext, useState, useMemo } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { ReactComponent as Question } from './question.svg';
import AccountInfo from './components/AccountInfo';
import { Trans, useTranslation } from 'react-i18next';
import { ScreenContext } from 'providers/ScreenProvider';
import { params } from 'domain/authConfig';

const env = process.env.REACT_APP_ENV || 'dev';

export const LogoBlock = () => {
  const { isMobile } = useContext(ScreenContext);
  const { t } = useTranslation();
  return (
    <div className={classes.logoBlock}>
      {env === 'prod' ? (
        isMobile ? (
          <Logo className={classes.icon} />
        ) : (
          <LogoBig className={classes.icon} />
        )
      ) : (
        <div className={classes.envBlock}>
          <div className={classes.env}>{env.toUpperCase()}</div>
          <a href={params.prod.url} target="_blank" rel="noreferrer">
            {t('Go IMS Portal')}
          </a>
        </div>
      )}
    </div>
  );
};

const Header = () => {
  const { entityImage, fullName } = useContext(AuthContext);

  const [accountInfoVisible, setAccountInfoVisible] = useState(false);

  const showAccountInfo = useCallback(() => {
    setAccountInfoVisible(true);
  }, []);

  const hideAccountInfo = useCallback(() => {
    setAccountInfoVisible(false);
  }, []);

  const image = useMemo(() => (entityImage ? 'data:image/jpg;base64,' + entityImage : undefined), [entityImage]);

  return (
    <header className={classes.header} id="header">
      <LogoBlock />
      <div className={classes.rightBlock}>
        <a
          className={classes.link}
          href="https://concourse.atlassian.net/servicedesk/customer/portal/14"
          target="_blank"
          id="header_link"
          rel="noreferrer"
        >
          <Question />
        </a>
        <div className={classes.userName}>
          <Trans>
            Welcome, <strong>{fullName}</strong>
          </Trans>
        </div>
        <div className={classes.user} onClick={showAccountInfo} id="header_user">
          <div className={classes.placeholder}>
            {image ? (
              <img alt="image" src={image} />
            ) : (
              <>
                {fullName
                  ?.split(' ')
                  .map((v) => v.split('')[0])
                  .join('')}
              </>
            )}
          </div>
        </div>
        <AccountInfo visible={accountInfoVisible} onHide={hideAccountInfo} />
      </div>
    </header>
  );
};

export default Header;
