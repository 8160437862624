import { useHistory, Link } from 'react-router-dom';
import { FC, ReactNode } from 'react';

export const HistoryLink: FC<{ to: string; children: ReactNode; from?: string; target?: '_self' | '_blank' }> = ({
  to,
  from,
  children,
  target = '_self',
}) => {
  const { location } = useHistory();

  return (
    <Link target={target} to={{ pathname: to, state: { from: from || location.pathname } }}>
      {children}
    </Link>
  );
};
