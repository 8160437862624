import ListPage, { TListPage } from 'components/ListPage';
import * as baseConfig from 'schemas/coverage';
import { useMemo } from 'react';

const CoverageList = (props: Partial<TListPage> & { search: baseConfig.Keys }) => {
  const { search, ...otherProps } = props;
  const { config: tempConfig, defaultMobileColumns: mobileColumns = [], ...restConfig } = baseConfig;

  const defaultMobileColumns = useMemo(() => [search as string].concat(...mobileColumns), [mobileColumns, search]);

  const config = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(tempConfig).map(([name, cfg]) => [name, name === search ? { searchable: true, ...cfg } : cfg])
      ),
    [search, tempConfig]
  );

  return (
    <ListPage
      defaultMobileColumns={defaultMobileColumns}
      entityName="coverage"
      config={config}
      {...restConfig}
      {...otherProps}
    />
  );
};

export default CoverageList;
