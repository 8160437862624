import entities from 'config';
import { config as resourceConfig } from 'schemas/resource';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { TLinkEntity } from 'components/ListPage';

type Keys = (typeof entities.invitedResource.columns)[number];

type ResourceKeys = `resource.${(typeof entities.resource.columns)[number]}`;

export const defaultMobileColumns: Array<ResourceKeys | Keys> = [
  'bahai_systemuserid',
  'resource.mobilephone',
  'resource.bahai_fluentlanguagecode',
];

export const links = {
  resource: {
    to: 'bahai_systemuserid',
    from: 'systemuserid',
    fields: entities.resource.columns,
  },
  event: {
    from: 'bahai_event_imsid',
    to: 'bahai_eventid',
    fields: entities.event.columns,
    condition: [],
  },
} as TLinkEntity;

export const columns = [
  ...entities.invitedResource.columns,
  ...entities.resource.columns.map((column) => `resource.${column}`),
];

export const config: AdditionalConfigType<Keys | 'bahai_eventparticipant_resourceid' | ResourceKeys> = {
  bahai_systemuserid: {
    searchable: true,
  },
  bahai_eventid: {
    hiddenForTable: true,
  },
  ...Object.fromEntries(
    Object.entries(resourceConfig).map(([key, props]) => ['resource.' + key, { ...props, searchable: false }])
  ),
  ...Object.fromEntries(entities.event.columns.map((key) => ['event.' + key, { hiddenForTable: true }])),
  'resource.fullname': { hiddenForTable: true },
};

export const getFormConfig: FormConfigGetter<Keys> = () => [];
