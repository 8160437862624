import entities, { Fields } from 'config';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { createFormAutocomplete } from 'components/Form/Autocomplete';
import { FieldValidator } from 'final-form';
import * as rules from 'lib/rules';
import * as inquiryMetadata from 'config/EntityMetadata/bahai_inquiry';
import { TextEditor } from 'components/Form/TextEditor';
import { HTMLComponent, ToolTipTextComponent } from 'lib/components';
import * as interactionMetadata from 'config/EntityMetadata/bahai_communication';
import { DatePicker } from 'components/Form/DatePicker';
import { Trans } from 'react-i18next';
import { bahai_inquirerstatus, statecode } from 'config/EntityMetadata/bahai_inquirer';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import { getFormattedNow } from 'lib/adapter';
import { getWarningImprover } from 'components/ListPage/components/Form';

export const columns = entities.interaction.columns;
export type Keys = Fields<(typeof columns)[number] | 'bahai_communicationid' | 'activityid'>;

export const defaultMobileColumns: Keys[] = [
  'bahai_directioncode',
  'bahai_communicationtypecode',
  'bahai_communicatedon',
];

export const isActive = (data: Record<string, any>) => data.statecode === interactionMetadata.statecode.Active;

export const isNotEditable = (data: Record<string, any>) =>
  !isActive(data) && <Trans>Interaction State is Inactive</Trans>;

export const isNotRemovable = (data: Record<string, any>) =>
  !isActive(data) && <Trans>Interaction State must be Active to delete</Trans>;

export const config: AdditionalConfigType<Keys> = {
  bahai_id: {
    component: ({ data }) => (
      <HistoryLink to={r.interaction({ id: data.activityid || 0 })}>{data.bahai_id}</HistoryLink>
    ),
  },
  bahai_directioncode: {
    isRequired: true,
  },
  bahai_inquirerid: {
    searchable: true,
    isRequired: true,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'bahai_inquirer',
          filters: {
            statecode: statecode.Active,
            bahai_statuscode: {
              operator: 'ne',
              value: bahai_inquirerstatus.DoNotContact,
            },
          },
        },
      ],
    }),
    fieldProps: ({ classes }) => ({ className: classes.long }),
  },
  bahai_inquiryid: {
    searchable: true,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'bahai_inquiry',
          filters: { statecode: inquiryMetadata.statecode.Active, bahai_inquirerid: 'bahai_inquirerid' },
        },
      ],
      dependencies: ['bahai_inquirerid'],
    }),
    fieldProps: ({ classes }) => ({ className: classes.long }),
  },
  bahai_communicationtypecode: {
    isRequired: true,
    fieldProps: ({ classes }) => ({ className: classes.long }),
  },
  bahai_communicationreasoncode: {
    isRequired: true,
    sortable: false,
    fieldProps: ({ classes }) => ({ className: classes.long }),
  },
  bahai_rawcomment: {
    searchable: true,
    component: ToolTipTextComponent('bahai_comment'),
  },
  bahai_comment: {
    isRequired: true,
    hiddenForTable: true,
    field: TextEditor,
    component: HTMLComponent('bahai_rawcomment'),
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  statecode: {
    hiddenForTable: true,
  },
  bahai_communicatedon: {
    isRequired: true,
    field: DatePicker,
    fieldProps: ({ classes }) => ({ className: classes.long, showTime: true, canSelectFutureDate: false }),
  },
};

export const getDetailsConfig: FormConfigGetter<Keys> = () => [
  [
    '',
    [
      'bahai_directioncode',
      'bahai_inquirerid',
      'bahai_inquiryid',
      'bahai_communicationtypecode',
      'bahai_communicationreasoncode',
      'bahai_comment',
      'bahai_communicatedon',
      'createdon',
      'createdby',
    ] as Keys[],
  ],
];

export const getFormConfig: FormConfigGetter<Keys> = () => [
  [
    '',
    [
      'bahai_directioncode',
      'bahai_inquirerid',
      'bahai_inquiryid',
      'bahai_communicationtypecode',
      'bahai_communicationreasoncode',
      'bahai_comment',
      'bahai_communicatedon',
    ] as Keys[],
  ],
];

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_directioncode: rules.required,
  bahai_inquirerid: rules.required,
  bahai_communicationtypecode: rules.required,
  bahai_comment: rules.compose([rules.maxRichTextLength(2000), rules.required]),
  bahai_communicatedon: rules.compose([rules.required, rules.pastTime]),
  bahai_communicationreasoncode: rules.required,
};

export const getInitialValues = async () => ({
  bahai_directioncode: true,
  bahai_communicatedon: getFormattedNow(),
});

export const WarningsImprover = getWarningImprover(
  ['bahai_communicationtypecode'],
  (values, setWarnings) => {
    if (Number(values.bahai_communicationtypecode) === interactionMetadata.bahai_communicationtypecode.Email) {
      setWarnings([
        <Trans>
          Please be aware that Interaction Type = “Email” does not send an email to the receivers and should be used
          only for historical record
        </Trans>,
      ]);
    } else {
      setWarnings([]);
    }
  },
  true
);
