import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEvent,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { ReactComponent as DismissIcon } from './icons/dismiss.svg';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';
import cx from 'classnames';

import Tooltip from 'components/Tooltip';
import inputClasses from 'components/Form/Input/input.module.scss';
import classes from './searchBox.module.scss';
import { ScreenContext } from 'providers/ScreenProvider';

type searchBoxProps = {
  disabled?: boolean;
  placeholder?: string;
  value: string;
  onChange: (v: string) => void;
  onBlur?: () => void;
  tooltipContent: JSX.Element;
};

const SearchBox = ({ disabled, placeholder = 'Search', value, onChange, onBlur, tooltipContent }: searchBoxProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => onChange(e?.target?.value || ''),
    [onChange]
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const { isMobile, isTablet } = useContext(ScreenContext);

  useEffect(() => {
    if (isMobile || isTablet) inputRef.current?.focus();
  }, [isMobile, isTablet]);

  const dismissSearch: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      onChange('');
      e.stopPropagation();
      e.preventDefault();
    },
    [onChange]
  );

  const onFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (isMobile) e.target.scrollIntoView();
    },
    [isMobile]
  );

  const onKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && onBlur) onBlur();
    },
    [onBlur]
  );

  return (
    <div
      className={cx(classes.searchGroup, { [classes.filled]: value.length }, { [classes.disabled]: disabled })}
      id="searchGroup"
    >
      <input
        name="_search"
        className={inputClasses.input}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={inputRef}
        autoComplete="off"
        onKeyDown={onKeyPress}
      />
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <div className={classes.warningIcon}>
        <Tooltip id="searchIcon" display="block" content={tooltipContent}>
          <WarningIcon />
        </Tooltip>
      </div>
      <div className={classes.dismissIcon} onMouseDown={dismissSearch}>
        <DismissIcon id="dismissIcon" />
      </div>
    </div>
  );
};

export default SearchBox;
