import { SinglePage } from 'components/SinglePage';
import { getInitialValues as getBaseInquiryInitialValues, config as inquiryConfig } from 'schemas/inquiry';
import { config as emailConfig } from 'schemas/email';
import { config as interactionConfig, getInitialValues as getBaseInteractionInitialValues } from 'schemas/interaction';
import { isNotRemovable as isReminderNotRemovable } from 'schemas/reminder';
import { isNotRemovable as invitedRequesterNotRemovable } from 'schemas/requesterEvents';
import {
  config,
  getFormConfig,
  getDetailsConfig,
  validation,
  isNotEditable,
  isDoNotContact,
  isActive,
  isNotRemovable,
  isConfirmationMessageNeeded,
  isConfirmationMessageRequired,
  statusFields,
  Keys,
  isLocalityCalculation,
  getWarningsImprover,
  links,
  isChild,
} from 'schemas/requester';
import { useMerge, useRequester, useRequesterValidation } from 'schemas/requester/hooks';
import { RequesterImprover } from 'pages/RequesterList';
import { Trans, useTranslation } from 'react-i18next';
import InquiryList, { addressFields } from 'pages/InquiryList';
import InteractionList from 'pages/InteractionList';
import { useParams } from 'react-router-dom';
import { useCallback, useMemo, useContext } from 'react';
import { useChangeStatus } from 'components/ChangeStatus';
import { AdditionalConfigType, ApiFilter, TEntityName } from 'lib';
import { Keys as InteractionKeys } from 'schemas/interaction';
import EmailList from 'pages/EmailList';
import HistoryLogTab from 'pages/HistoryLogTab';
import { useSystemUserId } from 'lib/helpers';
import { Action } from 'components/Actions';
import EventsTab from 'pages/Requester/components/EventsTab';
import * as requesterMetadata from 'config/EntityMetadata/bahai_inquirer';
import { useRemoveEventParticipants } from '../Common/hooks';
import { useUpdateAttendanceStatusActions } from 'pages/Event/hooks';
import { Loader } from 'components/Loader';
import classes from 'pages/Common/pages.common.module.scss';
import { useExport, useMetaData } from 'lib/hooks';
import { RegistrationHelper } from 'schemas/requester/components/RegistrationHelper';
import { useRecord } from 'lib/record';
import { getFormattedNow } from 'lib/adapter';
import ReminderList from 'pages/ReminderList';
import AreaAgencyList from 'pages/AreaAgencyList';
import { AuthContext } from 'providers/AuthProvider';

const getFileName = (data: Record<string, any>, format: string) =>
  ['Requester', data.bahai_firstname, data.bahai_lastname, getFormattedNow()].filter((v) => !!v).join('_') +
  '.' +
  format;

const tabNames: TEntityName[] = ['inquiry', 'interaction', 'email', 'invitedRequester', 'reminder'];

const updatedInquiryConfig: AdditionalConfigType<string> = {
  ...inquiryConfig,
  bahai_inquirerid: {
    ...inquiryConfig.bahai_inquirerid,
    fieldProps: ({ classes }) => ({ readOnly: true, className: classes.long }),
  },
};

const updatedInteractionConfig: AdditionalConfigType<InteractionKeys> = {
  ...interactionConfig,
  bahai_inquirerid: {
    ...interactionConfig.bahai_inquirerid,
    fieldProps: ({ classes }) => ({ readOnly: true, className: classes.long }),
  },
};

export const Requester = () => {
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();
  const { validate } = useRequesterValidation();
  const userId = useSystemUserId();
  const {
    entityConfig: { fields },
  } = useMetaData('requester');

  const getLockMessage = useCallback(
    (data: Record<Keys, any>) => {
      switch (true) {
        case isLocalityCalculation(data):
          return {
            message: t(
              'Please, note that Locality is being calculated. The form will be unlocked and can be edited after Locality is determined.'
            ),
            forceDisplay: true,
            icon: 'clock',
          };
        case Number(data.bahai_statuscode) === requesterMetadata.bahai_inquirerstatus.DoNotContact:
          return {
            message: t('Record Status is {{ status }}', {
              status: new Map(fields.bahai_statuscode.options).get(`${data.bahai_statuscode}`),
            }),
            forceDisplay: true,
            isWarning: true,
          };
        case !isActive(data):
          return {
            message: <Trans>Record State is Inactive</Trans>,
          };
        case isChild(data):
          return {
            message: t('Requester is Under the Age of 15'),
            forceDisplay: true,
            icon: 'exclamation',
            styles: {
              backgroundColor: '#D6FFD2',
            },
          };
        case data.bahai_isdraft === true:
          return {
            message: t('Draft Requester'),
            forceDisplay: true,
            icon: 'draft',
            styles: {
              backgroundColor: '#ffffff',
            },
          };
        default:
          return {};
      }
    },
    [fields.bahai_statuscode.options, t]
  );

  const { getFormValues } = useRecord('requester');

  const getInquiryInitialValues = useCallback(
    async (data: Record<string, any>) => {
      const baseValues = await getBaseInquiryInitialValues();
      const formValues = getFormValues(data);
      return {
        ...baseValues,
        ...Object.fromEntries(addressFields.map((key) => [key, formValues[key] ?? null])),
        bahai_inquirerid: id,
      };
    },
    [getFormValues, id]
  );

  const getInteractionInitials = useCallback(async () => {
    const baseValues = await getBaseInteractionInitialValues();
    return {
      ...baseValues,
      bahai_inquirerid: id,
      bahai_communicatedon: getFormattedNow(),
    };
  }, [id]);

  const getReminderInitialValues = useCallback(
    async (data: Record<string, any>) => {
      const to = ['systemuser<|>' + userId];
      const ownerId = data._ownerid_value;
      if (data['_ownerid_value@Microsoft.Dynamics.CRM.lookuplogicalname'] === `systemuser` && ownerId !== userId) {
        to.push('systemuser<|>' + ownerId);
      }

      return {
        bahai_inquirerid: id,
        requiredattendees: to,
      };
    },
    [id, userId]
  );

  const isNotCreatable = useCallback((data: Record<string, any>) => {
    switch (true) {
      case isDoNotContact(data):
        return <Trans>Requester Status is Do Not Contact</Trans>;
      case !isActive(data):
        return <Trans>Requester State is Inactive</Trans>;
      default:
        return false;
    }
  }, []);

  const isEmailNotCreatable = useCallback(
    (data: Record<string, any>) => {
      switch (true) {
        case !!isNotCreatable(data):
          return isNotCreatable(data);
        case !data.bahai_emailaddress1 && !data.bahai_emailaddress2:
          return <Trans>Please add an email address. Only recipients with an email address can receive emails</Trans>;
        default:
          return false;
      }
    },
    [isNotCreatable]
  );

  const isReminderNotCreatable = useCallback(
    (data: Record<string, any>) => !isActive(data) && <Trans>Requester State is Inactive</Trans>,
    []
  );

  const getEmailInitialValues = useCallback(async () => {
    return {
      directioncode: true,
      from: 'systemuser<|>' + userId,
      to: 'bahai_inquirer<|>' + id,
    };
  }, [id, userId]);

  const { getRemoveParticipantsAction, content: removeConfirmationDialog } = useRemoveEventParticipants(
    'requester',
    'event',
    invitedRequesterNotRemovable
  );

  const { actions: attendanceActions, loading: updateAttendanceStatus } = useUpdateAttendanceStatusActions(
    'requester',
    'event'
  );

  const getEventsActions = useCallback(
    (data: Record<string, any>) => (baseActions: Action[]) => [
      getRemoveParticipantsAction(data),
      ...baseActions.filter((a) => a.name !== 'delete'),
      ...attendanceActions,
    ],
    [getRemoveParticipantsAction, attendanceActions]
  );

  const {
    changeStatus,
    content,
    pageLoading: changeStatusLoading,
  } = useChangeStatus({
    entityName: 'requester',
    statusFieldName: 'bahai_statuscode',
    statusDetailsFieldName: 'bahai_statusdetailscode',
    additionalNotesFieldName: 'bahai_additionalnote',
  });

  const { action: mergeAction, content: mergeContent } = useMerge(id);
  const { action: exportAction, content: exportContent } = useExport(id, 'requester', tabNames, getFileName);

  const getPageActions = useCallback(
    (baseActions: Action[]) => [...baseActions, exportAction, changeStatus, mergeAction],
    [changeStatus, exportAction, mergeAction]
  );

  const { onSubmit, getActionControls, duplicatesContent } = useRequester(id);

  const hiddenFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'bahai_inquirerid', value: id }] }],
    [id]
  );

  const emailHiddenFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'bahai_primaryrowid', value: id }] }],
    [id]
  );
  const { businessUnitType } = useContext(AuthContext);
  const WarningsImprover = getWarningsImprover(businessUnitType);

  return (
    <>
      {(updateAttendanceStatus || changeStatusLoading) && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      {content}
      {mergeContent}
      {exportContent}
      {removeConfirmationDialog}
      {duplicatesContent}
      <SinglePage
        entityName="requester"
        FormImprover={RequesterImprover}
        getActions={getPageActions}
        displayAssign={true}
        {...{
          getFormConfig,
          getDetailsConfig,
          config,
          validate,
          validation,
          getActionControls,
          isNotEditable,
          isActive,
          isNotRemovable,
          isConfirmationMessageNeeded,
          isConfirmationMessageRequired,
          statusFields,
          onSubmit,
          getLockMessage,
          WarningsImprover,
          formHelper: <RegistrationHelper />,
          links,
        }}
        additionalTabs={[
          {
            label: t('Inquiries'),
            tab: 'inquiry',
            isDefault: true,
            content: (data: Record<string, any>, reload) => (
              <InquiryList
                getInitialValues={() => getInquiryInitialValues(data)}
                hiddenFilters={hiddenFilters}
                isSubgrid={true}
                config={updatedInquiryConfig}
                isNotCreatable={isNotCreatable(data)}
                isRemoveHidden={false}
                onItemCreated={reload}
                onItemsRemoved={reload}
              />
            ),
          },
          {
            label: t('Area Agencies'),
            tab: 'areaagency',
            isDefault: true,
            content: () => (
              <AreaAgencyList
                isExportAllowed={false}
                displayViews={false}
                hiddenFilters={hiddenFilters}
                isCreateHidden={true}
                isSubgrid={true}
              />
            ),
          },
          {
            label: t('Interactions'),
            tab: 'interaction',
            content: (data: Record<string, any>, reload: () => void) => (
              <InteractionList
                getInitialValues={getInteractionInitials}
                hiddenFilters={hiddenFilters}
                isSubgrid={true}
                config={updatedInteractionConfig}
                isNotCreatable={isNotCreatable(data)}
                onItemCreated={reload}
                onItemsRemoved={reload}
              />
            ),
          },
          {
            label: t('Reminders'),
            tab: 'reminder',
            content: (data: Record<string, any>, reload: () => void) => {
              return (
                <ReminderList
                  isNotCreatable={isReminderNotCreatable(data)}
                  onItemCreated={reload}
                  onItemsRemoved={reload}
                  getInitialValues={() => getReminderInitialValues(data)}
                  hiddenFilters={hiddenFilters}
                  isSubgrid={true}
                  isNotRemovable={isReminderNotRemovable}
                  onItemsComplited={reload}
                />
              );
            },
          },
          {
            label: t('Emails'),
            tab: 'email',
            content: (data: Record<string, any>) => (
              <EmailList
                getInitialValues={getEmailInitialValues}
                hiddenFilters={emailHiddenFilters}
                isSubgrid={true}
                config={emailConfig}
                isNotCreatable={isEmailNotCreatable(data)}
              />
            ),
          },
          {
            label: t('Events'),
            tab: 'event',
            content: (data: Record<string, any>) => (
              <EventsTab
                hiddenFilters={hiddenFilters}
                isCreateHidden={true}
                getActions={getEventsActions(data)}
                isSubgrid={true}
                isRemoveHidden={false}
                systemView={'Default For Requester'}
                displayViews={false}
              />
            ),
          },
          {
            label: t('History Log'),
            tab: 'historylog',
            content: (data: Record<string, any>) => <HistoryLogTab id={id || ''} data={data} />,
          },
        ]}
      />
    </>
  );
};
