import classes from './spinner.module.scss';
import cx from 'classnames';

export const Spinner = ({ success, text }: { success?: boolean; text?: string | JSX.Element }) => (
  <div className={cx(classes.root, { [classes.success]: success })}>
    <div className={classes.hider} />
    <div className={classes.circle} />
    {text && <div className={classes.text}>{text}</div>}
  </div>
);
