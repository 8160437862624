import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TConfig } from 'components/Table';
import {
  ApiFilter,
  apiFilterToXML,
  FetchQuery,
  FieldType,
  generateEntityFilters,
  PortalFilter,
  TEntityName,
} from 'lib';
import { useApi } from 'domain/api';
import { useMetaData } from 'lib/hooks';
import { EntityLink, TLinkEntity } from 'components/ListPage/index';
import { Sort, usePagination } from 'components/Table/hooks';
import { UserSettingsContext } from 'providers/UserSettingsProvider';
import * as emailMetadata from 'config/EntityMetadata/email';
import { useTranslation } from 'react-i18next';
import { useServerError } from 'providers/ErrorProvider';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { ReactComponent as ExcelExportIcon } from './icons/exportToExcel.svg';
import { ReactComponent as WordExportIcon } from './icons/exportToWord.svg';
import convert from 'xml-js';
import { format } from 'date-fns';
import { QueryParams, TableContext } from 'providers/TableProvider';
import { MetaDataContext } from 'providers/MetaDataProvider';
import { AuthContext } from 'providers/AuthProvider';
import { ScreenContext } from 'providers/ScreenProvider';
import { getFormattedNow } from 'lib/adapter';

type TListApiConfig<Data extends Record<string, any>> = {
  config: Record<string, TConfig<Data>>;
  search?: string;
  ready?: boolean;
  links?: TLinkEntity;
  entityName: TEntityName;
  hiddenFilters?: ApiFilter[];
  systemView?: string;
  queryParams?: QueryParams;
  distinct?: boolean;
};

const getActivitypartyLink = (participationtypemask: number) => ({
  _attributes: {
    name: 'activityparty',
    from: 'activityid',
    to: 'activityid',
    'link-type': 'outer',
    alias: `activityparty${participationtypemask}`,
  },
  filter: {
    condition: [
      {
        _attributes: {
          attribute: 'participationtypemask',
          operator: 'eq',
          value: participationtypemask.toString(),
        },
      },
    ],
  },
});

const getActivitypartyLinks = () => [
  getActivitypartyLink(emailMetadata.ParticipationTypeMask.from),
  getActivitypartyLink(emailMetadata.ParticipationTypeMask.to),
  getActivitypartyLink(emailMetadata.ParticipationTypeMask.cc),
  getActivitypartyLink(emailMetadata.ParticipationTypeMask.bcc),
  getActivitypartyLink(emailMetadata.ParticipationTypeMask.requiredattendees),
];

export const useLinks = () => {
  const { config, joinParams } = useContext(MetaDataContext);
  const generateLinkEntities = useCallback(
    (links?: TLinkEntity): any[] => {
      return links
        ? Object.entries(links).map(([name, linkProps]) => {
            const { logicalName } = config[name as TEntityName];
            const {
              from,
              to,
              fields,
              condition = [],
              links = undefined,
              linkType = undefined,
            } = Array.isArray(linkProps)
              ? {
                  from: joinParams[logicalName].PrimaryIdAttribute,
                  to: joinParams[logicalName].PrimaryIdAttribute,
                  fields: linkProps as string[],
                }
              : (linkProps as EntityLink);
            return {
              _attributes: {
                name: logicalName,
                from,
                to,
                'link-type': linkType ?? 'outer',
                alias: name,
              },
              filter: {
                _attributes: { type: 'and' },
                condition: condition.map((_attributes) => ({
                  _attributes,
                })),
              },
              attribute: fields.map((name) => ({
                _attributes: { name },
              })),
              'link-entity': generateLinkEntities(links),
            };
          })
        : [];
    },
    [config, joinParams]
  );

  return generateLinkEntities;
};

export const useListQuery = <Data extends Record<string, any>>(props: {
  config: Record<string, TConfig<Data>>;
  search?: string;
  links?: TLinkEntity;
  entityName: TEntityName;
  page?: number;
  sorting?: Sort[];
  filters?: PortalFilter[];
  hiddenFilters?: ApiFilter[];
  distinct?: boolean;
  onlyId?: boolean;
}): FetchQuery => {
  const {
    config,
    search,
    links,
    entityName,
    page = 1,
    sorting = [],
    hiddenFilters,
    filters = [],
    distinct = true,
    onlyId = false,
  } = props;

  const { logicalName, PrimaryIdAttribute } = useMetaData(entityName);

  const { pageSize } = useContext(UserSettingsContext);

  const generateLinkEntities = useLinks();

  return useMemo(() => {
    const entityContainsPartyListFields = Object.values(config).some((v) => v?.type === FieldType.PartyList);

    let activityparty: any[] = [];

    if (entityContainsPartyListFields) {
      activityparty = getActivitypartyLinks();
    }

    return {
      fetch: {
        _attributes: {
          version: '1.0',
          'output-format': 'xml-platform',
          mapping: 'logical',
          count: pageSize,
          distinct,
          returntotalrecordcount: true,
          page,
          'no-lock': true,
        },
        entity: {
          _attributes: {
            name: logicalName,
          },
          'link-entity': [...generateLinkEntities(links), ...activityparty],
          attribute: Object.keys(config)
            .filter((v) => !v.includes('.') && !config[v].excludeFromListQuery && !onlyId)
            .concat(PrimaryIdAttribute)
            .map((name) => ({ _attributes: { name } })),
          order: sorting.map(({ name, direction }) => ({
            _attributes: {
              ...(name.includes('.') ? { entityname: name.split('.')[0] } : {}),
              attribute: name.includes('.') ? name.split('.')[1] : name,
              descending: direction === 'desc',
            },
          })),
          filter: {
            _attributes: { type: 'and' },
            filter: [
              ...(search
                ? [
                    {
                      _attributes: { type: 'or' },
                      condition: [
                        ...Object.values(config)
                          .filter((v) => v.searchable)
                          .map(({ name, type }) => ({
                            _attributes: {
                              attribute: name + ([FieldType.Lookup, FieldType.Owner].includes(type) ? `name` : ``),
                              operator: 'like',
                              value: `%${search}%`,
                            },
                          })),
                      ],
                    },
                  ]
                : []),
              ...generateEntityFilters(entityName, filters),
              ...(hiddenFilters || []).map((v) => apiFilterToXML(v)),
            ],
          },
        },
      },
    };
  }, [
    config,
    pageSize,
    distinct,
    page,
    logicalName,
    generateLinkEntities,
    links,
    PrimaryIdAttribute,
    sorting,
    search,
    entityName,
    filters,
    hiddenFilters,
    onlyId,
  ]);
};

export const improveQuery = (query: FetchQuery): FetchQuery => {
  return query;
};

export const useListPageApi = <Data extends Record<string, any>>({
  config,
  ready = false,
  links,
  entityName,
  hiddenFilters,
  queryParams,
  distinct,
}: TListApiConfig<Data>) => {
  const { request } = useApi();
  const [data, setData] = useState<Data[]>([]);
  const [total, setTotal] = useState(0);
  const [recordsOverflow, setOverflow] = useState(false);
  const addServerError = useServerError();
  const {
    page,
    filters,
    links: viewLinks,
    sorting,
    search: rawSearch,
  } = useMemo(() => queryParams || { page: 1, filters: [], links: {}, sorting: [], search: '' }, [queryParams]);
  const [loading, setLoading] = useState(true);

  const search = useMemo(() => (rawSearch || '').trim(), [rawSearch]);

  useEffect(() => {
    setLoading(true);
  }, [search]);

  const { pageSize } = useContext(UserSettingsContext);
  const pages = useMemo(() => Math.ceil(total / pageSize), [pageSize, total]);

  const { goToPage } = usePagination(pages);

  const stats = useMemo(
    () => ({
      page,
      pages,
      firstIndex: (page - 1) * pageSize + 1,
      lastIndex: page === pages ? total : page * pageSize,
      total,
      recordsOverflow,
    }),
    [page, pageSize, pages, recordsOverflow, total]
  );

  const {
    entityConfig: { url },
  } = useMetaData(entityName);

  const mergedLinks = useMemo(() => ({ ...links, ...viewLinks }), [links, viewLinks]);
  const query = useListQuery({
    config,
    search,
    links: mergedLinks,
    entityName,
    page,
    filters,
    sorting,
    hiddenFilters,
    distinct,
  });

  const controller = useRef<AbortController>();

  const reload = useCallback(() => {
    setLoading(true);
    if (controller.current) controller.current.abort();
    controller.current = new AbortController();
    request<{
      value: Data[];
      '@odata.count': number;
      '@Microsoft.Dynamics.CRM.totalrecordcountlimitexceeded': boolean;
    }>({ url, query, signal: controller.current?.signal })
      .then((resp) => {
        if (resp.data['@odata.count'] > 0 && page > 1 && resp.data.value.length === 0) {
          goToPage(page - 1);
          return;
        }
        setData(resp.data.value);
        setTotal(resp.data['@odata.count']);
        setOverflow(resp.data['@Microsoft.Dynamics.CRM.totalrecordcountlimitexceeded']);
        setLoading(false);
      })
      .catch((e) => {
        if (e.message !== 'canceled') addServerError(e);
      });
  }, [addServerError, goToPage, page, query, request, url]);

  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (ready) {
      setLoading(true);
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => reload(), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, ready]);

  return { data, pages, recordsOverflow, loading, reload, stats, query };
};

export type ColumnConfig = {
  name: string;
  visible: boolean;
  pinned: boolean;
  width: number;
};

export type View = {
  id: string;
  name: string;
  description?: string;
  isSystem: boolean;
  isDefault: boolean;
  sorting: Sort[];
  filters: PortalFilter[];
  search: string;
  columnsConfig: ColumnConfig[];
  links?: TLinkEntity;
};

export const useExportToExcel = (
  isExportAllowed: boolean,
  query: any,
  setLoadingAbsolute: React.Dispatch<React.SetStateAction<boolean>>,
  columnsConfig: any,
  entityName: TEntityName
) => {
  const { exportToExcel } = useApi();
  const { PrimaryIdAttribute } = useMetaData(entityName);
  const { isDescTop } = useContext(ScreenContext);
  const addServerError = useServerError();
  const { t } = useTranslation();
  const exportQuery = useMemo(() => {
    const q = JSON.parse(JSON.stringify(query));
    delete q.fetch._attributes.count;
    delete q.fetch._attributes.page;
    return q;
  }, [query]);

  const { views, currentView } = useContext(TableContext);
  const systemViewId = Object.keys(views).find((id) => views[id].isSystem) || '';
  const fextXml = useMemo(() => {
    return convert.js2xml(exportQuery, { compact: true });
  }, [exportQuery]);

  const viewName = views[currentView]?.name || '';
  const layoutXml = useMemo(() => {
    const cells = [
      ...columnsConfig
        .filter((v: any) => v.pinned && v.visible)
        .map((column: any) => ({
          _attributes: {
            name: column.name,
            width: column.width,
          },
        })),
      columnsConfig
        .filter((v: any) => !v.pinned && v.visible)
        .map((column: any) => ({
          _attributes: {
            name: column.name,
            width: column.width,
          },
        })),
    ].flat();

    const layout = {
      grid: {
        _attributes: {
          name: 'resultset',
          select: '1',
          icon: '1',
          preview: '1',
        },
        row: {
          _attributes: {
            name: 'result',
          },
          cell: cells,
        },
      },
    };

    return convert.js2xml(layout, { compact: true });
  }, [columnsConfig]);

  const onExport = useCallback(
    (selectedItems: Record<string, any>[]) => {
      setLoadingAbsolute(true);
      exportToExcel(
        systemViewId,
        fextXml,
        layoutXml,
        selectedItems.map((v) => v[PrimaryIdAttribute])
      )
        .then((resp: any) => {
          const byteCharacters = atob(resp.data.ExcelFile);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'text/xlsx' });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${viewName} ${getFormattedNow()}.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          addServerError(e);
        })
        .finally(() => setLoadingAbsolute(false));
    },
    [PrimaryIdAttribute, addServerError, exportToExcel, fextXml, layoutXml, setLoadingAbsolute, systemViewId, viewName]
  );

  const exportToExcelAction: Action = useMemo(
    () => ({
      title: t('Export To Excel'),
      order: 11,
      onClick: ({ selectedItems }) => onExport(selectedItems),
      display: () => isDescTop && isExportAllowed,
      name: 'export',
      Icon: ExcelExportIcon,
      type: ActionType.SYSTEM_ACTION,
      actionContext: ActionContext.ListPageAndSubGid,
      allowedDevices: AllowedDevices.All,
    }),
    [isDescTop, isExportAllowed, onExport, t]
  );

  return exportToExcelAction;
};

export const useExportToWord = (
  isWordExportAllowed: boolean,
  setLoadingAbsolute: React.Dispatch<React.SetStateAction<boolean>>,
  entityName: TEntityName
) => {
  const { exportToWord } = useApi();
  const { PrimaryIdAttribute, entityConfig, PrimaryNameAttribute } = useMetaData(entityName);
  const { fullName } = useContext(AuthContext);
  const addServerError = useServerError();
  const { t } = useTranslation();

  const onExport = useCallback(
    (selectedItems: Record<string, string>[]) => {
      setLoadingAbsolute(true);
      exportToWord(
        entityConfig.ObjectTypeCode,
        entityConfig.logicalName == 'bahai_inquirer'
          ? 'e37d68b1-cb27-ee11-9966-00224809cc4d'
          : '5d46c181-cc27-ee11-9966-00224809cc4d',
        selectedItems.map((v) => v[PrimaryIdAttribute] as string)
      )
        .then((resp: any) => {
          const byteCharacters = atob(resp.data.WordFile);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'text/docx' });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          const now = new Date();
          link.setAttribute(
            'download',
            `${selectedItems[0][PrimaryNameAttribute]} ${format(now, 'MM_dd_yyyy HH-mm-ss')} ${fullName}.docx`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          addServerError(e);
        })
        .finally(() => setLoadingAbsolute(false));
    },
    [
      PrimaryIdAttribute,
      PrimaryNameAttribute,
      addServerError,
      entityConfig.ObjectTypeCode,
      entityConfig.logicalName,
      exportToWord,
      fullName,
      setLoadingAbsolute,
    ]
  );

  const exportToWordAction: Action = useMemo(
    () => ({
      title: t('Export To Word'),
      onClick: ({ selectedItems }) => onExport(selectedItems),
      display: () => isWordExportAllowed,
      name: 'exportToWord',
      order: 10,
      Icon: WordExportIcon,
      type: ActionType.SYSTEM_ACTION,
      actionContext: ActionContext.All,
      allowedDevices: AllowedDevices.All,
    }),
    [isWordExportAllowed, onExport, t]
  );

  return exportToWordAction;
};
